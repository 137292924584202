<script>
  import { Button, Card, CardText, CardTitle, Col, Icon, Row, Snackbar } from 'svelte-materialify';
  import { mdiWindowClose } from '@mdi/js';
  import { _ } from '../services/i18n';
  import { accountInfo } from '../services/account';

  let show = false;
  let errorMessage = '';
  let errorURL = '';

  function closeButtonClicked(event) {
    show = false;
  }

  function networkErrorReceived(event) {
    errorMessage = event.detail.errorMessage;
    errorURL = event.detail.errorURL;
    show = true;
  }
</script>

<span id="network-error-manager" on:network-error={networkErrorReceived} />
{#if show}
  <Snackbar absolute center={true} bottom={true}>
    <Row>
      <Col cols={12}>
        <Card outlined={true} raised={true} style="width: 600px;">
          <CardTitle>
            <div class="" style="width:100%;">
              <span class="error-text text-h5">{$_('network-error.title')}</span>
              <span class="float-right">
                <Button
                  class=""
                  fab
                  size="x-small"
                  title={$_('network-error.close')}
                  on:click={closeButtonClicked}
                  text
                >
                  <Icon path={mdiWindowClose} />
                </Button>
              </span>
            </div>
          </CardTitle>
          <CardText>
            <div>{$_('network-error.message')}</div>
            <div class="error-text">{$_('network-error.error')} {errorMessage}</div>
            <div>{$_('network-error.request')} {errorURL}</div>
            <div>
              {$_('network-error.account')}
              {$accountInfo.email} - {$accountInfo.tenant.name}
            </div>
          </CardText>
        </Card>
      </Col>
    </Row>
  </Snackbar>/>
{/if}
