export function validateForm(formElement) {
  const messageElements = formElement.querySelectorAll("[slot='messages'] div > span");
  for (const element of messageElements) {
    const text = element.textContent;
    if (text.length > 0) {
      return false;
    }
  }
  return true;
}
