<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    ProgressLinear,
    Select,
  } from 'svelte-materialify';
  import { quill } from 'svelte-quill';
  import { accountInfo } from '../services/account';
  import { _ } from '../services/i18n';
  import { validateForm } from '../services/validation';
  import { apiFetch } from '../services/network';
  import TextField from './TextField.svelte';

  export let show;
  export let url;
  export let note = {
    name: '',
    state: 'private',
    markup: '',
    owner: $accountInfo,
  };
  export let readonly = false;
  $: saveButtonDisabled = readonly || !note.name || !note.state;
  let noteNameField;
  let noteStateField;
  let isFocused = false;

  afterUpdate(() => {
    if (!isFocused && !note.name) {
      noteNameField.focus();
      isFocused = true;
    }
  });

  const quillOptions = {
    modules: {
      toolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline'],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
      ],
    },
    placeholder: $_('notes.editor.placeholder'),
    theme: 'snow',
    readOnly: readonly, // ,
    // scrollingContainer: "editor-wrapper"
  };

  const selectSharedItems = [
    { name: $_('notes.table.state.private'), value: 'private' },
    { name: $_('notes.table.state.shared'), value: 'shared' },
  ];

  const dispatch = createEventDispatcher();

  let originalMarkup = '<p><br></p>';
  let updatedMarkup = '<p><br></p>';
  let formElement;

  async function load() {
    const response = await apiFetch(url);
    if (response.ok) {
      originalMarkup = await response.text();
      updatedMarkup = originalMarkup;
      return originalMarkup;
    }
  }

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function save(event) {
    event.preventDefault();
    if (!validateForm(formElement)) {
      return;
    }
    let method = 'POST';
    if (note.id) {
      method = 'PUT';
    }
    note.markup = updatedMarkup;
    const response = await apiFetch(url, method, note);
    if (response.ok) {
      show = false;
      const responsePayload = await response.json();
      dispatch('save', { responsePayload });
    }
  }

  function textChanged(event) {
    const { html } = event.detail;
    updatedMarkup = html;
  }
</script>

<svelte:head>
  <link href="//cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" />
</svelte:head>
<Dialog width={900} persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{$_('notes.editor.title')}</h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color mb-2" />
      <form bind:this={formElement} class="pt-4">
        <TextField
          {readonly}
          bind:value={note.name}
          bind:inputElement={noteNameField}
          outlined={true}
          dense
          color={'secondary'}
          required
        >
          {$_('notes.editor.note-name')}
        </TextField>
        {#if readonly || note.owner.id !== note.owner.id}
          <TextField
            {readonly}
            value={$_(`notes.table.state.${note.state}`)}
            bind:inputElement={noteStateField}
            outlined={true}
            dense
            color={'secondary'}
            required
          >
            {$_('notes.editor.state')}
          </TextField>
        {:else}
          <Select bind:value={note.state} outlined={true} dense items={selectSharedItems} mandatory>
            {$_('notes.editor.state')}
          </Select>
        {/if}
        {#if note.id}
          {#await load()}
            <ProgressLinear color="blue" backgroundColor="secondary" indeterminate />
          {:then}
            <div class="mt-2">
              <div class="editor" use:quill={quillOptions} on:text-change={textChanged}>
                {@html originalMarkup}
              </div>
            </div>
          {/await}
        {:else}
          <div class="mt-2">
            <div class="editor" use:quill={quillOptions} on:text-change={textChanged}>
              {@html originalMarkup}
            </div>
          </div>
        {/if}
        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          disabled={saveButtonDisabled}
          class="mt-4 {saveButtonDisabled ? 'grey-color' : 'secondary-color'}"
          on:click={save}
          text
        >
          {$_('notes.editor.save')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('notes.editor.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>

<style>
  :global(.ql-container.ql-snow) {
    border: none;
    height: 300px;
    overflow: scroll;
  }
</style>
