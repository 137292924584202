<script>
  /**
   * DateField combines a visible Materialify TextField with a hidden FlatPickr field.
   *  A calendar button is appended to the TextField. When it is clicked:
   *      - a click event is simulated on the hidden FlatPickr which causes it's popover
   *      calendar view picker to be presented. If the TextField has a YYYY-MM-DD formatted
   *      string in it, then it is converted to a Date object used by the FlatPickr to show that date
   *      in the popover.
   *      - When a calendar entry is chosen, from FlatPickr, then the Date is converted to a
   *      YYYY-MM-DD string and updated in the TextField
   *
   * Only numbers can be entered in the text field. This control attempts to automatically add
   * the dash characters as the user types. This is implemented in the beforeinput() and formatInput()
   * functions.
   *
   * Validation on blur is performed as follows:
   *  - is the value either empty or of the form 'YYYY-MM-DD'
   *  - if the required parameter is provided ensure the field is not empty
   *  - if the min paramter is provided ensure that the value does not preceed min
   *  - if the max paramter is proviced ensure that the value does not exceed max
   *  - any other validations specifed in the rules parameter
   *
   * The text of validation errors can be overridden in the errorMessages parameter.
   */
  import { createEventDispatcher, onDestroy } from 'svelte';
  import { Button, Icon, TextField } from 'svelte-materialify';
  import { mdiCalendarMonth } from '@mdi/js';
  import Flatpickr from 'svelte-flatpickr';
  import uid from 'svelte-materialify/src/internal/uid/index.js';
  import { _ } from '../services/i18n';

  const dispatch = createEventDispatcher();

  let klass = '';
  export { klass as class };
  export let value = '';
  export let color = 'primary';
  export let filled = false;
  export let solo = false;
  export let outlined = false;
  export let flat = false;
  export let dense = false;
  export let rounded = false;
  export let clearable = false;
  export let readonly = false;
  export let disabled = false;
  export let placeholder = null;
  export let hint = '';
  export let counter = false;
  export let messages = [];
  export let rules = [];
  export let errorCount = 1;
  export let error = false;
  export let success = false;
  export let id = `s-input-${uid(5)}`;
  export let style = null;
  export let inputElement = null;
  export let required = false;
  export let min = null;
  export let max = null;
  export let errorMessages = null;

  let date = value;
  const flatPickrId = `date-input-${uid(5)}`;

  // clean up the event listener we registered
  onDestroy(() => {
    if (inputElement !== null) {
      inputElement.removeEventListener('beforeinput', beforeinput);
    }
  });

  // callback that fires when the calendar button appended to the end of the TextField is clicked
  function calendarButtonClicked(event) {
    event.stopPropagation();
    const element = document.getElementById(flatPickrId);
    if (element) {
      if (value && value.length > 0) {
        date = new Date(`${value}T12:00:00Z`);
      }
      element.click();
    }
  }
  // callback that fires from the Calendar popup attached to the FlatPickr field
  function calendarDateChanged(event) {
    const d = event.detail[1];
    value = d;
    validate();
  }

  // when the TextField acquires focus, add a temporary placeholder if one has not been specified already
  let placeholderReplaced = false;
  function onfocus(event) {
    if (placeholder === null) {
      placeholder = 'YYYY-MM-DD';
      placeholderReplaced = true;
    }
    dispatch('focus', event.detail);
  }

  // when the TextField looses focus, validate the field and remove our temporary placeholder
  function validate() {
    if (placeholderReplaced) {
      placeholder = null;
      placeholderReplaced = false;
    }
    if (!readonly) {
      let message = '';
      for (const ruleFunc of combinedRules) {
        const ret = ruleFunc(value);
        if (typeof ret === 'string') {
          message = ret;
          error = true;
          break;
        }
      }
      if (message.length === 0) {
        messages = [];
        error = false;
      } else {
        messages = [message];
        error = true;
      }
    }
  }

  function onkeyup(event) {
    validate();
    dispatch('keyup', event.detail);
  }
  function onblur(event) {
    validate();
    dispatch('blur', event.detail);
  }

  // when input element changes and is not null, add a beforeinput listener that
  //  checks input and only allow digit characters
  $: {
    if (inputElement !== null) {
      inputElement.addEventListener('beforeinput', beforeinput);
    }
  }
  function beforeinput(event) {
    if (event.data && event.data.match(/\d/) === null) {
      event.preventDefault();
    }
  }

  // when TextField value changes, format the input
  $: {
    if (value != null) {
      formatInput();
    }
  }

  function formatInput() {
    // if the length is zero, then the field may have been reset. clear any errors
    switch (value.length) {
      case 0: {
        validate();

        break;
      }
      case 4: {
        const arr = value.match(/\d{4}/);
        if (arr && arr.length === 1) {
          value += '-';
        }

        break;
      }
      case 7: {
        const arr = value.match(/\d{4}-\d{2}/);
        if (arr && arr.length === 1) {
          value += '-';
        }

        break;
      }
      // No default
    }
  }

  // internal validation rules
  const myRules = [
    requiredRule,
    validFormatRule,
    validYearRule,
    validMonthRule,
    validDayRule,
    validMinRule,
    validMaxRule,
  ];

  // internal rules combined with rules provided by enclosing component
  let combinedRules = myRules.concat(rules);

  function getErrorMessage(keyStr) {
    if (errorMessages) {
      const message = errorMessages[keyStr];
      if (message) {
        return message;
      }
    }
    const message = $_(`defaultDateFieldErrorStrings.${keyStr}`);
    if (message) {
      return message;
    }
    return `error Message "${keyStr}" not found.`;
  }
  function requiredRule(v) {
    if (required && (!v || v.length === 0)) {
      return getErrorMessage('required');
    }
    return true;
  }

  function validFormatRule(v) {
    if (!v || v.length === 0) return true;
    const arr = value.match(/\d{4}(?:-\d{2}){2}/);
    if (arr && arr.length === 1) {
      return true;
    }
    return getErrorMessage('invalidDateFormat');
  }

  function validYearRule(v) {
    if (!v || v.length === 0) return true;
    const year = Number.parseInt(v.split('-')[0]);
    if (year >= 1900 && year <= 2100) {
      return true;
    }
    return getErrorMessage('invalidYear');
  }

  function validMonthRule(v) {
    if (!v || v.length === 0) return true;
    const mon = Number.parseInt(v.split('-')[1]);
    if (mon >= 1 && mon <= 12) {
      return true;
    }
    return getErrorMessage('invalidMonth');
  }

  function validDayRule(v) {
    if (!v || v.length === 0) return true;
    const day = Number.parseInt(v.split('-')[2]);
    if (day >= 1 && day <= 31) {
      const localDate = new Date(`${v}T12:00:00Z`);
      try {
        const str = localDate.toISOString();
        const dateStr = str.split('T')[0];
        if (dateStr === v) {
          return true;
        }
      } catch {
        return getErrorMessage('invalidDay');
      }
    }
    return getErrorMessage('invalidDay');
  }

  function validMinRule(v) {
    if (!v || v.length === 0 || min === null) return true;
    if (min) {
      try {
        const minDate = new Date(`${min}T12:00:00Z`);
        const localDate = new Date(`${v}T12:00:00Z`);
        minDate.toISOString();
        if (localDate.getTime() >= minDate.getTime()) {
          return true;
        }
      } catch {
        console.error(`DateField min ${min} invalid. Ignoring validation rule`);
        return true;
      }
    }
    return getErrorMessage('dateBefore') + min;
  }

  function validMaxRule(v) {
    if (!v || v.length === 0 || max === null) return true;
    if (max) {
      try {
        const maxDate = new Date(`${max}T12:00:00Z`);
        maxDate.toISOString();
        const localDate = new Date(`${v}T12:00:00Z`);
        if (localDate.getTime() <= maxDate.getTime()) {
          return true;
        }
      } catch {
        console.error(`DateField max ${max} invalid. Ignoring validation rule`);
        return true;
      }
    }
    return getErrorMessage('dateAfter') + max;
  }
</script>

<TextField
  class={klass}
  {color}
  {filled}
  {solo}
  {outlined}
  {flat}
  {dense}
  {rounded}
  {clearable}
  {readonly}
  {disabled}
  {hint}
  {counter}
  {messages}
  {errorCount}
  bind:error
  {success}
  {id}
  {style}
  bind:value
  on:focus={onfocus}
  on:blur={onblur}
  on:input
  on:change
  on:keypress
  on:keydown
  on:keyup={onkeyup}
  bind:placeholder
  bind:inputElement
>
  <slot />
  <span slot="append">
    {#if !readonly}
      <Button icon on:click={calendarButtonClicked}>
        <Icon path={mdiCalendarMonth} />
      </Button>
    {/if}
  </span>
</TextField>

<div class="float-left">
  <Flatpickr
    tabindex="-1"
    style="height: 0px;width: 0px;"
    bind:value={date}
    id={flatPickrId}
    on:change={calendarDateChanged}
  />
</div>
