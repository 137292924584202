<script>
  import { createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider } from 'svelte-materialify';
  import { _ } from '../../../services/i18n';
  import { apiFetch } from '../../../services/network';
  // import TextField from '../../TextField.svelte';

  const dispatch = createEventDispatcher();

  export let show;
  export let tenantId = -1;
  export let client;

  let debounceSearchTimer;
  const searchString = '';
  const searchResults = [];

  let formElement;

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function deleteClient(event) {
    event.preventDefault();

    let url = `/api/admin/client/${client.id}`;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const response = await apiFetch(url, 'DELETE');
    if (response.ok) {
      show = false;
      dispatch('delete', { id: client.id });
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('administration.clients.delete')}
        {$_('administration.clients.singular')} - {client.clientTenant.name}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <div>{$_('administration.clients.confirm-delete')}</div>
      <Divider class="mt-4 secondary-color" />
      <Button type="button" class="mt-4 error-color" on:click={deleteClient} text>
        {$_('administration.clients.delete')}
      </Button>
      <Button class="mt-4 ml-2" on:click={cancel} text>
        {$_('administration.clients.cancel')}
      </Button>
    </CardText>
  </Card>
</Dialog>
