<script>
  import { onDestroy, onMount } from 'svelte';
  import { mdiMicrosoftExcel } from '@mdi/js';
  import {
    ExpansionPanel,
    ExpansionPanels,
    Icon,
    List,
    ListItem,
    ProgressLinear,
    Snackbar,
  } from 'svelte-materialify';
  import { _, IP_SCOPE_ITEM, IP_TYPE_REPORT } from '../services/i18n';
  import {
    accountHasPermissionForGroupAndType,
    accountHasPermissionForItemAndType,
    GROUP_ALL,
    GROUP_CONTRACT,
    GROUP_PATENT,
    GROUP_READ,
    GROUP_TRADEMARK,
    ITEM_UPDATE,
  } from '../services/account';
  import { apiFetch, apiFormatUrl } from '../services/network';
  import FilesContainer from './FilesContainer.svelte';

  let showSnackBar = false;
  let snackBarText = '';

  onMount(() => {});

  onDestroy(() => {});

  let report = {};
  let attachments = [];
  let activePanels = [0, 1];
  let errorString = '';

  async function loadReports() {
    const url = `/api/${IP_TYPE_REPORT}`;
    const response = await apiFetch(url);
    if (response.ok) {
      const reports = await response.json();
      if (reports.length === 1) {
        report = reports[0];
        report.groups = [];
        await loadAttachments();
      } else {
        errorString = `There should be one report record defined for this tenant. There are ${reports.length}`;
      }
    }
  }

  async function loadAttachments() {
    const url = `/api/${IP_TYPE_REPORT}/${report.id}/attachment`;
    const response = await apiFetch(url);
    if (response.ok) {
      attachments = await response.json();
    }
  }

  async function generateContractReport() {
    const url = apiFormatUrl(`/api/report/contract`);
    const response = await apiFetch(`/contract`);
    let res;
    if (response.ok) {
      res = await response.json();
    }
    if (res.length > 0) {
      const element = document.createElement('a');
      element.href = url;
      element.setAttribute('download', '');
      element.setAttribute('tinro-ignore', '');
      element.click();
    } else if (res.length === 0) {
      showSnackBar = true;
      snackBarText = $_('report.noContracts');
    } else {
      showSnackBar = true;
      snackBarText = 'Error';
    }
  }

  async function generatePatentReport() {
    const url = apiFormatUrl(`/api/report/patent`);
    const response = await apiFetch(`/patent`);
    let res;
    if (response.ok) {
      res = await response.json();
    }
    if (res.length > 0) {
      const element = document.createElement('a');
      element.href = url;
      element.setAttribute('download', '');
      element.setAttribute('tinro-ignore', '');
      element.click();
    } else if (res.length === 0) {
      showSnackBar = true;
      snackBarText = $_('report.noPatents');
    } else {
      showSnackBar = true;
      snackBarText = 'Error';
    }
  }

  async function generateTrademarkReport() {
    const url = apiFormatUrl(`/api/report/trademark`);
    const response = await apiFetch(`/trademark`);
    let res;
    if (response.ok) {
      res = await response.json();
    }
    if (res.length > 0) {
      const element = document.createElement('a');
      element.href = url;
      element.setAttribute('download', '');
      element.setAttribute('tinro-ignore', '');
      element.click();
    } else if (res.length === 0) {
      showSnackBar = true;
      snackBarText = $_('report.noTrademarks');
    } else {
      showSnackBar = true;
      snackBarText = 'Error';
    }
  }
</script>

<h5 class="secondary-text text-left">{$_('report.title')}</h5>
{#if errorString.length > 0}
  <div class="pr-2 pt-2 pb-2 text-left error-text">{errorString}</div>
{/if}

{#await loadReports()}
  <ProgressLinear />
{:then}
  <ExpansionPanels class="pt-2" multiple bind:value={activePanels}>
    <ExpansionPanel>
      <div slot="header" class="secondary-text">
        <Icon class="secondary-text" path={mdiMicrosoftExcel} />
        {$_('report.portfolio-reports.title')}
      </div>
      <List>
        {#if accountHasPermissionForGroupAndType(GROUP_READ, GROUP_ALL, GROUP_CONTRACT)}
          <ListItem on:click={generateContractReport}>
            <span class="secondary-text" slot="prepend">
              <p>{$_('report.portfolio-reports.contract-report')}</p>
            </span>
          </ListItem>
        {:else}
          <ListItem disabled={true}>
            <span class="secondary-text link-disabled" slot="prepend">
              <p color="grey">{$_('report.portfolio-reports.contract-report')}</p>
            </span>
          </ListItem>
        {/if}
        {#if accountHasPermissionForGroupAndType(GROUP_READ, GROUP_ALL, GROUP_PATENT)}
          <ListItem on:click={generatePatentReport}>
            <span class="secondary-text" slot="prepend">
              <p>{$_('report.portfolio-reports.patent-report')}</p>
            </span>
          </ListItem>
        {:else}
          <ListItem disabled={true}>
            <span class="secondary-text link-disabled" slot="prepend">
              <p color="grey">{$_('report.portfolio-reports.patent-report')}</p>
            </span>
          </ListItem>
        {/if}
        {#if accountHasPermissionForGroupAndType(GROUP_READ, GROUP_ALL, GROUP_TRADEMARK)}
          <ListItem on:click={generateTrademarkReport}>
            <span class="secondary-text" slot="prepend">
              <p>{$_('report.portfolio-reports.trademark-report')}</p>
            </span>
          </ListItem>
        {:else}
          <ListItem disabled={true}>
            <span class="secondary-text link-disabled" slot="prepend">
              <p>{$_('report.portfolio-reports.trademark-report')}</p>
            </span>
          </ListItem>
        {/if}
      </List>
    </ExpansionPanel>

    <FilesContainer
      ipType={IP_TYPE_REPORT}
      ipScope={IP_SCOPE_ITEM}
      scopeId={report.id}
      readonly={!accountHasPermissionForItemAndType(ITEM_UPDATE, report, IP_TYPE_REPORT)}
      bind:attachments
    />
  </ExpansionPanels>
{/await}
<!--ul class="pt-4">
        <li class="pt-2 pb-2 pr-r text-h6"><Icon class="secondary-text" path={mdiSitemap}/><span class="pl-2">{$_('reports.company.title')}</span></li>    
        <li class="pt-2 pb-2 pr-r text-h6"><Icon class="secondary-text" path={mdiShareVariant}/><span class="pl-2">{$_('reports.projects.title')}</span></li>    
        <li class="pt-2 pb-2 pr-r text-h6"><Icon class="secondary-text" path={mdiChartTimeline}/><span class="pl-2">{$_('reports.workflow.title')}</span></li>
        <li class="pt-2 pb-2 pr-r text-h6"><Icon class="secondary-text" path={mdiClipboardCheck}/><span class="pl-2">{$_('reports.dueDiligence.title')}</span></li>
    </ul-->

{#if showSnackBar}
  <Snackbar class="flex-column" bind:active={showSnackBar} timeout={2000} top right>
    <div>{snackBarText}</div>
  </Snackbar>
{/if}

<style>
  ul {
    list-style-type: none;
  }
  .link-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
</style>
