<script>
  import jstz from 'jstz';
  import { afterUpdate, createEventDispatcher, onDestroy, onMount, tick } from 'svelte';
  import { mdiLogin } from '@mdi/js';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Checkbox,
    Col,
    Container,
    Dialog,
    Divider,
    Icon,
    Row,
    TextField,
  } from 'svelte-materialify';
  import { apiFetch } from '../services/network';
  import { accountInfo, authenticated } from '../services/account';
  import { _ } from '../services/i18n';

  const dispatch = createEventDispatcher();
  const timezone = jstz.determine();
  export let email = '';
  export let password = '';
  export let phoneNumber;
  export let sessionExpired = false;
  let showResetPasswordTooltip = false;
  let errorString = '';
  let rememberMe = initRememberMe();
  let signInEmailField;
  let signInPasswordField;
  export let redirect = true;
  let isFocused = false;

  const timeoutId = null;

  $: signInButtonDisabled = !email || !password;

  onMount(() => {
    errorString = '';
    if (sessionExpired) {
      errorString = $_('signIn.fail.sessionExpired');
    }
  });

  afterUpdate(() => {
    if (!isFocused) {
      if (!email) {
        signInEmailField.focus();
        isFocused = true;
      } else if (!password) {
        signInPasswordField.focus();
        isFocused = true;
      }
    }
  });

  onDestroy(async () => {});

  function initRememberMe() {
    let value = localStorage.getItem('remember-me');
    if (value !== null) {
      value = value === 'true';
      if (value === true) {
        email = localStorage.getItem('email');
      }
      return value;
    }
    return false;
  }
  export function toggleRememberMe(event) {
    rememberMe = !rememberMe;
  }
  export function resetPassword(event) {
    dispatch('getEmail');
  }
  function showTooltip(event) {
    showResetPasswordTooltip = !showResetPasswordTooltip;
  }

  async function signInFormSubmitted(event) {
    errorString = '';
    const url = `/authentication/signin`;
    const request = {
      email,
      password,
      phoneNumber,
      timezone: timezone.name(),
    };
    const response = await apiFetch(url, 'POST', request);
    const signinReply = await response.json();
    if (response.ok) {
      const params = new URL(document.location).searchParams;
      const redirectUrl = params.get('redirectUrl');
      // Only redirect to known URLs (should be returned as part of response from backend)
      if (redirectUrl) {
        // parse redirectUrl and confirm it's a known URL
        const parsedRedirectUrl = decodeURI(window.atob(redirectUrl));
        // retrieve redirect host without the subdomain
        const redirectUrlHost = new URL(parsedRedirectUrl).hostname.split('.').slice(1).join('.');
        if (import.meta.env.FORTRESS_V2_FRONTEND_URL?.includes(redirectUrlHost)) {
          window.location.replace(parsedRedirectUrl);
        }
      }
      errorString = '';
      dispatch('signedIn');
      await tick();
      accountInfo.update((info) => signinReply);
      authenticated.update((auth) => true);
      dispatch('resetLogin');
      localStorage.setItem('remember-me', rememberMe.toString());
      localStorage.setItem('email', email);
    } else {
      if (response.status === 401) {
        errorString = $_('signIn.fail.unauthorized');
        authenticated.update((auth) => false);
      } else if (response.status === 403) {
        errorString = $_('signIn.fail.suspended');
        authenticated.update((auth) => false);
        localStorage.setItem('remember-me', rememberMe.toString());
        localStorage.setItem('email', email);
      } else if (response.status === 409 && signinReply.reason === 'mustChangePassword') {
        dispatch('needPasswordChange');
        localStorage.setItem('remember-me', rememberMe.toString());
        localStorage.setItem('email', email);
      } else if (response.status === 409 && signinReply.reason === 'mustProvidePhoneNumber') {
        dispatch('needOTPPhoneNumber');
        localStorage.setItem('remember-me', rememberMe.toString());
        localStorage.setItem('email', email);
      } else if (response.status === 409 && signinReply.reason === 'mustProvideOneTimePassword') {
        dispatch('needOTP', { phoneNumberVerified: signinReply.phoneNumberVerified });
        localStorage.setItem('remember-me', rememberMe.toString());
        localStorage.setItem('email', email);
      } else {
        errorString = $_('signIn.fail.other');
        authenticated.update((auth) => false);
      }

      return response;
    }
  }
</script>

<div id="signinDialog">
  <Dialog persistent={true} active={true}>
    <Card outlined={true} raised={true}>
      <CardTitle>
        <div class="d-flex align-center">
          <img
            src="/images/logos/Fortress_RGB.png"
            class="logo"
            alt="{$_('app.name')} logo"
            title={$_('app.name')}
          />
        </div>
      </CardTitle>
      <CardText>
        <Divider class="secondary-color" />
        <h5 class="pt-2 text-center">{$_('signIn.banner')}</h5>
        <div class="text-center">
          {$_('signIn.or')}
        </div>
        <div class="text-center">
          <a href="https://fortressiam.com/contact" tinro-ignore target="_blank">
            {$_('signIn.signUp')}</a
          >
          <span> {$_('signIn.freeTrial')}</span>
        </div>
        <div>
          <p />
          <form on:submit|preventDefault={signInFormSubmitted}>
            <TextField
              type="email"
              outlined={true}
              clearable={true}
              color={'secondary'}
              placeholder={$_('signIn.emailAddress')}
              bind:value={email}
              bind:inputElement={signInEmailField}
            >
              {$_('signIn.emailAddress')}
            </TextField>
            <TextField
              type="password"
              outlined={true}
              clearable={true}
              color={'secondary'}
              placeholder={$_('signIn.password')}
              bind:value={password}
              bind:inputElement={signInPasswordField}
            >
              {$_('signIn.password')}
            </TextField>
            <Container>
              <Row>
                <Col class="align-self-start">
                  <Checkbox color="secondary" checked={rememberMe} on:change={toggleRememberMe}>
                    {$_('signIn.rememberMe')}
                  </Checkbox>
                </Col>
                <Col class="align-self-end">
                  <Button class="secondary-color" size="x-small" outlined on:click={resetPassword}>
                    <!--resetPassword-->
                    {$_('signIn.resetPassword')}
                  </Button>
                  <!--Tooltip top bind:active={showResetPasswordTooltip}>
                      <span slot="tip"><Icon path={mdiEmailOutline} />{$_('signIn.resetPasswordTooltip')}</span>
                    </Tooltip-->
                </Col>
              </Row>
            </Container>
            <div class="error-text">
              {errorString}
            </div>
            <br />
            {#if redirect}
              <Button
                block
                class={signInButtonDisabled ? 'grey-color' : 'secondary-color'}
                type="submit"
                disabled={signInButtonDisabled}
                color="secondary"
              >
                <Icon
                  path={mdiLogin}
                  class={signInButtonDisabled ? 'grey-color' : 'secondary-color'}
                />{$_('signIn.signIn')}
              </Button>
            {:else}
              <Button
                block
                class={signInButtonDisabled ? 'grey-color' : 'secondary-color'}
                type="submit"
                disabled={signInButtonDisabled}
                color="secondary"
              >
                <Icon path={mdiLogin} class="mr-3" />{$_('signIn.signIn')}
              </Button>
            {/if}
          </form>
        </div>
      </CardText>
    </Card>
  </Dialog>
</div>
