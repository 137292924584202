<script context="module" lang="ts">
  export enum PermissionStatusType {
    STATUS = 'status',
    ERRORS = 'errors',
  }

  export const permissionStatusStore = writable({ inProgress: false, status: {} });
  export function updatePermissionStatus(userId, statusType, message) {
    permissionStatusStore.update((previous) => {
      if (!(userId in previous.status)) {
        previous.status[userId] = {};
      }
      previous.status[userId][statusType] = message;
      return previous;
    });
  }
</script>

<script>
  import { mdiPlus } from '@mdi/js';
  import { createEventDispatcher, getContext, onDestroy } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    ExpansionPanels,
    Icon,
    List,
    ListItem,
    Menu,
    ProgressLinear,
  } from 'svelte-materialify';
  import { writable } from 'svelte/store';
  import { GROUP_PERMISSION_SETS } from '../../../services/account';
  import { _ } from '../../../services/i18n';
  import { apiFetch, defaultHeaders } from '../../../services/network';
  import AddFamilyPermissionsEntityGroup from './AddFamilyPermissionsEntityGroup.svelte';
  import ApplyPermissionsToUsersDialogV2 from './ApplyPermissionsToUsersDialogV2.svelte';

  const dispatch = createEventDispatcher();
  const tenant = getContext('tenantInfo');
  export let show;

  // Used to show the dialog.  This is needed because the "add to multiple users" dialog is a
  //  child of this dialog, but we want the first dialog hidden when we show the "multiple users"
  //  dialog.
  $: active = show;
  export let userId;
  export let selectedEntities = [];
  export let errorString = '';
  export let showSnackBar = false;
  let allPermissions = [];
  let entities = [];
  let showApplyPermissionsToUsersDialog = false;
  $: showProgressOverlay = $permissionStatusStore.inProgress;

  $: invalidPermissions = allPermissions.filter((perm) => !perm.ipType || !perm.families);
  $: saveButtonDisabled = allPermissions.length == 0 || invalidPermissions.length > 0;
  $: chooseConnectedEntityMenuDisabled = selectedEntities.length == entities.length;

  onDestroy(() => {
    selectedEntities = [];
    allPermissions = [];
    showProgressOverlay = false;
    $permissionStatusStore = { inProgress: false, status: {} };
  });

  // using function in case additional steps need to be performed
  function cancel() {
    show = false;
  }

  async function loadEntities() {
    let url = `/api/admin/client`;
    if (tenant.id > -1) {
      url += `?tenantId=${tenant.id}`;
    }
    let response = await apiFetch(url);
    if (response.ok) {
      entities = await response.json();
      // Ensure entities (and selected entities) are populated in the various scenarios (tenant has
      //  or does not have a connected entity) to simplify markup
      entities = entities.length > 0 ? entities : [tenant];
      if (entities.length == 1) {
        selectedEntities = [...entities];
      }
    }
  }

  async function handleSave(event) {
    let _users = event.detail.selectedUsers ?? [parseInt(userId)];
    let _perms = event.detail.permissions ?? allPermissions;
    _perms = _perms.filter((p) => p.ipType.length > 0);

    await saveAllPermissions(_users, _perms);
  }

  async function saveAllPermissions(_users, _permissions) {
    console.log('in save');
    errorString = '';
    $permissionStatusStore.inProgress = true; // reset the permission status store

    // -- Iterate through list of users first
    for (let selectedUserId of _users) {
      updatePermissionStatus(selectedUserId, PermissionStatusType.STATUS, 'Updating permissions');
      // Call endpoint to get the user's permissions.  Will need them to check existing, and
      //   update (via permission.id) if existing permission needs to be updated
      let usersGroupPermissionsUrl = `/api/user/${selectedUserId}/group-access-permission?tenantId=${tenant.id}`;
      let usersGroupPermissionsResponse = await apiFetch(
        usersGroupPermissionsUrl,
        'GET',
        null,
        defaultHeaders,
        true
      );
      let usersGroupPermissions = await usersGroupPermissionsResponse.json();

      // -- Then list of permissions
      for (let perm of _permissions) {
        // Then the families within that permission
        for (let fam of perm.families) {
          let tid = -1;
          if (perm.entityId != tenant.id) {
            tid = perm.entityId;
          } else if (tenant.id > -1) {
            tid = tenant.id;
          }
          // 1. check if the user has a permission for the iptype + group
          // 1a. if they do, and permSet is same, skip
          // 1b. if they do, and permSet is different, update
          // 1c. if they don't, create

          let existingPermission = usersGroupPermissions.find((ugp) => {
            return (
              ugp.group.id == fam.value &&
              ugp.group.groupType == perm.ipType &&
              ugp.tenant.id == tid
            );
          });
          let method = 'POST';
          let url = `/api/user/${selectedUserId}/group-access-permission`;

          if (existingPermission) {
            // User has a permission set for the group.  Check if has all rights for permSet
            if (
              arraySymmetricDifference(
                GROUP_PERMISSION_SETS[perm.permissionType.type],
                existingPermission.rights
              ).length === 0
            ) {
              // Permission is the same
              console.log(
                `User ${selectedUserId} already has ${perm.permissionType.type} permission on group ${fam.value}`
              );
              continue;
            }

            // Permission is different, so need to update
            method = 'PUT';
            url += `/${existingPermission.id}/${perm.permissionType.type}`;
          } else {
            // Permission doesn't exist, so need to create it
            method = 'POST';
            url += `/${perm.ipType}/${fam.value}/${perm.permissionType.type}`;
          }

          // -- add tenantId to URL, if required
          if (tid > -1) {
            url += `?tenantId=${tid}`;
          }
          console.log(`url: ${url}`);

          let response = await apiFetch(url, method, null, defaultHeaders, true);
          if (response.ok) {
            let item = await response.json();
            item.userId = selectedUserId;
            if (response.status == 201) {
              updatePermissionStatus(
                selectedUserId,
                PermissionStatusType.STATUS,
                'Permission added'
              );
              dispatch('save', { item: item });
            } else if (response.status == 200) {
              updatePermissionStatus(
                selectedUserId,
                PermissionStatusType.STATUS,
                'Permissions updated'
              );
              dispatch('updated', item);
            }
          } else if (response.status == 409) {
            console.log(
              `user ${selectedUserId} already has permission ${perm.permissionType.type} on ${perm.ipType} family id ${fam.value}`
            );
          } else {
            let err = await response.json();
            updatePermissionStatus(selectedUserId, PermissionStatusType.STATUS, '');
            updatePermissionStatus(
              selectedUserId,
              PermissionStatusType.ERRORS,
              'Error updating permissions'
            );
          }
        }
      }
    }
    $permissionStatusStore.inProgress = false;

    // Hide dialog if everything completed successfully
    let permStatus = $permissionStatusStore.status;

    // remove the "inProgress" key
    let psKeys = Object.keys(permStatus).filter((k) => k != 'inProgress');

    // check if any entries have errors
    if (!psKeys.some((k) => permStatus[k][PermissionStatusType.ERRORS]?.length > 0)) {
      // no errors
      errorString = $_('administration.users.group-permissions.status.success');
      showSnackBar = true;
      show = false;
    } else {
      errorString = $_('administration.users.group-permissions.add-dialog.error');
      showSnackBar = true;
    }
  }

  function selectEntity(entity) {
    selectedEntities.push(entity.clientTenant);
    // So that svelte picks up the change to the array
    selectedEntities = selectedEntities;
  }

  function isListItemDisabled(entity) {
    return selectedEntities.includes(entity.clientTenant);
  }

  /**
   * Find the difference between the two simple arrays
   * @param array1
   * @param array2
   * @returns the resulting array, containing all of the items that exist in one
   *   of the parameter arrays, but noth both
   */
  function arraySymmetricDifference(array1, array2) {
    let difference = [];
    difference.push(...array1.filter((a1) => !array2.includes(a1)));
    difference.push(...array2.filter((a2) => !array1.includes(a2)));
    return difference;
  }
</script>

<svelte:body
  on:keyup={(event) => {
    // Used to close dialog on escape
    if (event.keyCode == 27) {
      cancel();
    }
  }}
/>

<Dialog width={900} persistent={true} bind:active>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('administration.users.group-permissions.add-dialog.title')}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      {#await loadEntities()}
        <ProgressLinear />
      {:then}
        {#if entities.length > 1}
          <div class="d-flex justify-end">
            <Menu disabled={chooseConnectedEntityMenuDisabled} closeOnClick>
              <div slot="activator">
                <Button
                  class={chooseConnectedEntityMenuDisabled
                    ? 'mt-2 disabled'
                    : 'mt-2 secondary-color'}
                  disabled={chooseConnectedEntityMenuDisabled}
                  size="small"
                >
                  <Icon class="mr-1" size="20" path={mdiPlus} />
                  {$_(
                    'administration.users.group-permissions.add-dialog.entity-group.choose-connected-entity'
                  )}
                </Button>
              </div>
              <List dense>
                {#each entities as entity}
                  <ListItem
                    class={isListItemDisabled(entity) ? 'grey-text' : ''}
                    disabled={isListItemDisabled(entity)}
                    on:click={selectEntity(entity)}>{entity.clientTenant.name}</ListItem
                  >
                {/each}
              </List>
            </Menu>
          </div>
        {/if}
        <ExpansionPanels>
          {#each selectedEntities as entity, index (entity.id)}
            <AddFamilyPermissionsEntityGroup
              showRemoveEntity={entities.length > 1}
              {entity}
              bind:allPermissions
              bind:selectedEntities
            />
          {/each}
        </ExpansionPanels>
        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          on:click={handleSave}
          disabled={saveButtonDisabled}
          class="mt-4 {!saveButtonDisabled ? 'secondary-color' : 'grey-color'}"
        >
          {$_('administration.users.save')}
        </Button>
        <Button
          on:click={(_) => (showApplyPermissionsToUsersDialog = true)}
          disabled={saveButtonDisabled}
          class="mt-4 {!saveButtonDisabled ? 'secondary-color' : 'grey-color'}"
        >
          {$_('administration.users.apply-to-multiple-users')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('administration.users.cancel')}
        </Button>
      {/await}
    </CardText>
  </Card>
</Dialog>

{#if showApplyPermissionsToUsersDialog}
  <ApplyPermissionsToUsersDialogV2
    bind:show={showApplyPermissionsToUsersDialog}
    tenantId={tenant.id}
    {userId}
    {selectedEntities}
    bind:permissions={allPermissions}
    bind:errorString
    bind:showSnackBar
    on:save={handleSave}
    on:cancelAddToMultupleUsers={() => (showApplyPermissionsToUsersDialog = false)}
  />
{/if}
