<script>
  import { router } from 'tinro';
  import { Button, Icon, ProgressLinear, Snackbar, TextField } from 'svelte-materialify';
  import { mdiContentCopy, mdiPlus } from '@mdi/js';
  import {
    accountHasTenantPermission,
    PER_TENANT_USER_ADMIN,
    SYSTEM_WIDE_USER_ADMIN,
  } from '../../../services/account';
  import { _ } from '../../../services/i18n';
  import AddOrUpdateUser from './AddOrUpdateUser.svelte';
  import ChangePassword from './ChangePassword.svelte';
  import ReactivateUser from './ReactivateUser.svelte';
  import DeleteUser from './DeleteUser.svelte';
  import ResetPhoneNumber from './ResetPhoneNumber.svelte';
  import DataTable from '../../DataTable.svelte';

  export let tenantId = -1;
  export let columnDefs = [];
  export let users = [];
  let suspendedUsers = [];
  let showNewUserDialog = false;
  let showEditUserDialog = false;
  let showChangePasswordDialog = false;
  let showSuspendUserDialog = false;
  let showReactivateUserDialog = false;
  let showDeleteUserDialog = false;
  let showNewPasswordNotice = false;
  let showResetPhoneNumberDialog = false;
  let selectedUser = {};
  let newPasswordNoticeText = '';
  let suspendedUsersTable;

  export let rowActions = [];

  const linkActionsSuspended = [
    {
      enabledFunction: isActionEnabled,
      href: `/administration/tenant/${tenantId}/user/`,
      title: $_('administration.clients.open'),
      onClickEvent: 'userPermissionsClicked',
      elementId: 'suspended-users',
      getNameFunction: getName,
    },
  ];

  const globalActions = [
    {
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.clients.new'),
      path: mdiPlus,
      onClickEvent: 'newUserClicked',
      elementId: `suspended-users`,
    },
  ];

  function isActionEnabled(item) {
    return (
      accountHasTenantPermission(SYSTEM_WIDE_USER_ADMIN) ||
      accountHasTenantPermission(PER_TENANT_USER_ADMIN)
    );
  }

  function getName(item) {
    return item.email;
  }

  async function load() {
    suspendedUsers = users.filter((i) => i.suspended === true);
  }

  function newUserClicked() {
    showNewUserDialog = true;
  }

  function findUserById(id) {
    return users.find((item) => item.id === id);
  }

  function userPermissionsClicked(event) {
    const { id, action } = event.detail;
    let url = `/administration/user/${id}`;
    if (tenantId > -1) {
      url = action.href + id;
    }
    router.goto(url);
  }

  function editUserClicked(event) {
    selectedUser = findUserById(event.detail.id);
    showEditUserDialog = true;
  }

  function changeUserPasswordClicked(event) {
    selectedUser = findUserById(event.detail.id);
    showChangePasswordDialog = true;
  }

  function resetUserPhoneNumberClicked(event) {
    selectedUser = findUserById(event.detail.id);
    showResetPhoneNumberDialog = true;
  }

  function suspendUserClicked(event) {
    selectedUser = findUserById(event.detail.id);
    showSuspendUserDialog = true;
  }

  function reactivateUserClicked(event) {
    selectedUser = findUserById(event.detail.id);
    showReactivateUserDialog = true;
  }

  async function userUpdated(event) {
    const item = event.detail;
    suspendedUsersTable.updateRow(item.item.id);
  }

  async function userAdded(event) {
    const { item } = event.detail;
    users.push(item);
    if (item.suspended) {
      suspendedUsers.push(item);
    }
    if (item.suspended) {
      suspendedUsersTable.addNewRows([item]);
    } else {
      const eventDetail = { item };
      const customEvent = new CustomEvent('userReactivated', { detail: eventDetail });
      const eventElement = document.querySelector('#active-users');
      eventElement.dispatchEvent(customEvent);
    }
  }

  function userSuspended(event) {
    console.log('In suspended users user suspended');
    suspendedUsers.push(event.detail.item);
    console.log(`suspended users ${JSON.stringify(suspendedUsers, null, 2)}`);
    console.log(`users ${JSON.stringify(users, null, 2)}`);
    for (const user of users) {
      if (user.id == event.detail.item.id) {
        suspendedUsersTable.addNewRows([user]);
        break;
      }
    }
  }

  function userReactivated(event) {
    console.log('In suspended user user reactivated');
    const { item } = event.detail;
    console.log(`Item ${JSON.stringify(item, null, 2)}`);
    console.log(`suspendedUsers ${JSON.stringify(suspendedUsers, null, 2)}`);
    // set the user as not suspended in the data
    for (const user of users) {
      if (user.id == item.id) {
        user.suspended = false;
        break;
      }
    }
    suspendedUsers = users.filter((i) => i.suspended === true);
    // remove the user from the list of suspended users and update the table
    suspendedUsersTable.deleteRows(item.id);
    for (let j = 0; j < suspendedUsers.length; j++) {
      if (suspendedUsers[j].id == item.id) {
        suspendedUsers.splice(j, 1);
        break;
      }
    }
    console.log(`suspendedUsers ${JSON.stringify(suspendedUsers, null, 2)}`);
    const eventDetail = { item };
    const customEvent = new CustomEvent('userReactivated', { detail: eventDetail });
    const eventElement = document.querySelector('#active-users');
    eventElement.dispatchEvent(customEvent);
  }

  function copyToClipboardClicked(event) {
    navigator.clipboard.writeText(newPasswordNoticeText);
  }
</script>

{#await load()}
  <ProgressLinear />
{:then}
  <div
    id="suspended-users"
    on:newUserClicked={newUserClicked}
    on:userAdded={userAdded}
    on:userSuspended={userSuspended}
    on:userReactivated={userReactivated}
    on:load={load}
  >
    <DataTable
      bind:rowData={suspendedUsers}
      bind:this={suspendedUsersTable}
      {columnDefs}
      {rowActions}
      {globalActions}
      linkActions={linkActionsSuspended}
      tableName="users"
      gridId="suspendedusers"
      on:userPermissionsClicked={userPermissionsClicked}
      on:editUserClicked={editUserClicked}
      on:changeUserPasswordClicked={changeUserPasswordClicked}
      on:resetUserPhoneNumberClicked={resetUserPhoneNumberClicked}
      on:reactivateUserClicked={reactivateUserClicked}
      on:suspendUserClicked={suspendUserClicked}
    />
  </div>
{/await}

{#if showNewUserDialog}
  <AddOrUpdateUser bind:show={showNewUserDialog} {tenantId} on:save={userAdded} />
{/if}
{#if showEditUserDialog}
  <AddOrUpdateUser
    bind:show={showEditUserDialog}
    {tenantId}
    user={selectedUser}
    on:save={userUpdated}
  />
{/if}
{#if showChangePasswordDialog}
  <ChangePassword
    bind:show={showChangePasswordDialog}
    {tenantId}
    user={selectedUser}
    on:save={userUpdated}
  />
{/if}
<!-- {#if showSuspendUserDialog}
        <SuspendUser bind:show={showSuspendUserDialog} tenantId={tenantId} user={selectedUser} on:save={userUpdated}/>
    {/if} -->
{#if showReactivateUserDialog}
  <ReactivateUser
    bind:show={showReactivateUserDialog}
    {tenantId}
    user={selectedUser}
    on:save={userReactivated}
  />
{/if}
{#if showDeleteUserDialog}
  <DeleteUser
    bind:show={showDeleteUserDialog}
    {tenantId}
    user={selectedUser}
    on:save={userUpdated}
  />
{/if}
{#if showResetPhoneNumberDialog}
  <ResetPhoneNumber
    bind:show={showResetPhoneNumberDialog}
    {tenantId}
    user={selectedUser}
    on:save={userUpdated}
  />
{/if}
{#if showNewPasswordNotice}
  <Snackbar class="flex-column" bind:active={showNewPasswordNotice} center>
    <div class="pa-4" style="width: 550px;background-color: white;">
      <div class="mt-4">
        <TextField
          bind:value={newPasswordNoticeText}
          outlined={true}
          dense
          color={'secondary'}
          readonly
        >
          {$_('administration.users.account-info-title')}
          <span slot="append">
            <Button
              icon
              on:click={copyToClipboardClicked}
              title={$_('administration.users.copy-to-clipboard')}
            >
              <Icon path={mdiContentCopy} />
            </Button>
          </span>
        </TextField>
      </div>
      <div class="pt-2 d-flex justify-center">
        <Button
          class="secondary-color"
          title={$_('administration.users.dismiss')}
          on:click={() => {
            showNewPasswordNotice = false;
          }}
          text
        >
          {$_('administration.users.dismiss')}
        </Button>
      </div>
    </div>
  </Snackbar>
{/if}

<style>
  table {
    border: 1px solid white;
    border-collapse: collapse;
  }

  thead > th:first-child {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }

  thead > th:last-child {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }

  th {
    border: 1px solid white;
    height: 41px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #1a76d2;
    color: white;
  }

  td {
    padding: 4px;
    border-bottom: 1px solid #1a76d2;
  }
</style>
