<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    ProgressLinear,
    Select,
  } from 'svelte-materialify';
  import {
    _,
    IP_SCOPE_ITEM,
    IP_TYPE_CONTRACT,
    IP_TYPE_PATENT,
    IP_TYPE_TRADEMARK,
  } from '../../../services/i18n';
  import { apiFetch } from '../../../services/network';
  import ApplyPermissionsToUsersDialog from './ApplyPermissionsToUsersDialog.svelte';

  const dispatch = createEventDispatcher();
  export let show;
  export let tenantId = -1;
  export let userId;

  let showApplyPermissionsToUsersDialog = false;

  let errorString = '';
  let ipTypeError = false;
  let permissionSetError = false;
  let formElement;
  let clients = [];
  let clientItems = [];
  let clientSelected = false;
  let selectedClientIndex = null;

  $: {
    if (selectedClientIndex !== null) {
      clientSelected = true;
    }
  }
  $: saveButtonDisabled = permissionSet.length === 0 || !itemId || !ipType;

  let ipType = '';
  const ipTypeItems = [
    { name: IP_TYPE_PATENT, value: IP_TYPE_PATENT },
    { name: IP_TYPE_TRADEMARK, value: IP_TYPE_TRADEMARK },
    { name: IP_TYPE_CONTRACT, value: IP_TYPE_CONTRACT },
  ];

  let itemId;
  let itemItems = [{ name: 'all', value: new Number(0) }];

  let permissionSet = '';
  const permissionSetItems = [
    { name: $_('administration.users.item-permissions.permission-set.create'), value: 'create' },
    { name: $_('administration.users.item-permissions.permission-set.edit'), value: 'edit' },
    { name: $_('administration.users.item-permissions.permission-set.view'), value: 'view' },
  ];

  onMount(() => {});

  async function loadClients() {
    let url = `/api/admin/client`;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const response = await apiFetch(url);
    if (response.ok) {
      clients = await response.json();
      let index = 0;
      for (const client of clients) {
        clientItems.push({ name: client.clientTenant.name, value: new Number(index) });
        index++;
      }
      clientItems = clientItems;
    }
  }

  async function ipTypeChanged(event) {
    let url = `/api/user/${ipType}/item`;
    if (clientSelected) {
      url += `?tenantId=${clients[selectedClientIndex].clientTenant.id}`;
    } else if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const response = await apiFetch(url);
    if (response.ok) {
      const items = await response.json();
      itemId = undefined;
      itemItems = [{ name: 'all', value: new Number(0) }];
      for (const item of items) {
        itemItems.push({ name: item.name, value: item.id });
      }
    }
  }

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function save(event) {
    event.preventDefault();
    if (ipType.length === 0) {
      ipTypeError = true;
      return;
    }
    ipTypeError = false;
    if (permissionSet.length === 0) {
      permissionSetError = true;
      return;
    }
    permissionSetError = false;

    let url = `/api/user/${userId}/item-access-permission/${ipType}/${itemId}/${permissionSet}`;
    if (clientSelected) {
      url += `?tenantId=${clients[selectedClientIndex].clientTenant.id}`;
    } else if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const response = await apiFetch(url, 'POST');
    if (response.ok) {
      show = false;
      const item = await response.json();
      dispatch('save', { permissions: [item] });
    } else if (response.status === 409) {
      const err = await response.json();
      errorString = err.reason;
    }
  }

  async function applyToMultipleUsers(event) {
    showApplyPermissionsToUsersDialog = true;
  }

  async function permissionsApplied(event) {
    const { permissions } = event.detail;
    dispatch('save', { permissions });
  }

  function dismiss(event) {
    const { errorCount } = event.detail;
    if (errorCount == 0) {
      show = false;
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{$_('administration.users.item-permissions.add-dialog.title')}</h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      {#await loadClients()}
        <ProgressLinear />
      {:then}
        {#if clientItems.length > 0}
          <Select
            outlined
            dense
            items={clientItems}
            bind:value={selectedClientIndex}
            class="pt-4"
            mandatory
          >
            {$_('administration.other-entity-users.choose-connected-entity')}
          </Select>
        {/if}
        <form bind:this={formElement} class="pt-4">
          {#if clientSelected || clientItems.length === 0}
            <Select
              outlined
              dense
              items={ipTypeItems}
              bind:value={ipType}
              class="pb-2"
              on:change={ipTypeChanged}
              mandatory
            >
              {$_('administration.users.item-permissions.table.itemType')}
            </Select>
          {/if}
          {#if ipType != ''}
            {#if ipTypeError}
              <span class="error-text ">{$_('defaultTextFieldErrorStrings.required')}</span>
            {/if}
            <Select
              outlined
              dense
              items={itemItems}
              bind:value={itemId}
              class="pb-2 pt-2"
              mandatory
            >
              {$_('administration.users.item-permissions.table.itemName')}
            </Select>
            <Select
              outlined
              dense
              items={permissionSetItems}
              bind:value={permissionSet}
              class="pt-2 pb-2"
              mandatory
            >
              {$_('administration.users.item-permissions.table.permissions')}
            </Select>
            {#if permissionSetError}
              <span class="error-text">{$_('defaultTextFieldErrorStrings.required')}</span>
            {/if}
            {#if errorString.length > 0}
              <span class="error-text">{errorString}</span>
            {/if}
          {/if}
          <Divider class="mt-4 secondary-color" />
          <Button
            type="submit"
            on:click={save}
            disabled={saveButtonDisabled}
            class="mt-4 {saveButtonDisabled ? 'grey-color' : 'secondary-color'}"
          >
            {$_('administration.users.save')}
          </Button>
          <Button
            on:click={applyToMultipleUsers}
            disabled={saveButtonDisabled}
            class="mt-4 {saveButtonDisabled ? 'grey-color' : 'secondary-color'}"
          >
            {$_('administration.users.apply-to-multiple-users')}
          </Button>
          <Button class="mt-4 ml-2" on:click={cancel} text>
            {$_('administration.users.cancel')}
          </Button>
        </form>
      {/await}
    </CardText>
  </Card>
</Dialog>

{#if showApplyPermissionsToUsersDialog}
  <ApplyPermissionsToUsersDialog
    bind:show={showApplyPermissionsToUsersDialog}
    {userId}
    tenantIdForUsers={tenantId}
    tenantIdForPermissions={clientSelected
      ? clients[selectedClientIndex].clientTenant.id
      : tenantId}
    {ipType}
    ipScope={IP_SCOPE_ITEM}
    scopeId={itemId}
    scopeName={itemItems.find((item) => item.value == itemId).name}
    {permissionSet}
    on:save={permissionsApplied}
    on:dismiss={dismiss}
  />
{/if}
