<script>
  import { mdiAccountGroup } from '@mdi/js';
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import { Icon, ProgressLinear, Select } from 'svelte-materialify';
  import { router } from 'tinro';
  import {
    accountHasSomeGroupPermissionForTenant,
    accountHasSomeItemPermissionForTenant,
    accountInfo,
    authenticated,
  } from '../services/account';
  import {
    getIPScopeFromPath,
    getIPScopeIdFromPath,
    getIPTypeFromPath,
  } from '../services/pathhelper';
  import { _ } from '../services/i18n';
  import {
    apiFetch,
    clientContacts,
    noClientContactSelectedId,
    selectableClientContacts,
    selectedClientContact,
    selectedClientContactId,
  } from '../services/network';

  let list = [];
  const items = [];
  let value;

  // Name of sessionStore variable for selectedClient
  const SELECTED_CLIENT_CONTACT = 'selectedClientContact';

  $: value =
    $selectedClientContactId == noClientContactSelectedId ? null : $selectedClientContactId;

  const dispatch = createEventDispatcher();

  onMount(() => {});

  onDestroy(async () => {
    // Need to clear sessionStore when signed out
    if (!$authenticated) {
      sessionStorage.removeItem(SELECTED_CLIENT_CONTACT);
    }
  });

  async function load() {
    const url = `/api/client`;
    const response = await apiFetch(url);
    if (response.ok) {
      list = await response.json();
      clientContacts.update((l) =>
        list.sort((a, b) =>
          a.userProfile.tenant.name.localeCompare(b.userProfile.tenant.name) ||
          a.client.lawFirmIdentifer.localeCompare(b.client.lawFirmIdentifier)
        )
      );
    }
    for (const element of list) {
      // Only add active tenants to the list of selectable entities
      if (element.userProfile.tenant.status !== 'dormant' && clientSelectable(element)) {
        const clientName = element.userProfile.tenant.name;
        const lawFirmId = element.client.lawFirmIdentifier
          ? `:${element.client.lawFirmIdentifier}`
          : '';
        const dropDownName = clientName + lawFirmId;
        items.push({ name: dropDownName, value: element.id });
        $selectableClientContacts.push(element);
      }
    }

    // Set the selectedClientContact from the sessionStore if it has been set
    const sessionSelectedClientContact = sessionStorage.getItem(SELECTED_CLIENT_CONTACT);
    if (sessionSelectedClientContact) {
      const selectedClient = JSON.parse(sessionSelectedClientContact);
      selectedClientContact.update((_) => selectedClient);
      selectedClientContactId.update((_) => selectedClient.id);
    }
  }

  function findClientById(id) {
    return list.find((item) => {
      if (item.id === id) {
        return item;
      }
    });
  }

  function entitySelected(event) {
    if (value) {
      const id = Number.parseInt(value);
      const client = findClientById(id);
      // Only available DOMEvent is on:change which fires even without a selection change
      // So check whether value has changed explicitly
      if ($selectedClientContactId != client.id) {
        selectedClientContact.update((c) => client);
        selectedClientContactId.update((identifier) => client.id);

        // Save selected client to session storage to persist across refreshes
        sessionStorage.setItem(SELECTED_CLIENT_CONTACT, JSON.stringify(client));

        handleRoute(client.userProfile.tenant.id);
      }
    }
  }

  /**
   * @function clientSelectable
   * @param client
   * @returns boolean - true if the logged on user has at least one group access permission for this client
   */
  function clientSelectable(client) {
    return (
      $accountInfo.groupAccessPermissions.some(
        (perms) => perms.tenantId === client.userProfile.tenant.id
      ) ||
      $accountInfo.roles.some((role) => role.tenantId === client.userProfile.tenant.id) ||
      $accountInfo.recordLevelPermissions.some(
        (recordLevelPermission) => recordLevelPermission.tenantId === client.userProfile.tenant.id
      )
    );
  }

  function handleRoute(tenantId) {
    const ipType = getIPTypeFromPath($router.path);
    const scope = getIPScopeFromPath($router.path);
    const scopeId = getIPScopeIdFromPath($router.path);
    if (ipType) {
      if (scope == 'family') {
        if (accountHasSomeGroupPermissionForTenant(ipType, tenantId)) {
          if (scopeId) {
            router.goto(`/${ipType}/${scope}`);
          }
        } else {
          router.goto('/');
        }
      } else if (scope == 'item') {
        if (
          accountHasSomeItemPermissionForTenant(ipType, tenantId) ||
          accountHasSomeGroupPermissionForTenant(ipType, tenantId)
        ) {
          if (scopeId) {
            router.goto(`/${ipType}/${scope}`);
          }
        } else {
          router.goto('/');
        }
      }
    }
  }
</script>

{#await load()}
  <ProgressLinear />
{:then}
  <style>
    .s-input label {
      height: fit-content;
    }

    .s-text-field__input label.active {
      transform: translateY(-25px) scale(0.7);
    }
  </style>
  <div class="d-flex justify-center pt-4 pb-4">
    <Select {items} on:change={entitySelected} bind:value solo mandatory>
      <p class="text-h6 secondary-text text-left">
        {#if $selectedClientContactId !== noClientContactSelectedId}
          {$_('select-client.selected')}
        {:else}
          {$_('select-client.select-client')}
        {/if}
        <Icon class="secondary-text" path={mdiAccountGroup} />
      </p>
    </Select>
  </div>
{/await}
