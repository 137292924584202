<script>
  import { _ } from '../services/i18n';

  export let iconType = 'new';
  let text = $_('new-feature.new');
  let altText = $_('new-feature.alt-text');

  if (iconType === 'beta') {
    text = $_('new-feature.beta');
    altText = $_('new-feature.beta-feature');
  }
</script>

<span title={altText} class="new-feature">
  {text}
</span>

<style>
  .new-feature {
    width: 34px;
    height: 34px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 9px;
    background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="%23fbc02d" d="M23,12L20.56,9.22L20.9,5.54L17.29,4.72L15.4,1.54L12,3L8.6,1.54L6.71,4.72L3.1,5.53L3.44,9.21L1,12L3.44,14.78L3.1,18.47L6.71,19.29L8.6,22.47L12,21L15.4,22.46L17.29,19.28L20.9,18.46L20.56,14.78L23,12Z" /></svg>');
  }
</style>
