<script>
  import { createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider } from 'svelte-materialify';
  import { _ } from '../../services/i18n';
  import { apiFetch } from '../../services/network';
  import ContractItemsAddToGroup from './ContractItemsAddToGroup.svelte';

  const dispatch = createEventDispatcher();

  export let show;
  export let group;
  let saveDisabled = false;
  export let checkList = {};

  function cancel() {
    show = false;
    dispatch('cancel');
  }

  async function save(event) {
    event.preventDefault();
    const url = `/contract/group/${group.id}/items`;
    const request = [];
    for (const item of Object.keys(checkList)) {
      if (checkList[item] === true) {
        request.push(Number.parseInt(item));
      }
    }
    saveDisabled = true;
    const response = await apiFetch(url, 'POST', request);
    saveDisabled = false;
    if (response.ok) {
      const item = await response.json();
      dispatch('save', { item });
      show = false;
    }
  }

  function checked(event) {
    const { id } = event.detail;
    checkList[id] = !checkList[id];
  }
</script>

<Dialog width={900} persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{$_('contract-group.add-contracts.title')} - {group.name}</h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form class="pt-4">
        <div id="add-contract-to-group" style="overflow-x: auto;">
          <ContractItemsAddToGroup {group} on:rowSelected={checked} />
        </div>
        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          on:click={save}
          text
          bind:disabled={saveDisabled}
          class="mt-4 {saveDisabled ? 'grey-color' : 'secondary-color'}"
        >
          {$_('contract-group.add-contracts.add')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('contract-group.add-contracts.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>

<style>
</style>
