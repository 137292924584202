<script>
  import { createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider, Icon } from 'svelte-materialify';
  import { mdiDelete } from '@mdi/js';
  import { _ } from '../../services/i18n';
  import { apiFetch } from '../../services/network';

  const dispatch = createEventDispatcher();
  export let show;
  export let groupId;
  export let trademark;

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function deleteTrademark(event) {
    event.preventDefault();

    const url = `/trademark/group/${groupId}/item/${trademark.id}`;
    const response = await apiFetch(url, 'DELETE');
    if (response.ok) {
      show = false;
      dispatch('delete', { id: trademark.id });
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{$_('trademark-group.delete-trademark.title')}</h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />

      <div class="pt-4">
        <h6>
          {$_('trademark-group.delete-trademark.confirmation', {
            values: { trademarkName: trademark.name },
          })}
        </h6>
        <h6 class="mt-4">
          {$_('trademark-group.delete-trademark.explanation')}
        </h6>
      </div>

      <form>
        <Button type="submit" class="mt-4 error-color" on:click={deleteTrademark} text>
          <Icon path={mdiDelete} />
          {$_('trademark-group.delete')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('trademark-group.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
