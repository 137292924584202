<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { ExpansionPanel, ExpansionPanels, Icon } from 'svelte-materialify';
  import { mdiFolderOutline } from '@mdi/js';
  import { apiFetch } from '../services/network';
  import { _, getFolderSymbols, IP_SCOPE_GROUP, IP_SCOPE_ITEM, IP_TYPE_REPORT } from '../services/i18n';
  import FileFolder from './FileFolder.svelte';

  /**
   * @param ipType - string: one of the ip types or group types eg) 'patent'
   */
  export let ipType;

  /**
   * @param scopeId - number: the identifier of the respective item or group
   */
  export let scopeId;

  /**
   * @param ipScope - string: are we dealing with a group or an item
   */
  export let ipScope;

  /**
   * @param readonly - bool: are uploads and modifications allowed?
   */
  export let readonly = true;

  /**
   * @param attachments - optional array of attachment objects
   */
  export let attachments = null;

  let panelsActive = [];
  const dispatch = createEventDispatcher();

  onMount(async () => {
    if (attachments === null) {
      await load();
    }
  });

  async function load() {
    let url = `/api/${ipType}`;
    if (ipScope === IP_SCOPE_GROUP) {
      url += `/${ipScope}`;
    }
    url += `/${scopeId}/attachment`;
    const response = await apiFetch(url);
    if (response.ok) {
      attachments = await response.json();
    }
  }
</script>

<ExpansionPanel>
  <div slot="header" class="secondary-text">
    <span><Icon class="secondary-text" path={mdiFolderOutline} /></span>
    {#if ipType === IP_TYPE_REPORT}
    <span>{$_('report.saved-reports')}</span>
    {:else}
      {#if ipScope === IP_SCOPE_ITEM}
        <span>{$_('files.title')}</span>
      {/if}
      {#if ipScope === IP_SCOPE_GROUP}
        <span>{$_('files.group-title', { values: { ipType: $_(`${ipType}.singular`) } })}</span>
      {/if}
    {/if}
  </div>

  <ExpansionPanels multiple={true} bind:value={panelsActive}>
    {#each getFolderSymbols(ipType, ipScope) as folderSymbol, folderIndex}
      <FileFolder
        bind:panelsActive
        {folderIndex}
        {ipType}
        {scopeId}
        {ipScope}
        {folderSymbol}
        {readonly}
        bind:attachments
      />
    {/each}
  </ExpansionPanels>
</ExpansionPanel>

<style>
</style>
