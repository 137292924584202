<script>
  import { ProgressLinear } from 'svelte-materialify';
  import { _ } from '../../services/i18n';
  import DataTable from '../DataTable.svelte';
  import { apiFetch } from '../../services/network';
  import { cellRendererFactory } from '../DataTable/CellRendererFactory';
  import DataTableRowActions from '../DataTable/DataTableRowActions.svelte';

  let list = [];

  export let rowSelectable = false;
  export let group;
  // Array of objects that the button renderer uses to create the buttons for each table.
  export let rowActions = [];
  // same as rowActions but buttons go above the datatable
  export let globalActions = [];
  export let linkActions;
  export let tableName;

  let currentItem;
  let dataTable;
  let countries = [];

  function extractValues(mappings) {
    return Object.keys(mappings);
  }

  const trademarkTypeMappings = {
    word: $_('trademark.kinds.word'),
    logo: $_('trademark.kinds.logo'),
  };

  const trademarkTypes = extractValues(trademarkTypeMappings);

  const trademarkStatusMappings = {
    'not-yet-filed': $_('trademark.states.not-yet-filed'),
    pending: $_('trademark.states.pending'),
    allowed: $_('trademark.states.allowed'),
    registered: $_('trademark.states.registered'),
    abandoned: $_('trademark.states.abandoned'),
  };

  const trademarkStatuses = extractValues(trademarkStatusMappings);

  function lookupValue(mappings, key) {
    return mappings[key];
  }

  // coloumn headers for the grid. See DataTable for more information.
  const columnDefs = [
    { field: 'id', headerName: '', hide: true, suppressColumnsToolPanel: true },
    ...(rowActions.some((rowAction) => rowAction.type === 'button')
      ? [
          {
            field: 'buttons',
            headerName: '',
            pinned: 'left',
            resizable: false,
            sortable: false,
            maxWidth: 40,
            editable: false,
            cellClass: 'table-actions pa-0 text-center',
            cellStyle: { cursor: 'pointer' },
            cellRenderer: cellRendererFactory((cell) => {
              new DataTableRowActions({
                target: cell.eGui,
              });
            }),
            suppressColumnsToolPanel: true,
          },
        ]
      : []),
    ...(rowSelectable
      ? [
          {
            checkboxSelection: true,
            field: 'checkbox',
            pinned: 'left',
            resizable: false,
            sortable: false,
            maxWidth: 40,
            editable: false,
            cellStyle: { paddingLeft: '10px' },
            suppressColumnsToolPanel: true,
          },
        ]
      : []),
    {
      field: 'name',
      headerName: $_('trademark.table.title'),
      cellRenderer: 'linkRenderer',
      sortable: true,
      filter: true,
      resizable: true,
      sort: 'asc',
    },
    {
      field: 'country',
      valueGetter: lookupCountry,
      headerName: $_('trademark.table.country'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'trademarkKind',
      headerName: $_('trademark.table.kind'),
      sortable: true,
      filter: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: trademarkTypes },
      filterParams: {
        valueFormatter(params) {
          return lookupValue(trademarkTypeMappings, params.value);
        },
      },
      valueFormatter(params) {
        return lookupValue(trademarkTypeMappings, params.value);
      },
      resizable: true,
    },
    {
      field: 'status',
      headerName: $_('trademark.table.status'),
      sortable: true,
      filter: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: trademarkStatuses },
      filterParams: {
        valueFormatter(params) {
          return lookupValue(trademarkStatusMappings, params.value);
        },
      },
      valueFormatter(params) {
        return lookupValue(trademarkStatusMappings, params.value);
      },
      resizable: true,
    },
    {
      field: 'nickName',
      headerName: $_('trademark.table.nickName'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'lawFirmIdentifier',
      headerName: $_('trademark.table.lawFirmId'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'applicationDate',
      headerName: $_('trademark.table.applicationDate'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'applicationNumber',
      headerName: $_('trademark.table.applicationNumber'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'registrationDate',
      headerName: $_('trademark.table.registrationDate'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'registrationNumber',
      headerName: $_('trademark.table.registrationNumber'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'classes',
      headerName: $_('trademark.table.classes'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'nextAction',
      headerName: $_('patent.table.grantDate'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'grantNumber',
      headerName: $_('patent.table.grantNumber'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'nextAction',
      headerName: $_('trademark.table.nextAction'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'actionDueDate',
      headerName: $_('trademark.table.actionDueDate'),
      sortable: true,
      filter: true,
      resizable: true,
    },
  ];

  async function getItemsList(event) {
    if (tableName == 'trademark-infamily') {
      list = group.items;
    } else if (tableName == 'trademark-addtogroup') {
      list = [];
      const url = `/trademark`;
      const response = await apiFetch(url);
      let res;
      if (response.ok) {
        res = await response.json();
        const ids = [];
        for (let i = 0; i < group.items.length; i++) {
          ids.push(group.items[i].id);
        }
        for (const re of res) {
          if (!ids.includes(re.id)) {
            list.push(re);
          }
        }
      }
    } else {
      const url = `/trademark`;
      const response = await apiFetch(url);
      if (response.ok) {
        list = await response.json();
      }
    }
    
    const countriesResponse = await fetch('/countries2.json');
    if (countriesResponse.ok) {
      countries = await countriesResponse.json();
    }  
  }

  function lookupCountry(params) {
    return countries.find(country => country.code === params.data.country)?.name ?? params.data.country;
  }

  function findItemById(id) {
    return list.find((item) => item.id === id);
  }

  export function addNewRows(newItems, add = false) {
    if (add) {
      for (const newItem of newItems) {
        list.push(newItem);
      }
    }
    dataTable.addNewRows(newItems);
  }

  export function updateRow(itemId) {
    dataTable.updateRow(itemId);
  }

  export function deleteRows(itemId) {
    dataTable.deleteRows(itemId);
    for (let i = 0; i < list.length; i++) {
      if (list[i].id == itemId) {
        list.splice(i, 1);
        break;
      }
    }
  }
</script>

{#await getItemsList()}
  <ProgressLinear indeterminate color="secondary" />
{:then}
  <DataTable
    bind:this={dataTable}
    bind:rowData={list}
    {columnDefs}
    {rowActions}
    {globalActions}
    {linkActions}
    {tableName}
    {rowSelectable}
    bind:currentItem
    on:viewButtonClicked
    on:editButtonClicked
    on:deleteButtonClicked
    on:removeFromGroupClicked
    on:moveFromGroupClicked
    on:rowSelected
  />
{/await}

<style>
</style>
