<script>
  import { Button, Icon } from 'svelte-materialify';
  import { mdiDownloadOutline } from '@mdi/js';
  import { _ } from '../services/i18n';
  import { apiFormatUrl } from '../services/network';

  export let downloadUrl;
  export let title = $_('files.download');
  export let icon = mdiDownloadOutline;

  function downloadButtonClicked(event) {
    const a = document.createElement('a');
    a.href = apiFormatUrl(downloadUrl);
    a.setAttribute('download', '');
    a.setAttribute('tinro-ignore', '');
    a.click();
  }
</script>

<Button icon {title} class="secondary-text" on:click={downloadButtonClicked}>
  <Icon path={icon} class="secondary-text" />
</Button>
