import Bugsnag from '@bugsnag/js';
import { get } from 'svelte/store';
import { accountInfo } from './account';

Bugsnag.start({
  apiKey: import.meta.env.FORTRESS_BUGSNAG_API_KEY,
  onError: (event) => {
    const user = get(accountInfo);

    if (user) {
      event.setUser(user.id, user.email, `${user.firstName} ${user.lastName}`);
      event.addMetadata('tenant', user.tenant);
      event.addMetadata('proxy user', user.proxyUser);
      event.addMetadata('proxy tenant', user.proxyTenant);
      event.addMetadata('tenant permissions', user.tenantPermissions);
    }
  },
  releaseStage: import.meta.env.MODE,
  appVersion: __APP_VERSION__,
  enabledReleaseStages: ['production', 'devqa'],
});

export { default as bugsnag } from '@bugsnag/js';
