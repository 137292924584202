<script>
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Col,
    Divider,
    Row,
    Snackbar,
  } from 'svelte-materialify';
  import { _ } from '../services/i18n';
  import { apiFetch } from '../services/network';

  let show = false;

  const uiVersion = typeof webuiVersion === 'undefined' ? 'unknown' : webuiVersion;
  let apiVersion = 'unknown';
  let privacyPolicyUrl = '';

  async function load() {
    const response = await apiFetch('/api/');
    if (response.ok) {
      const apiInfo = await response.json();
      apiVersion = apiInfo.version;
      privacyPolicyUrl = apiInfo.privacyPolicyUrl;
    }
  }

  function closeButtonClicked(event) {
    show = false;
  }

  function showReceived(event) {
    show = true;
  }
</script>

<span id="about-dialog" on:show={showReceived} />
{#if show}
  <Snackbar absolute center={true}>
    <Row>
      <Col cols={12}>
        <Card outlined={true} raised={true} style="width: 600px;">
          <CardTitle>
            <div class="d-flex align-center" style="width:100%;">
            <img
              src="/images/logos/Fortress_RGB.png"
              class="logo"
              alt="{$_('app.name')} logo"
              title="{$_('app.name')}"
            />
            </div>
          </CardTitle>
          <Divider />
          <CardText>
            <table>
              <tr>
                <th align="right">
                  {$_('about.appName')}
                </th>
                <td class="pl-2">
                  {$_('app.name')}
                </td>
              </tr>
              <tr>
                <th align="right">
                  {$_('about.webuiVersion')}
                </th>
                <td class="pl-2">
                  {uiVersion}
                </td>
              </tr>
              <tr>
                <th align="right">
                  {$_('about.webapiVersion')}
                </th>
                <td class="pl-2">
                  {#await load()}
                    {apiVersion}
                  {:then}
                    {apiVersion}
                  {/await}
                </td>
              </tr>
              <!--tr>
                            <th align="right">
                                {$_('about.privacyPolicy')}
                            </th>
                            <td class="pl-2">
                                <a href={privacyPolicyUrl} target="_blank" tinro-ignore>{privacyPolicyUrl}</a>
                            </td>
                        </tr-->
            </table>
            <div class="pt-4 text-center text--secondary text-sm">{$_('about.copyright')}</div>
          </CardText>
          <Divider />
          <div class="pt-2 pb-2 text-center">
            <Button
              class="secondary-color"
              title={$_('about.dismiss')}
              on:click={closeButtonClicked}
              text
            >
              {$_('about.dismiss')}
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  </Snackbar>/>
{/if}

<style>
  img {
    width: 48px;
    height: 48px;
  }
</style>
