<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    ProgressLinear,
    Select,
    TextField,
  } from 'svelte-materialify';
  import { _ } from '../../../services/i18n';
  import { validateForm } from '../../../services/validation';
  import { apiFetch } from '../../../services/network';

  const dispatch = createEventDispatcher();

  export let tenantId = -1;
  export let clientId;
  export let clientContactId;
  export let existingContacts = [];

  let client;
  let clientContact;
  $: saveButtonDisabled = !(client && clientContact);

  const existingPrimaryContact = existingContacts.find(
    (existingContact) => existingContact.contactType === 'primary'
  );
  let formValues;
  let contactPhoneField;
  let contactInfoField;
  let isFocused = false;
  const contactTypeItems = [
    { name: 'primary', value: 'primary' },
    { name: 'secondary', value: 'secondary' },
  ];

  let formElement;

  afterUpdate(async () => {
    if (!isFocused && clientContact && contactPhoneField && !clientContact.phone) {
      contactPhoneField.focus();
      isFocused = true;
    }
  });

  async function load() {
    let url = `/api/admin/client/${clientId}`;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    let response = await apiFetch(url);
    if (response.ok) {
      client = await response.json();
      url = `/api/admin/client/${clientId}/contact/${clientContactId}`;
      if (tenantId > -1) {
        url += `?tenantId=${tenantId}`;
      }
      response = await apiFetch(url);
      if (response.ok) {
        clientContact = await response.json();
        formValues = {};
        Object.assign(formValues, clientContact);
      }
    }
  }

  function cancel(event) {
    dispatch('cancel');
  }

  async function save(event) {
    event.preventDefault();
    Object.assign(clientContact, formValues);
    if (!validateForm(formElement)) {
      return;
    }
    let url = `/api/admin/client/${client.id}/contact/${clientContact.id}`;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const request = {
      clientUserProfileId: formValues.userProfile.id,
      contactType: formValues.contactType,
      phone: formValues.phone,
      info: formValues.info,
    };
    const response = await apiFetch(url, 'PUT', request);
    if (response.ok) {
      const item = await response.json();
      const updatedContacts = [item];

      // If we have a new primary contact, we need to downgrade the existing primary contact to secondary
      // It's done on the server, but the response only includes the object acted on (/client/:id),
      // so we need to monkey patch it on the client-side as well
      if (item.contactType === 'primary' && existingPrimaryContact) {
        updatedContacts.push({ ...existingPrimaryContact, contactType: 'secondary' });
      }
      dispatch('save', { items: updatedContacts });
    }
  }
</script>

<Dialog persistent={true} active>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('administration.clients.contacts.edit')}
        {$_('administration.clients.contacts.singular')}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      {#await load()}
        <ProgressLinear />
      {:then}
        <form bind:this={formElement} class="pt-4">
          {clientContact.userProfile.email}
          <Select
            outlined
            dense
            items={contactTypeItems}
            bind:value={formValues.contactType}
            class="pt-2 pb-2"
            mandatory
          >
            {$_('administration.clients.contacts.table.type')}
          </Select>
          <TextField
            bind:value={formValues.phone}
            bind:inputElement={contactPhoneField}
            class="pb-2"
            outlined={true}
            dense
            clearable
            color={'secondary'}
            required
          >
            {$_('administration.clients.contacts.table.phone')}
          </TextField>
          <TextField
            bind:value={formValues.info}
            bind:inputElement={contactInfoField}
            class="pb-2"
            outlined={true}
            dense
            clearable
            color={'secondary'}
            required
          >
            {$_('administration.clients.contacts.table.info')}
          </TextField>
          <Divider class="mt-4 secondary-color" />
          {#if formValues.contactType === 'primary' && existingPrimaryContact && existingPrimaryContact.id !== formValues.id}
            <div class="warning-text text-caption mt-4">
              {$_('administration.clients.contacts.downgrade-primary-warning', {
                values: {
                  currentPrimaryContactEmail: existingPrimaryContact.userProfile.email,
                  newPrimaryContactEmail: formValues.userProfile.email,
                },
              })}
            </div>
          {/if}
          <Button
            type="submit"
            on:click={save}
            text
            disabled={saveButtonDisabled}
            class="mt-4 {saveButtonDisabled ? 'grey-color' : 'secondary-color'}"
          >
            {$_('administration.clients.contacts.save')}
          </Button>
          <Button class="mt-4 ml-2" on:click={cancel} text>
            {$_('administration.clients.contacts.cancel')}
          </Button>
        </form>
      {/await}
    </CardText>
  </Card>
</Dialog>
