<script>
  import { createEventDispatcher } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    ProgressLinear,
  } from 'svelte-materialify';
  import { _ } from '../../../services/i18n';
  import { apiFetch } from '../../../services/network';
  // import TextField from '../../TextField.svelte';

  const dispatch = createEventDispatcher();

  export let tenantId = -1;
  export let clientId;
  export let clientContactId;

  let client;
  let clientContact;
  let debounceSearchTimer;
  const searchString = '';
  const searchResults = [];

  let formElement;

  async function load() {
    let url = `/api/admin/client/${clientId}`;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    let response = await apiFetch(url);
    if (response.ok) {
      client = await response.json();
      url = `/api/admin/client/${clientId}/contact/${clientContactId}`;
      if (tenantId > -1) {
        url += `?tenantId=${tenantId}`;
      }
      response = await apiFetch(url);
      if (response.ok) {
        clientContact = await response.json();
      }
    }
  }

  function cancel(event) {
    dispatch('cancel');
  }

  async function deleteClientContact(event) {
    event.preventDefault();

    let url = `/api/admin/client/${clientId}/contact/${clientContactId}`;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const response = await apiFetch(url, 'DELETE');
    if (response.ok) {
      dispatch('delete', { id: clientContactId });
    }
  }
</script>

<Dialog persistent={true} active>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('administration.clients.contacts.delete')}
        {$_('administration.clients.contacts.singular')}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      {#await load()}
        <ProgressLinear />
      {:then}
        <div>
          {$_('administration.clients.contacts.confirm-delete', {
            values: { email: clientContact.userProfile.email },
          })}
        </div>
      {/await}
      <Divider class="mt-4 secondary-color" />
      <Button type="button" class="mt-4 error-color" on:click={deleteClientContact} text>
        {$_('administration.clients.delete')}
      </Button>
      <Button class="mt-4 ml-2" on:click={cancel} text>
        {$_('administration.clients.cancel')}
      </Button>
    </CardText>
  </Card>
</Dialog>
