import { get } from 'svelte/store';
import { accountInfo } from '../account';

export const hasPermissions = (tenantId, action, resource, resourceId) => {
  const user = get(accountInfo);
  const { roles, recordLevelPermissions } = user;
  if (
    roles.some(
      (role) =>
        role.tenantId === tenantId &&
        role.permissions.some(
          (permission) => permission.action === action && permission.resourceType === resource
        )
    )
  ) {
    return true;
  }

  return Boolean(
    resourceId &&
      recordLevelPermissions.some(
        (recordLevelPermission) =>
          recordLevelPermission.permission.resourceType === resource &&
          recordLevelPermission.resourceId === resourceId &&
          recordLevelPermission.permission.action === action
      )
  );
};
