<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    TextField,
  } from 'svelte-materialify';
  import { _ } from '../../../services/i18n';
  import { validateForm } from '../../../services/validation';
  import { apiFetch } from '../../../services/network';
  // import TextField from '../../TextField.svelte';

  const dispatch = createEventDispatcher();

  export let show;
  export let tenantId = -1;
  export let client;
  let lawFirmId = client.lawFirmIdentifier;

  let debounceSearchTimer;
  const searchString = '';
  const searchResults = [];
  let formElement;
  let clientLawFirmIdField;
  let isFocused = false;

  afterUpdate(async () => {
    if (!isFocused && !lawFirmId) {
      clientLawFirmIdField.focus();
      isFocused = true;
    }
  });

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function save(event) {
    event.preventDefault();
    client.lawFirmIdentifier = lawFirmId;
    if (!validateForm(formElement)) {
      return;
    }

    let url = `/api/admin/client/${client.id}`;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const request = {
      lawFirmIdentifier: lawFirmId,
    };
    const response = await apiFetch(url, 'PUT', request);
    if (response.ok) {
      show = false;
      const item = await response.json();
      dispatch('save', { item });
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('administration.clients.edit')}
        {$_('administration.clients.singular')} - {client.clientTenant.name}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <TextField
          bind:value={lawFirmId}
          class="pb-2"
          bind:inputElement={clientLawFirmIdField}
          outlined={true}
          dense
          clearable
          color={'secondary'}
        >
          {$_('administration.clients.table.lawFirmId')}
        </TextField>
        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          class="mt-4 {client ? 'secondary-color' : 'grey-color'}"
          on:click={save}
          disabled={!client}
          text
        >
          {$_('administration.clients.save')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('administration.clients.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
