<script>
  import { onDestroy, onMount } from 'svelte';
  import { _ } from '../services/i18n';

  onMount(() => {});

  onDestroy(() => {});
</script>

<h5 class="secondary-text text-left">{$_('projects.title')}</h5>
<div class="pr-2 text-left error-text">Coming Soon</div>
