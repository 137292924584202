<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    TextField,
  } from 'svelte-materialify';
  import { _ } from '../../services/i18n';
  import { validateForm } from '../../services/validation';
  import { apiFetch } from '../../services/network';
  // import TextField from '../../TextField.svelte';

  const dispatch = createEventDispatcher();

  export let fileSystemNode;

  let formElement;
  let { name } = fileSystemNode;
  let folderNameField;
  let isFocused = false;
  let duplicateFolderName = false;

  afterUpdate(async () => {
    if (!isFocused && !name) {
      folderNameField.focus();
      isFocused = true;
    }
  });
  function isDataRoom() {
    let answer = false;
    if (fileSystemNode.parentNodeId === -1) {
      answer = true;
    }
    return answer;
  }
  async function save(event) {
    event.preventDefault();
    if (!validateForm(formElement)) {
      return;
    }

    const url = `/api/file-system/folder/${fileSystemNode.id}/rename`;
    const response = await apiFetch(url, 'PUT', { name });
    if (response.ok) {
      const fileSystemNode = await response.json();
      dispatch('success', { fileSystemNode });
    } else if (response.status === 409) {
      duplicateFolderName = true;
    }
  }
</script>

<Dialog persistent={true} active>
  <Card outlined={true} raised={true}>
    <CardTitle>
      {#if isDataRoom()}
        <h6 class="secondary-text">{$_('data-rooms.rename.title')}</h6>
      {:else}
        <h6 class="secondary-text">{$_('files.rename-folder-dialog.title')}</h6>
      {/if}
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <TextField
          bind:value={name}
          bind:inputElement={folderNameField}
          class="pb-2"
          outlined={true}
          dense
          clearable
          color={'secondary'}
          required
        >
          {#if isDataRoom()}
            {$_('data-rooms.data-room-name')}
          {:else}
            {$_('files.rename-folder-dialog.folder-name')}
          {/if}
        </TextField>
        {#if duplicateFolderName}
          {#if isDataRoom()}
            <span class="error-text">{$_('data-rooms.duplicate-data-room')}</span>
          {:else}
            <span class="error-text">{$_('files.rename-folder-dialog.duplicate-folder')}</span>
          {/if}
        {/if}
        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          class="mt-4 {name ? 'secondary-color' : 'grey-color'}"
          on:click={save}
          text
          disabled={!name}
        >
          {$_('files.rename-folder-dialog.rename')}
        </Button>
        <Button class="mt-4 ml-2" on:click={() => dispatch('cancel')} text>
          {$_('files.rename-folder-dialog.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
