<script context="module">
  import {
    accountHasPermissionForGroupAndType,
    accountHasPermissionForItemAndType,
    accountInfo,
    GROUP_CREATE,
    GROUP_UPDATE,
    ITEM_CREATE,
    ITEM_UPDATE,
  } from '../../services/account';

  export function isImportActionEnabledForGroups(ipType) {
    return (
      accountHasPermissionForGroupAndType(GROUP_CREATE, 0, ipType) ||
      accountHasPermissionForGroupAndType(GROUP_UPDATE, 0, ipType)
    );
  }

  export function isImportActionEnabledForItems(ipType) {
    return (
      accountHasPermissionForItemAndType(ITEM_CREATE, 0, ipType) ||
      accountHasPermissionForItemAndType(ITEM_UPDATE, 0, ipType)
    );
  }
</script>

<script>
  import { mdiApplicationImport, mdiDownloadOutline, mdiInformationOutline } from '@mdi/js';
  import { onMount } from 'svelte';
  import {
    Button,
    Card,
    CardActions,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    ExpansionPanel,
    ExpansionPanels,
    Icon,
    ProgressLinear,
    Snackbar,
    Tabs,
    Tab,

    TabContent

  } from 'svelte-materialify';
  import { IP_TYPE_CONTRACT, IP_TYPE_PATENT, IP_TYPE_TRADEMARK, _ } from '../../services/i18n';
  import { apiFetch, selectedClientContact, clientContacts } from '../../services/network';
  import {
    previousImportResultsList,
    previousImportResultsTimestamp,
  } from '../../services/stores/data-import';
  import ImportInstructions from './ImportInstructions.svelte';
  import ImportResults from './ImportResults.svelte';
    import NewFeatureIcon from '../NewFeatureIcon.svelte';

  let fileInputElement;
  let showErrorSnackbar = false;
  let snackBarText;
  let instructionPanelValue = [0];
  let showResultsPanel = false;
  let fileSelected = false;
  let importButtonDisabled = false;
  let confirmationDialogActive = false;

  onMount(() => {
    if ($previousImportResultsList.length > 0) {
      showResultsPanel = true;
      instructionPanelValue = [1];
    }
  });

  function downloadTemplateClicked(event) {
    const a = document.createElement('a');
    a.href = '/assets/DataImportTemplate.xlsx';
    a.setAttribute('download', 'DataImportTemplate.xlsx');
    a.setAttribute('tinro-ignore', '');
    a.click();
  }

  async function uploadImportFileClicked(event) {
    fileInputElement.click();
  }

  async function fileSelectionChanged(event) {
    confirmationDialogActive = true;
  }

  async function emailImportClicked(event) {
    event.preventDefault();
    let searchParams = new URLSearchParams();
    if ($selectedClientContact?.id > 0) {
      searchParams = new URLSearchParams(window.location.search);
      searchParams.set('clientId', $selectedClientContact.id)
    }
    window.location.assign(`${import.meta.env.FORTRESS_V2_FRONTEND_URL}/import${
      searchParams.size > 0 ?
        `?${searchParams.toString()}` :
        ''
    }`);
  }

  function cancel() {
    confirmationDialogActive = false;
    fileInputElement.value = '';
  }

  async function confirm() {
    fileSelected = true;
    importButtonDisabled = true;
    confirmationDialogActive = false;
    instructionPanelValue = [1];
    let url = `/import/ip`;
    url = url;
    const headers = {
      Accept: 'application/json',
    };

    const formData = new FormData();
    formData.append('attachment', fileInputElement.files[0], fileInputElement.files[0].name);
    const response = await apiFetch(url, 'POST', formData, headers, true);
    if (response.ok) {
      const results = await response.json();
      previousImportResultsList.update((r) => results);
      previousImportResultsTimestamp.update((t) => new Date());
      showResultsPanel = true;
    } else {
      instructionPanelValue = [0];
      const errorResponse = await response.json();
      snackBarText = errorResponse?.reason
        ? errorResponse?.reason + $_('ip-import.invalid-template-reason')
        : $_('ip-import.invalid-template');
      showErrorSnackbar = true;
    }
    fileSelected = false;
    fileInputElement.value = '';
    importButtonDisabled = false;
  }
</script>

<h5 class="secondary-text text-left mb-5">{$_('ip-import.title')}</h5>
<Tabs grow class="secondary-text">
  <div slot="tabs">
    <Tab>{$_('ip-import.tombstone-data-import')}</Tab>
    <Tab>{$_('data-rooms.import-data-rooms')}</Tab>
    <Tab on:click={emailImportClicked}>
      {$_('email-import.title')}
      <NewFeatureIcon iconType="beta" />
    </Tab>
  </div>
  <TabContent>
    <div class="mt-4">
      <a href="/assets/DataImportTemplate.xlsx" download="DataImportTemplate.xlsx" tinro-ignore target="_blank">
        <Button
          text
          fab
          size="small"
          class="secondary-color"
          title={$_('ip-import.download-template')}
        >
          <Icon path={mdiDownloadOutline} />
        </Button>
      </a>
      <Button
        text
        fab
        size="small"
        class={importButtonDisabled ? 'grey lighten-2 white-text' : 'secondary-color'}
        title={$_('ip-import.upload-data')}
        disabled={importButtonDisabled}
        on:click={uploadImportFileClicked}
      >
        <Icon path={mdiApplicationImport} />
      </Button>
    </div>
    <input bind:this={fileInputElement} on:change={fileSelectionChanged} type="file" hidden />
    <ExpansionPanels class="mt-5 mb-5" accordion bind:value={instructionPanelValue}>
      <ExpansionPanel>
        <div slot="header" class="secondary-text">
          <Icon class="secondary-text" path={mdiInformationOutline} />
          <span>{$_('ip-import.instructions.title')}</span>
        </div>
        <ImportInstructions />
      </ExpansionPanel>
    </ExpansionPanels>
    {#if fileSelected}
      <ProgressLinear indeterminate />
    {/if}
    {#if showResultsPanel}
      <ImportResults
        errors={$previousImportResultsList.filter((result) => result.status.code !== 201)}
        successfulPatents={$previousImportResultsList.filter(
          (result) => result.status.code === 201 && result.record.ipType === IP_TYPE_PATENT
        )}
        successfulTrademarks={$previousImportResultsList.filter(
          (result) => result.status.code === 201 && result.record.ipType === IP_TYPE_TRADEMARK
        )}
        successfulContracts={$previousImportResultsList.filter(
          (result) => result.status.code === 201 && result.record.ipType === IP_TYPE_CONTRACT
        )}
        timestamp={$previousImportResultsTimestamp}
      />
    {/if}
  </TabContent>
  <TabContent>
    <div class="mt-4">
      {$_('data-rooms.import-instructions')}
    </div>
  </TabContent>
</Tabs>

<Dialog persistent bind:active={confirmationDialogActive}>
  <Card>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('ip-import.confirmation-dialog.title')}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <div class="pt-4">
        <h6>
          {#if $clientContacts.length > 0}
            {$_('ip-import.confirmation-dialog.message')
              .replace('{{filename}}', fileInputElement.files[0].name)
              .replace('{{entity}}', $selectedClientContact.userProfile.tenant.name)}
          {:else}
            {$_('ip-import.confirmation-dialog.message')
              .replace('{{filename}}', fileInputElement.files[0].name)
              .replace('{{entity}}', $accountInfo.tenant.name)}
          {/if}
        </h6>
      </div>
    </CardText>
    <CardActions>
      <Button type="submit" class="mt-4 ml-4 mb-4 secondary-color" on:click={confirm} text>
        {$_('ip-import.confirmation-dialog.actions.confirm')}
      </Button>
      <Button on:click={cancel} class="mt-4 mb-4 ml-2" text>
        {$_('ip-import.confirmation-dialog.actions.cancel')}
      </Button>
    </CardActions>
  </Card>
</Dialog>

{#if showErrorSnackbar}
  <Snackbar class="flex-column" bind:active={showErrorSnackbar} timeout={8000} center>
    <div>{snackBarText}</div>
  </Snackbar>
{/if}
