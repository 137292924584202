<script>
  import { router } from 'tinro';
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import { Breadcrumbs, ProgressLinear } from 'svelte-materialify';
  import { mdiDeleteOutline, mdiPencilOutline, mdiPlus } from '@mdi/js';
  import {
    accountHasTenantPermission,
    PER_TENANT_CLIENT_ADMIN,
    SYSTEM_WIDE_CLIENT_ADMIN,
  } from '../../../services/account';
  import { apiFetch } from '../../../services/network';
  import { _ } from '../../../services/i18n';
  import AddClientContact from './AddClientContact.svelte';
  import UpdateClientContact from './UpdateClientContact.svelte';
  import DeleteClientContact from './DeleteClientContact.svelte';
  import DataTable from '../../DataTable.svelte';
  import { cellRendererFactory } from '../../DataTable/CellRendererFactory';
  import DataTableRowActions from '../../DataTable/DataTableRowActions.svelte';

  export let tenantId = -1;
  export let clientId;
  export let initialBreadCrumbItems = [];

  export let breadCrumbItems = [];
  export let showAddClientContactDialog = false;
  let showUpdateClientContactDialog = false;
  let showDeleteClientContactDialog = false;
  const tableElement = null;
  const searchTerm = '';
  let clientContacts = [];
  const selectedContact = {};
  let dataTable;
  let currentItem;

  $: {
    if (searchTerm === '') {
      search();
    }
  }

  const dispatch = createEventDispatcher();

  const rowActions = [
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.clients.edit'),
      path: mdiPencilOutline,
      onClickEvent: 'editButtonClicked',
      elementId: 'entity-details',
      value: '',
    },
    {
      type: 'copyLinkButton',
    },
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.clients.delete'),
      path: mdiDeleteOutline,
      onClickEvent: 'deleteButtonClicked',
      cssClasses: ['red-text'],
      elementId: 'entity-details',
      value: '',
    },
  ];

  const columnDefs = [
    { field: 'id', headerName: '', hide: true, suppressColumnsToolPanel: true },
    {
      field: 'buttons',
      headerName: '',
      pinned: 'left',
      resizable: false,
      sortable: false,
      maxWidth: 40,
      editable: false,
      cellClass: 'table-actions pa-0 text-center',
      cellStyle: { cursor: 'pointer' },
      cellRenderer: cellRendererFactory((cell) => {
        new DataTableRowActions({
          target: cell.eGui,
        });
      }),
      suppressColumnsToolPanel: true,
    },
    {
      field: 'name',
      headerName: $_('administration.clients.contacts.table.name'),
      cellRenderer: 'linkRenderer',
      sortable: true,
      filter: true,
      resizable: true,

      sort: 'asc',
    },
    {
      field: 'info',
      headerName: $_('administration.clients.contacts.table.info'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'contactType',
      headerName: $_('administration.clients.contacts.table.type'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'userProfile.email',
      headerName: $_('administration.clients.contacts.table.email'),
      suppressColumnsToolPanel: true,
    },
    {
      field: 'phone',
      headerName: $_('administration.clients.contacts.table.phone'),
      resizable: true,
      suppressColumnsToolPanel: true,
    },
  ];

  // same as rowActions but buttons go above the datatable
  const globalActions = [
    {
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.clients.new'),
      path: mdiPlus,
      onClickEvent: 'newClicked',
      elementId: 'entity-details',
    },
  ];

  function isActionEnabled(item) {
    return (
      accountHasTenantPermission(SYSTEM_WIDE_CLIENT_ADMIN) ||
      accountHasTenantPermission(PER_TENANT_CLIENT_ADMIN)
    );
  }

  onMount(() => {});

  onDestroy(() => {});

  function addColumn() {
    for (const clientContact of clientContacts) {
      clientContact.name = `${clientContact.userProfile.firstName} ${clientContact.userProfile.lastName}`;
    }
  }

  async function load() {
    let url = `/api/admin/client/${clientId}/contact`;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const response = await apiFetch(url);
    if (response.ok) {
      clientContacts = await response.json();
      let clientName = '';
      if (clientContacts.length > 0) {
        clientName = clientContacts[0].userProfile.tenant.name;
      }
      addColumn();
      breadCrumbItems =
        tenantId > -1
          ? initialBreadCrumbItems.concat([
              {
                text: `${$_('administration.clients.singular')} - ${clientName}`,
                href: `/administration/tenant/${tenantId}/client/${clientId}`,
              },
            ])
          : breadCrumbItems.concat([
              {
                text: `${$_('administration.clients.singular')} - ${clientName}`,
                href: `/administration/client/${clientId}`,
              },
            ]);
    }
  }

  function newClicked(event) {
    showAddClientContactDialog = true;
  }

  function findContactById(id) {
    return clientContacts.find((item) => {
      if (item.id === id) {
        return item;
      }
    });
  }

  function search() {
    if (tableElement === null) {
      return;
    }
    const filter = searchTerm.toLowerCase();
    const rows = tableElement.querySelectorAll('tr');
    for (const row_ of rows) {
      const cols = row_.querySelectorAll('td');
      let found = false;
      for (const col_ of cols) {
        const txtValue = col_.textContent || col_.innerText;
        if (txtValue.toLowerCase().includes(filter)) {
          found = true;
        }
      }
      row_.style.display = found ? '' : 'none';
    }
  }

  function formatName(userProfile) {
    return `${userProfile.firstName} ${userProfile.lastName}`;
  }

  function viewButtonClicked(event) {
    const { href } = event.detail.action;
    router.goto(href + event.detail.id);
  }

  function editButtonClicked(event) {
    currentItem = event.detail.data;
    showUpdateClientContactDialog = true;
  }

  function deleteButtonClicked(event) {
    currentItem = event.detail.data;
    showDeleteClientContactDialog = true;
  }

  async function updatedItemSaved(event) {
    const updatedContacts = event.detail.items;
    for (const updatedContact of updatedContacts) {
      updateContact(updatedContact);
    }
    showUpdateClientContactDialog = false;
  }

  function updateContact(updatedContact) {
    // This is O(n*m) but the number of contacts is small so I'm hoping this code disappears before the dataset gets too large
    const contact = clientContacts.find((clientContact) => clientContact.id === updatedContact.id);
    if (contact) {
      contact.name = `${updatedContact.userProfile.firstName} ${updatedContact.userProfile.lastName}`;
      contact.info = updatedContact.info;
      contact.contactType = updatedContact.contactType;
      contact.phone = updatedContact.phone;
    }
    dataTable.updateRow(updatedContact.id);
  }

  function updateCurrentItem(event) {
    currentItem = event.detail.item;
  }

  async function handleContactAdd(event) {
    // Creating a contact might trigger an update to another one, if so, `event.detail` will contain an `update` key with the client to be updated
    if (event.detail.update) {
      updateContact(event.detail.update);
    }
    clientContacts.push(event.detail.create);
    dataTable.addNewRows([
      {
        ...event.detail.create,
        name: `${event.detail.create.userProfile.firstName} ${event.detail.create.userProfile.lastName}`,
      },
    ]);
    showAddClientContactDialog = false;
  }

  async function itemDeleted(event) {
    currentItem = findContactById(event.detail.id);
    dataTable.deleteRows(event.detail.id);
    for (let i = 0; i < clientContacts.length; i++) {
      if (clientContacts[i].id == event.detail.id) {
        clientContacts.splice(i, 1);
        break;
      }
    }
    showDeleteClientContactDialog = false;
  }
</script>

<Breadcrumbs bind:items={breadCrumbItems} />

{#await load()}
  <ProgressLinear indeterminate color="secondary" />
{:then}
  <div id="entity-details" on:updateCurrentItem={updateCurrentItem} on:newClicked={newClicked}>
    <DataTable
      bind:rowData={clientContacts}
      bind:this={dataTable}
      {columnDefs}
      {rowActions}
      {globalActions}
      tableName="entity-details"
      on:viewButtonClicked={viewButtonClicked}
      on:editButtonClicked={editButtonClicked}
      on:deleteButtonClicked={deleteButtonClicked}
    />
  </div>
{/await}

{#if showAddClientContactDialog}
  <AddClientContact
    {tenantId}
    {clientId}
    existingContacts={clientContacts}
    on:save={handleContactAdd}
    on:cancel={() => (showAddClientContactDialog = false)}
  />
{/if}
{#if showUpdateClientContactDialog}
  <UpdateClientContact
    {tenantId}
    {clientId}
    existingContacts={clientContacts}
    clientContactId={currentItem.id}
    on:save={updatedItemSaved}
    on:cancel={() => (showUpdateClientContactDialog = false)}
  />
{/if}
{#if showDeleteClientContactDialog}
  <DeleteClientContact
    {tenantId}
    {clientId}
    clientContactId={currentItem.id}
    on:delete={itemDeleted}
    on:cancel={() => (showDeleteClientContactDialog = false)}
  />
{/if}

<style>
  table {
    border: 1px solid white;
    border-collapse: collapse;
  }
  thead > th:first-child {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }
  thead > th:last-child {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  th {
    border: 1px solid white;
    height: 41px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #1a76d2;
    color: white;
  }
  td {
    padding: 4px;
    border-bottom: 1px solid #1a76d2;
  }
</style>
