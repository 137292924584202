<script>
  import {
    mdiApplicationImport,
    mdiDeleteOutline,
    mdiFolderOpenOutline,
    mdiPencilOutline,
    mdiPlus,
  } from '@mdi/js';
  import { createEventDispatcher } from 'svelte';
  import { router } from 'tinro';
  import { _ } from '../../services/i18n';
  import {
    accountHasPermissionForItemAndType,
    ITEM_CREATE,
    ITEM_DELETE,
    ITEM_READ,
    ITEM_TRADEMARK,
    ITEM_UPDATE,
  } from '../../services/account';
  import AddOrUpdateTrademark from './AddOrUpdateTrademark.svelte';
  import DeleteTrademark from './DeleteTrademark.svelte';
  import TrademarkItems from './TrademarkItems.svelte';

  import { isImportActionEnabledForItems } from '../Import/Import.svelte';

  export let readonly = true;
  let currentItem;
  let showUpdateDialog = false;
  let showDeleteDialog = false;
  let showAddItemDialog = false;
  export let trademarkItems;

  const rowActions = [
    {
      type: 'button',
      enabledFunction: isViewActionEnabled,
      href: '/trademark/item/',
      title: $_('trademark.open'),
      path: mdiFolderOpenOutline,
      onClickEvent: 'viewButtonClicked',
      elementId: 'trademark-items-all',
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isUpdateActionEnabled,
      href: '',
      title: $_('trademark.edit'),
      path: mdiPencilOutline,
      onClickEvent: 'editButtonClicked',
      elementId: 'trademark-items-all',
      value: '',
    },
    {
      type: 'copyLinkButton',
    },
    {
      type: 'button',
      enabledFunction: isDeleteActionEnabled,
      href: '',
      title: $_('trademark.delete'),
      path: mdiDeleteOutline,
      onClickEvent: 'deleteButtonClicked',
      cssClasses: ['red-text'],
      elementId: 'trademark-items-all',
      value: '',
    },
  ];

  // same as rowActions but buttons go above the datatable
  const globalActions = [
    {
      enabledFunction: readonly,
      href: '',
      title: $_('groups-and-items.new'),
      path: mdiPlus,
      onClickEvent: 'newItemClicked',
      elementId: 'trademark-items-all',
    },
    {
      enabledFunction: isImportActionEnabledForItems(ITEM_CREATE),
      href: '/import',
      title: $_('groups-and-items.import-records'),
      path: mdiApplicationImport,
      onClickEvent: 'importButtonClicked',
      elementId: 'trademark-items-all',
    },
  ];

  const linkActions = [
    {
      enabledFunction: isViewActionEnabled,
      href: '/trademark/item/',
      title: $_('trademark.open'),
      onClickEvent: 'viewButtonClicked',
      elementId: 'trademark-items-all',
    },
  ];

  function isViewActionEnabled(item) {
    return accountHasPermissionForItemAndType(ITEM_READ, item, ITEM_TRADEMARK);
  }

  function isUpdateActionEnabled(item) {
    return accountHasPermissionForItemAndType(ITEM_UPDATE, item, ITEM_TRADEMARK);
  }

  function isDeleteActionEnabled(item) {
    return accountHasPermissionForItemAndType(ITEM_DELETE, item, ITEM_TRADEMARK);
  }

  async function updatedItemSaved(event) {
    const { item } = event.detail;
    const { id } = item;
    trademarkItems.updateRow([id]);
  }

  function itemDeleted(event) {
    const { id } = event.detail;
    trademarkItems.deleteRows(id);
  }

  async function newItem(event) {
    const newItem = event.detail.item;
    router.goto(`/trademark/item/${newItem.id}`);
    trademarkItems.addNewRows([newItem], true);
  }

  function viewButtonClicked(event) {
    const { href } = event.detail.action;
    router.goto(href + event.detail.id);
  }

  function editButtonClicked(event) {
    currentItem = event.detail.data;
    showUpdateDialog = true;
  }

  function deleteButtonClicked(event) {
    currentItem = event.detail.data;
    showDeleteDialog = true;
  }

  function newItemClicked(event) {
    showAddItemDialog = true;
  }

  function importButtonClicked(event) {
    router.goto(event.detail.href);
  }
</script>

<div
  id="trademark-items-all"
  on:itemDeleted={itemDeleted}
  on:newItem={newItem}
  on:updatedItemSaved={updatedItemSaved}
  on:newItemClicked={newItemClicked}
  on:importButtonClicked={importButtonClicked}
>
  <TrademarkItems
    bind:this={trademarkItems}
    tableName="trademark-all"
    {rowActions}
    {globalActions}
    {linkActions}
    on:viewButtonClicked={viewButtonClicked}
    on:editButtonClicked={editButtonClicked}
    on:deleteButtonClicked={deleteButtonClicked}
  />
</div>

{#if showUpdateDialog}
  <AddOrUpdateTrademark
    bind:show={showUpdateDialog}
    trademark={currentItem}
    on:save={updatedItemSaved}
  />
{/if}
{#if showDeleteDialog}
  <DeleteTrademark bind:show={showDeleteDialog} on:delete={itemDeleted} trademark={currentItem} />
{/if}
{#if showAddItemDialog}
  <AddOrUpdateTrademark bind:show={showAddItemDialog} on:save={newItem} />
{/if}
