<script>
  import { Col, Container, Row } from 'svelte-materialify';
  import { _ } from '../../services/i18n';

  /**
   * @param successes - array: the list of errors
   */
  export let successes;

  const listHeight = '120px';

  function getUrl(item) {
    return `/${item.record.ipType}/item/${item.item.id}`;
  }
</script>

<Container fluid>
  <div class="container pl-4 mb-4">
    <!-- Header -->
    <Row class="font-weight-bold" dense style="scrollbar-gutter: stable; overflow: hidden auto;">
      <Col>{$_('ip-import.successes.lawfirm-id')}</Col>
      <Col>{$_('ip-import.successes.row')}</Col>
      <Col>{$_('ip-import.successes.title')}</Col>
      <Col>{$_('ip-import.successes.families')}</Col>
    </Row>
    <div
      style="padding-top:10px; max-height:{listHeight}; scrollbar-gutter: stable; overflow: hidden auto;"
    >
      {#each successes as success}
        <Row dense style="margin-top:-11px;">
          <Col>{success.item.lawFirmIdentifier}</Col>
          <Col>{success.record.row}</Col>
          <Col><a href={getUrl(success)}>{success.item.title}</a></Col>
          <Col>{success.item.families.join(', ')}</Col>
        </Row>
      {/each}
    </div>
  </div>
</Container>
