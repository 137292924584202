<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider } from 'svelte-materialify';
  import { _ } from '../../services/i18n';
  import { validateForm } from '../../services/validation';
  import { apiFetch } from '../../services/network';
  import TextField from '../TextField.svelte';

  const dispatch = createEventDispatcher();

  export let show;
  export let group = {
    name: '',
    nickName: '',
    lawFirmIdentifier: '',
    otherIdentifier: '',
  };

  const newGroup = {};
  Object.assign(newGroup, group);

  $: saveDisabled = !newGroup.name;
  let formElement;
  let trademarkGroupNameField;
  let trademarkGroupNickNameField;
  let trademarkGroupLawFirmIdField;
  let trademarkGroupOtherIdField;
  let isFocused = false;

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  afterUpdate(async () => {
    if (!isFocused && !group.name) {
      trademarkGroupNameField.focus();
      isFocused = true;
    }
  });

  async function save(event) {
    event.preventDefault();
    Object.assign(group, newGroup);
    if (!validateForm(formElement)) {
      return;
    }
    let url = `/api/trademark/group`;
    let method = 'POST';
    if (group.id) {
      url += `/${group.id}`;
      method = 'PUT';
    }
    const request = {};
    for (const property in newGroup) {
      if (newGroup[property] != null && property !== 'id') {
        request[property] = newGroup[property];
      }
    }
    saveDisabled = true;
    const response = await apiFetch(url, method, request);
    saveDisabled = !newGroup.name;
    if (response.ok) {
      show = false;
      const item = await response.json();
      dispatch('save', { item });
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      {#if group.id}
        <h6 class="secondary-text">
          {$_('trademark-group.edit')}
          {$_('trademark-group.singular')}
        </h6>
      {:else}
        <h6 class="secondary-text">{$_('trademark-group.new')} {$_('trademark-group.singular')}</h6>
      {/if}
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <TextField
          bind:value={newGroup.name}
          bind:inputElement={trademarkGroupNameField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          required
        >
          {$_('trademark-group.table.title')}
        </TextField>
        <TextField
          bind:value={newGroup.nickName}
          bind:inputElement={trademarkGroupNickNameField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('trademark-group.table.nickName')}
        </TextField>
        <TextField
          bind:value={newGroup.lawFirmIdentifier}
          bind:inputElement={trademarkGroupLawFirmIdField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('trademark-group.table.lawFirmId')}
        </TextField>

        <TextField
          bind:value={newGroup.otherIdentifier}
          bind:inputElement={trademarkGroupOtherIdField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('trademark-group.table.otherId')}
        </TextField>

        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          on:click={save}
          text
          bind:disabled={saveDisabled}
          class="mt-4 {saveDisabled ? 'grey-color' : 'secondary-color'}"
        >
          {$_('trademark-group.save')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('trademark-group.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
