<script>
  import { onDestroy, onMount } from 'svelte';
  import { Route, router } from 'tinro';
  import { _ } from '../services/i18n';

  const buttonGroupIndex = 0;
  const tableSettings = {
    sortable: true,
    pagination: false,
    columnFilter: true,
  };

  const list = [];
  let tableData = [];
  let currentItem;
  const breadCrumbItems = [{ text: $_('designs.title'), href: '/designs' }];
  let showItemDialog = false;

  onMount(() => {
    tableData = list;
  });

  onDestroy(() => {});

  function buttonGroupChanged(event) {
    const MINE = 0;
    const SHARED = 1;
    const BOTH = 2;
    switch (event.detail) {
      case MINE: {
        tableData = list;
        break;
      }
      case SHARED: {
        tableData = [];
        break;
      }
      case BOTH: {
        tableData = list;
        break;
      }
    }
  }

  function findItemById(id) {
    return list.find((item) => {
      if (item.id === id) {
        return item;
      }
    });
  }

  function itemSelected(event) {
    const element = event.currentTarget;
    const id = Number.parseInt(element.dataset.id);
    const { href } = element;
    const path = new URL(href).pathname;
    currentItem = findItemById(id);
    breadCrumbItems[1] = { text: `${$_('designs.singular')} - ${patent.title}` };
    router.goto(path);
  }

  function newItemClicked() {
    showItemDialog = true;
  }

  function closeItemDialog() {
    showItemDialog = false;
  }
</script>

<Route path="/">
  <div class="ml-2">
    <h5 class="secondary-text text-left">{$_('designs.title')}</h5>
    <div class="pr-2 text-left error-text">Coming Soon</div>
  </div>
</Route>

<style>
</style>
