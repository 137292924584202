<script>
  import {
    mdiDownload,
    mdiDownloadOutline,
    mdiFolderMultipleOutline,
    mdiPlus,
    mdiShareVariantOutline,
  } from '@mdi/js';
  import classNames from 'classnames';
  import { sortBy } from 'lodash-es';
  import { Button, ExpansionPanels, Icon, ProgressLinear, Snackbar } from 'svelte-materialify';
  import { SYSTEM_WIDE_TENANT_ADMIN, accountInfo } from '../../services/account';
  import { hasPermissions } from '../../services/helpers/permissions';
  import { _ } from '../../services/i18n';
  import { apiFetch, apiFormatUrl, selectedClientContact } from '../../services/network';
  import CreateDataRoom from './CreateDataRoom.svelte';
  import FileSystemFolder from './FileSystemFolder.svelte';
  import ImportDataRooms from './ImportDataRooms.svelte';
  import ShareDataRoom from './ShareDataRoom.svelte';

  let selectedDataRoomContentListElement;

  export let fileSystemGroupName;
  let listing;
  let outerPanels = [0];
  let showCreateDataRoomDialog = false;
  let selectedDataRoom;
  let selectedDataRoomToShare;
  let showDownloadDataRoomSnackBar = false;
  const downloadDataRoomSnackBarText = $_('files.downloading-data-room-snackbar-text');

  function resolveFileSystemNodeName(fileSystemNode) {
    if (fileSystemNode.name.length > 0) {
      return fileSystemNode.name;
    }
    return $_(`file-system.${fileSystemNode.symbolName}.title`);
  }

  $: sortedListing = listing
    ? sortBy(
        listing.map((item) => ({ ...item, name: resolveFileSystemNodeName(item) })),
        'name'
      )
    : undefined;

  async function load() {
    const url = `/api/file-system?fileSystemGroupName=${fileSystemGroupName}`;
    const response = await apiFetch(url);
    if (response.ok) {
      listing = await response.json();
    }
  }

  function createDataRoomButtonClicked(event) {
    event.stopPropagation();
    showCreateDataRoomDialog = true;
  }

  function folderCreated(event) {
    const { fileSystemNode } = event.detail;
    listing.push(fileSystemNode);
    listing = listing;
    showCreateDataRoomDialog = false;
  }

  async function selectDataRoom(dataRoom) {
    const { id } = dataRoom;
    if (id === selectedDataRoom?.id) {
      // deselect and return
      unselectDataRoom();
      return;
    }
    // de-select the data room to re-trigger the rendering of the data room contents,
    //  due to the '{#if selectedDataRoom}' surrounding the folder list
    //  (un-mount and re-mount, refreshing the folder list)
    selectedDataRoom = undefined;
    setTimeout(() => {
      selectedDataRoom = dataRoom;
    }, 500);
  }

  async function unselectDataRoom() {
    await load();
    selectedDataRoom = undefined;
  }

  async function downloadAllDrives() {
    showDownloadDataRoomSnackBar = true;
    sortedListing.forEach(async (dataRoom) => {
      await downloadDataRoomButtonClicked(dataRoom.id);
    });
  }

  async function downloadDataRoomButtonClicked(dataRoomId) {
    if (!showDownloadDataRoomSnackBar) {
      showDownloadDataRoomSnackBar = true;
    }
    event.stopPropagation();
    const a = document.createElement('a');
    const url = `/api/file-system/folder/${dataRoomId}/export`;
    a.href = apiFormatUrl(url);
    a.setAttribute('download', '');
    a.setAttribute('tinro-ignore', '');
    a.click();
  }

  $: selectedDataRoomIndex = sortedListing?.findIndex((item) => item.id === selectedDataRoom?.id);

  function shareDataRoomButtonClicked(dataRoom) {
    event.stopPropagation();
    selectedDataRoomToShare = dataRoom;
  }
</script>

<div>
  {#if hasPermissions($selectedClientContact?.userProfile?.tenant?.id ?? $accountInfo.tenant.id, 'create', 'data_room')}
    <Button
      text
      fab
      size="small"
      class="secondary-color"
      title={$_('data-rooms.new-data-room')}
      on:click={createDataRoomButtonClicked}
    >
      <Icon path={mdiPlus} />
    </Button>
  {/if}
  {#if hasPermissions($selectedClientContact?.userProfile?.tenant?.id ?? $accountInfo.tenant.id, 'download', 'data_room')}
    <Button
      text
      fab
      size="small"
      class="secondary-color"
      title={$_('files.download-all-data-rooms-beta')}
      on:click={downloadAllDrives}
    >
      <Icon path={mdiDownload} />
    </Button>
  {/if}
  {#if $accountInfo.tenantPermissions.includes(SYSTEM_WIDE_TENANT_ADMIN) || ['jen@fortress.legal', 'nat@fortress.legal'].includes($accountInfo.email)}
    <ImportDataRooms on:folder-created={folderCreated} />
  {/if}
</div>
<div class="pt-2">
  <!--Icon class="" path={mdiDatabaseOutline} /-->
  <span class="secondary-text">{$_('data-rooms.title')}</span>
</div>
{#await load()}
  <ProgressLinear />
{:then}
  <div class="grid mt-2">
    {#if sortedListing?.length === 0}
      <span class="pl-3 pt-2">You don't currently have access to any Data Rooms.</span>
    {:else if sortedListing?.length > 0}
      {#each sortedListing as dataRoom (dataRoom.id)}
        <div
          class={classNames(
            'data-room grey lighten-4 pb-2 pt-2 pr-2 pl-2 flex-grow-1 justify-space-between d-flex flex-column',
            selectedDataRoom?.id === dataRoom.id ? 'lighten-2' : 'lighten-4'
          )}
          on:click={() => selectDataRoom(dataRoom)}
        >
          <span class="pl-2"><Icon path={mdiFolderMultipleOutline} /></span>
          <span class="secondary-text underline data-room-title mt-2 mb-2 text-center">
            {resolveFileSystemNodeName(dataRoom)}
          </span>
          <div class="data-room-actions align-self-end">
            {#if hasPermissions($selectedClientContact?.userProfile?.tenant?.id ?? $accountInfo.tenant.id, 'download', 'data_room', dataRoom.id)}
              <Button
                title={$_('files.download-data-room-beta')}
                text
                fab
                size="x-small"
                on:click={() => downloadDataRoomButtonClicked(dataRoom.id)}
              >
                <Icon class="secondary-text" path={mdiDownloadOutline} />
              </Button>
            {/if}
            {#if hasPermissions($selectedClientContact?.userProfile?.tenant?.id ?? $accountInfo.tenant.id, 'share', 'data_room', dataRoom.id)}
              <Button
                title={$_('files.share')}
                text
                fab
                size="x-small"
                on:click={shareDataRoomButtonClicked(dataRoom)}
              >
                <Icon class="secondary-text" path={mdiShareVariantOutline} />
              </Button>
            {/if}
          </div>
        </div>
      {/each}
    {/if}
  </div>
  <div class="pt-2" bind:this={selectedDataRoomContentListElement}>
    {#if selectedDataRoom}
      <div>{$_('data-rooms.selected-data-room')}</div>
      <ExpansionPanels class="pt-2" multiple bind:value={outerPanels} accordion>
        <FileSystemFolder
          bind:fileSystemGroupName
          bind:rootFileSystemNode={selectedDataRoom}
          bind:fileSystemNode={selectedDataRoom}
          bind:outerPanelsActive={outerPanels}
          bind:showDownloadDataRoomSnackBar
          bind:folderIndex={selectedDataRoomIndex}
          on:delete={unselectDataRoom}
          on:rename={load}
          on:listingRetrieved={() => {
            selectedDataRoomContentListElement.scrollIntoView({ behaviour: 'smooth' });
          }}
        />
      </ExpansionPanels>
    {/if}
  </div>
{/await}

{#if showCreateDataRoomDialog}
  <CreateDataRoom
    {fileSystemGroupName}
    on:success={folderCreated}
    on:cancel={() => (showCreateDataRoomDialog = false)}
  />
{/if}
{#if selectedDataRoomToShare}
  <ShareDataRoom
    fileSystemNode={selectedDataRoomToShare}
    on:cancel={() => (selectedDataRoomToShare = undefined)}
  />
{/if}

{#if showDownloadDataRoomSnackBar}
  <Snackbar
    class="flex-column"
    bind:active={showDownloadDataRoomSnackBar}
    timeout={10_000}
    top
    right
  >
    <div>{downloadDataRoomSnackBarText}</div>
  </Snackbar>
{/if}

<style>
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
    grid-gap: 1rem;
  }

  .data-room {
    border-radius: 1em;
    cursor: pointer;
    min-width: 12rem;
    max-width: 20rem;
    width: 100%;
    height: 9rem;
    flex-basis: 0;
  }
  .data-room-title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
  }
  .underline {
    text-decoration: underline;
  }
</style>
