<script>
  import { onMount, tick } from 'svelte';
  import { router } from 'tinro';
  import { mdiContentCopy, mdiDeleteOutline, mdiLogout, mdiPencilOutline, mdiPlus } from '@mdi/js';
  import {
    Button,
    Checkbox,
    Col,
    Container,
    DataTable,
    DataTableBody,
    DataTableCell,
    DataTableHead,
    DataTableRow,
    Icon,
    ProgressLinear,
    Row,
    Snackbar,
    Switch,
    TextField,
    Tooltip,
  } from 'svelte-materialify';
  import { _ } from '../services/i18n';
  import { accountInfo, authenticated } from '../services/account';
  import { sendSettingsRequest } from '../services/settings';
  import {
    apiFetch,
    API_URL,
    selectableClientContacts,
    selectedClientContact,
    selectedClientContactId,
  } from '../services/network';
  import ChangePassword from './ChangePassword.svelte';
  import ChangePhoneNumber from './ChangePhoneNumber.svelte';
  import SignInOTP from './SignInOTP.svelte';
  import AddAdditionalEmail from './AddAdditionalEmail.svelte';

  let showChangePasswordDialog = false;
  let showSnackBar = false;
  let snackBarText = '';
  let showChangePhoneNumberDialog = false;
  let showAddAdditionalEmails = false;
  let provideOneTimePassword = false;
  const providePhoneNumber = false;
  let settings = {};
  let entityClientSettings = [];
  let clientSettings = [];
  let password = '';
  let showChangePhoneNumberButton = true;
  const onProfilePage = true;
  let { phoneNumber } = $accountInfo;
  let tmpPhoneNumber = '';
  let enableEmailNotifications;
  let includeExpiredDates;
  const switchOnOrOff = [$_('profile.on'), $_('profile.off')];
  let currentSwitchValue;
  let tenantSettings = {};
  let copiedToClipboard = -1;
  let verifiedEmails = [];
  let emailToDelete = -1;

  // Need sort here to properly put the tenant setting in the right spot in the entityClientSettings array
  $: clientSettings = [settings, ...entityClientSettings].sort((a, b) =>
    a.tenant.name.localeCompare(b.tenant.name)
  );

  onMount(async () => {
    if ($authenticated) {
      const enableEmailNotificationsResponse = await sendSettingsRequest(
        `/api/user-settings/notifications/reminders.email_notifications.enabled`,
        'GET'
      );
      if (enableEmailNotificationsResponse.ok) {
        const res = await enableEmailNotificationsResponse.json();
        enableEmailNotifications = res.value;
      }
      currentSwitchValue = enableEmailNotifications ? switchOnOrOff[0] : switchOnOrOff[1];
      const includeExpiredDatesResponse = await sendSettingsRequest(
        `/api/user-settings/notifications/reminders.include_expired`,
        'GET'
      );
      if (includeExpiredDatesResponse.ok) {
        const res = await includeExpiredDatesResponse.json();
        includeExpiredDates = res.value;
      }
      await getClientTenantSettings();
      await getVerifiedEmails();
    }
  });

  async function signOut(event) {
    const account = $accountInfo;
    const url = `/authentication/signout?email=${account.email}&reason=signout`;
    const response = await apiFetch(url, 'POST');
    authenticated.update((auth) => false);
    selectedClientContact.update((c) => {});
    selectedClientContactId.update((identifier) => -1);
    const signInWorkflow = document.querySelector('#sign-in-workflow');
    signInWorkflow.dispatchEvent(new CustomEvent('signedOut'));
    router.goto('/');
  }

  function changePassword(event) {
    showChangePasswordDialog = true;
    password = $accountInfo.password;
  }
  function changePhoneNumber(event) {
    showChangePhoneNumberDialog = true;
  }
  function passwordChanged(event) {
    showChangePasswordDialog = false;
    snackBarText = $_('profile.passwordChanged');
    showSnackBar = true;
    password = '';
  }
  function passwordChangeCanceled(event) {
    showChangePasswordDialog = false;
    snackBarText = $_('profile.passwordChangeCanceled');
    showSnackBar = true;
    password = '';
  }
  function phoneNumberChangeCanceled(event) {
    provideOneTimePassword = false;
    showChangePhoneNumberDialog = false;
    snackBarText = $_('profile.phoneNumberChangeCanceled');
    showSnackBar = true;
    password = '';
  }

  async function needOTPPhoneNumber(event) {
    provideOneTimePassword = false;
    showChangePhoneNumberDialog = true;
    await tick();
  }

  async function getVerifiedEmails() {
    const url = `${API_URL}/user/verified-emails`;
    const method = 'GET';
    const response = await apiFetch(url, method);
    if (response.ok) {
      verifiedEmails = await response.json();
      verifiedEmails = verifiedEmails.toSorted((a, b) => a.email < b.email);
    }
  }

  async function deleteVerifiedEmail(event) {
    const id = parseInt(event.target.offsetParent.dataset.emailId, 10);
    emailToDelete = id;
    await tick();
    const url = `${API_URL}/user/verified-emails/${id}`;
    const method = 'DELETE';
    const response = await apiFetch(url, method);
    if (response.ok) {
      verifiedEmails = verifiedEmails.filter((email) => email.id !== id);
      snackBarText = $_('profile.additionalEmails.notifications.emailRemoved');
      showSnackBar = true;
    } else {
      snackBarText = $_('profile.additionalEmails.notifications.failedToRemove');
      showSnackBar = true;
    }
    emailToDelete = -1;
  }

  async function onVerifiedEmailAdded(event) {
    snackBarText = $_('profile.additionalEmails.notifications.emailAdded');
    showSnackBar = true;

    await getVerifiedEmails();
  }

  async function onVerifiedEmailConflict(event) {
    console.log(event);
    snackBarText = event.detail.reason;
    showSnackBar = true;
  }

  async function getTenantSettings() {
    const url = `${API_URL}/tenant-settings/tenant/${$accountInfo.tenant.id}`;
    const method = 'GET';
    const response = await apiFetch(url, method);
    if (response.ok) {
      settings = await response.json();
    }
  }

  async function getClientTenantSettings() {
    const urlBase = `/api/tenant-settings/tenant`;
    const method = 'GET';
    const promises = [];
    for (const client of $selectableClientContacts) {
      const resp = await apiFetch(
        `${urlBase}/${client.userProfile.tenant.id}?clientContactId=${client.id}`
      );
      if (resp.ok) {
        const respBody = await resp.json();
        if (!clientSettings.some((setting) => setting.id === respBody.id)) {
          entityClientSettings.push(respBody);
        }
      }
    }
    entityClientSettings = entityClientSettings;
  }

  function oneTimePasswordProvided(event) {
    showChangePhoneNumberDialog = false;
    provideOneTimePassword = false;
    snackBarText = $_('profile.phoneNumberChanged');
    showSnackBar = true;
    phoneNumber = tmpPhoneNumber;
    password = '';
  }

  function resetLogin(event) {
    email = '';
    password = '';
  }
  async function needOTP(event) {
    showChangePhoneNumberButton = !event.detail.phoneNumberVerified;
    tmpPhoneNumber = event.detail.tmpPhoneNumber;
    if (showChangePhoneNumberDialog) {
      showChangePhoneNumberDialog = false;
    }
    provideOneTimePassword = true;
  }

  async function toggleEnableEmailNotifications() {
    enableEmailNotifications = !enableEmailNotifications;
    const response = await sendSettingsRequest(
      `/api/user-settings/notifications/reminders.email_notifications.enabled`,
      'PUT',
      enableEmailNotifications
    );
    if (response.ok) {
      currentSwitchValue = enableEmailNotifications ? switchOnOrOff[0] : switchOnOrOff[1];
      snackBarText = $_('profile.notificationSettingsChanged');
    } else {
      enableEmailNotifications = !enableEmailNotifications;
      snackBarText = $_('profile.cantEditSettings');
    }
    showSnackBar = true;
  }

  async function toggleIncludeExpiredDates() {
    includeExpiredDates = !includeExpiredDates;
    const response = await sendSettingsRequest(
      `/api/user-settings/notifications/reminders.include_expired`,
      'PUT',
      includeExpiredDates
    );
    if (response.ok) {
      snackBarText = $_('profile.notificationSettingsChanged');
    } else {
      includeExpiredDates = !includeExpiredDates;
      snackBarText = $_('profile.cantEditSettings');
    }
    showSnackBar = true;
  }
</script>

<h5 class="secondary-text text-left">{$_('profile.title')}</h5>

<Container>
  <Row>
    <span class="font-weight-bold" color="secondary">{$_('profile.general')}</span>
  </Row>
  <Row>
    <Col class="col-4">
      <TextField value={$accountInfo.firstName} outlined={true} dense color="secondary" readonly>
        {$_('profile.firstName')}
      </TextField>
    </Col>
    <Col class="col-4">
      <TextField value={$accountInfo.lastName} outlined={true} dense color="secondary" readonly>
        {$_('profile.lastName')}
      </TextField>
    </Col>
  </Row>
  <Row>
    <Col class="col-4">
      <TextField value={$accountInfo.email} outlined={true} dense color="secondary" readonly>
        {$_('profile.email')}
      </TextField>
    </Col>
    <Col class="col-4">
      <TextField
        value={$_('profile.changePassword')}
        outlined={true}
        dense
        color="secondary"
        readonly
      >
        {$_('profile.password')}
        <span slot="append">
          <Button icon on:click={changePassword}>
            <Icon path={mdiPencilOutline} class="secondary-text" />
          </Button>
        </span>
      </TextField>
    </Col>
  </Row>
  {#await getTenantSettings()}
    <ProgressLinear />
  {:then}
    <Row>
      {#if settings.twofa == 'otp'}
        <Col class="col-4">
          <TextField bind:value={phoneNumber} outlined={true} dense color="secondary" readonly>
            {$_('profile.phoneNumber')}
            <span slot="append">
              <Button icon on:click={changePhoneNumber}>
                <Icon path={mdiPencilOutline} class="secondary-text" />
              </Button>
            </span>
          </TextField>
        </Col>
        <Col class="col-4">
          <span>{$_('profile.about')}</span>
        </Col>
      {/if}
    </Row>
    <Row>
      <span class="font-weight-bold">{$_('profile.notificationSettings')}</span>
    </Row>
    {#if enableEmailNotifications !== undefined && includeExpiredDates !== undefined}
      <Row>
        <Col class="col-4">
          <Row>
            <Col class="pl-3 pr-3">{$_('profile.emailNotifications')}</Col>
            <Col class="col-6">
              <Switch
                class="pl-3"
                style="float: right;"
                color="secondary"
                checked={enableEmailNotifications}
                on:change={toggleEnableEmailNotifications}
              />
              <span style="float: right;">{currentSwitchValue}</span>
            </Col>
          </Row>
          <Row class={enableEmailNotifications ? '' : 'text--disabled'}>
            <Col>{$_('profile.includeExpiredDates')}</Col>
            <Col>
              <Checkbox
                style="float: right;"
                color="secondary"
                checked={includeExpiredDates}
                disabled={!enableEmailNotifications}
                on:change={toggleIncludeExpiredDates}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    {:else}
      <Row>
        <Col class="pl-3 pr-3 error-text">
          {$_('profile.cantLoadSettings')}
        </Col>
      </Row>
    {/if}
  {/await}
  <!-- <Row>
    <span class="font-weight-bold">{$_('profile.additionalEmails.title')}</span>
    <span class="ml-4">
      <Tooltip>
        <Button
          text
          fab
          size="x-small"
          class="secondary-color"
          title={$_('profile.additionalEmails.addAdditionalEmails')}
          on:click={() => {
            showAddAdditionalEmails = true;
          }}
        >
          <Icon path={mdiPlus} />
          <span slot="tip">{$_('profile.additionalEmails.addAdditionalEmails')}</span>
        </Button>
      </Tooltip>
    </span>
  </Row>
  <Row class="ml-1 mt-3 mb-3">
    {#if verifiedEmails.length > 0}
      <DataTable>
        <DataTableHead>
          <DataTableRow>
            <DataTableCell>{$_('profile.additionalEmails.email')}</DataTableCell>
            <DataTableCell>{$_('profile.additionalEmails.action')}</DataTableCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {#each verifiedEmails as verifiedEmail (verifiedEmail.id)}
            <DataTableRow>
              <DataTableCell>{verifiedEmail.email}</DataTableCell>
              <DataTableCell>
                <Button
                  class="error-color"
                  data-email-id={verifiedEmail.id}
                  on:click={deleteVerifiedEmail}
                >
                  <Icon path={mdiDeleteOutline} class="mr-3" />
                  {emailToDelete === verifiedEmail.id
                    ? $_('profile.additionalEmails.actions.removing')
                    : $_('profile.additionalEmails.actions.remove')}
                </Button>
              </DataTableCell>
            </DataTableRow>
          {/each}
        </DataTableBody>
      </DataTable>
    {:else}
      {$_('profile.additionalEmails.noEmails')}
    {/if}
  </Row> -->

  {#if clientSettings.some((cs) => cs.emailImportEnabled && cs.emailImportAddress?.length > 0)}
    <Row>
      <span class="font-weight-bold">Email Import Settings</span>
    </Row>
    <Row class="ml-1 mt-3 mb-3">
      <DataTable>
        <DataTableHead>
          <DataTableRow>
            <DataTableCell>Entity</DataTableCell>
            <DataTableCell>Import Email Address</DataTableCell>
            <DataTableCell>Action</DataTableCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {#each clientSettings as clientSetting, i (clientSetting.id)}
            {#if clientSetting.emailImportEnabled && clientSetting.emailImportAddress}
              <DataTableRow>
                <DataTableCell>{clientSetting.tenant.name}</DataTableCell>
                <DataTableCell class="elipsis">{clientSetting.emailImportAddress}</DataTableCell>
                <DataTableCell>
                  <Button
                    class={copiedToClipboard === clientSetting.id
                      ? 'success-color'
                      : 'secondary-color'}
                    data-setting-id={clientSetting.id}
                    on:click={(event) => {
                      // console.log(clientSettings.find((setting) => setting.id == event.currentTarget.dataset.settingId)?.emailImportAddress);
                      window.navigator.clipboard.writeText(
                        clientSettings.find(
                          (setting) => setting.id == event.currentTarget.dataset.settingId
                        )?.emailImportAddress
                      );
                      copiedToClipboard = Number(event.currentTarget.dataset.settingId);
                      setTimeout(() => (copiedToClipboard = -1), 2_000);
                    }}
                  >
                    <Icon path={mdiContentCopy} class="mr-3" />
                    {copiedToClipboard === clientSetting.id
                      ? $_('administration.settings.settings.copied')
                      : $_('administration.settings.settings.copy')}
                  </Button>
                </DataTableCell>
              </DataTableRow>
            {/if}
          {/each}
        </DataTableBody>
      </DataTable>
    </Row>
  {/if}
  <Button class="secondary-color" on:click={signOut}>
    <Icon path={mdiLogout} class="mr-3" />{$_('profile.signOut')}
  </Button>
</Container>

{#if showChangePasswordDialog}
  <ChangePassword
    email={$accountInfo.email}
    redirect={false}
    url={`/authentication/changepasswordwhensignedin`}
    onProfilePage={true}
    on:passwordChanged={passwordChanged}
    on:passwordChangeCanceled={passwordChangeCanceled}
  />
{/if}
{#if showChangePhoneNumberDialog}
  <ChangePhoneNumber
    email={$accountInfo.email}
    bind:password
    on:phoneNumberChangeCanceled={phoneNumberChangeCanceled}
    on:needOTP={needOTP}
  />
{/if}
{#if provideOneTimePassword}
  <div id="provideOneTimePassword">
    <SignInOTP
      bind:email={$accountInfo.email}
      bind:password
      phoneNumber={tmpPhoneNumber}
      url={`/authentication/changephonenumber`}
      {onProfilePage}
      showChangePhoneNumberButton={true}
      on:oneTimePasswordProvided={oneTimePasswordProvided}
      on:resetLogin={resetLogin}
      on:needOTPPhoneNumber={needOTPPhoneNumber}
      on:phoneNumberChangeCanceled={phoneNumberChangeCanceled}
    />
  </div>
{/if}
{#if showAddAdditionalEmails}
  <AddAdditionalEmail
    bind:show={showAddAdditionalEmails}
    on:success={onVerifiedEmailAdded}
    on:conflict={onVerifiedEmailConflict}
  />
{/if}
{#if showSnackBar}
  <Snackbar class="flex-column" bind:active={showSnackBar} timeout={2000} top right>
    <div>{snackBarText}</div>
  </Snackbar>
{/if}
