<script>
  import {
    mdiApplicationImport,
    mdiDeleteOutline,
    mdiFolderOpenOutline,
    mdiPencilOutline,
    mdiPlus,
    mdiShareVariantOutline,
  } from '@mdi/js';
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import { router } from 'tinro';
  import { ProgressLinear } from 'svelte-materialify';
  import { _ } from '../../services/i18n';
  import {
    accountHasPermissionForGroupAndType,
    GROUP_ALL,
    GROUP_DELETE,
    GROUP_READ,
    GROUP_SHARE,
    GROUP_TRADEMARK,
    GROUP_UPDATE,
  } from '../../services/account';
  import AddOrUpdateTrademarkGroup from './AddOrUpdateTrademarkGroup.svelte';
  import DeleteTrademarkGroup from './DeleteTrademarkGroup.svelte';
  import ShareGroup from '../ShareGroup.svelte';
  import DataTable from '../DataTable.svelte';
  import { apiFetch } from '../../services/network';
  import { isImportActionEnabledForGroups } from '../Import/Import.svelte';
  import { cellRendererFactory } from '../DataTable/CellRendererFactory';
  import DataTableRowActions from '../DataTable/DataTableRowActions.svelte';

  export let list;

  const allGroup = { id: GROUP_ALL, name: $_('trademark-group.all-groups'), owner: { id: 0 } };
  let currentItem;
  let showUpdateDialog = false;
  let showDeleteDialog = false;
  let showShareAllGroupsDialog = false;
  let showShareGroupDialog = false;
  let showAddGroupDialog = false;
  let dataTable;

  const rowActions = [
    {
      type: 'button',
      enabledFunction: isViewActionEnabled,
      href: '/trademark/family/',
      title: $_('trademark-group.open'),
      path: mdiFolderOpenOutline,
      onClickEvent: 'viewButtonClicked',
      elementId: 'trademark-groups',
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isUpdateActionEnabled,
      href: '',
      title: $_('trademark-group.edit'),
      path: mdiPencilOutline,
      onClickEvent: 'editButtonClicked',
      elementId: 'trademark-groups',
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isShareActionEnabled,
      href: '',
      title: $_('trademark-group.share'),
      path: mdiShareVariantOutline,
      onClickEvent: 'shareButtonClicked',
      elementId: 'trademark-groups',
      value: '',
    },
    {
      type: 'copyLinkButton',
    },
    {
      type: 'button',
      enabledFunction: isDeleteActionEnabled,
      href: '',
      title: $_('trademark-group.delete'),
      path: mdiDeleteOutline,
      onClickEvent: 'deleteButtonClicked',
      cssClasses: ['red-text'],
      elementId: 'trademark-groups',
      value: '',
    },
  ];

  // same as rowActions but buttons go above the datatable
  const globalActions = [
    {
      enabledFunction: true,
      href: '',
      title: $_('groups-and-items.new'),
      path: mdiPlus,
      onClickEvent: 'newItemClicked',
      elementId: 'trademark-groups',
    },
    {
      type: 'button',
      enabledFunction: isImportActionEnabledForGroups(GROUP_TRADEMARK),
      href: '/import',
      title: $_('groups-and-items.import-records'),
      path: mdiApplicationImport,
      onClickEvent: 'importButtonClicked',
      elementId: 'trademark-groups',
    },
    {
      enabledFunction: isShareAllActionEnabled,
      href: '',
      title: $_('trademark-group.share-all'),
      path: mdiShareVariantOutline,
      onClickEvent: 'shareAllButtonClicked',
      elementId: 'trademark-groups',
    },
  ];

  // coloumn headers for the grid. See DataTable for more information.
  const columnDefs = [
    { field: 'id', headerName: '', hide: true, suppressColumnsToolPanel: true },
    {
      field: 'buttons',
      headerName: '',
      pinned: 'left',
      resizable: false,
      sortable: false,
      maxWidth: 40,
      editable: false,
      cellClass: 'table-actions pa-0 text-center',
      cellStyle: { cursor: 'pointer' },
      cellRenderer: cellRendererFactory((cell) => {
        new DataTableRowActions({
          target: cell.eGui,
        });
      }),
      suppressColumnsToolPanel: true,
    },
    {
      field: 'name',
      headerName: $_('trademark-group.table.title'),
      cellRenderer: 'linkRenderer',
      sortable: true,
      filter: true,

      resizable: true,
      sort: 'asc',
    },
    {
      field: 'nickName',
      headerName: $_('trademark-group.table.nickName'),
      sortable: true,
      filter: true,

      resizable: true,
    },
    {
      field: 'lawFirmIdentifier',
      headerName: $_('trademark-group.table.lawFirmId'),
      sortable: true,
      filter: true,

      resizable: true,
    },
    {
      field: 'otherIdentifier',
      headerName: $_('trademark-group.table.otherId'),
      sortable: true,
      filter: true,

      resizable: true,
    },
  ];
  const linkActions = [
    {
      enabledFunction: isViewActionEnabled,
      href: '/trademark/family/',
      title: $_('trademark.open'),
      onClickEvent: 'viewButtonClicked',
      elementId: 'trademark-groups',
    },
  ];

  function isViewActionEnabled(group) {
    return accountHasPermissionForGroupAndType(GROUP_READ, group.id, GROUP_TRADEMARK);
  }

  function isUpdateActionEnabled(group) {
    return accountHasPermissionForGroupAndType(GROUP_UPDATE, group.id, GROUP_TRADEMARK);
  }

  function isDeleteActionEnabled(group) {
    return accountHasPermissionForGroupAndType(GROUP_DELETE, group.id, GROUP_TRADEMARK);
  }
  function isShareActionEnabled(group) {
    return accountHasPermissionForGroupAndType(GROUP_SHARE, group.id, GROUP_TRADEMARK, group.owner);
  }
  function isShareAllActionEnabled() {
    return accountHasPermissionForGroupAndType(GROUP_SHARE, GROUP_ALL, GROUP_TRADEMARK);
  }

  function findItemById(id) {
    return list.find((item) => item.id === id);
  }

  function displayUpdateDialog() {
    showUpdateDialog = true;
  }

  function displayDeleteDialog() {
    showDeleteDialog = true;
  }

  function updateCurrentItem(event) {
    currentItem = event.detail.item;
  }

  async function getGroupsList() {
    const url = `/trademark/group`;
    const response = await apiFetch(url);
    if (response.ok) {
      list = await response.json();
    }
  }

  function viewButtonClicked(event) {
    const { href } = event.detail.action;
    router.goto(href + event.detail.id);
  }

  function editButtonClicked(event) {
    currentItem = event.detail.data;
    showUpdateDialog = true;
  }

  function deleteButtonClicked(event) {
    currentItem = event.detail.data;
    showDeleteDialog = true;
  }

  function shareButtonClicked(event) {
    currentItem = event.detail.data;
    showShareGroupDialog = true;
  }

  function importButtonClicked(event) {
    router.goto(event.detail.href);
  }

  function shareAllButtonClicked(event) {
    showShareAllGroupsDialog = true;
  }

  function itemDeleted(event) {
    const { id } = event.detail;
    dataTable.deleteRows(id);
    for (let i = 0; i < list.length; i++) {
      if (list[i].id == event.detail.id) {
        list.splice(i, 1);
        break;
      }
    }
  }

  async function updatedItemSaved(event) {
    const { item } = event.detail;
    const { id } = item;
    dataTable.updateRow(id);
  }

  function newGroupClicked(event) {
    showAddGroupDialog = true;
  }

  function newGroup(event) {
    const newGroup = event.detail.item;
    list.push(newGroup);
    router.goto(`/trademark/family/${newGroup.id}`);
    dataTable.addNewRows([newGroup]);
  }
</script>

{#await getGroupsList()}
  <ProgressLinear indeterminate color="secondary" />
{:then}
  <div
    id="trademark-groups"
    on:displayUpdateDialog={displayUpdateDialog}
    on:displayDeleteDialog={displayDeleteDialog}
    on:updateCurrentItem={updateCurrentItem}
    on:newItemClicked={newGroupClicked}
    on:importButtonClicked={importButtonClicked}
    on:shareAllButtonClicked={shareAllButtonClicked}
  >
    <DataTable
      bind:rowData={list}
      bind:this={dataTable}
      {columnDefs}
      {rowActions}
      {globalActions}
      {linkActions}
      tableName="trademark-group"
      bind:currentItem
      on:viewButtonClicked={viewButtonClicked}
      on:editButtonClicked={editButtonClicked}
      on:deleteButtonClicked={deleteButtonClicked}
      on:shareButtonClicked={shareButtonClicked}
    />
  </div>
{/await}

{#if showUpdateDialog}
  <AddOrUpdateTrademarkGroup
    bind:show={showUpdateDialog}
    group={currentItem}
    on:save={updatedItemSaved}
  />
{/if}

{#if showDeleteDialog}
  <DeleteTrademarkGroup bind:show={showDeleteDialog} on:delete={itemDeleted} group={currentItem} />
{/if}

{#if showShareAllGroupsDialog}
  <ShareGroup bind:show={showShareAllGroupsDialog} groupType="trademark" group={allGroup} />
{/if}

{#if showShareGroupDialog}
  <ShareGroup bind:show={showShareGroupDialog} groupType="trademark" group={currentItem} />
{/if}

{#if showAddGroupDialog}
  <AddOrUpdateTrademarkGroup bind:show={showAddGroupDialog} on:save={newGroup} />
{/if}

<style>
</style>
