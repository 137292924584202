<script>
  import jstz from 'jstz';
  import { afterUpdate, createEventDispatcher, onMount, tick } from 'svelte';
  import { router } from 'tinro';
  import { mdiLockReset } from '@mdi/js';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    Icon,
    TextField,
  } from 'svelte-materialify';
  import { accountInfo, authenticated } from '../services/account';
  import { apiFetch } from '../services/network';
  import { _ } from '../services/i18n';

  const dispatch = createEventDispatcher();

  const timezone = jstz.determine();
  export let email = '';
  export let password = '';
  export let redirect = true;
  export let newPassword = '';
  export let phoneNumber;
  export let url = `/authentication/changepassword`;
  export let onProfilePage = false;
  let repeatNewPassword = '';
  let errorString = '';
  let passwordsDontMatch = false;
  $: passwordsDontMatch = newPassword !== repeatNewPassword;
  let tokenuuid;
  let showPasswordChangeConfirmation = false;
  let invalidLink = false;
  let changePasswordEmailField;
  let changePasswordCurrentPasswordField;
  let changePasswordNewPasswordField;
  let changePasswordRepeatNewPasswordField;
  let isFocused = false;

  $: resetPasswordButtonDisabled = !email || !newPassword || passwordsDontMatch;
  $: changePasswordWhenSignedInButtonDisabled =
    !email || !password || !newPassword || passwordsDontMatch;

  onMount(async () => {
    if (!onProfilePage) {
      tokenuuid = location.hash.slice(2).replaceAll('passwordReset?token=', '');
      if (String(location).includes('passwordReset') && tokenuuid == '') {
        email = '';
      }
    }
    errorString = '';
    newPassword = '';
    repeatNewPassword = '';
    if (changePasswordEmailField && email === '') {
      changePasswordEmailField.focus();
    } else if (onProfilePage) {
      changePasswordCurrentPasswordField.focus();
    } else {
      changePasswordNewPasswordField.focus();
    }
  });

  afterUpdate(async () => {
    if (!isFocused && changePasswordEmailField && !email) {
      changePasswordEmailField.focus();
      isFocused = true;
    }
  });

  async function changePasswordFormSubmitted(event) {
    const request = {
      email,
      password,
      newPassword,
      phoneNumber,
      tokenuuid,
      timezone: timezone.name(),
    };
    const response = await apiFetch(url, 'POST', request);
    const signinReply = await response.json();
    if (response.ok) {
      if (onProfilePage) {
        showPasswordChangeConfirmation = true;
      } else {
        authenticated.update((auth) => true);
        accountInfo.update(() => signinReply);
      }
      dispatch('passwordChanged');
      await tick();
      dispatch('resetLogin');
    } else {
      if (response.status === 400 && signinReply.reason === 'tokenNotFound') {
        errorString = $_('changePassword.fail.tokenNotFound');
      }
      switch (response.status) {
        case 401: {
          if (signinReply.reason === 'invalidEmailOrPassword') {
            errorString = $_('changePassword.fail.unauthorized');
          }
          if (signinReply.reason === 'invalidToken') {
            errorString = $_('changePassword.fail.invalidToken');
            invalidLink = true;
          }
          if (signinReply.reason === 'tokenExpired') {
            errorString = $_('changePassword.fail.tokenExpired');
            invalidLink = true;
          }
          if (signinReply.reason === 'userAndTokenOwnerMismatch') {
            errorString = $_('changePassword.fail.invalidToken');
          }
          if (redirect) {
            authenticated.update((auth) => false);
          }

          break;
        }
        case 403: {
          errorString = $_('changePassword.fail.suspended');
          if (redirect) {
            authenticated.update((auth) => false);
          }

          break;
        }
        case 409: {
          switch (signinReply.reason) {
            case 'mustProvidePhoneNumber': {
              dispatch('needOTPPhoneNumber', { newPassword });

              break;
            }
            case 'mustProvideOneTimePassword': {
              dispatch('needOTP', {
                newPassword,
                phoneNumberVerified: signinReply.phoneNumberVerified,
              });

              break;
            }
            case 'mustSignIn': {
              if (redirect) {
                router.goto('/');
                showPasswordChangeConfirmation = true;
              } else {
                dispatch('signInEventReceived');
              }

              break;
            }
            default: {
              errorString = $_(`changePassword.fail.${signinReply.reason}`);
              if (redirect) {
                authenticated.update((auth) => false);
              }
            }
          }

          break;
        }
        default: {
          if (redirect) {
            authenticated.update((auth) => false);
          }
          errorString = $_('changePassword.fail.other');
        }
      }
      return response;
    }
  }

  function cancel(event) {
    dispatch('passwordChangeCanceled');
  }

  function getNewLink(event) {
    dispatch('getEmail');
  }

  function backToLogin(event) {
    dispatch('signInEventReceived');
  }
</script>

<div id="changePasswordDialog">
  <Dialog persistent={true} active={true}>
    <Card outlined={true} raised={true}>
      <CardTitle>
        <div class="d-flex align-center">
          <img
            src="/images/logos/Fortress_RGB.png"
            class="logo"
            alt="{$_('app.name')} logo"
            title="{$_('app.name')}"
          />
        </div>
      </CardTitle>
      <CardText>
        <Divider class="secondary-color" />
        <h5 class="pt-2 text-center">{$_('changePassword.banner')}</h5>
        <div>
          <p />
          <form on:submit|preventDefault={changePasswordFormSubmitted}>
            <!-- Only show email input field in UI on resetPassword flow -->
            {#if tokenuuid?.length > 0}
              <TextField
                type="email"
                outlined={true}
                color="secondary"
                readonly={onProfilePage}
                placeholder={$_('changePassword.emailAddress')}
                bind:value={email}
                bind:inputElement={changePasswordEmailField}
              >
                {$_('changePassword.emailAddress')}
              </TextField>
            {/if}
            {#if onProfilePage}
              <TextField
                type="password"
                outlined={true}
                color="secondary"
                readonly={!onProfilePage}
                placeholder={$_('changePassword.currentPassword')}
                bind:value={password}
                bind:inputElement={changePasswordCurrentPasswordField}
              >
                {$_('changePassword.currentPassword')}
              </TextField>
            {/if}
            <TextField
              type="password"
              outlined={true}
              color="secondary"
              placeholder={$_('changePassword.newPassword')}
              bind:value={newPassword}
              bind:inputElement={changePasswordNewPasswordField}
            >
              {$_('changePassword.newPassword')}
            </TextField>
            <TextField
              type="password"
              outlined={true}
              clearable={true}
              color="secondary"
              placeholder={$_('changePassword.repeatNewPassword')}
              bind:value={repeatNewPassword}
              bind:inputElement={changePasswordRepeatNewPasswordField}
            >
              {$_('changePassword.repeatNewPassword')}
            </TextField>
            {#if passwordsDontMatch}
              <div class="error-text">
                {$_('changePassword.fail.newAndRepeatPasswordDontMatch')}
              </div>
            {/if}
            <div class="error-text">
              {errorString}
            </div>
            <br />
            {#if invalidLink}
              <Button class="secondary-color" color="secondary" on:click={getNewLink}>
                <Icon path={mdiLockReset} class="mr-3" />{$_('getEmail.sendAgain')}
              </Button>
            {:else if redirect && !invalidLink}
              {#if showPasswordChangeConfirmation}
                <div class="secondary-text">{$_('changePassword.passwordChanged')}</div>
                <Button class="secondary-color" color="secondary" on:click={backToLogin}>
                  <Icon path={mdiLockReset} class="mr-3" />{$_('changePassword.backToLogin')}
                </Button>
              {:else}
                <Button
                  id="resetPasswordButton"
                  block
                  class={resetPasswordButtonDisabled ? 'grey-color' : 'secondary-color'}
                  type="submit"
                  disabled={resetPasswordButtonDisabled}
                  color="secondary"
                >
                  <Icon
                    path={mdiLockReset}
                    class={resetPasswordButtonDisabled ? 'grey-color' : 'secondary-color'}
                  />
                  {$_('changePassword.changePassword')}
                </Button>
              {/if}
            {:else}
              <div>
                <Button
                  id="changePasswordWhenSignedInButton"
                  class={changePasswordWhenSignedInButtonDisabled
                    ? 'grey-color'
                    : 'secondary-color'}
                  type="submit"
                  disabled={changePasswordWhenSignedInButtonDisabled}
                  color="secondary"
                >
                  <Icon
                    path={mdiLockReset}
                    class={changePasswordWhenSignedInButtonDisabled
                      ? 'grey-color'
                      : 'secondary-color'}
                  />
                  {$_('changePassword.changePassword')}
                </Button>
                <Button class="" style="float: right;" type="button" on:click={cancel}>
                  {$_('changePassword.cancel')}
                </Button>
              </div>
            {/if}
          </form>
        </div>
      </CardText>
    </Card>
  </Dialog>
</div>
