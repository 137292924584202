<script>
  import { createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Checkbox, Dialog, Divider } from 'svelte-materialify';
  import { _ } from '../../services/i18n';
  import { accountInfo } from '../../services/account';
  import { hasPermissions } from '../../services/helpers/permissions';
  import { apiFetch, selectedClientContact } from '../../services/network';

  const dispatch = createEventDispatcher();

  export let dataRoom;

  export let user;

  const recordLevelPermissionsForDataRoom = user.recordLevelPermissions?.filter(
    (recordLevelPermission) =>
      recordLevelPermission.resourceId === dataRoom.id &&
      recordLevelPermission.permission.resourceType === 'data_room'
  );

  const errorString = '';
  let formElement;

  const replaceRecordLevelPermissions = () =>
    apiFetch(
      `/record-level-permission/user/${user.id}/data_room/${dataRoom.id}`,
      'PUT',
      checkedPermissionIds.map((permissionId) => ({
        id: permissionId,
      }))
    ).then(async (response) => {
      if (response.ok) {
        dispatch('success');
      }
    });

  const dataRoomPermissions = [];
  for (const role of $accountInfo.roles) {
    const tenantId = $selectedClientContact?.userProfile?.tenant?.id ?? $accountInfo.tenant.id;

    if (role.tenantId === tenantId)
      for (const permission of role.permissions) {
        if (
          permission.resourceType === 'data_room' &&
          permission.action !== 'create' &&
          !dataRoomPermissions.some((dataRoomPermission) => dataRoomPermission.id === permission.id)
        ) {
          dataRoomPermissions.push(permission);
        }
      }
  }
  for (const recordLevelPermission of $accountInfo.recordLevelPermissions) {
    if (
      recordLevelPermission.resourceId === dataRoom.id &&
      recordLevelPermission.permission.resourceType === 'data_room' &&
      !dataRoomPermissions.some(
        (permission) => permission.id === recordLevelPermission.permission.id
      )
    ) {
      dataRoomPermissions.push(recordLevelPermission.permission);
    }
  }
  let checkedPermissionIds =
    recordLevelPermissionsForDataRoom
      ?.filter((recordLevelPermissionsForDataRoom) =>
        dataRoomPermissions.some(
          (dataRoomPermission) =>
            dataRoomPermission.id === recordLevelPermissionsForDataRoom.permission.id
        )
      )
      .map(
        (filteredRecordLevelPermissionForDataRoom) =>
          filteredRecordLevelPermissionForDataRoom.permission.id
      ) ?? [];
</script>

<Dialog persistent={true} active width={800}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{$_('data-rooms.share-data-room.edit')}</h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <div>{dataRoom.name}</div>
        <div class="pt-2 pb-2">
          {user.email}
        </div>
        <div class="pt-4">
          {#each dataRoomPermissions as dataRoomPermission}
            <Checkbox
              bind:group={checkedPermissionIds}
              name="permissions"
              value={dataRoomPermission.id}
            >
              {$_(`data-rooms.share-data-room.right-${dataRoomPermission.action}`)}
            </Checkbox>
          {/each}
        </div>

        {#if errorString !== ''}
          <div class="error-text">{errorString}</div>
        {/if}
        <Divider class="mt-4 secondary-color" />
        <Button on:click={replaceRecordLevelPermissions} text class="mt-4 secondary-color">
          {$_('data-rooms.share-data-room.save')}
        </Button>
        <Button class="mt-4 ml-2 secondary-color" on:click={() => dispatch('cancel')} text>
          {$_('data-rooms.share-data-room.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>

<style>
  table {
    border: 1px solid white;
    border-collapse: collapse;
  }
  thead > th:first-child {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }
  thead > th:last-child {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  th {
    border: 1px solid white;
    height: 41px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #1a76d2;
    color: white;
  }
  td {
    padding: 4px;
    border-bottom: 1px solid #1a76d2;
  }
</style>
