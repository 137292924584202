<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { Button, ExpansionPanel, Icon, ProgressLinear } from 'svelte-materialify';
  import {
    mdiDeleteOutline,
    mdiEyeOutline,
    mdiNoteMultipleOutline,
    mdiNoteOutline,
    mdiPencilOutline,
    mdiPlus,
  } from '@mdi/js';
  import Portal from 'svelte-portal';
  import { _, IP_SCOPE_GROUP } from '../services/i18n';
  import { apiFetch } from '../services/network';
  import NoteEditor from './NoteEditor.svelte';
  import DeleteNoteDialog from './DeleteNoteDialog.svelte';

  /**
   * @param ipType - string: one of the ip types or group types eg) IP_TYPE_PATENT
   */
  export let ipType;

  /**
   * @param ipScope - number: is the ipType for an 'item' or 'group'
   */
  export let ipScope;

  /**
   * @param scopeId - number: the identifier of the respective item or group
   */
  export let scopeId;

  /**
   * @param readonly - bool: are uploads and modifications allowed?
   */
  export let readonly = true;

  let tableElement;
  let showNoteEditorForNewNote = false;
  let showNoteEditorForExistingNote = false;
  let showNoteEditorReadOnly = false;
  let showDeleteNoteDialog = false;
  let selectedNoteUrl = '';
  let selectedNoteName = '';
  let selectedNote = null;
  let ipTypeAndScopeText;
  let notes = [];


  onMount(() => {
    if (ipScope === IP_SCOPE_GROUP) {
      ipTypeAndScopeText = $_(`${ipType}-group.singular`);
    }
    else {
      ipTypeAndScopeText = $_(`${ipType}.singular`);
    }
  });

  const dispatch = createEventDispatcher();

  function newNoteButtonClicked(event) {
    showNoteEditorForNewNote = true;
    selectedNote = null;
    selectedNoteName = '';
    selectedNoteUrl = `${getBaseUrl()}/note`;
  }

  function viewButtonClicked(event) {
    const element = event.currentTarget;
    const id = Number.parseInt(element.dataset.id);
    selectedNote = notes.find((note) => id === note.id);
    selectedNoteName = selectedNote.name;
    selectedNoteUrl = element.dataset.url;
    showNoteEditorForExistingNote = true;
    showNoteEditorReadOnly = true;
  }

  function editButtonClicked(event) {
    const element = event.currentTarget;
    const id = Number.parseInt(element.dataset.id);
    selectedNote = notes.find((note) => id === note.id);
    selectedNoteName = selectedNote.name;
    selectedNoteUrl = element.dataset.url;
    showNoteEditorForExistingNote = true;
    showNoteEditorReadOnly = false;
  }

  async function load() {
    let url = `/api/${ipType}`;
    if (ipScope === IP_SCOPE_GROUP) {
      url += `/${ipScope}`;
    }
    url += `/${scopeId}/note`;
    const response = await apiFetch(url);
    if (response.ok) {
      notes = await response.json();
    }
  }

  function noteSaved(event) {
    const { responsePayload } = event.detail;
    notes = responsePayload;
  }

  function formatDate(isoStr) {
    if (isoStr === '') {
      return '';
    }
    const d = Date.parse(isoStr);
    return new Intl.DateTimeFormat('fr-ca').format(d);
    // if( isoStr === "" ) { return ""; }
    // const d = Date.parse(isoStr);
    // const s = new Intl.DateTimeFormat('en').format(d);
    // return s;
  }

  function deleteButtonClicked(event) {
    const element = event.currentTarget;
    const id = Number.parseInt(element.dataset.id);
    selectedNote = notes.find((note) => id === note.id);
    selectedNoteName = selectedNote.name;
    selectedNoteUrl = element.dataset.url;
    showDeleteNoteDialog = true;
  }

  function noteDeleted(event) {
    const { responsePayload } = event.detail;
    notes = responsePayload;
  }

  function getBaseUrl() {
    let url = `/api/${ipType}`;
    if (ipScope === IP_SCOPE_GROUP) {
      url += '/group';
    }
    url += `/${scopeId}`;
    return url;
  }
</script>

<ExpansionPanel>
  <div slot="header" class="secondary-text">
    <span><Icon class="secondary-text" path={mdiNoteMultipleOutline} /></span>
    <span>{$_('notes.title')}</span>
  </div>
  <div>
    <Button
      disabled={readonly}
      class={readonly ? 'secondary-color grey-text mt-2 mb-2' : 'secondary-color mt-2 mb-2'}
      on:click={newNoteButtonClicked}
      text
      title={$_('notes.new')}
      fab
      size="small"
    >
      <Icon path={mdiPlus} />
    </Button>
    {#await load()}
      <ProgressLinear />
    {:then}
      {#if notes.length === 0}
        <div>
          {$_('notes.table.empty-state-text.no-notes', { values: { ipType: ipTypeAndScopeText } })}
          {$_('notes.table.empty-state-text.create-prompt')}
        </div>
      {:else}
        <table classList="mt-4" bind:this={tableElement}>
          <thead class="text-caption ">
            <th style="width: 130px;min-width: 124px;" />
            <th style="width: 300px;">{$_('notes.table.name')}</th>
            <th>{$_('notes.table.lastUpdated')}</th>
            <th>{$_('notes.table.owner')}</th>
            <th>{$_('notes.table.state.title')}</th>
          </thead>
          <tbody class="text-caption font-weight-thin">
              {#each notes as note, index}
                <tr>
                  <td>
                    <Button
                      icon
                      title={$_('notes.view')}
                      class="secondary-text"
                      data-id={note.id}
                      data-url="{getBaseUrl()}/note/{note.id}"
                      on:click={viewButtonClicked}
                    >
                      <Icon path={mdiEyeOutline} />
                    </Button>
                    <Button
                      icon
                      title={$_('notes.edit')}
                      disabled={readonly}
                      class={readonly ? '' : 'secondary-text'}
                      data-id={note.id}
                      data-url="{getBaseUrl()}/note/{note.id}"
                      on:click={editButtonClicked}
                    >
                      <Icon path={mdiPencilOutline} />
                    </Button>
                    <Button
                      icon
                      title={$_('notes.delete')}
                      disabled={readonly}
                      class={readonly ? '' : 'secondary-text'}
                      data-id={note.id}
                      data-url="{getBaseUrl()}/note/{note.id}"
                      on:click={deleteButtonClicked}
                    >
                      <Icon path={mdiDeleteOutline} />
                    </Button>
                  </td>
                  <td><Icon path={mdiNoteOutline} />{note.name ? note.name : ''}</td>
                  <td>{formatDate(note.updated)}</td>
                  <td>{note.owner.firstName} {note.owner.lastName}</td>
                  <td>{$_(`notes.table.state.${note.state}`)}</td>
                </tr>
              {/each}
            </tbody>
          </table>
      {/if}
    {/await}
  </div>
</ExpansionPanel>

<Portal target=".s-app">
  {#if showNoteEditorForNewNote}
    <NoteEditor bind:show={showNoteEditorForNewNote} on:save={noteSaved} url={selectedNoteUrl} />
  {/if}

  {#if showNoteEditorForExistingNote}
    <NoteEditor
      readonly={showNoteEditorReadOnly}
      bind:show={showNoteEditorForExistingNote}
      on:save={noteSaved}
      url={selectedNoteUrl}
      note={selectedNote}
    />
  {/if}

  {#if showDeleteNoteDialog}
    <DeleteNoteDialog
      bind:show={showDeleteNoteDialog}
      on:delete={noteDeleted}
      url={selectedNoteUrl}
      name={selectedNoteName}
    />
  {/if}
</Portal>

<style>
  table {
    border: 1px solid white;
    border-collapse: collapse;
  }
  thead > th:first-child {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }
  thead > th:last-child {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  th {
    border: 1px solid white;
    height: 41px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #1a76d2;
    color: white;
  }
  td {
    padding: 4px;
    border-bottom: 1px solid #1a76d2;
  }
</style>
