<script>
  import { createEventDispatcher } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Col,
    Container,
    Dialog,
    Divider,
    Row,
    TextField,
  } from 'svelte-materialify';
  import { _ } from '../../services/i18n';
  import { validateForm } from '../../services/validation';
  import { uploadFiles } from '../UploadProgressManager.svelte';

  const dispatch = createEventDispatcher();

  export let show;
  export let filesToUpload;
  export let listing;
  export let uploadUrl;
  export let senderId;
  export let folderName;

  const fullFileNames = filesToUpload.map((f) => f.name.trim());
  const baseNames = fullFileNames.map((n) => n.split('.').slice(0, -1).join('.'));
  const extensions = fullFileNames.map((n) => n.split('.').slice(-1)[0]);
  const duplicateNames = Array.from({ length: fullFileNames.length }).fill(false);

  let formElement;

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function save(event) {
    event.preventDefault();
    if (!validateForm(formElement)) {
      return;
    }
    if (duplicateNames.includes(true)) {
      return;
    }
    const fileNames = baseNames.map((name, index) => `${name}.${extensions[index]}`);
    uploadFiles(filesToUpload, fileNames, 'file-system', 'user-generated', uploadUrl, senderId);
    show = false;
  }

  function isDuplicate(filename, index) {
    for (const fileSystemNode of listing) {
      if (fileSystemNode.name.localeCompare(filename, undefined, { sensitivity: 'accent' }) === 0) {
        duplicateNames[index] = true;
        return true;
      }
    }
    duplicateNames[index] = false;
    return false;
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('files.upload-file-dialog.title', { values: { folderName } })}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="">
        <Container>
          {#each baseNames as baseName, index}
            <Row>
              <Col cols={10}>
                <TextField
                  bind:value={baseName}
                  outlined={true}
                  dense
                  clearable
                  color={'secondary'}
                  required
                >
                  {$_('files.upload-file-dialog.file-name')}
                </TextField>
              </Col>
              <Col class="pt-5 pl-0" cols={2}>
                <span class="font-weight-regular text-body-1">.{extensions[index]}</span>
              </Col>
            </Row>

            {#if isDuplicate(`${baseNames[index]}.${extensions[index]}`, index)}
              <Row>
                <span class="error-text">{$_('files.rename-file-dialog.duplicate-folder')}</span>
              </Row>
            {/if}
            <Divider class="mt-4 secondary-color" />
          {/each}
        </Container>
        <Button type="submit" class="mt-4 secondary-color" on:click={save} text>
          {$_('files.upload-file-dialog.upload')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('files.upload-file-dialog.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
