<script>
  import { createEventDispatcher } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    Select,
    TextField,
  } from 'svelte-materialify';
  import { _, getFolderSelectOptions } from '../services/i18n';
  import { uploadFiles } from './UploadProgressManager.svelte';

  /**
   * @param ipType - string: one of the ip types eg) IP_TYPE_PATENT
   */
  export let ipType;

  /**
   * @param ipScope - string: one of the ip scopes eg) IP_SCOPE_ITEM or IP_SCOPE_GROUP
   */
  export let ipScope;

  /**
   * @param folderSymbol - string: key string that indexes into folderDescriptions
   */
  export let folderSymbol;

  /**
   * @param url - string: indicating the server target for the file upload
   */
  export let url;

  /**
   * @param show - bool: show/hide the dialog
   */
  export let show = true;

  /**
   * @param filesToUpload array of files
   */
  export let filesToUpload;

  const dispatch = createEventDispatcher();

  let fileNames = [];

  $: {
    if (filesToUpload !== null) {
      initFileNames();
    }
  }

  function initFileNames() {
    fileNames = [];
    for (const file of filesToUpload) {
      fileNames.push(file.name);
    }
  }

  function reset() {
    show = false;
  }

  async function uploadButtonClicked(event) {
    event.preventDefault();
    const filesListId = location.hash.slice(2).replaceAll('/', '-');
    uploadFiles([...filesToUpload], fileNames, ipType, folderSymbol, url, filesListId);
    reset();
  }

  function cancelButtonClicked(event) {
    reset();
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{$_('upload.dialog.title')}</h6>
    </CardTitle>
    <CardText>
      <form>
        {#each filesToUpload as file, index}
          <Divider class="secondary-color" />
          <TextField outlined dense class="mt-2 secondary-text" bind:value={fileNames[index]}>
            {$_('upload.dialog.filename')}
          </TextField>
          <Select
            outlined
            dense
            class="mt-2 mb-2"
            items={getFolderSelectOptions(ipType, ipScope)}
            bind:value={folderSymbol}
            mandatory
          >
            {$_('upload.dialog.folder')}
          </Select>
          <Divider class="secondary-color" />
        {/each}
        <Button
          type="submit"
          class="mt-4 secondary-color"
          on:click={uploadButtonClicked}
          text
          disabled={filesToUpload === null}
        >
          {$_('upload.dialog.upload')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancelButtonClicked} text>
          {$_('upload.dialog.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>

<style>
</style>
