<script>
  import { Col, Divider, Icon, Row } from 'svelte-materialify';
  import { mdiAlert, mdiAlertCircle } from '@mdi/js';
  import { _ } from '../../services/i18n';
  import { isDateInPast } from '../../services/date';
  import { clientContacts } from '../../services/network';
  import { lookupCountry } from '../../services/countrieshelper';

  export let items = [];
  $: showReminders = items.some((item) => !!item.reminderDate);
  $: showFamilies = items.some((item) => !!item.ipFamily?.length);
  $: showCountries = items.some((item) => !!item.country);
</script>

<div class="container pl-4 mb-4">
  <table class="text-left">
    <thead>
      <th>
        {#if $clientContacts.length > 0}
          {$_('dashboard.reminders.table-headings.entity')} -
        {/if}
        {$_('dashboard.reminders.table-headings.ip-type')}
      </th>
      {#if showFamilies}
        <th>{$_('dashboard.reminders.table-headings.ip-family')}</th>
      {/if}
      {#if showCountries}
        <th>{$_('dashboard.reminders.table-headings.country')}</th>
      {/if}
      <th>{$_('dashboard.reminders.table-headings.title')}</th>
      <th>{$_('dashboard.reminders.table-headings.action-item')}</th>
      <th>{$_('dashboard.reminders.table-headings.due-date')}</th>
    </thead>
    <tbody>
      {#each items as item, index}
        <tr>
          <td>
            {#if $clientContacts.length > 0}
              {item.owningTenant.name} -
            {/if}
            <!-- IP Type -->
            <span class="text-capitalize">{item.ipType}</span>
          </td>
          {#if showFamilies}
            <td>
              <!-- Families -->
              {#if item.ipFamily}
                {item.ipFamily
                  .map((fam) => fam.name)
                  .sort()
                  .join(', ')}
              {/if}
            </td>
          {/if}
          {#if showCountries}
            <td>
              <!-- Countries -->
              {#await lookupCountry(item.country) then country}
                {country}
              {/await}
            </td>
          {/if}
          <td>
            <!-- LawFirm Identifier -->
            {#if item.lawFirmIdentifier}
              <span class="mr-1">
                {item.lawFirmIdentifier}
              </span>
            {/if}
            <!-- IP Name -->
            <a href="/{item.ipType}/item/{item.ipId}">
              {item.ipName}
            </a>
          </td>
          <td>{item.name}</td>
          <td>
            <div>
              {item.date}
              {#if isDateInPast(item.date)}
                <Icon size="16px" class="error-text" path={mdiAlertCircle} />
              {/if}
            </div>
            {#if item.reminderDate}
              <div class="text-caption">
                {item.reminderDate}
                ({$_('dashboard.reminders.table-headings.reminder-date')})
                {#if isDateInPast(item.reminderDate)}
                  <Icon size="16px" class="warning-text" path={mdiAlert} />
                {/if}
              </div>
            {/if}
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>

<style>
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  tr {
    border: 1px solid #e0e0e0;
    border-width: 1px 0;
  }
  th,
  td {
    padding: 4px;
  }
</style>
