<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Checkbox,
    Dialog,
    Divider,
    ProgressCircular,
  } from 'svelte-materialify';
  import { _, IP_SCOPE_GROUP, IP_SCOPE_ITEM } from '../../../services/i18n';
  import { apiFetch } from '../../../services/network';

  const dispatch = createEventDispatcher();
  export let show;
  export let tenantIdForPermissions;
  export let tenantIdForUsers = -1;
  export let userId;
  export let ipType;
  export let ipScope;
  export let scopeId;
  export let scopeName;
  export let permissionSet;

  let userIndex = -1;
  let userList = [];
  let chosen = [];
  let status = [];
  let errors = [];
  let applyDisabled = false;
  let showProgressSpinner = false;

  onMount(async () => {
    userList = await load();
  });

  async function load() {
    let url = `/api/user`;
    if (tenantIdForUsers > -1) {
      url += `?tenantId=${tenantIdForUsers}`;
    }
    const response = await apiFetch(url);
    if (response.ok) {
      const users = await response.json();
      const activeUsers = users.filter((i) => i.suspended === false);
      activeUsers.sort((a, b) => a.firstName.localeCompare(b.firstName));
      chosen = Array.from({ length: activeUsers.length }).fill(false);
      status = Array.from({ length: activeUsers.length }).fill('');
      errors = Array.from({ length: activeUsers.length }).fill('');
      userIndex = activeUsers.findIndex((user) => user.id == userId);
      if (userIndex >= 0) {
        chosen[userIndex] = true;
      }
      return activeUsers;
    }
    return [];
  }

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  function dismiss(event) {
    show = false;
    let errorCount = 0;
    for (const err of errors) {
      if (err != '') {
        errorCount++;
      }
    }
    dispatch('dismiss', { errorCount });
  }
  async function applyClicked(event) {
    event.preventDefault();
    showProgressSpinner = true;
    const permissions = [];
    for (const [i, element] of chosen.entries()) {
      if (element) {
        let url = `/api/user/${userList[i].id}/`;
        switch (ipScope) {
          case IP_SCOPE_ITEM: {
            url += 'item-access-permission';
            break;
          }
          case IP_SCOPE_GROUP: {
            url += 'group-access-permission';
            break;
          }
        }
        url += `/${ipType}/${scopeId}/${permissionSet}`;
        if (tenantIdForPermissions > -1) {
          url += `?tenantId=${tenantIdForPermissions}`;
        }
        const response = await apiFetch(url, 'POST');
        if (response.ok) {
          const item = await response.json();
          permissions.push(item);
          status[i] = $_('administration.users.apply-permissions-to-users-dialog.success');
        } else if (response.status === 409) {
          const err = await response.json();
          errors[i] = err.reason;
        }
      }
    }
    if (permissions.length > 0) {
      dispatch('save', { permissions });
    }
    showProgressSpinner = false;
    applyDisabled = true;
  }
</script>

<Dialog persistent={true} bind:active={show} width={800}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('administration.users.apply-permissions-to-users-dialog.title')}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color mb-4" />
      <div class="grey-text mb-4">
        {$_('administration.users.apply-permissions-to-users-dialog.sub-title', {
          values: {
            permissionSet,
            ipType: $_(`${ipType}.singular`),
            ipScope: ipScope == IP_SCOPE_ITEM ? '' : $_(`groups-and-items.groups.singular`),
            name: scopeName,
          },
        })}
      </div>
      <form>
        {#each userList as user, index}
          <table>
            <tbody>
              <tr>
                <td width="450px">
                  <Checkbox
                    bind:checked={chosen[index]}
                    color={userIndex === index ? '' : 'secondary'}
                    disabled={userIndex === index}
                  >
                    {user.firstName}
                    {user.lastName} ({user.email})
                  </Checkbox>
                </td>
                <td>
                  <span class="secondary-text">{status[index]}</span>
                  <span class="error-text">{errors[index]}</span>
                </td>
              </tr>
            </tbody>
          </table>
        {/each}

        <Divider class="mt-4 secondary-color" />
        {#if applyDisabled}
          <div class="text-center">
            <Button class="mt-4 ml-2 secondary-color" on:click={dismiss} text>
              {$_('administration.users.apply-permissions-to-users-dialog.dismiss')}
            </Button>
          </div>
        {:else}
          <Button type="submit" class="mt-4 ml-2 secondary-color" on:click={applyClicked} text>
            {#if showProgressSpinner}
              <ProgressCircular indeterminate color={'grey-color'} size={24} class="mr-3" />
            {/if}
            {$_('administration.users.apply-permissions-to-users-dialog.apply')}
          </Button>
          <Button class="mt-4 ml-2" on:click={cancel} text>
            {$_('administration.users.apply-permissions-to-users-dialog.cancel')}
          </Button>
        {/if}
      </form>
    </CardText>
  </Card>
</Dialog>

<style>
</style>
