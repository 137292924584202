<script>
  import { createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider, Icon } from 'svelte-materialify';
  import { mdiDelete } from '@mdi/js';
  import { _ } from '../services/i18n';
  import { apiFetch } from '../services/network';

  export let show;
  export let name;
  export let url;

  const dispatch = createEventDispatcher();

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function deleteFile(event) {
    event.preventDefault();

    const options = {
      method: 'DELETE',
    };
    const response = await apiFetch(url, 'DELETE');
    if (response.ok) {
      const attachments = await response.json();
      dispatch('delete', { responsePayload: attachments });
      show = false;
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{$_('files.delete')} {$_('files.singular')}</h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />

      <div class="pt-4">
        <h6>
          {$_('files.deleteConfirmation')}{name}?
        </h6>
      </div>

      <form>
        <Button type="submit" class="mt-4 error-color" on:click={deleteFile} text>
          <Icon path={mdiDelete} />
          {$_('files.delete')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('files.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
