<script>
  /**
   * TextField Is a wrapper around the TextField component from Materialify
   *
   * The primary function of this wrapper is to ensure validation occurs for provided rules and for required.
   */
  import { createEventDispatcher, onDestroy } from 'svelte';
  import { TextField } from 'svelte-materialify';
  import uid from 'svelte-materialify/src/internal/uid/index.js';
  import { _ } from '../services/i18n';

  const dispatch = createEventDispatcher();

  let klass = '';
  export { klass as class };
  export let value = '';
  export let color = 'primary';
  export let filled = false;
  export let solo = false;
  export let outlined = false;
  export let flat = false;
  export let dense = false;
  export let rounded = false;
  export let clearable = false;
  export let readonly = false;
  export let disabled = false;
  export let placeholder = null;
  export let hint = '';
  export let counter = false;
  export let messages = [];
  export let rules = [];
  export let errorCount = 1;
  export let error = false;
  export let success = false;
  export let id = `s-input-${uid(5)}`;
  export let style = null;
  export let inputElement = null;
  export let required = false;
  export let errorMessages = null;

  // clean up the event listener we registered
  onDestroy(() => {});

  // when the TextField looses focus, validate the field
  function validate() {
    if (!readonly) {
      let message = '';
      for (const ruleFunc of combinedRules) {
        const ret = ruleFunc(value);
        if (typeof ret === 'string') {
          message = ret;
          error = true;
          break;
        }
      }
      if (message.length === 0) {
        messages = [];
        error = false;
      } else {
        messages = [message];
        error = true;
      }
    }
  }

  function onkeyup(event) {
    validate();
    dispatch('keyup', event.detail);
  }
  function onblur(event) {
    validate();
    dispatch('blur', event.detail);
  }

  // when TextField value changes, check for validity
  $: {
    if (value != null) {
      validate();
    }
  }

  // internal validation rules
  const myRules = [requiredRule];

  // internal rules combined with rules provided by enclosing component
  let combinedRules = myRules.concat(rules);

  function getErrorMessage(keyStr) {
    if (errorMessages) {
      const message = errorMessages[keyStr];
      if (message) {
        return message;
      }
    }
    const message = $_(`defaultTextFieldErrorStrings.${keyStr}`);
    if (message) {
      return message;
    }
    return `error Message "${keyStr}" not found.`;
  }

  function requiredRule(v) {
    if (required && v.length === 0) {
      return getErrorMessage('required');
    }
    return true;
  }
</script>

<TextField
  class={klass}
  type="search"
  name="search"
  autocomplete="off"
  {color}
  {filled}
  {solo}
  {outlined}
  {flat}
  {dense}
  {rounded}
  {clearable}
  {readonly}
  {disabled}
  {hint}
  {counter}
  {messages}
  {errorCount}
  bind:error
  {success}
  {id}
  {style}
  bind:value
  on:focus
  on:blur={onblur}
  on:input
  on:change
  on:keypress
  on:keydown
  on:keyup={onkeyup}
  bind:placeholder
  bind:inputElement
>
  <slot />
</TextField>
