<script>
  import { onMount } from 'svelte';
  import { Button, Checkbox, Col, Icon, Overlay, Row, TextField } from 'svelte-materialify';
  import { mdiPencilOutline } from '@mdi/js';
  import { _ } from '../../services/i18n';

  export let classes;

  let active = false;
  let classesChecked;

  onMount(() => {
    classesChecked = Array.from({ length: 45 }).fill(false);
    const classesArray = JSON.parse(`[${classes}]`);
    for (const klass of classesArray) {
      classesChecked[klass - 1] = true;
    }
  });

  function editClassesButtonClicked(event) {
    active = !active;
    console.log(`editClassesButtonClicked active=${active}`);
  }

  function done(event) {
    active = false;
    console.log(`${JSON.stringify(classesChecked, null, 2)}`);
    classes = '';
    for (const [i, element] of classesChecked.entries()) {
      if (element) {
        if (classes !== '') {
          classes += ',';
        }
        classes += (i + 1).toString();
      }
    }
    console.log(`SelectClasses.done - "${classes}"`);
  }
</script>

<TextField bind:value={classes} class="pb-2" outlined dense color={'secondary'} readonly>
  <span slot="append">
    <Button icon on:click={editClassesButtonClicked}>
      <Icon path={mdiPencilOutline} />
    </Button>
  </span>
  {$_('trademark.table.classes')}
</TextField>

<div>
  <Overlay opacity={100} color="white" absolute bind:active>
    <h4>{$_('trademark.classes.title')}</h4>
    <a target="_blank" tinro-ignore href={$_('trademark.classes.wipoLinkUrl')}>
      {$_('trademark.classes.wipoLinkText')}
    </a>
    <Row>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[0]}>{1}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[1]}>{2}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[2]}>{3}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[3]}>{4}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[4]}>{5}</Checkbox></Col>
    </Row>
    <Row>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[5]}>{6}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[6]}>{7}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[7]}>{8}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[8]}>{9}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[9]}>{10}</Checkbox></Col>
    </Row>
    <Row>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[10]}>{11}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[11]}>{12}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[12]}>{13}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[13]}>{14}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[14]}>{15}</Checkbox></Col>
    </Row>
    <Row>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[15]}>{16}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[16]}>{17}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[17]}>{18}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[18]}>{19}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[19]}>{20}</Checkbox></Col>
    </Row>
    <Row>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[20]}>{21}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[21]}>{22}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[22]}>{23}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[23]}>{24}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[24]}>{25}</Checkbox></Col>
    </Row>
    <Row>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[25]}>{26}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[26]}>{27}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[27]}>{28}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[28]}>{29}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[29]}>{30}</Checkbox></Col>
    </Row>
    <Row>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[30]}>{31}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[31]}>{32}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[32]}>{33}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[33]}>{34}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[34]}>{35}</Checkbox></Col>
    </Row>
    <Row>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[35]}>{36}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[36]}>{37}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[37]}>{38}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[38]}>{39}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[39]}>{40}</Checkbox></Col>
    </Row>
    <Row>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[40]}>{41}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[41]}>{42}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[42]}>{43}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[43]}>{44}</Checkbox></Col>
      <Col><Checkbox color="secondary" bind:checked={classesChecked[44]}>{45}</Checkbox></Col>
    </Row>
    <Button block class="mt-4 secondary-color" on:click={done} text
      >{$_('trademark.classes.done')}</Button
    >
  </Overlay>
</div>
