<script>
  import { createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider } from 'svelte-materialify';
  import { _ } from '../../../services/i18n';
  import { validateForm } from '../../../services/validation';
  import { apiFetch } from '../../../services/network';

  const dispatch = createEventDispatcher();
  export let show;
  export let tenantId = -1;
  export let userId;
  export let groupPermission;

  let formElement;

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function save(event) {
    event.preventDefault();
    if (!validateForm(formElement)) {
      return;
    }

    let url = `/api/user/${userId}/group-access-permission/${groupPermission.id}`;
    const method = 'DELETE';
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }

    const response = await apiFetch(url, method);
    if (response.ok) {
      show = false;
      dispatch('delete', { id: groupPermission.id });
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('administration.users.group-permissions.delete-dialog-title')}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <div>
          {$_('administration.users.group-permissions.confirm-delete', {
            values: {
              groupType: groupPermission.group.groupType,
              groupName: groupPermission.group.name,
            },
          })}
        </div>
        <Divider class="mt-4 secondary-color" />
        <Button type="submit" class="mt-4 error-color" on:click={save} text>
          {$_('administration.users.group-permissions.delete')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('administration.users.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
