<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Checkbox,
    Dialog,
    Divider,
    Icon,
    ProgressLinear,
    Select,
    TextField,
  } from 'svelte-materialify';
  import { mdiMagnify } from '@mdi/js';
  import { _ } from '../../../services/i18n';
  import { validateForm } from '../../../services/validation';
  import { apiFetch } from '../../../services/network';
  // import TextField from '../../TextField.svelte';

  const dispatch = createEventDispatcher();

  export let tenantId = -1;
  export let clientId;
  export let existingContacts = [];

  let client;
  let clientUserProfile;
  const existingPrimaryContact = existingContacts.find(
    (existingContact) => existingContact.contactType === 'primary'
  );

  // We'll set the initial dropdown value to "secondary" if there's already a primary contact, as secondary contacts
  // are more likely to be created once a primary contact is defined.
  let contactType = existingPrimaryContact ? 'secondary' : 'primary';
  let phone;
  let info;

  let debounceSearchTimer;
  let searchString = '';
  let searchResults = [];

  let searchBar;
  let contactPhoneField;
  let contactInfoField;
  let isFocused = false;

  const contactTypeItems = [
    { name: 'primary', value: 'primary' },
    { name: 'secondary', value: 'secondary' },
  ];

  let formElement;

  $: saveButtonDisabled = !(client && clientUserProfile);

  afterUpdate(() => {
    if (!clientUserProfile && searchBar) {
      searchBar.focus();
    }
    // afterUpdate is called after every change, which means every time a chatacter is entered in the text field.
    // Using isFocused to a focus is only done once.
    // Otherwise after typing one chatacter in phone, it would jump to the info field.
    if (!isFocused && clientUserProfile && !phone) {
      contactPhoneField.focus();
      isFocused = true;
    }
  });

  async function load() {
    let url = `/api/admin/client/${clientId}`;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const response = await apiFetch(url);
    if (response.ok) {
      client = await response.json();
    }
  }

  function cancel(event) {
    dispatch('cancel');
  }

  async function search() {
    clearTimeout(debounceSearchTimer);

    async function innerSearch() {
      const searchTerm = searchString.toLowerCase();
      if (searchTerm.length < 2) {
        return;
      }
      let url = `/api/admin/client/${client.clientTenant.id}/search-users/${searchTerm}`;
      if (tenantId > -1) {
        url += `?tenantId=${tenantId}`;
      }
      const response = await apiFetch(url, 'POST');
      if (response.ok) {
        searchResults = await response.json();
      }
    }
    debounceSearchTimer = setTimeout(innerSearch, 500);
  }

  async function save(event) {
    console.log('In save');
    if (clientUserProfile) {
      event.preventDefault();
      if (!validateForm(formElement)) {
        return;
      }

      let url = `/api/admin/client/${client.id}/contact`;
      if (tenantId > -1) {
        url += `?tenantId=${tenantId}`;
      }
      const request = {
        clientUserProfileId: clientUserProfile.id,
        contactType,
        phone,
        info,
      };
      const response = await apiFetch(url, 'POST', request);
      if (response.ok) {
        const item = await response.json();

        // If we have a new primary contact, we need to downgrade the existing primary contact to secondary
        // It's done on the server, but the response only includes the object acted on (/client/:id), so we need to monkey patch it on the client-side as well
        const maybeUpdate =
          item.contactType === 'primary'
            ? { update: { ...existingPrimaryContact, contactType: 'secondary' } }
            : {};
        dispatch('save', {
          // FIXME: Remove this business logic when DataTable component is able to detect changes to the `rowData` prop.
          create: item,
          ...maybeUpdate,
        });
      }
    } else {
    }
  }

  function userProfileSelected(event) {
    const element = event.currentTarget;
    const { id } = element;
    const index = Number.parseInt(id.split('-')[1]);
    clientUserProfile = searchResults[index];
  }
</script>

<Dialog persistent={true} active>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('administration.clients.contacts.new')}
        {$_('administration.clients.contacts.singular')}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      {#await load()}
        <ProgressLinear />
      {:then}
        <form bind:this={formElement} class="pt-4">
          {#if !clientUserProfile}
            <TextField
              type="search"
              name="search"
              autocomplete="off"
              bind:value={searchString}
              bind:inputElement={searchBar}
              on:keyup={search}
              style="max-width: 400px;"
              class="pt-2 mb-4"
              color={'blue'}
              outlined
              rounded
              dense
            >
              <div slot="prepend">
                <Icon path={mdiMagnify} />
              </div>
              {$_('administration.clients.contacts.search-users')}
            </TextField>
            {#if searchString.length > 0 && searchResults.length > 0}
              {#each searchResults as searchResult, index}
                {@const alreadyExists = existingContacts.some(
                  (existingContact) => existingContact.userProfile.email === searchResult.email
                )}
                <Checkbox
                  color="secondary"
                  id="company-{index}"
                  disabled={alreadyExists}
                  checked={alreadyExists}
                  on:change={userProfileSelected}
                >
                  {searchResult.email}
                  {#if alreadyExists}
                    {' - '}{$_('administration.clients.contacts.already-exists')}
                  {/if}
                </Checkbox>
              {/each}
            {/if}
          {:else}
            <div class="pb-2">
              <span>{$_('administration.clients.contacts.table.email')}</span><span> : </span><span
                >{clientUserProfile.email}</span
              >
            </div>
            <Select
              outlined
              dense
              items={contactTypeItems}
              bind:value={contactType}
              class="pb-2"
              mandatory
            >
              {$_('administration.clients.contacts.table.type')}
            </Select>
            <TextField
              bind:value={phone}
              bind:inputElement={contactPhoneField}
              class="pb-2"
              outlined={true}
              dense
              clearable
              color={'secondary'}
            >
              {$_('administration.clients.contacts.table.phone')}
            </TextField>
            <TextField
              bind:value={info}
              bind:inputElement={contactInfoField}
              class="pb-2"
              outlined={true}
              dense
              clearable
              color={'secondary'}
            >
              {$_('administration.clients.contacts.table.info')}
            </TextField>
          {/if}
          <Divider class="mt-4 secondary-color" />
          {#if contactType === 'primary' && existingPrimaryContact}
            <div class="warning-text text-caption mt-4">
              {$_('administration.clients.contacts.downgrade-primary-warning', {
                values: {
                  currentPrimaryContactEmail: existingPrimaryContact.userProfile.email,
                  newPrimaryContactEmail: clientUserProfile.email,
                },
              })}
            </div>
          {/if}
          <Button
            type="submit"
            class="mt-4 {saveButtonDisabled ? 'grey-color' : 'secondary-color'}"
            on:click={save}
            text
            disabled={saveButtonDisabled}
          >
            {$_('administration.clients.contacts.save')}
          </Button>
          <Button class="mt-4 ml-2" on:click={cancel} text>
            {$_('administration.clients.contacts.cancel')}
          </Button>
        </form>
      {/await}
    </CardText>
  </Card>
</Dialog>
