<script context="module">
  let uploadProgressManagerElement;

  export function uploadFiles(filesToUpload, fileNames, ipType, folderSymbol, url, sender) {
    const eventDetail = { files: filesToUpload, fileNames, ipType, folderSymbol, url, sender };
    const event = new CustomEvent('upload-files', { detail: eventDetail });
    if (!uploadProgressManagerElement) {
      uploadProgressManagerElement = document.querySelector('#upload-progress-manager');
    }
    uploadProgressManagerElement.dispatchEvent(event);
  }
</script>

<script>
  import { createEventDispatcher, onMount, onDestroy } from 'svelte';
  import {
    ProgressLinear,
    Icon,
    Row,
    Col,
    Button,
    Snackbar,
    Card,
    CardTitle,
    CardText,
    Divider,
    Dialog,
    ItemGroup,
  } from 'svelte-materialify';
  import { mdiCheckOutline, mdiWindowClose, mdiWindowMinimize, mdiWindowMaximize } from '@mdi/js';
  import { _ } from '../services/i18n';
  import { authenticated } from '../services/account';
  import { apiFormatUrl } from '../services/network';

  let currentlyUploading = null;
  let percentUploaded = 0;
  let uploadQueue = [];
  let processedList = [];
  let show = false;
  let showConfirmCloseDialog = false;
  let maximized = true;
  let minimizeMaximizeIcon = mdiWindowMinimize;
  let minimizeMaximizeTitle = $_('upload.progress-manager.minimize');
  $: {
    if (maximized) {
      minimizeMaximizeIcon = mdiWindowMinimize;
      minimizeMaximizeTitle = $_('upload.progress-manager.minimize');
    } else {
      minimizeMaximizeIcon = mdiWindowMaximize;
      minimizeMaximizeTitle = $_('upload.progress-manager.maximize');
    }
  }

  $: {
    if (show) {
      window.addEventListener('beforeunload', beforeUnload);
    } else {
      window.removeEventListener('beforeunload', beforeUnload);
    }
  }

  function beforeUnload(event) {
    if (uploadQueue.length > 0 || currentlyUploading != null) {
      event.preventDefault();
      const str = $_('upload.progress-manager.unfinished');
      event.returnValue = str;
      return str;
    }
    return false;
  }

  const dispatch = createEventDispatcher();

  onMount(() => {});

  onDestroy(() => {
    uploadProgressManagerElement = undefined;
  });

  function reset() {
    show = false;
    currentlyUploading = null;
    percentUploaded = 0;
    uploadQueue = [];
    processedList = [];
    showConfirmCloseDialog = false;
  }

  function minimizeMaximizeClicked(event) {
    maximized = !maximized;
  }

  function uploadToServer(file) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('attachment', file.file);
      formData.append('fileName', file.fileName);
      formData.append('ipType', file.ipType);
      formData.append('folderSymbol', file.folderSymbol);

      // use XHR rather than fetch so we can get upload progress
      const xhr = new XMLHttpRequest();
      const formattedUrl = apiFormatUrl(file.url);
      xhr.open('POST', formattedUrl);
      function progressUpdate(event) {
        const percent = event.lengthComputable ? (event.loaded / event.total) * 100 : 0;
        const fixed = percent.toFixed(0);
        percentUploaded = fixed === '100' ? 99 : Number.parseInt(fixed);
      }
      xhr.upload.addEventListener('progress', progressUpdate);
      xhr.withCredentials = true;
      xhr.addEventListener('readystatechange', async () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200 || xhr.status === 201) {
            percentUploaded = 100;
            const uploadResponse = JSON.parse(xhr.responseText);
            await delay(200);
            resolve(uploadResponse);
          } else if (xhr.status === 401) {
            authenticated.update((auth) => false);
          } else {
            file.showError = true;
            console.error(
              `ERROR: uploadToServer() "${file.fileName}" FAIlED. - ${xhr.status} ${xhr.responseText} doing POST to ${formattedUrl}`
            );
            reject();
          }
        }
      });
      xhr.send(formData);
    });
  }

  function delay(ms) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms);
    });
  }

  async function uploadAllFiles() {
    while ((currentlyUploading = uploadQueue.shift()) !== undefined) {
      try {
        uploadQueue = uploadQueue.concat([]);
        percentUploaded = 0;
        const resultItem = await uploadToServer(currentlyUploading);
        const element = document.getElementById(currentlyUploading.sender);
        console.log(
          `UploadProgressManager.uploadAllFiles - should notify "${currentlyUploading.sender}"`
        );
        if (element) {
          const event = new CustomEvent('file-uploaded', {
            detail: { responsePayload: resultItem },
          });
          console.log(
            `UploadProgressManager.uploadAllFiles - ${currentlyUploading.sender} - file-uploaded`
          );
          element.dispatchEvent(event);
        }
        processedList.unshift(currentlyUploading);
        processedList = processedList.concat([]);
        currentlyUploading = null;
      } catch (error) {
        console.error(`uploadAllFiles() Exception: ${JSON.stringify(error, null, 2)}`);
      }
    }
    if (currentlyUploading === undefined) {
      currentlyUploading = null;
    }
  }

  function uploadFilesEventHandler(event) {
    let noUploadsInProgress = false;
    if (uploadQueue.length === 0 && currentlyUploading === null) {
      noUploadsInProgress = true;
    }
    let index = 0;
    for (const file of event.detail.files) {
      const item = {
        file,
        fileName: event.detail.fileNames[index],
        ipType: event.detail.ipType,
        folderSymbol: event.detail.folderSymbol,
        url: event.detail.url,
        sender: event.detail.sender,
        showError: false,
        percentUploaded: 0,
      };
      uploadQueue.push(item);
      uploadQueue = uploadQueue.concat([]);
      index++;
    }
    show = true;
    if (noUploadsInProgress) {
      uploadAllFiles();
    }
  }

  function closeButtonClicked(event) {
    if (uploadQueue.length === 0 && currentlyUploading === null) {
      reset();
    } else {
      showConfirmCloseDialog = true;
    }
  }

  function confirmClose(event) {
    reset();
  }

  function cancel(event) {
    showConfirmCloseDialog = false;
  }
</script>

<div id="upload-progress-manager" on:upload-files={uploadFilesEventHandler}>
  <Snackbar bind:active={show} right={true} bottom={true} outlined class="white">
    <Row
      ><Col cols={12}>
        <Card style="width: 350px;max-height: 350px;">
          <CardTitle>
            <div class="" style="width:100%;">
              <span class="secondary-text text-h5">{$_('upload.progress-manager.title')}</span>
              {#if !maximized}
                <span class="text-subtitle-2">
                  {$_('upload.progress-manager.finished')}: {processedList.length}</span
                >
                <span class="text-subtitle-2">
                  {$_('upload.progress-manager.waiting')}: {uploadQueue.length}</span
                >
              {/if}
              <span class="float-right">
                <Button
                  class=""
                  fab
                  size="x-small"
                  title={minimizeMaximizeTitle}
                  on:click={minimizeMaximizeClicked}
                >
                  <Icon path={minimizeMaximizeIcon} />
                </Button>
                <Button
                  class=""
                  fab
                  size="x-small"
                  title={$_('upload.progress-manager.close')}
                  on:click={closeButtonClicked}
                  text
                >
                  <Icon path={mdiWindowClose} />
                </Button>
              </span>
            </div>
          </CardTitle>
          {#if maximized}
            <CardText style="max-height: 300px;overflow-y:auto">
              <form>
                {#if currentlyUploading}
                  <Divider class="secondary-color" />
                  <div class="text-center font-weight-bold">
                    {currentlyUploading.fileName}
                  </div>
                  <ProgressLinear
                    class="mb-2"
                    height="16px"
                    backgroundColor="secondary"
                    bind:value={percentUploaded}
                  >
                    <div class={percentUploaded < 50 ? 'black-text' : 'white-text'}>
                      {percentUploaded} %
                    </div>
                  </ProgressLinear>
                {/if}

                {#each uploadQueue as queuedItem}
                  <Divider class="secondary-color" />
                  <div class="text-center font-weight-bold">
                    {queuedItem.fileName} -
                    <span class="text-center mb-2 font-weight-light"
                      >{$_('upload.progress-manager.waiting')}</span
                    >
                  </div>
                {/each}

                {#each processedList as processedItem}
                  {#if processedItem.showError}
                    <Divider class="secondary-color" />
                    <div class="text-center font-weight-bold">
                      {processedItem.fileName} -
                      <span class="text-center mb-2 error-text"
                        >{$_('upload.progress-manager.error')}</span
                      >
                    </div>
                  {:else}
                    <Divider class="secondary-color" />
                    <Icon path={mdiCheckOutline} class="success-text" /><span
                      class="font-weight-bold ml-2">{processedItem.fileName}</span
                    >
                  {/if}
                {/each}
                <Divider class="secondary-color" />
              </form>
            </CardText>
          {/if}
        </Card>
      </Col></Row
    >
  </Snackbar>
</div>

{#if showConfirmCloseDialog}
  <Dialog persistent={true} bind:active={showConfirmCloseDialog}>
    <Card outlined={true} raised={true}>
      <CardTitle>
        <h6 class="secondary-text">{$_('upload.close-confirmation.title')}</h6>
      </CardTitle>
      <CardText>
        <Divider class="secondary-color" />

        <div class="pt-4">
          <h6>
            {$_('upload.close-confirmation.message')}
          </h6>
        </div>

        <form>
          <Button type="submit" class="mt-4 error-color" on:click={confirmClose} text>
            <Icon path={mdiWindowClose} />
            {$_('upload.close-confirmation.close')}
          </Button>
          <Button class="mt-4 ml-2" on:click={cancel} text>
            {$_('upload.close-confirmation.dont-close')}
          </Button>
        </form>
      </CardText>
    </Card>
  </Dialog>
{/if}

<style>
</style>
