<script>
  import { Button, Icon, Snackbar } from 'svelte-materialify';
  import { mdiReload, mdiWindowClose } from '@mdi/js';
  import { _ } from '../services/i18n';

  let show = false;
  let dismissed = false;
  let dismissedAt;

  async function checkAvailableVersion() {
    // the constant webuiVersion is defined in index.html but only in production builds
    if (typeof webuiVersion !== 'undefined') {
      const now = Date.now() / 1000;
      const response = await fetch(`/version.json?now=${now}`); // add timestamp to query to defeat caching
      if (response.ok) {
        const availableVersion = await response.json();
        if (availableVersion.webuiVersion !== webuiVersion) {
          // If the notification was previously shown and dismissed but more than an hour has passed, then show again
          if (dismissed) {
            if (now - dismissedAt > 60 * 60) {
              show = true;
              dismissed = false;
            }
          } else {
            show = true;
          }
        }
      }
    }
    setTimeout(checkAvailableVersion, 60 * 60 * 1000); // check every hour
  }

  function reloadButtonClicked(event) {
    location.reload();
  }

  function closeButtonClicked(event) {
    show = false;
    dismissed = true;
    dismissedAt = Date.now() / 1000;
  }
</script>

{#await checkAvailableVersion()}
  <span />
{:then}
  {#if show}
    <Snackbar absolute center right outlined>
      <div>
        {$_('version-check.message')}
      </div>
      <div class="float-right">
        <Button
          class="secondary-text"
          fab
          size="x-small"
          title={$_('version-check.reload')}
          on:click={reloadButtonClicked}
          text
        >
          <Icon path={mdiReload} />
        </Button>
        <Button
          class=""
          fab
          size="x-small"
          title={$_('version-check.close')}
          on:click={closeButtonClicked}
          text
        >
          <Icon path={mdiWindowClose} />
        </Button>
      </div>
    </Snackbar>/>
  {/if}
{/await}
