<script>
  import { Route, router } from 'tinro';
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import { Breadcrumbs, ProgressLinear } from 'svelte-materialify';
  import { mdiDeleteOutline, mdiFolderOpenOutline, mdiPencilOutline, mdiPlus } from '@mdi/js';
  import { accountHasTenantPermission, SYSTEM_WIDE_TENANT_ADMIN } from '../../../services/account';
  import { apiFetch } from '../../../services/network';
  import { _ } from '../../../services/i18n';
  import AddClient from './AddClient.svelte';
  import UpdateClient from './UpdateClient.svelte';
  import DeleteClient from './DeleteClient.svelte';
  import ClientDetail from './ClientDetail.svelte';
  import DataTable from '../../DataTable.svelte';
  import { cellRendererFactory } from '../../DataTable/CellRendererFactory';
  import DataTableRowActions from '../../DataTable/DataTableRowActions.svelte';

  export let selectOnly = false;
  export let tenantId = -1;
  export let initialBreadCrumbItems = [];

  export let breadCrumbItems = [];
  let showAddClientContactDialog;
  let showAddClientDialog = false;
  let showUpdateClientDialog = false;
  let showDeleteClientDialog = false;
  const tableElement = null;
  const searchTerm = '';
  let clientList = [];
  let dataTable;
  let currentItem;

  $: {
    if (searchTerm === '') {
      search();
    }
  }

  const dispatch = createEventDispatcher();

  const rowActions = [
    {
      type: 'button',
      enabledFunction: isViewActionEnabled,
      href: `/administration/tenant/${tenantId}/client/`,
      title: $_('administration.clients.open'),
      path: mdiFolderOpenOutline,
      onClickEvent: 'viewButtonClicked',
      elementId: 'entities',
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isUpdateActionEnabled,
      href: '',
      title: $_('administration.clients.edit'),
      path: mdiPencilOutline,
      onClickEvent: 'editButtonClicked',
      elementId: 'entities',
      value: '',
    },
    {
      type: 'copyLinkButton',
    },
    {
      type: 'button',
      enabledFunction: isDeleteActionEnabled,
      href: '',
      title: $_('administration.clients.delete'),
      path: mdiDeleteOutline,
      onClickEvent: 'deleteButtonClicked',
      cssClasses: ['red-text'],
      elementId: 'entities',
      value: '',
    },
  ];

  function addColumn() {
    for (const element of clientList) {
      element.name = element.clientTenant.name;
    }
  }

  const columnDefs = [
    { field: 'id', headerName: '', hide: true, suppressColumnsToolPanel: true },
    {
      field: 'buttons',
      headerName: '',
      pinned: 'left',
      resizable: false,
      sortable: false,
      maxWidth: 40,
      editable: false,
      cellClass: 'table-actions pa-0 text-center',
      cellStyle: { cursor: 'pointer' },
      cellRenderer: cellRendererFactory((cell) => {
        new DataTableRowActions({
          target: cell.eGui,
        });
      }),
      suppressColumnsToolPanel: true,
    },
    {
      field: 'name',
      headerName: $_('administration.clients.table.name'),
      cellRenderer: 'linkRenderer',
      sortable: true,
      filter: true,
      resizable: true,

      sort: 'asc',
    },
    {
      field: 'lawFirmIdentifier',
      headerName: $_('administration.clients.table.lawFirmId'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'createdDate',
      headerName: $_('administration.clients.table.created'),
      suppressColumnsToolPanel: true,
    },
    {
      field: 'updatedDate',
      headerName: $_('administration.clients.table.updated'),
      resizable: true,
      suppressColumnsToolPanel: true,
    },
  ];

  // same as rowActions but buttons go above the datatable
  const globalActions = [
    {
      enabledFunction: isUpdateActionEnabled,
      href: '',
      title: $_('administration.clients.new'),
      path: mdiPlus,
      onClickEvent: 'newClicked',
      elementId: 'entities',
    },
  ];

  const linkActions = [
    {
      enabledFunction: isViewActionEnabled,
      href: `/administration/tenant/${tenantId}/client/`,
      title: $_('administration.clients.open'),
      onClickEvent: 'viewButtonClicked',
      elementId: 'entities',
      getNameFunction: getName,
    },
  ];

  function isViewActionEnabled(item) {
    return true;
  }

  function isUpdateActionEnabled(item) {
    return accountHasTenantPermission(SYSTEM_WIDE_TENANT_ADMIN);
  }

  function isDeleteActionEnabled(item) {
    return accountHasTenantPermission(SYSTEM_WIDE_TENANT_ADMIN);
  }

  function getName(item) {
    return item.clientTenant.name;
  }

  onMount(() => {});

  onDestroy(() => {});

  async function load() {
    showAddClientContactDialog = false;
    let url = `/api/admin/client`;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const response = await apiFetch(url);
    if (response.ok) {
      clientList = await response.json();
      addColumn();
      if (tenantId > -1) {
        breadCrumbItems = accountHasTenantPermission(SYSTEM_WIDE_TENANT_ADMIN)
          ? initialBreadCrumbItems.concat([
              {
                text: `${$_('administration.clients.title')}`,
                href: `/administration/tenant/${tenantId}/client`,
              },
            ])
          : [
              { text: `${$_('administration.titlen')}`, href: `/administration` },
              {
                text: `${$_('administration.clients.title')}`,
                href: `/administration/tenant/${tenantId}/client`,
              },
            ];
      } else if (accountHasTenantPermission(SYSTEM_WIDE_TENANT_ADMIN)) {
        breadCrumbItems = breadCrumbItems.concat([
          { text: `${$_('administration.clients.title')}`, href: `/administration/client` },
        ]);
      } else {
        breadCrumbItems = [
          { text: `${$_('administration.title')}`, href: `/administration` },
          { text: `${$_('administration.clients.title')}`, href: `/administration/client` },
        ];
      }
    }
  }

  function newClicked(event) {
    currentItem = event.detail.item;
    showAddClientDialog = true;
  }

  function findClientById(id) {
    return clientList.find((item) => {
      if (item.id === id) {
        return item;
      }
    });
  }

  function viewButtonClicked(event) {
    const { href } = event.detail.action;
    router.goto(href + event.detail.id);
  }

  function editButtonClicked(event) {
    currentItem = event.detail.data;
    showUpdateClientDialog = true;
  }

  function deleteButtonClicked(event) {
    currentItem = event.detail.data;
    showDeleteClientDialog = true;
  }

  async function updatedItemSaved(event) {
    const { item } = event.detail;
    const { id } = item;
    currentItem = event.detail.item;
    dataTable.updateRow(id);
  }

  function updateCurrentItem(event) {
    currentItem = event.detail.item;
  }

  async function newItemAdded(event) {
    const newItem = event.detail.item;
    clientList.push(newItem);
    currentItem = event.detail.item;
    dataTable.addNewRows([newItem]);
  }

  async function itemDeleted(event) {
    currentItem = findClientById(event.detail.id);
    dataTable.deleteRows(event.detail.id);
    for (let i = 0; i < clientList.length; i++) {
      if (clientList[i].id == event.detail.id) {
        clientList.splice(i, 1);
        break;
      }
    }
  }

  function search() {
    if (tableElement === null) {
      return;
    }
    const filter = searchTerm.toLowerCase();
    const rows = tableElement.querySelectorAll('tr');
    for (const row_ of rows) {
      const cols = row_.querySelectorAll('td');
      let found = false;
      for (const col_ of cols) {
        const txtValue = col_.textContent || col_.innerText;
        if (txtValue.toLowerCase().includes(filter)) {
          found = true;
        }
      }
      row_.style.display = found ? '' : 'none';
    }
  }
</script>

<Route path="/">
  <Breadcrumbs bind:items={breadCrumbItems} />

  {#await load()}
    <ProgressLinear indeterminate color="secondary" />
  {:then}
    <div id="entities" on:updateCurrentItem={updateCurrentItem} on:newClicked={newClicked}>
      <DataTable
        bind:rowData={clientList}
        bind:this={dataTable}
        {columnDefs}
        {rowActions}
        {globalActions}
        {linkActions}
        tableName="entities"
        on:viewButtonClicked={viewButtonClicked}
        on:editButtonClicked={editButtonClicked}
        on:deleteButtonClicked={deleteButtonClicked}
      />
    </div>
  {/await}
</Route>
{#if showAddClientDialog}
  <AddClient
    bind:show={showAddClientDialog}
    {tenantId}
    on:save={newItemAdded}
    on:addContact={(showAddClientContactDialog = true)}
  />
{/if}
{#if showUpdateClientDialog}
  <UpdateClient
    bind:show={showUpdateClientDialog}
    {tenantId}
    client={currentItem}
    on:save={updatedItemSaved}
  />
{/if}
{#if showDeleteClientDialog}
  <DeleteClient
    bind:show={showDeleteClientDialog}
    {tenantId}
    client={currentItem}
    on:delete={itemDeleted}
  />
{/if}
<Route path="/:id" let:meta>
  <ClientDetail
    {showAddClientContactDialog}
    {tenantId}
    clientId={meta.params.id}
    initialBreadCrumbItems={breadCrumbItems}
  />
</Route>

<style>
  table {
    border: 1px solid white;
    border-collapse: collapse;
  }
  thead > th:first-child {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }
  thead > th:last-child {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  th {
    border: 1px solid white;
    height: 41px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #1a76d2;
    color: white;
  }
  td {
    padding: 4px;
    border-bottom: 1px solid #1a76d2;
  }
</style>
