<script>
  import { mdiDeleteOutline, mdiMagnify, mdiPencilOutline, mdiWindowClose } from '@mdi/js';
  import { capitalize, keyBy } from 'lodash-es';
  import { afterUpdate, createEventDispatcher, onMount } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Checkbox,
    Dialog,
    Divider,
    Icon,
    ProgressCircular,
    Radio,
    TextField,
  } from 'svelte-materialify';
  import { accountInfo } from '../../services/account';
  import { _ } from '../../services/i18n';
  import { apiFetch, selectedClientContact } from '../../services/network';
  import DeleteUserFromDataRoomSharing from './DeleteUserFromDataRoomSharing.svelte';
  import EditPermission from './EditPermission.svelte';

  export let fileSystemNode;
  let checkedPermissionIds = [];
  let nodeName = '';
  let isFocused = false;

  let debounceSearchTimer;
  let searchString = '';
  let searchBarField;
  let userProfileSearchResults = [];
  let selectedUserId;
  let selectedUserForDeletion;
  let selectedUserForEditing;

  // Holds the responses from API calls
  let recordLevelPermissions = [];
  let roles = [];
  const usersWithRoles = [];

  let loading;
  let error;

  // build out list of users who have permissions through a role
  $: {
    for (const role of roles) {
      for (const user of role.users) {
        const existingUser = usersWithRoles.find((existingUser) => existingUser.id === user.id);
        if (existingUser) {
          for (const permission of role.permissions) {
            if (
              !existingUser.permissions.some(
                (existingPermission) => permission.id === existingPermission.id
              )
            )
              existingUser.permissions.push(permission);
          }
        } else {
          user.permissions = role.permissions;
          usersWithRoles.push(user);
        }
      }
    }
  }

  $: selectedUser = selectedUserId
    ? userProfileSearchResults.find((item) => item.id === selectedUserId)
    : undefined;

  async function load() {
    const response = await apiFetch(
      `/record-level-permission/resource/data_room/${fileSystemNode.id}`
    );
    if (response.ok) {
      recordLevelPermissions = await response.json();
    }
    const rolesResponse = await apiFetch(`/role/tenant/resource/data_room`);
    if (rolesResponse.ok) {
      roles = await rolesResponse.json();
    }
  }

  onMount(async () => {
    loading = true;
    try {
      await load();
    } catch (_error) {
      error = _error;
    } finally {
      loading = false;
    }
    if (searchString === '') {
      searchBarField.focus();
    }
    if (fileSystemNode.name && fileSystemNode.name.length > 0) {
      nodeName = fileSystemNode.name;
    }
    if (fileSystemNode.mimeType === 'inode/directory' && nodeName.length === 0) {
      nodeName = $_(`file-system.${fileSystemNode.symbolName}.title`);
    }
  });

  const replaceRecordLevelPermissions = () =>
    apiFetch(
      `/record-level-permission/user/${selectedUser.id}/data_room/${fileSystemNode.id}`,
      'PUT',
      checkedPermissionIds.map((permissionId) => ({
        id: permissionId,
      }))
    ).then(async (response) => {
      if (response.ok) {
        await load();
        userProfileSearchResults = [];
        selectedUserId = undefined;
        checkedPermissionIds = [];
        searchString = '';
      }
    });

  const dataRoomPermissions = [];
  for (const role of $accountInfo.roles) {
    const tenantId = $selectedClientContact?.userProfile?.tenant?.id ?? $accountInfo.tenant.id;

    if (role.tenantId === tenantId)
      for (const permission of role.permissions) {
        // Remove "create" action since it makes no sense on a record that already exists
        if (permission.resourceType === 'data_room' && permission.action !== 'create') {
          dataRoomPermissions.push(permission);
        }
      }
  }
  for (const recordLevelPermission of $accountInfo.recordLevelPermissions) {
    if (
      recordLevelPermission.resourceId === fileSystemNode.id &&
      recordLevelPermission.permission.resourceType === 'data_room'
    ) {
      dataRoomPermissions.push(recordLevelPermission.permission);
    }
  }

  afterUpdate(async () => {
    if (!isFocused && !searchString) {
      searchBarField.focus();
      isFocused = true;
    }
  });

  async function searchUsers(event) {
    clearTimeout(debounceSearchTimer);
    event.preventDefault();

    async function innerSearch() {
      const searchTerm = searchString.toLowerCase();
      if (searchTerm.length < 2) {
        return;
      }
      const response = await apiFetch(
        `/api/file-system/node/${fileSystemNode.id}/search-users/${searchTerm}`,
        'POST'
      );
      if (response.ok) {
        const results = await response.json();
        // filter out search results that are already in our share item list
        userProfileSearchResults = results;
      }
    }
    debounceSearchTimer = setTimeout(innerSearch, 500);
  }

  function canEditOrDeleteRecordLevelPermissionForUser(user) {
    // can't edit or delete your own record-level-permission
    if (user.id === $accountInfo.id) {
      return false;
    }
    // need to determine if the requestor is part of the tenant that owns the Data Room.
    //     if NOT, then only allow editing of users who are part of the requestors tenancy.
    if (fileSystemNode.tenant.id === $accountInfo.tenant.id) {
      return true;
    }

    return user.tenant.id === $accountInfo.tenant.id;
  }

  // Group actions together by user
  $: usersWithPermissions =
    recordLevelPermissions?.reduce((acc, recordLevelPermission) => {
      const existingItem = acc.find((i) => i.id === recordLevelPermission.userProfile.id);
      if (existingItem) {
        existingItem.recordLevelPermissions = [
          ...existingItem.recordLevelPermissions,
          recordLevelPermission,
        ];
      } else {
        acc.push({
          ...recordLevelPermission.userProfile,
          recordLevelPermissions: [recordLevelPermission],
        });
      }
      return acc;
    }, []) ?? [];

  $: usersWithPermissionsById = keyBy(usersWithPermissions, 'id');

  const dispatch = createEventDispatcher();
</script>

<Dialog persistent={true} active width={800}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <div class="" style="width:100%;">
        <div class="" style="width:100%;">
          <span class="secondary-text text-h5"
            >{$_('data-rooms.share-data-room.title', { values: { dataRoomName: nodeName } })}
          </span>
          <span class="float-right">
            <Button
              class=""
              fab
              size="x-small"
              title={$_('data-rooms.share-data-room.close')}
              on:click={() => {
                dispatch('cancel');
              }}
              text
            >
              <Icon path={mdiWindowClose} />
            </Button>
          </span>
        </div>
      </div></CardTitle
    >
    <CardText>
      <Divider class="secondary-color" />
      <form class="pt-4" on:submit|preventDefault>
        <div class="pt-2">
          <TextField
            name="search"
            autocomplete="off"
            bind:value={searchString}
            bind:inputElement={searchBarField}
            on:keyup={searchUsers}
            style="max-width: 620px;"
            class="pt-2"
            color={'blue'}
            clearable
            outlined
            rounded
            dense
          >
            <!-- This text field was marked as type="search" but that was removed because it was causing two clear field buttons to show.-->
            <div slot="prepend">
              <Icon path={mdiMagnify} />
            </div>
            {$_('data-rooms.share-data-room.new')}
          </TextField>
          {#if !selectedUser && searchString.length > 0}
            {#if userProfileSearchResults.length > 0}
              {#each userProfileSearchResults as searchResult, index}
                <Radio
                  bind:group={selectedUserId}
                  value={searchResult.id}
                  on:change
                  disabled={Boolean(usersWithPermissionsById[searchResult.id])}
                >
                  {searchResult.firstName}
                  {searchResult.lastName} ({searchResult.email})
                </Radio>
              {/each}
            {:else}
              <div class="error-text">{$_('data-rooms.share-data-room.no-search-results')}</div>
            {/if}
          {:else if selectedUser}
            <div class="pt-2 pb-2">
              {selectedUser.email}
            </div>
            <div class="pt-4">
              {#if dataRoomPermissions?.length > 0}
                {#each dataRoomPermissions as dataRoomPermission}
                  <Checkbox
                    bind:group={checkedPermissionIds}
                    name="permissions"
                    value={dataRoomPermission.id}
                  >
                    {$_(`data-rooms.share-data-room.right-${dataRoomPermission.action}`)}
                  </Checkbox>
                {/each}
              {/if}
            </div>
            {#if error?.message}
              <div class="error-text">{error?.message}</div>
            {/if}
            <Button
              type="submit"
              class="mt-4 secondary-color"
              on:click={replaceRecordLevelPermissions}
              text
            >
              {$_('data-rooms.share-data-room.share')}
            </Button>
            <Button
              class="mt-4 ml-2"
              on:click={() => {
                userProfileSearchResults = [];
                selectedUserId = undefined;
                checkedPermissionIds = [];
                searchString = '';
              }}
              text
            >
              {$_('data-rooms.share-data-room.cancel')}
            </Button>
          {/if}
        </div>
      </form>
      {#if loading}
        <div class="text-center">
          <ProgressCircular indeterminate class="mt-8 mb-8" />
        </div>
      {:else}
        <table class="mt-2">
          <thead class="text-caption ">
            <th style="width: 85px;min-width: 85px;" />
            <th style="width: 400px;">User</th>
            <th>Permissions</th>
            <th>Share Type</th>
          </thead>
          <tbody class="text-caption font-weight-thin">
            {#if usersWithPermissions?.length > 0}
              {#each usersWithPermissions as userWithPermission}
                <tr>
                  <td>
                    {#if canEditOrDeleteRecordLevelPermissionForUser(userWithPermission)}
                      <Button
                        icon
                        title={$_('data-rooms.share-data-room.edit')}
                        class="secondary-text"
                        on:click={() => (selectedUserForEditing = userWithPermission)}
                      >
                        <Icon path={mdiPencilOutline} />
                      </Button>
                      <Button
                        icon
                        title={$_('data-rooms.share-data-room.delete-title')}
                        class="secondary-text"
                        on:click={() => (selectedUserForDeletion = userWithPermission)}
                      >
                        <Icon path={mdiDeleteOutline} />
                      </Button>
                    {:else}
                      <Button
                        icon
                        title={$_('data-rooms.share-data-room.edit')}
                        class="grey-text "
                        disabled
                      >
                        <Icon path={mdiPencilOutline} />
                      </Button>
                      <Button
                        icon
                        title={$_('data-rooms.share-data-room.delete-title')}
                        class="grey-text"
                        disabled
                      >
                        <Icon path={mdiDeleteOutline} />
                      </Button>
                    {/if}
                  </td>
                  <td>
                    {userWithPermission.firstName}
                    {userWithPermission.lastName} ({userWithPermission.email})
                    <br />
                    {userWithPermission.tenant.name}
                  </td>
                  <td>
                    {capitalize(
                      userWithPermission.recordLevelPermissions
                        .map(({ permission }) => permission.action)
                        .join(', ')
                    )}
                  </td>
                  <td>This Data Room</td>
                </tr>
              {/each}
            {/if}
            {#if usersWithRoles?.length > 0}
              {#each usersWithRoles as userWithRoleAccess}
                <tr>
                  <td />
                  <td>
                    {userWithRoleAccess.firstName}
                    {userWithRoleAccess.lastName} ({userWithRoleAccess.email})
                    <br />
                    {userWithRoleAccess.tenant.name}
                  </td>
                  <td>
                    {capitalize(
                      userWithRoleAccess.permissions
                        .map((permission) => permission.action)
                        .join(', ')
                    )}
                  </td>
                  <td>All Data Rooms</td>
                </tr>
              {/each}
            {/if}
          </tbody>
        </table>
      {/if}
    </CardText>
  </Card>
</Dialog>

{#if selectedUserForEditing}
  <EditPermission
    dataRoom={fileSystemNode}
    user={selectedUserForEditing}
    on:success={async () => {
      selectedUserForEditing = undefined;
      await load();
    }}
    on:cancel={() => {
      selectedUserForEditing = undefined;
    }}
  />
{/if}

{#if selectedUserForDeletion}
  <DeleteUserFromDataRoomSharing
    dataRoom={fileSystemNode}
    user={selectedUserForDeletion}
    on:success={async () => {
      selectedUserForDeletion = undefined;
      await load();
    }}
    on:cancel={() => {
      selectedUserForDeletion = undefined;
    }}
  />
{/if}

<style>
  table {
    border: 1px solid white;
    border-collapse: collapse;
  }
  thead > th:first-child {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }
  thead > th:last-child {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  th {
    border: 1px solid white;
    height: 41px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #1a76d2;
    color: white;
  }
  td {
    padding: 4px;
    border-bottom: 1px solid #1a76d2;
  }
</style>
