<script>
  import jstz from 'jstz';
  import { afterUpdate, createEventDispatcher, onMount } from 'svelte';
  import { mdiLockReset } from '@mdi/js';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    Icon,
    TextField,
  } from 'svelte-materialify';
  import { authenticated } from '../services/account';
  import { apiFetch } from '../services/network';
  import { _ } from '../services/i18n';

  const dispatch = createEventDispatcher();

  const timezone = jstz.determine();
  export let email = '';
  const newPassword = '';
  export let redirect = true;
  let errorString = '';
  let token = '';
  let showInstructions = false;
  let getEmailField;
  let isFocused = false;

  onMount(() => {
    errorString = '';
  });

  afterUpdate(() => {
    if (!isFocused && !email) {
      getEmailField.focus();
      isFocused = true;
    }
  });

  function sendAgain() {
    errorString = '';
    showInstructions = false;
  }

  async function getEmailFormSubmitted(event) {
    errorString = $_('getEmail.generating');
    const url = `/authentication/getResetPasswordLink`;
    const request = {
      email,
      newPassword,
      token,
    };
    const response = await apiFetch(url, 'POST', request);
    const signinReply = await response.json();
    if (response.ok) {
      token = signinReply.token;
      errorString = $_('getEmail.fail.linkSent');
      showInstructions = true;
      // dispatch("passwordChanged");
      // await tick();
      // dispatch("resetLogin");
    } else {
      switch (response.status) {
        case 401: {
          errorString = $_('getEmail.fail.unauthorized');
          if (redirect) {
            authenticated.update((auth) => false);
          }

          break;
        }
        case 403: {
          errorString = $_('getEmail.fail.suspended');
          if (redirect) {
            authenticated.update((auth) => false);
          }

          break;
        }
        case 409: {
          errorString = $_(`getEmail.fail.${signinReply.reason}`);
          if (redirect) {
            authenticated.update((auth) => false);
          }

          break;
        }
        default: {
          if (redirect) {
            authenticated.update((auth) => false);
          }
          errorString = $_('getEmail.fail.other');
        }
      }

      return response;
    }
  }

  function cancel(event) {
    dispatch('backToLogin');
  }
</script>

{#if showInstructions}
  <div id="getEmailDialog">
    <Dialog persistent={true} active={true}>
      <Card outlined={true} raised={true}>
        <CardTitle>
          <div class="d-flex align-center">
            <img
              src="/images/logos/Fortress_RGB.png"
              class="logo"
              alt="{$_('app.name')} logo"
              title="{$_('app.name')}"
            />
          </div>
        </CardTitle>
        <CardText>
          <Divider class="secondary-color" />
          <h5 class="pt-2 text-center">{$_('getEmail.banner')}</h5>
          <p class="secondary-text">{$_('getEmail.instructions')}</p>
          <div>
            <div>
              <Button
                class="secondary-color"
                disabled={!email}
                color="secondary"
                on:click={sendAgain}
              >
                <Icon path={mdiLockReset} class={email ? 'secondary-color' : 'grey-color'} />{$_(
                  'getEmail.sendAgain'
                )}
              </Button>
            </div>
          </div>
        </CardText>
      </Card>
    </Dialog>
  </div>
{:else}
  <div id="getEmailDialog">
    <Dialog persistent={true} active={true}>
      <Card outlined={true} raised={true}>
        <CardTitle>
          <div class="d-flex align-center">
            <img
              src="/images/logos/Fortress_RGB.png"
              class="logo"
              alt="{$_('app.name')} logo"
              title="{$_('app.name')}"
            />
          </div>
        </CardTitle>
        <CardText>
          <Divider class="secondary-color" />
          <h5 class="pt-2 text-center">{$_('getEmail.banner')}</h5>
          <div>
            <p />
            <form on:submit|preventDefault={getEmailFormSubmitted}>
              <TextField
                type="email"
                outlined={true}
                color={'secondary'}
                readonly={false}
                clearable={true}
                placeholder={$_('getEmail.emailAddress')}
                bind:value={email}
                bind:inputElement={getEmailField}
              >
                {$_('getEmail.emailAddress')}
              </TextField>
              <div class="error-text">
                {errorString}
              </div>
              <Button
                class={email ? 'secondary-color' : 'grey-color'}
                type="submit"
                disabled={!email}
                color="secondary"
              >
                <Icon path={mdiLockReset} class={email ? 'secondary-color' : 'grey-color'} />{$_(
                  'getEmail.sendLink'
                )}
              </Button>
              <Button class="" style="float: right;" type="button" on:click={cancel}>
                {$_('getEmail.cancel')}
              </Button>
            </form>
          </div>
        </CardText>
      </Card>
    </Dialog>
  </div>
{/if}
