<script>
  import { createEventDispatcher } from 'svelte';
  import { Tab, TabContent, Tabs } from 'svelte-materialify';

  export let groupsTitle;
  export let itemsTitle;
  export let tabIndex = 0;
  export let groupsSearchTerm = '';
  export let itemsSearchTerm = '';
  export let readonly = true;

  const dispatch = createEventDispatcher();

  function groupsSearch() {
    dispatch('groupsSearch', { searchTerm: groupsSearchTerm });
  }

  function itemsSearch() {
    dispatch('itemsSearch', { searchTerm: itemsSearchTerm });
  }

  $: {
    if (itemsSearchTerm === '') {
      itemsSearch();
    }
    if (groupsSearchTerm === '') {
      groupsSearch();
    }
  }

  function tabChanged() {
    const GROUPS = 0;
    const ITEMS = 1;
    let tabName = 'unknown';
    switch (tabIndex) {
      case GROUPS: {
        tabName = 'groups';
        break;
      }
      case ITEMS: {
        tabName = 'items';
        break;
      }
    }
    dispatch('tabChanged', { tabName, tabIndex });
  }
</script>

<Tabs class="secondary-text pt-4" bind:value={tabIndex} on:change={tabChanged}>
  <div slot="tabs">
    <Tab>{groupsTitle}</Tab>
    <Tab>{itemsTitle}</Tab>
  </div>
</Tabs>

<div class={tabIndex === 0 ? 'd-block h-full flex-grow-1' : 'd-none'}>
  <slot name="groups" />
</div>
<div class={tabIndex === 1 ? 'd-block h-full flex-grow-1' : 'd-none'}>
  <slot name="items" />
</div>
