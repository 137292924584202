<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import {
    Button,
    Col,
    Container,
    ExpansionPanel,
    ExpansionPanels,
    Icon,
    ProgressLinear,
    Row,
  } from 'svelte-materialify';
  import { mdiPencilOutline } from '@mdi/js';
  import { _, IP_SCOPE_ITEM, IP_TYPE_PATENT } from '../../services/i18n';
  import { apiFetch, setSelectedClientByTenant } from '../../services/network';
  import { accountHasPermissionForItemAndType, ITEM_UPDATE } from '../../services/account';
  import FilesContainer from '../FilesContainer.svelte';
  import AddOrUpdatePatent from './AddOrUpdatePatent.svelte';
  import Notes from '../Notes.svelte';
  import UrlBreadcrumb from '../UrlBreadcrumb.svelte';
  import CopyURL from '../CopyURL.svelte';

  export let patentId;
  export let name = '';
  let patent;
  let detailsPanel = [0];
  let activePanels = [0, 1];
  const dispatch = createEventDispatcher();
  let breadCrumbItems = [];
  let showUpdateDialog = false;
  let countries = [];

  onMount(() => {});

  async function load() {
    const url = `/patent/${patentId}`;
    const response = await apiFetch(url);
    if (response.ok) {
      patent = await response.json();
      setSelectedClientByTenant(patent.tenant);
      if (name == '') {
        name = patent.name;
      }
      breadCrumbItems = [
        { text: $_('patent.title'), href: '/patent/item' },
        { text: `${$_('patent.singular')} - ${patent.name}` },
      ];
    }

    const countriesResponse = await fetch('/countries2.json');
    if (countriesResponse.ok) {
      countries = await countriesResponse.json();
    }
  }

  function editOwner(event) {}

  function groups() {
    return patent.groups?.map((group) => `"${group.name}"`).join(' ') ?? '';
  }

  function updateItemButtonClicked(event) {
    showUpdateDialog = true;
  }

  function itemSaved(event) {
    patent = event.detail.item;
    dispatch('itemUpdated', { item: patent });
  }
</script>

{#if name.length === 0}
  <h5 class="secondary-text text-center">{$_('patent.singular')}</h5>
{/if}
{#await load()}
  <ProgressLinear />
{:then}
  <Container>
    <Row>
      <Col class="col-12">
        <ExpansionPanels accordion bind:value={detailsPanel}>
          <ExpansionPanel>
            <div slot="header">
              <span>
                <UrlBreadcrumb {name} name2={patent.name} />
              </span>
            </div>
            <Container>
              <Button
                icon
                disabled={!accountHasPermissionForItemAndType(ITEM_UPDATE, patent, IP_TYPE_PATENT)}
                class={accountHasPermissionForItemAndType(ITEM_UPDATE, patent, IP_TYPE_PATENT)
                  ? 'secondary-color'
                  : ''}
                on:click={updateItemButtonClicked}
                title={$_('patent.edit')}
              >
                <Icon class="text-white" path={mdiPencilOutline} />
              </Button>
              <CopyURL />
              <div class="pl-3 d-flex flex-wrap">
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.type')}: </span>
                  </Row>
                  <Row>
                    {$_(`patent.types.${patent.patentType}`) ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.status')}: </span>
                  </Row>
                  <Row>
                    {$_(`patent.states.${patent.status}`) ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.title')}: </span>
                  </Row>
                  <Row>
                    {patent.name ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.productId')}: </span>
                  </Row>
                  <Row>
                    {patent.productId ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.nickName')}: </span>
                  </Row>
                  <Row>
                    {patent.nickName ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.country')}: </span>
                  </Row>
                  <Row>
                    {countries.find((country) => country.code === patent.country)?.name ??
                      patent.country ??
                      ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.filingDate')}: </span>
                  </Row>
                  <Row>
                    {patent.filingDate ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.applicationNumber')}: </span>
                  </Row>
                  <Row>
                    {patent.applicationNumber ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.lawFirmId')}: </span>
                  </Row>
                  <Row>
                    {patent.lawFirmIdentifier ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.otherId')}: </span>
                  </Row>
                  <Row>
                    {patent.otherIdentifier ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold"
                      >{$_('patent.table.earliestPriorityDate')}:
                    </span>
                  </Row>
                  <Row>
                    {patent.earliestPriorityDate ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold"
                      >{$_('patent.table.priorityApplicationNumber')}:
                    </span>
                  </Row>
                  <Row>
                    {patent.priorityApplicationNumber ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.grantDate')}: </span>
                  </Row>
                  <Row>
                    {patent.grantDate ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.grantNumber')}: </span>
                  </Row>
                  <Row>
                    {patent.grantNumber ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.publicationDate')}: </span>
                  </Row>
                  <Row>
                    {patent.publicationDate ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.publicationNumber')}: </span>
                  </Row>
                  <Row>
                    {patent.publicationNumber ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.expiryDate')}: </span>
                  </Row>
                  <Row>
                    {patent.expiryDate ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.nextAction')}: </span>
                  </Row>
                  <Row>
                    {patent.nextAction ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.actionDueDate')}: </span>
                  </Row>
                  <Row>
                    {patent.actionDueDate ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.ownershipStatus')}: </span>
                  </Row>
                  <Row>
                    {patent.ownershipStatus.length > 0
                      ? patent.ownershipStatus.map((status) => `"${status}"`).join(' ')
                      : ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.patentOwners')}: </span>
                  </Row>
                  <Row>
                    {patent.patentOwners ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.licensors')}: </span>
                  </Row>
                  <Row>
                    {patent.licensors ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.licensees')}: </span>
                  </Row>
                  <Row>
                    {patent.licensees ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold"
                      >{$_('patent.table.licenseDocumentLocation')}:
                    </span>
                  </Row>
                  <Row>
                    {patent.licenseDocumentLocation ?? ''}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.inventors')}: </span>
                  </Row>
                  <Row>
                    {patent.inventors_text}
                  </Row>
                </Col>
                <Col class="col-4">
                  <Row>
                    <span class="font-weight-bold">{$_('patent.table.groups')}: </span>
                  </Row>
                  <Row>
                    {groups() ?? ''}
                  </Row>
                </Col>
              </div>
            </Container>
          </ExpansionPanel>
        </ExpansionPanels>
      </Col>
    </Row>
    <Row>
      <Col class="col-12">
        <ExpansionPanels bind:value={activePanels} multiple>
          <FilesContainer
            ipType={IP_TYPE_PATENT}
            ipScope={IP_SCOPE_ITEM}
            scopeId={patent.id}
            readonly={!accountHasPermissionForItemAndType(ITEM_UPDATE, patent, IP_TYPE_PATENT)}
          />
          <Notes
            ipType={IP_TYPE_PATENT}
            ipScope={IP_SCOPE_ITEM}
            scopeId={patent.id}
            readonly={!accountHasPermissionForItemAndType(ITEM_UPDATE, patent, IP_TYPE_PATENT)}
          />
          <!-- <ExpansionPanel>
                    <div slot="header" class="secondary-text">
                        <span ><Icon class="secondary-text" path={mdiMedalOutline} /></span>
                        <span >{$_('patent.inventors')}</span>
                    </div>
                    <div>No inventors.</div>
                </ExpansionPanel> -->
        </ExpansionPanels>
      </Col>
    </Row>
  </Container>
{/await}

{#if showUpdateDialog}
  <AddOrUpdatePatent bind:show={showUpdateDialog} {patent} on:save={itemSaved} />
{/if}
