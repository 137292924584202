<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { last } from 'lodash-es';
  import { Badge, Button, ExpansionPanel, Icon, Overlay, Tooltip } from 'svelte-materialify';
  import {
    mdiDeleteOutline,
    mdiEyeOutline,
    mdiFileMoveOutline,
    mdiFolderOutline,
    mdiInformationOutline,
    mdiUploadOutline,
  } from '@mdi/js';
  import {
    _,
    getFolderNameForSymbol,
    getFolderSymbols,
    getTooltipForSymbol,
    IP_SCOPE_GROUP,
  } from '../services/i18n';
  import { apiFormatUrl } from '../services/network';
  import UploadDialog from './UploadDialog.svelte';
  import DownloadButton from './DownloadButton.svelte';
  import DeleteFileDialog from './DeleteFileDialog.svelte';
  import MoveRenameFileDialog from './MoveRenameFileDialog.svelte';
  import FileIcon from './FileIcon.svelte';
  import { openedFile, supportedFileTypes } from '../services/stores/doc-viewer';

  /**
   * @param ipType - string: one of the ip types or group types eg) IP_TYPE_PATENT
   */
  export let ipType;

  /**
   * @param scopeId - number: the identifier of the respective item or group
   */
  export let scopeId;

  /**
   * @param {string} ipScope - 'group' or 'item'
   */
  export let ipScope;

  /**
   * @param folderSymbol - string: key string that indexes into folderDescriptions
   */
  export let folderSymbol;

  /**
   * @param attachments - array of attachment objects
   */
  export let attachments;

  /**
   * @param readonly - bool: are uploads and modifications allowed?
   */
  export let readonly = true;

  export let panelsActive;

  export let folderIndex;

  let filteredAttachments = [];
  const filesListId = location.hash.slice(2).replaceAll('/', '-');
  let dropAreaElement = null;
  let filesTableElement;
  let fileInputElement;
  let showUploadDialog = false;
  let showDeleteFileDialog = false;
  let showMoveRenameFileDialog = false;
  let selectedFileUrl = '';
  let selectedFileName = '';
  let filesToUpload;
  let showDropZone = false;

  const dispatch = createEventDispatcher();

  let categoryKeys;

  onMount(() => {
    const className = generateDropAreaClassName();
    dropAreaElement = document.getElementsByClassName(className)[0];
    categoryKeys = getFolderSymbols(ipType, ipScope);
  });

  $: {
    if (attachments !== null) {
      filterAttachments(folderSymbol);
    }
  }

  function filterAttachments(folderSymbol) {
    if (attachments === null) {
      filteredAttachments = [];
    }
    filteredAttachments = attachments.filter(
      (attachment) => attachment.folderSymbol === folderSymbol
    );
    filteredAttachments.reverse();
  }

  $: {
    if (dropAreaElement !== null) {
      registerDragNDropHandlers();
    }
  }

  function registerDragNDropHandlers() {
    dropAreaElement.addEventListener('dragenter', preventDefaults, false);
    dropAreaElement.addEventListener('dragover', preventDefaults, false);
    dropAreaElement.addEventListener('dragleave', preventDefaults, false);
    dropAreaElement.addEventListener('drop', preventDefaults, false);

    dropAreaElement.addEventListener('dragenter', highlight, false);
    dropAreaElement.addEventListener('dragover', highlight, false);

    dropAreaElement.addEventListener('dragleave', unhighlight, false);
    dropAreaElement.addEventListener('drop', unhighlight, false);

    dropAreaElement.addEventListener('drop', handleDrop, false);
  }

  function preventDefaults(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  function highlight(event) {
    showDropZone = true;
  }

  function unhighlight(event) {
    showDropZone = false;
  }

  function handleDrop(event) {
    const dt = event.dataTransfer;
    filesToUpload = [...dt.files]; // convert FileList to javascript Array
    showUploadDialog = true;
  }

  function uploadButtonClicked(event) {
    fileInputElement.click();
  }

  function fileUploaded(event) {
    console.log(`FileFolder.fileUploaded()`);
    const { responsePayload } = event.detail;
    attachments = responsePayload;
  }

  function formatBytes(bytes, decimals = 1) {
    if (bytes == 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${Number.parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }

  function formatDate(isoStr) {
    if (isoStr === '') {
      return '';
    }
    const d = Date.parse(isoStr);
    return new Intl.DateTimeFormat('fr-ca').format(d);
    // if( isoStr === "" ) { return ""; }
    // const d = Date.parse(isoStr);
    // const s = new Intl.DateTimeFormat('en').format(d);
    // return s;
  }

  function deleteButtonClicked(event) {
    const element = event.currentTarget;
    const id = Number.parseInt(element.dataset.id);
    const attachment = attachments.find((item) => item.id === id);
    if (attachment) {
      selectedFileName = attachment.name;
      selectedFileUrl = element.dataset.url;
      showDeleteFileDialog = true;
    }
  }

  function moveRenameButtonClicked(event) {
    const element = event.currentTarget;
    const id = Number.parseInt(element.dataset.id);
    const attachment = attachments.find((item) => item.id === id);
    if (attachment) {
      selectedFileName = attachment.name;
      selectedFileUrl = element.dataset.url;
      showMoveRenameFileDialog = true;
    }
  }

  function fileDeleted(event) {
    const { responsePayload } = event.detail;
    attachments = responsePayload;
  }

  function fileMoved(event) {
    const { responsePayload } = event.detail;
    attachments = responsePayload;
  }

  function fileSelectionChanged(event) {
    filesToUpload = [...fileInputElement.files]; // convert FileList to javascript Array
    showUploadDialog = true;
  }

  function getBaseUrl() {
    let url = `/api/${ipType}`;
    if (ipScope === IP_SCOPE_GROUP) {
      url += '/group';
    }
    url += `/${scopeId}`;
    return url;
  }

  function generateDropAreaClassName() {
    return `drop-area-${folderSymbol}`;
  }

  function isFolderOpen(event) {
    const index = panelsActive.indexOf(folderIndex);
    if (index > -1) {
      return true;
    }
    return false;
  }
</script>

<ExpansionPanel class={generateDropAreaClassName()}>
  <!-- bind:this={dropAreaElement}  -->
  <div slot="header" class="secondary-text">
    {#if filteredAttachments.length > 0}
      <Badge
        class="secondary-color"
        bordered
        value={filteredAttachments.length}
        offsetX={8}
        offsetY={16}
        title="Number of files in this folder"
      >
        <span><Icon class="secondary-text" path={mdiFolderOutline} /></span>
      </Badge>
    {:else}
      <span><Icon class="secondary-text" path={mdiFolderOutline} /></span>
    {/if}
    <span id={filesListId} class="pl-6" on:file-uploaded={fileUploaded}>
      {getFolderNameForSymbol(ipType, ipScope, folderSymbol)}
      {#if getTooltipForSymbol(ipType, ipScope, folderSymbol).length > 0}
        <Tooltip right>
          <Icon size="16px" class="secondary-text" path={mdiInformationOutline} />
          <span slot="tip">{getTooltipForSymbol(ipType, ipScope, folderSymbol)}</span>
        </Tooltip>
      {/if}
    </span>
    {#if showDropZone && !isFolderOpen()}
      <Overlay color="secondary" bind:active={showDropZone} absolute={true}>
        <Icon size="60px" class="secondary-text" path={mdiUploadOutline} />
      </Overlay>
    {/if}
  </div>
  <input
    bind:this={fileInputElement}
    on:change={fileSelectionChanged}
    type="file"
    name="file"
    multiple={true}
  />
  <div>
    <table bind:this={filesTableElement}>
      <thead class="text-caption">
        <th style="width: 164px;min-width: 158px;">
          <Button
            icon
            disabled={readonly}
            on:click={uploadButtonClicked}
            text
            title={$_('upload.new')}
            fab
            size="small"
          >
            <Icon path={mdiUploadOutline} />
          </Button>
        </th>
        <th style="width: 400px;">{$_('files.table.name')}</th>
        <th>{$_('files.table.size')}</th>
        <th>{$_('files.table.lastUpdated')}</th>
        <!-- <th >{$_('files.table.owner')}</th> -->
        <th>{$_('files.table.originator')}</th>
      </thead>
      <tbody class="text-caption font-weight-thin">
        {#each filteredAttachments as attachment}
          {@const previewEnabled = supportedFileTypes.includes(attachment.mimeType)}
          <tr>
            <td>
              <Button
                icon
                title={$_('files.view')}
                disabled={!previewEnabled}
                class={previewEnabled ? 'secondary-text' : 'grey-text'}
                data-id={attachment.id}
                on:click={() =>
                  openedFile.set({
                    url: apiFormatUrl(`${getBaseUrl()}/attachment/${attachment.id}`),
                    extension: last(attachment.storagePath.split('.')),
                  })}
              >
                <Icon path={mdiEyeOutline} />
              </Button>
              <DownloadButton
                title={$_('files.download')}
                downloadUrl="{getBaseUrl()}/attachment/{attachment.id}"
              />
              <Button
                icon
                title={$_('files.move-rename')}
                disabled={readonly}
                class={readonly ? 'grey-text' : 'secondary-text'}
                data-id={attachment.id}
                data-url="{getBaseUrl()}/attachment/{attachment.id}"
                on:click={moveRenameButtonClicked}
              >
                <Icon path={mdiFileMoveOutline} />
              </Button>
              <Button
                icon
                title={$_('files.delete')}
                disabled={readonly}
                class={readonly ? 'grey-text' : 'secondary-text'}
                data-id={attachment.id}
                data-url="{getBaseUrl()}/attachment/{attachment.id}"
                on:click={deleteButtonClicked}
              >
                <Icon path={mdiDeleteOutline} />
              </Button>
            </td>
            <td>
              <FileIcon filename={attachment.name} />
              <span>{attachment.name ? attachment.name : ''}</span>
            </td>
            <td>{formatBytes(attachment.sizeInBytes)}</td>
            <td>{formatDate(attachment.updated)}</td>
            <!-- <td>{attachment.owner.firstName} {attachment.owner.lastName}<br>{attachment.owner.email}</td> -->
            <td
              >{attachment.originator
                ? `${attachment.originator.firstName} ${attachment.originator.lastName}`
                : 'External User'}</td
            >
          </tr>
        {/each}
      </tbody>
    </table>
    {#if showDropZone && isFolderOpen()}
      <Overlay color="secondary" bind:active={showDropZone} absolute={true}>
        <Icon size="100px" class="secondary-text" path={mdiUploadOutline} />
      </Overlay>
    {/if}
  </div>
</ExpansionPanel>

{#if showUploadDialog}
  <UploadDialog
    bind:show={showUploadDialog}
    {ipType}
    {ipScope}
    {folderSymbol}
    on:upload={fileUploaded}
    url={`${getBaseUrl()}/attachment`}
    {filesToUpload}
  />
{/if}

{#if showDeleteFileDialog}
  <DeleteFileDialog
    bind:show={showDeleteFileDialog}
    on:delete={fileDeleted}
    url={selectedFileUrl}
    name={selectedFileName}
  />
{/if}

{#if showMoveRenameFileDialog}
  <MoveRenameFileDialog
    bind:show={showMoveRenameFileDialog}
    on:moved={fileMoved}
    url={selectedFileUrl}
    name={selectedFileName}
    {ipType}
    {ipScope}
    {folderSymbol}
  />
{/if}

<style>
  table {
    border: 1px solid white;
    border-collapse: collapse;
  }
  thead > th:first-child {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }
  thead > th:last-child {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  th {
    border: 1px solid white;
    height: 41px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #1a76d2;
    color: white;
  }
  td {
    padding: 4px;
    /*border-bottom: 1px solid #1a76d2;*/
  }
  input {
    display: none;
  }
</style>
