<script>
  import { onDestroy, onMount } from 'svelte';
  import { Route, router } from 'tinro';
  import { _ } from '../services/i18n';
  import {
    clientContacts,
    noClientContactSelectedId,
    selectedClientContactId,
  } from '../services/network';
  import Administration from './administration/Administration.svelte';
  import Company from './Company.svelte';
  import Contracts from './Contracts/Contracts.svelte';
  import Copyrights from './Copyrights.svelte';
  import Dashboard from './Dashboard.svelte';
  import Designs from './Designs.svelte';
  import Guidance from './Guidance.svelte';
  import Ideas from './Ideas.svelte';
  import NoClientSelected from './NoClientSelected.svelte';
  import OpenSource from './OpenSource.svelte';
  import PatentsContainer from './Patents/PatentsContainer.svelte';
  import PlantBreedersRights from './PlantBreedersRights.svelte';
  import Profile from './Profile.svelte';
  import Projects from './Projects.svelte';
  import Reports from './Reports.svelte';
  import SelectEntity from './SelectEntity.svelte';
  import Trademarks from './Trademarks/Trademarks.svelte';
  import TradeSecrets from './TradeSecrets.svelte';
  import Import from './Import/Import.svelte';
  import Analytics from './Analytics.svelte';

  router.mode.hash();

  onMount(() => {});

  onDestroy(() => {});

  // Array that updates every time selectedClientContactId changes
  // Allows for refreshing of components with a keyed each block
  $: selectedEntity = [$selectedClientContactId];
</script>

{#if $clientContacts.length > 0}
  <SelectEntity />
{/if}

<Route>
  <Analytics />

  <Route path="/">
    <Dashboard />
  </Route>

  <Route path="/company">
    {#if $clientContacts.length > 0 && $selectedClientContactId === noClientContactSelectedId}
      <NoClientSelected />
    {:else}
      {#each selectedEntity as key (key)}
        <Company />
      {/each}
    {/if}
  </Route>

  <Route path="/contract/*" let:meta>
    {#each selectedEntity as key (key)}
      <Contracts />
    {/each}
  </Route>

  <Route path="/copyrights/*" let:meta>
    {#each selectedEntity as key (key)}
      <Copyrights />
    {/each}
  </Route>

  <Route path="/designs/*" let:meta>
    {#each selectedEntity as key (key)}
      <Designs />
    {/each}
  </Route>

  <Route path="/ideas/*" let:meta>
    {#each selectedEntity as key (key)}
      <Ideas />
    {/each}
  </Route>

  <Route path="/patent/*" let:meta>
    {#each selectedEntity as key (key)}
      <PatentsContainer />
    {/each}
  </Route>

  <Route path="/trademark/*" let:meta>
    {#each selectedEntity as key (key)}
      <Trademarks />
    {/each}
  </Route>

  <Route path="/tradesecrets/*" let:meta>
    {#each selectedEntity as key (key)}
      <TradeSecrets />
    {/each}
  </Route>

  <Route path="/plantbreedersrights/*" let:meta>
    {#each selectedEntity as key (key)}
      <PlantBreedersRights />
    {/each}
  </Route>

  <Route path="/opensource/*" let:meta>
    {#each selectedEntity as key (key)}
      <OpenSource />
    {/each}
  </Route>

  <Route path="/projects/*" let:meta>
    <Route path="/">
      <Projects />
    </Route>

    <!--Route path="/:projectId/content" let:meta>
        <Content projectId={meta.params.projectId} />
      </Route-->
  </Route>

  <Route path="/guidance/*" let:meta>
    <Guidance />
  </Route>

  <Route path="/report">
    {#if $clientContacts.length > 0 && $selectedClientContactId === noClientContactSelectedId}
      <NoClientSelected />
    {:else}
      {#each selectedEntity as key (key)}
        <Reports />
      {/each}
    {/if}
  </Route>

  <Route path="/administration/*">
    <Administration />
  </Route>

  <Route path="/profile">
    <Profile />
  </Route>

  <Route path="/import">
    {#if $clientContacts.length > 0 && $selectedClientContactId === noClientContactSelectedId}
      <NoClientSelected />
    {:else}
      {#each selectedEntity as key (key)}
        <Import />
      {/each}
    {/if}
  </Route>

  <Route fallback>
    <span class="h-6 error-text">{$_('sidebar.pageNotFound')}</span>
  </Route>
</Route>
