<script>
  import jstz from 'jstz';
  import { createEventDispatcher, onDestroy, onMount, tick } from 'svelte';
  import { router } from 'tinro';
  import { apiFetch } from '../services/network';
  import { accountInfo, authenticated } from '../services/account';
  import { _ } from '../services/i18n';
  import ChangePassword from './ChangePassword.svelte';
  import SignInOTP from './SignInOTP.svelte';
  import SignIn from './SignIn.svelte';
  import GetPhoneNumber from './GetPhoneNumber.svelte';
  import GetEmail from './GetEmail.svelte';

  const dispatch = createEventDispatcher();
  const timezone = jstz.determine();
  let email = '';
  let password = '';
  let newPassword = '';
  let phoneNumber;
  const repeatNewPassword = '';
  let passwordsDontMatch = false;
  const showResetPasswordTooltip = false;
  const providedOneTimePassword = '';
  const numbersDontMatch = false;
  let errorString = '';
  const redirect = true;
  let changePassword = false;
  let provideOneTimePassword = false;
  let providePhoneNumber = false;
  let signIn = true;
  let signInDiv;
  let resetUserPassword = false;
  let getUserEmail = false;
  let showChangePhoneNumberButton = false;
  let sessionExpired = false;
  let onProfilePage = false;
  $: passwordsDontMatch = newPassword !== repeatNewPassword;

  let timeoutId = null;

  const unsubscribeFromAuthenticated = authenticated.subscribe((value) => {
    if (value === true && timeoutId === null) {
      timeoutId = setTimeout(isAlreadySignedIn, 60 * 1000);
    }
    if (!$authenticated) {
      if (
        !signIn &&
        !getUserEmail &&
        !changePassword &&
        !providedOneTimePassword &&
        !providePhoneNumber
      ) {
        sessionExpired = true;
      }
      resetLogin();
      signInEventReceived();
    }
  });
  onMount(() => {
    const url = location.hash.slice(2);
    if (url.includes('passwordReset')) {
      signIn = false;
      changePassword = true;
    } else if (timeoutId == null) {
      isAlreadySignedIn();
    } else {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  });

  onDestroy(() => {
    email = '';
    password = '';
  });

  export async function isAlreadySignedIn() {
    const url = `/authentication/issignedin`;
    try {
      const response = await apiFetch(url, 'POST', { timezone: timezone.name() });
      if (response.ok) {
        const signInResponse = await response.json();
        accountInfo.update((info) => signInResponse);
        authenticated.update((auth) => true);
        signedIn();
        timeoutId = setTimeout(isAlreadySignedIn, 60 * 1000);
      } else {
        if (response.status === 401) {
          timeoutId = null;
          if ($authenticated) {
            sessionExpired = true;
          }
          authenticated.update((auth) => false);
        } else if (response.status === 403) {
          authenticated.update((auth) => false);
          errorString = $_('signIn.fail.suspended');
          resetLogin();
          signInEventReceived();
        } else {
          authenticated.update((auth) => false);
          resetLogin();
          signInEventReceived();
        }

        return response;
      }
    } catch {
      authenticated.update((auth) => false);
      timeoutId = null;
    }
  }

  function signedIn(event) {
    sessionExpired = false;
    localStorage.setItem('email', email);
    if (timeoutId === null && $authenticated == true) {
      timeoutId = setTimeout(isAlreadySignedIn, 60 * 1000);
    }
    signIn = false;
    changePassword = false;
  }

  function passwordChanged(event) {
    password = newPassword;
    if (!onProfilePage) {
      signedIn();
    }
  }

  async function getEmail(event) {
    changePassword = false;
    providePhoneNumber = false;
    signIn = false;
    provideOneTimePassword = false;
    resetUserPassword = false;
    await tick();
    getUserEmail = true;
  }

  async function needOTP(event) {
    showChangePhoneNumberButton = !event.detail.phoneNumberVerified;
    if (changePassword) {
      const { newPassword } = event.detail;
      password = newPassword;
      changePassword = false;
    }
    if (signIn) {
      signIn = false;
    }
    if (providePhoneNumber) {
      providePhoneNumber = false;
    }
    await tick();
    provideOneTimePassword = true;
    await tick();
  }

  async function needOTPPhoneNumber(event) {
    if (changePassword) {
      const { newPassword } = event.detail;
      password = newPassword;
      changePassword = false;
    } else if (provideOneTimePassword) {
      provideOneTimePassword = false;
    } else if (signIn) {
      signIn = false;
    }
    await tick();
    providePhoneNumber = true;
  }

  async function needPasswordChange(event) {
    signIn = false;
    await tick();
    changePassword = true;
  }

  function oneTimePasswordProvided(event) {
    localStorage.setItem('email', email);
    if (timeoutId === null) {
      timeoutId = setTimeout(isAlreadySignedIn, 60 * 1000);
    }
    provideOneTimePassword = false;
  }

  function phoneNumberProvided(event) {
    localStorage.setItem('email', email);
    if (timeoutId === null) {
    }
    providePhoneNumber = false;
  }

  function signInEventReceived(event) {
    changePassword = false;
    provideOneTimePassword = false;
    providePhoneNumber = false;
    signIn = true;
    getUserEmail = false;
  }

  function resetLogin(event) {
    email = '';
    password = '';
    newPassword = '';
    phoneNumber = '';
  }

  function signedOut(event) {
    resetLogin();
    $authenticated = false;
    signInEventReceived();
    sessionExpired = false;
  }

  function backToLogin(event) {
    if (resetUserPassword) {
      router.goto('/');
    }
    email = '';
    password = '';
    newPassword = '';
    changePassword = false;
    providePhoneNumber = false;
    provideOneTimePassword = false;
    resetUserPassword = false;
    getUserEmail = false;
    signIn = true;
  }
</script>

<div id="sign-in-workflow" on:signedOut={signedOut}>
  {#if changePassword}
    <div id="changePassword">
      <ChangePassword
        bind:email
        bind:newPassword
        bind:phoneNumber
        bind:onProfilePage
        on:passwordChanged={passwordChanged}
        on:needOTPPhoneNumber={needOTPPhoneNumber}
        on:needOTP={needOTP}
        on:getEmail={getEmail}
        on:resetLogin={resetLogin}
        on:signInEventReceived={signInEventReceived}
      />
    </div>
  {/if}
  {#if signIn}
    <div id="signIn">
      <SignIn
        bind:email
        bind:password
        bind:phoneNumber
        {sessionExpired}
        on:signedIn={signedIn}
        on:needPasswordChange={needPasswordChange}
        on:needOTPPhoneNumber={needOTPPhoneNumber}
        on:needOTP={needOTP}
        on:resetLogin={resetLogin}
        on:getEmail={getEmail}
      />
    </div>
  {/if}
  {#if providePhoneNumber}
    <div id="getPhoneNumber">
      <GetPhoneNumber
        bind:email
        {password}
        bind:phoneNumber
        on:phoneNumberProvided={phoneNumberProvided}
        on:needOTP={needOTP}
        on:resetLogin={resetLogin}
      />
    </div>
  {/if}
  {#if provideOneTimePassword}
    <div id="provideOneTimePassword">
      <SignInOTP
        bind:email
        {password}
        {showChangePhoneNumberButton}
        bind:phoneNumber
        on:needOTPPhoneNumber={needOTPPhoneNumber}
        on:oneTimePasswordProvided={oneTimePasswordProvided}
        on:resetLogin={resetLogin}
        on:signInEventReceived={signInEventReceived}
      />
    </div>
  {/if}
  {#if getUserEmail}
    <div id="getEmail">
      <GetEmail bind:email on:backToLogin={backToLogin} />
      <!-- on:emailReceived={emailReceived}  -->
    </div>
  {/if}
</div>
