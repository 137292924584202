<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Checkbox,
    Col,
    Dialog,
    Divider,
    Icon,
    Row,
    TextField,
  } from 'svelte-materialify';
  import { mdiMagnify } from '@mdi/js';
  import { router } from 'tinro';
  import { _ } from '../../../services/i18n';
  import { validateForm } from '../../../services/validation';
  import { apiFetch } from '../../../services/network';
  // import TextField from '../../TextField.svelte';

  const dispatch = createEventDispatcher();

  export let show;
  export let tenantId = -1;

  let clientTenant;
  let client;
  let lawFirmIdentifier = '';

  let debounceSearchTimer;
  let searchString = '';
  let searchResults = [];

  let formElement;
  let searchBar;
  let lawFirmIdField;
  let searchIsFocused = false;
  let idIsFocused = false;

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }
  afterUpdate(() => {
    if (searchIsFocused && !clientTenant) {
      searchBar.focus();
      searchIsFocused = true;
    }
    if (idIsFocused && clientTenant && !lawFirmIdentifier) {
      lawFirmIdField.focus();
      idIsFocused = true;
    }
  });

  async function search() {
    clearTimeout(debounceSearchTimer);

    async function innerSearch() {
      const searchTerm = searchString.toLowerCase();
      if (searchTerm.length < 2) {
        return;
      }
      let url = `/api/admin/client/search-tenants/${searchTerm}`;
      if (tenantId > -1) {
        url += `?tenantId=${tenantId}`;
      }
      const response = await apiFetch(url, 'POST');
      if (response.ok) {
        searchResults = await response.json();
      }
    }
    debounceSearchTimer = setTimeout(innerSearch, 500);
  }

  async function save(event) {
    if (clientTenant) {
      event.preventDefault();
      if (!validateForm(formElement)) {
        return;
      }

      let url = `/api/admin/client`;
      if (tenantId > -1) {
        url += `?tenantId=${tenantId}`;
      }
      const request = {
        clientTenantId: clientTenant.id,
        lawFirmIdentifier,
      };
      const response = await apiFetch(url, 'POST', request);
      if (response.ok) {
        show = false;
        const item = await response.json();
        client = item;
        dispatch('save', { item });
        return true;
      }
    } else {
      return false;
    }
  }
  async function saveAndAddContacts(event) {
    event.preventDefault();
    const saveSuccessful = await save(event);
    if (saveSuccessful && !!client.id) {
      router.goto(`/administration/tenant/${tenantId}/client/${client.id}`);
      dispatch('addContact');
    }
  }

  function companySelected(event) {
    const element = event.currentTarget;
    const { id } = element;
    const index = Number.parseInt(id.split('-')[1]);
    clientTenant = searchResults[index];
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('administration.clients.new')}
        {$_('administration.clients.singular')}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        {#if !clientTenant}
          <TextField
            type="search"
            name="search"
            autocomplete="off"
            bind:value={searchString}
            bind:inputElement={searchBar}
            on:keyup={search}
            style="max-width: 400px;"
            class="pt-2"
            color={'blue'}
            clearable
            outlined
            rounded
            dense
          >
            <div slot="prepend">
              <Icon path={mdiMagnify} />
            </div>
            {$_('administration.clients.search-entities')}
          </TextField>
          {#if searchString.length > 0 && searchResults.length > 0}
            {#each searchResults as searchResult, index}
              <Row class="pt-0">
                <Col cols={4} class="text-subtitle-2">
                  <Checkbox color="secondary" id="company-{index}" on:change={companySelected}
                    >{searchResult.name}</Checkbox
                  >
                </Col>
              </Row>
            {/each}
          {/if}
        {:else}
          <div class="pb-2">
            <span>{$_('administration.clients.entity-name')}</span><span> : </span><span
              >{clientTenant.name}</span
            >
          </div>
          <TextField
            bind:value={lawFirmIdentifier}
            bind:inputElement={lawFirmIdField}
            class="pb-2"
            outlined={true}
            dense
            clearable
            color={'secondary'}
          >
            {$_('administration.clients.table.lawFirmId')}
          </TextField>
        {/if}
        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          class="mt-4 {clientTenant ? 'secondary-color' : 'grey-color'}"
          on:click={save}
          text
          disabled={!clientTenant}
        >
          {$_('administration.clients.save')}
        </Button>
        <Button
          type="submit"
          class="mt-4 {clientTenant ? 'secondary-color' : 'grey-color'}"
          on:click={saveAndAddContacts}
          text
          disabled={!clientTenant}
        >
          {$_('administration.clients.save-and-add-contacts')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('administration.clients.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
