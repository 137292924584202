<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { Avatar, Button, Divider, Icon, List, ListGroup, ListItem } from 'svelte-materialify';
  import {
    mdiApplicationImport,
    mdiCertificate,
    mdiChartAreaspline,
    mdiChevronUp,
    mdiCogs,
    mdiCommentQuoteOutline,
    mdiFileCertificateOutline,
    mdiFileSign,
    mdiFrequentlyAskedQuestions,
    mdiLifebuoy,
    mdiMonitorDashboard,
    mdiRegisteredTrademark,
    mdiSitemap,
  } from '@mdi/js';
  import { router } from 'tinro';
  import {
    accountHasTenantPermission,
    accountInfo,
    SYSTEM_WIDE_TENANT_ADMIN,
  } from '../services/account';
  import {
    noClientContactSelectedId,
    selectedClientContact,
    selectedClientContactId,
  } from '../services/network';
  import {
    _,
    IP_TYPE_CONTRACT,
    IP_TYPE_PATENT,
    IP_TYPE_REPORT,
    IP_TYPE_TRADEMARK,
  } from '../services/i18n';

  const dispatch = createEventDispatcher();
  const unregisteredActive = false;
  const registeredActive = false;
  const feedbackActive = false;
  let version = import.meta.env.MODE;

  $: contractsSelectable = itemSelectable(IP_TYPE_CONTRACT, $selectedClientContactId, $accountInfo);
  $: patentsSelectable = itemSelectable(IP_TYPE_PATENT, $selectedClientContactId, $accountInfo);
  $: trademarksSelectable = itemSelectable(
    IP_TYPE_TRADEMARK,
    $selectedClientContactId,
    $accountInfo
  );
  $: reportsSelectable = itemSelectable(IP_TYPE_REPORT, $selectedClientContactId, $accountInfo);

  onMount(() => {
    if (typeof webuiVersion !== 'undefined') {
      version = webuiVersion;
    }
  });

  /**
   * @function itemSelectable
   * @param ipType
   * @returns boolean - true if the logged on user has some permissions for this ipType
   */
  function itemSelectable(ipType, clientContactId, accountInfo) {
    let tenantId = accountInfo?.tenant?.id;
    // Handle race condition with reactive declaration running before store is updated.
    if (!tenantId) {
      return false;
    }
    if (clientContactId !== noClientContactSelectedId) {
      tenantId = $selectedClientContact.userProfile.tenant.id;
    }
    for (const groupAccessPermission of accountInfo.groupAccessPermissions) {
      if (
        groupAccessPermission.groupType === ipType &&
        groupAccessPermission.tenantId === tenantId
      ) {
        return true;
      }
    }

    for (const itemAccessPermission of accountInfo.itemAccessPermissions) {
      if (itemAccessPermission.itemType === ipType && itemAccessPermission.tenantId === tenantId) {
        return true;
      }
    }
    return false;
  }

  function itemClicked(e) {
    const element = e.currentTarget;
    if (element.classList.contains('link-disabled')) {
      return;
    }
    const { href } = element;
    const path = new URL(href).pathname;
    dispatch('itemClicked', { path });
    // document.querySelector('#body').scrollTo();
    const elmnt = document.querySelector('#top');
    if (elmnt) {
      elmnt.scrollIntoView(true);
    }
    router.goto(path);
  }
</script>

<List>
  <a href="/" tinro-ignore on:click|preventDefault={itemClicked}>
    <ListItem class="pt-1 pb-1">
      <span slot="prepend">
        <Avatar size="32px" tile>
          <Icon class="secondary-text ma-0" path={mdiMonitorDashboard} />
        </Avatar>
      </span>
      <span class="secondary-text">{$_('dashboard.title')}</span>
    </ListItem>
  </a>

  {#if reportsSelectable}
    <a
      href="/report"
      class="secondary-text {reportsSelectable ? '' : 'link-disabled'}"
      tinro-ignore
      on:click|preventDefault={itemClicked}
    >
      <ListItem
        class="pt-1 pb-1 {reportsSelectable ? '' : 'link-disabled'}"
        disabled={!reportsSelectable}
      >
        <span slot="prepend">
          <Avatar size="32px" tile>
            <Icon class="secondary-text ma-0" path={mdiChartAreaspline} />
          </Avatar>
        </span>
        <span class="secondary-text">{$_('report.title')}</span>
      </ListItem>
    </a>
  {/if}

  <a href="/company" tinro-ignore on:click|preventDefault={itemClicked}>
    <ListItem class="pt-1 pb-1">
      <span slot="prepend">
        <Avatar size="32px" tile>
          <Icon class="secondary-text ma-0" path={mdiSitemap} />
        </Avatar>
      </span>
      <span class="secondary-text">{$_('company.title')}</span>
    </ListItem>
  </a>

  <!--{#if $accountInfo.tenant.type !== "company"}-->
  {#if contractsSelectable}
    <a
      href="/contract/family"
      class={contractsSelectable ? '' : 'link-disabled'}
      tinro-ignore
      on:click|preventDefault={itemClicked}
    >
      <ListItem
        class="pt-1 pb-1 {contractsSelectable ? '' : 'link-disabled'}"
        disabled={!contractsSelectable}
      >
        <span slot="prepend">
          <Avatar size="32px" tile>
            <Icon class="secondary-text ma-0" path={mdiFileSign} />
          </Avatar>
        </span>
        <span class="secondary-text">{$_('contract.title')}</span>
      </ListItem>
    </a>
  {/if}

  {#if patentsSelectable}
    <a
      href="/patent/family"
      class={patentsSelectable ? '' : 'link-disabled'}
      tinro-ignore
      on:click|preventDefault={itemClicked}
    >
      <ListItem
        class="pt-1 pb-1 {patentsSelectable ? '' : 'link-disabled'}"
        disabled={!patentsSelectable}
      >
        <span slot="prepend">
          <Avatar size="32px" tile>
            <Icon class="secondary-text ma-0" path={mdiCertificate} />
          </Avatar>
        </span>
        <span class="secondary-text">{$_('patent.title')}</span>
      </ListItem>
    </a>
  {/if}

  {#if trademarksSelectable}
    <a
      href="/trademark/family"
      class={trademarksSelectable ? '' : 'link-disabled'}
      tinro-ignore
      on:click|preventDefault={itemClicked}
    >
      <ListItem
        class="pb-1 pt-1 {trademarksSelectable ? '' : 'link-disabled'}"
        disabled={!trademarksSelectable}
      >
        <span slot="prepend">
          <Avatar size="32px" tile>
            <Icon class="secondary-text ma-0" path={mdiRegisteredTrademark} />
          </Avatar>
        </span>
        <span class="secondary-text">{$_('trademark.title')}</span>
      </ListItem>
    </a>
  {/if}

  <a href="/import" tinro-ignore on:click|preventDefault={itemClicked} class="secondary-text">
    <ListItem class="pt-1 pb-1">
      <span slot="prepend">
        <Avatar size="32px" tile>
          <Icon class="secondary-text ma-0" path={mdiApplicationImport} />
        </Avatar>
      </span>
      <span class="secondary-text">{$_('ip-import.title')}</span>
    </ListItem>
  </a>

  <a href="/guidance" tinro-ignore on:click|preventDefault={itemClicked} class="secondary-text">
    <ListItem class="pt-1 pb-1">
      <span slot="prepend">
        <Avatar size="32px" tile>
          <Icon class="secondary-text ma-0" path={mdiFrequentlyAskedQuestions} />
        </Avatar>
      </span>
      <span class="secondary-text">{$_('guidance.title')}</span>
    </ListItem>
  </a>

  <a
    href="https://form.asana.com/?k=YQb2ZbFr063-6kob9X_DFA&d=1201659926619370"
    tinro-ignore
    target="_blank"
    class="secondary-text"
  >
    <ListItem class="pb-1 pt-1">
      <span slot="prepend">
        <Avatar size="32px" tile>
          <Icon class="secondary-text ma-0" path={mdiCommentQuoteOutline} />
        </Avatar>
      </span>
      <span class="secondary-text">{$_('sidebar.feedback')}</span>
    </ListItem>
  </a>

  <a
    href={`mailto:support@fortressiam.com?subject=Fortress IAM Feedback [${version}]&cc=${$accountInfo.email}`}
    tinro-ignore
    class="secondary-text"
  >
    <ListItem class="pb-1 pt-1">
      <span slot="prepend">
        <Avatar size="32px" tile>
          <Icon class="secondary-text ma-0" path={mdiLifebuoy} />
        </Avatar>
      </span>
      <span class="secondary-text">{$_('sidebar.email-support')}</span>
    </ListItem>
  </a>

  {#if accountHasTenantPermission(SYSTEM_WIDE_TENANT_ADMIN)}
    <a
      class="secondary-text"
      href="/administration"
      tinro-ignore
      on:click|preventDefault={itemClicked}
    >
      <ListItem class="pb-1 pt-1">
        <span slot="prepend">
          <Avatar size="32px" tile>
            <Icon class="secondary-text ma-0" path={mdiCogs} />
          </Avatar>
        </span>
        <span class="secondary-text">{$_('administration.title')}</span>
      </ListItem>
    </a>
    <a class="secondary-text" href={`${import.meta.env.FORTRESS_V2_FRONTEND_URL}/administration`}>
      <ListItem class="pb-1 pt-1">
        <span slot="prepend">
          <Avatar size="32px" tile>
            <Icon class="secondary-text ma-0" path={mdiCogs} />
          </Avatar>
        </span>
        <span class="secondary-text">{$_('administration.title')} (New)</span>
      </ListItem>
    </a>
  {/if}
</List>

<style>
  .link-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-radius: 6px;
  }
  a {
    text-decoration: none;
  }

  .tooltip {
    position: relative;
    /* display: inline-block; */
    border-bottom: 1px dotted black;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: #111183;
    color: #fff;
    text-align: center;
    padding: 7px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 0;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
</style>
