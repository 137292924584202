<script>
  import { onMount } from 'svelte';
  import { Checkbox, Icon, Snackbar } from 'svelte-materialify';
  import { mdiBell } from '@mdi/js';
  import { _ } from '../../services/i18n';
  import { isDateInPast } from '../../services/date';
  import { initializeCountries } from '../../services/countrieshelper';
  import { sendSettingsRequest } from '../../services/settings';
  import { authenticated } from '../../services/account';
  import {
    apiFetch,
    selectedClientContactId,
    selectedClientContact,
    selectableClientContacts
  } from '../../services/network';
  import RemindersList from './RemindersList.svelte';

  let importantDates = null;
  let showPastDates = true;
  let filterBySelectedEntity = false;
  let shownDates = [];

  const baseSettingsUrl = '/api/user-settings/important_dates';
  const showPastDatesKey = 'filter.show_past_dates';
  const filterBySelectedEntityKey = 'filter.by_selected_entity';

  $: showSnackBar = Boolean(snackBarText);
  let snackBarText = '';

  // needed to access utility function to check past date
  let remindersListComponent;

  // trigger if selected entity changes.  Toggling checkbox options will trigger
  //  the filtering within those handlers
  $: if ($selectedClientContactId !== -1) {
    filterDates();
  }

  onMount(async () => {
    if ($authenticated) {
      await initializeCountries();
      await load();

      // retrieve filter settings
      const showPastDatesResponse = await sendSettingsRequest(
        [baseSettingsUrl, showPastDatesKey].join('/'),
        'GET'
      );
      if (showPastDatesResponse.ok) {
        showPastDates = (await showPastDatesResponse.json()).value;
      }
      const filterBySelectedEntityResponse = await sendSettingsRequest(
        [baseSettingsUrl, filterBySelectedEntityKey].join('/'),
        'GET'
      );
      if (filterBySelectedEntityResponse.ok) {
        filterBySelectedEntity = (await filterBySelectedEntityResponse.json()).value;
      }
      // Call filterDates as we aren't using reactive block anymore
      filterDates();
    }
  });

  async function load() {
    const response = await apiFetch(`/important-dates`);
    if (response.ok) {
      importantDates = await response.json();
    }
  }

  async function filterDates() {
    shownDates = importantDates?.filter((date) => {
      if (
        filterBySelectedEntity &&
        $selectedClientContactId !== -1 &&
        date.owningTenant.id !== $selectedClientContact.userProfile.tenant.id
      ) {
        return false;
      }
      if (showPastDates) {
        return true;
      }
      // return true if the date is greater than the current date
      return !isDateInPast(date.date);
    }) ?? [];
    shownDates.sort((d1, d2) => {
      // Sort based on reminder and due date
      const date1 = d1.reminderDate && !isDateInPast(d1.reminderDate) ? d1.reminderDate : d1.date;
      const date2 = d2.reminderDate && !isDateInPast(d2.reminderDate) ? d2.reminderDate : d2.date;
      return Date.parse(date1) - Date.parse(date2);
    });
  }

  const onToggleFilterBySelectedEntity = async () => {
    const response = await sendSettingsRequest(
      [baseSettingsUrl, filterBySelectedEntityKey].join('/'),
      'PUT',
      filterBySelectedEntity
    );
    if (response.ok) {
      snackBarText = $_('dashboard.reminders.notifications.filter-by-selected-entity-setting-saved');
      filterDates();
    } else {
      filterBySelectedEntity = !filterBySelectedEntity;
      snackBarText = $_('dashboard.reminders.notifications.filter-by-selected-entity-setting-not-saved');
    }
  };

  const onToggleShowPastDates = async () => {
    const response = await sendSettingsRequest(
      [baseSettingsUrl, showPastDatesKey].join('/'),
      'PUT',
      showPastDates
    );
    if (response.ok) {
      snackBarText = $_('dashboard.reminders.notifications.show-past-dates-setting-saved');
      filterDates();
    } else {
      showPastDates = !showPastDates;
      snackBarText = $_('dashboard.reminders.notifications.show-past-dates-setting-not-saved');
    }
  }
</script>

<div class="text-h6 secondary-text py-4 px-4">
  <Icon class="secondary-text" path={mdiBell} />
  <span class="pl-2">{$_('dashboard.reminders.title')}</span>
</div>

{#if !importantDates }
  <div class="pa-4 pl-9">
    <span class="pl-2">{$_('dashboard.reminders.loading')}</span>
  </div>
{:else if importantDates.length === 0 }
  <div class="pa-4 pl-9">
    <span class="pl-2">{$_('dashboard.reminders.no-reminders')}</span>
  </div>
{:else }
  <div class="pa-1 pl-9 mr-5 d-flex justify-start">
    <div class="mr-9">
      <Checkbox bind:checked={showPastDates} class="secondary-text" on:change={onToggleShowPastDates}>
        {$_('dashboard.reminders.show-past-dates')}
      </Checkbox>
    </div>
    {#if $selectableClientContacts.length > 1}
    <div>
      <Checkbox
        bind:checked={filterBySelectedEntity}
        class="secondary-text"
        on:change={onToggleFilterBySelectedEntity}
      >
        {$_('dashboard.reminders.filter-by-selected-entity')}
      </Checkbox>
    </div>
    {/if}
  </div>
  {#if shownDates.length === 0 }
    <div class="pa-4 pl-9">
      <span class="pl-2">{$_('dashboard.reminders.no-reminders')}</span>
    </div>
  {:else }
  <div
    class="pa-2 pl-9 flex-column"
    style="height:fit-content"
  >
    <RemindersList bind:this={remindersListComponent} items={shownDates} />
  </div>
  {/if }
{/if}

{#if showSnackBar}
  <Snackbar
    class="flex-column"
    bind:active={showSnackBar}
    timeout={2000}
    top
    right
    on:outroend={() => snackBarText = undefined}
  >
    <div>{snackBarText}</div>
  </Snackbar>
{/if}
