<script>
  import { createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider, Icon } from 'svelte-materialify';
  import { mdiDelete } from '@mdi/js';
  import { _ } from '../../services/i18n';
  import { apiFetch } from '../../services/network';

  export let fileSystemNode;

  const dispatch = createEventDispatcher();

  async function deleteFile(event) {
    event.preventDefault();

    const response = await apiFetch(`/api/file-system/folder/${fileSystemNode.id}`, 'DELETE');
    if (response.ok) {
      dispatch('delete', { fileSystemNode });
    }
  }
</script>

<Dialog persistent={true} active>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{$_('files.delete-folder-dialog.title')}</h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />

      <div class="pt-4">
        <h6>
          {$_('files.delete-folder-dialog.delete-confirmation', {
            values: { folderName: fileSystemNode.name },
          })}
        </h6>
        <br />
        <p class="text-h6">{$_('files.delete-folder-dialog.delete-explanation')}</p>
      </div>

      <form>
        <Button type="submit" class="mt-4 error-color" on:click={deleteFile} text>
          <Icon path={mdiDelete} />
          {$_('files.delete-folder-dialog.delete')}
        </Button>
        <Button class="mt-4 ml-2" on:click={() => dispatch('cancel')} text>
          {$_('files.delete-folder-dialog.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
