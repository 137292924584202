<script>
  import { onDestroy, onMount } from 'svelte';
  import { Route } from 'tinro';
  import { _ } from '../services/i18n';

  onMount(() => {});

  onDestroy(() => {});
</script>

<Route path="/">
  <div class="ml-2">
    <h5 class="secondary-text text-left">{$_('open-source.title')}</h5>
    <div class="pr-2 text-left error-text">Coming Soon</div>
  </div>
</Route>

<style>
</style>
