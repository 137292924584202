<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    ProgressLinear,
    Select,
  } from 'svelte-materialify';
  import { router } from 'tinro';
  import { _ } from '../../../services/i18n';
  import { validateForm } from '../../../services/validation';
  import { apiFetch } from '../../../services/network';
  import TextField from '../../TextField.svelte';

  const dispatch = createEventDispatcher();

  export let show;
  export let tenant = {
    name: '',
    type: 'company',
    status: 'trial',
  };

  const newTenant = {};
  Object.assign(newTenant, tenant);

  let formElement;
  let tenantTypeItems = [];
  let tenantStateItems = [];
  let addTenantNameField;
  let isFocused = false;

  afterUpdate(() => {
    if (!isFocused && !tenant.name) {
      addTenantNameField.focus();
      isFocused++;
    }
  });

  async function load() {
    const response = await apiFetch('/api/tenant/tenant-configuration');
    if (response.ok) {
      const configuration = await response.json();
      tenantTypeItems = configuration.tenantTypes.map((item) => ({ name: item, value: item }));
      tenantStateItems = configuration.tenantStates.map((item) => ({ name: item, value: item }));
    }
  }

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function save(event) {
    event.preventDefault();
    Object.assign(tenant, newTenant);
    if (!validateForm(formElement)) {
      return;
    }

    let url = `/api/tenant`;
    let method = 'POST';
    if (tenant.id) {
      url += `/${tenant.id}`;
      method = 'PUT';
    }
    const request = {};
    for (const property in newTenant) {
      if (newTenant[property] != null && newTenant[property].length > 0 && newTenant !== 'id') {
        request[property] = newTenant[property];
      }
    }
    const response = await apiFetch(url, method, request);
    if (response.ok) {
      show = false;
      const item = await response.json();
      Object.assign(tenant, item);
      dispatch('save', { item });
      return true;
    }
  }

  /**
   * Save the tenant, then redirect to the "add users" flow
   */
  async function saveAndAddUsers(event) {
    event.preventDefault();

    const saveSuccessful = await save(event);
    if (!saveSuccessful || !tenant.id) {
      return;
    }

    router.goto(`/administration/tenant/${tenant.id}/user`);
  }

  const tenantNameRules = [(v) => !!v || 'Tenant Name is required'];
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {#if tenant.id}
          {$_('administration.tenants.edit')}
        {:else}
          {$_('administration.tenants.new')}
        {/if}
        {$_('administration.tenants.singular')}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <TextField
          bind:value={newTenant.name}
          bind:inputElement={addTenantNameField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          required
        >
          {$_('administration.tenants.table.name')}
        </TextField>

        {#await load()}
          <ProgressLinear />
        {:then}
          <Select
            outlined
            dense
            items={tenantTypeItems}
            bind:value={newTenant.type}
            class="pb-2"
            mandatory
          >
            {$_('administration.tenants.table.type')}
          </Select>
          <Select
            outlined
            dense
            items={tenantStateItems}
            bind:value={newTenant.status}
            class="pb-2"
            mandatory
          >
            {$_('administration.tenants.table.status')}
          </Select>
        {/await}
        <Divider class="mt-4 secondary-color" />
        <div class="pt-4">
          <Button
            type="submit"
            class={newTenant.name ? 'secondary-color' : 'grey-color'}
            text
            disabled={!newTenant.name}
            on:click={save}
          >
            {$_('administration.tenants.save')}
          </Button>
          <Button
            type="submit"
            class={newTenant.name ? 'secondary-color' : 'grey-color'}
            text
            disabled={!newTenant.name}
            on:click={saveAndAddUsers}
          >
            {$_('administration.tenants.save-and-add-users')}
          </Button>
          <Button class="ml-2" on:click={cancel} text>
            {$_('administration.tenants.cancel')}
          </Button>
        </div>
      </form>
    </CardText>
  </Card>
</Dialog>
