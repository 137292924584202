<script>
  import { createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider, Icon } from 'svelte-materialify';
  import { mdiDelete } from '@mdi/js';
  import { _ } from '../../services/i18n';
  import { apiFetch } from '../../services/network';

  const dispatch = createEventDispatcher();
  export let show;
  export let group;

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function deletegroup(event) {
    event.preventDefault();
    const url = `/contract/group/${group.id}`;
    const response = await apiFetch(url, 'DELETE');
    if (response.ok) {
      show = false;
      dispatch('delete', { id: group.id });
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('contract-group.delete')}
        {$_('contract-group.singular')} - {group.name}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />

      <div class="pt-4">
        <h6>
          {$_('contract-group.deleteConfirmation')}
        </h6>
        <h6 class="mt-4">
          {$_('contract-group.deleteExplanation')}
        </h6>
      </div>

      <form>
        <Button type="submit" class="mt-4 error-color" on:click={deletegroup} text>
          <Icon path={mdiDelete} />
          {$_('contract-group.delete')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('contract-group.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
