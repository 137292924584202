<script>
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import { ProgressLinear } from 'svelte-materialify';
  import { _ } from '../../services/i18n';
  import DataTable from '../DataTable.svelte';
  import { apiFetch } from '../../services/network';
  import { cellRendererFactory } from '../DataTable/CellRendererFactory';
  import DataTableRowActions from '../DataTable/DataTableRowActions.svelte';

  export let rowSelectable = false;

  let list = [];
  export let group;
  // Array of objects that the button renderer uses to create the buttons for each table.
  export let rowActions = [];
  // same as rowActions but buttons go above the datatable
  export let globalActions = [];
  export let linkActions;
  export let tableName;
  // export let tableHeight = "605px";
  let dataTable;
  let currentItem;
  let countries = [];

  // ++++++++ area between + is used to map data in the table to localized data.
  function extractValues(mappings) {
    return Object.keys(mappings);
  }

  // let localizedList = list;
  const patentTypeMappings = {
    provisional: $_('patent.types.provisional'),
    'non-provisional': $_('patent.types.non-provisional'),
    patent: $_('patent.types.patent'),
    'pct-application': $_('patent.types.pct-application'),
    continuation: $_('patent.types.continuation'),
    'continuation-in-part': $_('patent.types.continuation-in-part'),
    divisional: $_('patent.types.divisional'),
    'utility-model': $_('patent.types.utility-model'),
  };

  const patentTypes = extractValues(patentTypeMappings);
  const patentStatusMappings = {
    'not-yet-filed': $_('patent.states.not-yet-filed'),
    pending: $_('patent.states.pending'),
    granted: $_('patent.states.granted'),
    lapsed: $_('patent.states.lapsed'),
    abandoned: $_('patent.states.abandoned'),
    closed: $_('patent.states.closed'),
  };

  const patentStatuses = extractValues(patentStatusMappings);
  function lookupValue(mappings, key) {
    return mappings[key];
  }

  const columnDefs = [
    { field: 'id', headerName: '', hide: true, suppressColumnsToolPanel: true },
    ...(rowActions.some((rowAction) => rowAction.type === 'button')
      ? [
          {
            field: 'buttons',
            headerName: '',
            pinned: 'left',
            resizable: false,
            sortable: false,
            maxWidth: 40,
            editable: false,
            cellClass: 'table-actions pa-0 text-center',
            cellStyle: { cursor: 'pointer' },
            cellRenderer: cellRendererFactory((cell) => {
              new DataTableRowActions({
                target: cell.eGui,
              });
            }),
            suppressColumnsToolPanel: true,
          },
        ]
      : []),
    ...(rowSelectable
      ? [
          {
            checkboxSelection: true,
            field: 'checkbox',
            pinned: 'left',
            resizable: false,
            sortable: false,
            maxWidth: 40,
            editable: false,
            cellStyle: { paddingLeft: '10px' },
            suppressColumnsToolPanel: true,
          },
        ]
      : []),
    {
      field: 'name',
      headerName: $_('patent.table.title'),
      cellRenderer: 'linkRenderer',
      sortable: true,
      filter: true,
      resizable: true,
      sort: 'asc',
    },
    {
      field: 'country',
      valueGetter: lookupCountry,
      headerName: $_('patent.table.country'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'status',
      headerName: $_('patent.table.status'),
      sortable: true,
      filter: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: patentStatuses },
      filterParams: {
        valueFormatter(params) {
          return lookupValue(patentStatusMappings, params.value);
        },
      },
      valueFormatter(params) {
        return lookupValue(patentStatusMappings, params.value);
      },
      resizable: true,
    },
    {
      field: 'patentType',
      headerName: $_('patent.table.type'),
      sortable: true,
      filter: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: patentTypes },
      filterParams: {
        valueFormatter(params) {
          return lookupValue(patentTypeMappings, params.value);
        },
      },
      valueFormatter(params) {
        return lookupValue(patentTypeMappings, params.value);
      },
      resizable: true,
    },
    {
      field: 'nickName',
      headerName: $_('patent.table.nickName'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'lawFirmIdentifier',
      headerName: $_('patent.table.lawFirmId'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'productId',
      headerName: $_('patent.table.productId'),
      sortable: true,
      filter: true,
      resizeable: true,
    },
    {
      field: 'filingDate',
      headerName: $_('patent.table.filingDate'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'applicationNumber',
      headerName: $_('patent.table.applicationNumber'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'grantDate',
      headerName: $_('patent.table.grantDate'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'grantNumber',
      headerName: $_('patent.table.grantNumber'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'nextAction',
      headerName: $_('patent.table.nextAction'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'actionDueDate',
      headerName: $_('patent.table.actionDueDate'),
      sortable: true,
      filter: true,
      resizable: true,
    },
  ];

  async function getItemsList(event) {
    if (tableName == 'patent-infamily') {
      list = group.items;
    } else if (tableName == 'patent-addtogroup') {
      list = [];
      const url = `/patent`;
      const response = await apiFetch(url);
      let res;
      if (response.ok) {
        res = await response.json();
        const ids = [];
        for (let i = 0; i < group.items.length; i++) {
          ids.push(group.items[i].id);
        }
        for (const re of res) {
          if (!ids.includes(re.id)) {
            list.push(re);
          }
        }
      }
    } else {
      const url = `/patent`;
      const response = await apiFetch(url);
      if (response.ok) {
        list = await response.json();
      }
    }

    const countriesResponse = await fetch('/countries2.json');
    if (countriesResponse.ok) {
      countries = await countriesResponse.json();
    }
  }

  function lookupCountry(params) {
    return (
      countries.find((country) => country.code === params.data.country)?.name ?? params.data.country
    );
  }

  export function addNewRows(newItems, add = false) {
    console.log('patent items add new rows');
    if (add) {
      console.log('In if');
      for (const newItem of newItems) {
        list.push(newItem);
      }
    }
    dataTable.addNewRows(newItems);
  }

  export function updateRow(itemId) {
    dataTable.updateRow(itemId);
  }

  export function deleteRows(itemId) {
    dataTable.deleteRows(itemId);
    for (let i = 0; i < list.length; i++) {
      if (list[i].id == itemId) {
        list.splice(i, 1);
        break;
      }
    }
  }
</script>

{#await getItemsList()}
  <ProgressLinear indeterminate color="secondary" />
{:then}
  <DataTable
    bind:rowData={list}
    bind:this={dataTable}
    bind:currentItem
    on:viewButtonClicked
    on:editButtonClicked
    on:deleteButtonClicked
    on:removeFromGroupClicked
    on:moveFromGroupClicked
    on:rowSelected
    {columnDefs}
    {rowActions}
    {globalActions}
    {linkActions}
    {tableName}
    {rowSelectable}
  />
{/await}

<style>
</style>
