<script>
  import { afterUpdate, createEventDispatcher, getContext } from 'svelte';
  import uid from 'svelte-materialify/src/internal/uid';
  import { Button, Card, CardText, CardTitle, Dialog, Divider, Switch } from 'svelte-materialify';
  import { _ } from '../../../services/i18n';
  import { validateForm } from '../../../services/validation';
  import { apiFetch } from '../../../services/network';
  import TextField from '../../TextField.svelte';

  const tenantInfo = getContext('tenantInfo');

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const dispatch = createEventDispatcher();
  const fortress = 'FORTRESS';
  export let show;
  export let tenantId = -1;
  export let user = {
    email: '',
    firstName: '',
    lastName: '',
    suspended: false,
    changePasswordAtNextSignin: true,
    password: `${fortress[getRandomInt(0, 7)]}${uid(3)}-${uid(5)}`,
  };

  const newUser = {};
  Object.assign(newUser, user);

  $: filledPassword = user.id ? true : newUser.password != '';

  $: saveButtonDisabled = !newUser.email || !filledPassword;
  let errorString = '';
  let formElement;

  let userEmailField;
  let userFirstNameField;
  let userLastNameField;
  let userPasswordField;
  let isFocused = false;

  afterUpdate(async () => {
    if (!isFocused && user && userEmailField && !user.email) {
      userEmailField.focus();
      isFocused = true;
    }
  });

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function save(event) {
    event.preventDefault();
    Object.assign(user, newUser);
    if (!validateForm(formElement)) {
      return;
    }

    let url = `/api/user`;
    let method = 'POST';
    if (user.id) {
      url += `/${user.id}`;
      method = 'PUT';
    }
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const request = {};
    for (const property in newUser) {
      if (newUser[property] != null && newUser !== 'id') {
        request[property] = newUser[property];
      }
    }
    request.email.trim();
    request.firstName.trim();
    request.lastName.trim();
    const response = await apiFetch(url, method, request);
    if (response.ok) {
      const item = await response.json();
      const msg = { item };
      if (user.password) {
        msg.password = user.password;
      }
      show = false;
      dispatch('save', msg);
      errorString = '';
    } else if (response.status === 409) {
      const err = await response.json();
      errorString = $_(`changePassword.fail.${err.reason}`);
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {#if user.id}
          {$_('administration.users.edit')}
        {:else}
          {$_('administration.users.new')}
        {/if}
        {$_('administration.users.singular')}
        - (Tenant: {tenantInfo.name})
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <TextField
          bind:value={newUser.email}
          bind:inputElement={userEmailField}
          class="pb-2"
          outlined={true}
          dense
          clearable
          color={'secondary'}
          required
        >
          {$_('administration.users.table.email')}
        </TextField>
        <TextField
          bind:value={newUser.firstName}
          bind:inputElement={userFirstNameField}
          class="pb-2"
          outlined={true}
          dense
          clearable
          color={'secondary'}
        >
          {$_('administration.users.table.firstName')}
        </TextField>
        <TextField
          bind:value={newUser.lastName}
          bind:inputElement={userLastNameField}
          class="pb-2"
          outlined={true}
          dense
          clearable
          color={'secondary'}
        >
          {$_('administration.users.table.lastName')}
        </TextField>
        {#if !user.id}
          <Switch bind:checked={newUser.suspended} color="secondary">
            {$_('administration.users.table.suspended')}
          </Switch>
          <Switch bind:checked={newUser.changePasswordAtNextSignin} color="secondary">
            {$_('administration.users.force-password-change')}
          </Switch>
          <TextField
            bind:value={newUser.password}
            bind:inputElement={userPasswordField}
            class="pb-2"
            outlined={true}
            dense
            clearable
            color={'secondary'}
            required
          >
            {$_('administration.users.initialPassword')}
          </TextField>
        {/if}
        {#if errorString.length > 0}
          <span class="error-text">{errorString}</span>
        {/if}
        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          on:click={save}
          text
          disabled={saveButtonDisabled}
          class="mt-4 {saveButtonDisabled ? 'mt-4 grey-color' : 'secondary-color'}"
        >
          {$_('administration.users.save')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('administration.users.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
