<script>
  import { onDestroy, onMount } from 'svelte';
  import { Route, router } from 'tinro';
  import { _ } from '../../services/i18n';
  import {
    accountHasPermissionForGroupAndType,
    GROUP_ALL,
    GROUP_CONTRACT,
    GROUP_CREATE,
    GROUP_PATENT,
  } from '../../services/account';
  import {
    clientContacts,
    noClientContactSelectedId,
    selectedClientContactId,
  } from '../../services/network';
  import ContractItemsAll from './ContractItemsAll.svelte';
  import ContractDetail from './ContractDetail.svelte';
  import ContractGroups from './ContractGroups.svelte';
  import ContractGroupDetail from './ContractGroupDetail.svelte';
  import FamiliesAndItemsContainer from '../FamiliesAndItemsContainer.svelte';
  import NoClientSelected from '../NoClientSelected.svelte';

  const GROUPS_TAB = 0;
  const ITEMS_TAB = 1;

  let itemsTableElement;
  let groupsTableElement;
  let itemsList = [];
  let groupsList = [];

  onMount(() => {});

  onDestroy(() => {});

  function tabChanged(event) {
    const { tabIndex } = event.detail;
    const routeNames = ['/contract/family', '/contract/item'];
    router.goto(routeNames[tabIndex]);
  }
</script>

<Route path="/family">
  {#if $clientContacts.length > 0 && $selectedClientContactId === noClientContactSelectedId}
    <NoClientSelected />
  {:else}
    <FamiliesAndItemsContainer
      readonly={!accountHasPermissionForGroupAndType(GROUP_CREATE, GROUP_ALL, GROUP_CONTRACT)}
      groupsTitle={`${$_('contract.singular')} ${$_('groups-and-items.groups.plural')}`}
      itemsTitle={$_('contract.title')}
      tabIndex={GROUPS_TAB}
      on:tabChanged={tabChanged}
    >
      <div slot="groups" class="ml-2">
        <ContractGroups bind:list={groupsList} bind:tableElement={groupsTableElement} />
      </div>
      <div slot="items" class="ml-2">
        <ContractItemsAll bind:contractItems={itemsList} bind:tableElement={itemsTableElement} />
      </div>
    </FamiliesAndItemsContainer>
  {/if}
</Route>

<Route path="/family/:id/*" let:meta>
  <ContractGroupDetail contractGroupId={meta.params.id} />
</Route>

<Route path="/item">
  {#if $clientContacts.length > 0 && $selectedClientContactId === noClientContactSelectedId}
    <NoClientSelected />
  {:else}
    <FamiliesAndItemsContainer
      readonly={!accountHasPermissionForGroupAndType(GROUP_CREATE, GROUP_ALL, GROUP_PATENT)}
      groupsTitle={`${$_('contract.singular')} ${$_('groups-and-items.groups.plural')}`}
      itemsTitle={$_('contract.title')}
      tabIndex={ITEMS_TAB}
      on:tabChanged={tabChanged}
    >
      <div slot="groups" class="ml-2">
        <ContractGroups bind:list={groupsList} bind:tableElement={groupsTableElement} />
      </div>
      <div slot="items" class="ml-2">
        <ContractItemsAll bind:contractItems={itemsList} bind:tableElement={itemsTableElement} />
      </div>
    </FamiliesAndItemsContainer>
  {/if}
</Route>

<Route path="/item/:id" let:meta>
  <ContractDetail contractId={meta.params.id} />
</Route>

<style>
</style>
