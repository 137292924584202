import { writable } from 'svelte/store';

export const authenticated = writable(false);
export const accountInfo = writable({});

let localAccountInfo = {};
let unsubscribeAccountInfoFunc = null;

if (unsubscribeAccountInfoFunc === null) {
  unsubscribeAccountInfoFunc = accountInfo.subscribe((value) => {
    localAccountInfo = value;
  });
}

let localAuthenticated = false;
let unsubscribeAuthenticated = null;

if (unsubscribeAuthenticated === null) {
  unsubscribeAuthenticated = authenticated.subscribe((value) => {
    if (!value) {
      accountInfo.update(() => ({}));
    }
    localAuthenticated = value;
  });
}

export const GROUP_ALL = 0; // if groupId === 0 , then this is the ALL group

export const GROUP_PATENT = 'patent';
export const GROUP_TRADEMARK = 'trademark';
export const GROUP_COPYRIGHT = 'copyright';
export const GROUP_DESIGN = 'design';
export const GROUP_PLANTBREEDERSRIGHT = 'plant-breeders-right';
export const GROUP_CONTRACT = 'contract';
export const GROUP_IDEA = 'idea';
export const GROUP_TRADESECRET = 'trade-secret';
export const GROUP_OPENSOURCE = 'open-source';

export const GROUP_SHARE = 'share';
export const GROUP_LIST = 'list';
export const GROUP_READ = 'read';
export const GROUP_CREATE = 'create';
export const GROUP_UPDATE = 'update';
export const GROUP_DELETE = 'delete';

export const GROUP_PERMISSION_SETS = {
  create: [GROUP_CREATE],
  view: [GROUP_LIST, GROUP_READ],
  edit: [GROUP_LIST, GROUP_READ, GROUP_CREATE, GROUP_UPDATE, GROUP_DELETE, GROUP_SHARE],
};

export const ITEM_PATENT = 'patent';
export const ITEM_TRADEMARK = 'trademark';
export const ITEM_COPYRIGHT = 'copyright';
export const ITEM_DESIGN = 'design';
export const ITEM_PLANTBREEDERSRIGHT = 'plant-breeders-right';
export const ITEM_CONTRACT = 'contract';
export const ITEM_IDEA = 'idea';
export const ITEM_TRADESECRET = 'trade-secret';
export const ITEM_OPENSOURCE = 'open-source';

export const ITEM_READ = 'read';
export const ITEM_CREATE = 'create';
export const ITEM_UPDATE = 'update';
export const ITEM_DELETE = 'delete';

export const SYSTEM_WIDE_TENANT_ADMIN = 'system-wide-tenant-admin'; // used by orula to do tenant administration across the entire SaaS offering
export const SYSTEM_WIDE_USER_ADMIN = 'system-wide-user-admin'; // used by orula to do user administration across all tenants
export const SYSTEM_WIDE_CLIENT_ADMIN = 'system-wide-client-admin'; // used by orula to do Client administration across all tenants

export const PER_TENANT_CONTENT_CREATE = 'per-tenant-content-create'; // a user who has content creation rights for all content types within a tenant
export const PER_TENANT_CONTENT_ADMIN = 'per-tenant-content-admin'; // a user who has content admin rights for all content types within a tenant
export const PER_TENANT_USER_ADMIN = 'per-tenant-user-admin'; // a user who has user admin rights within a tenant
export const PER_TENANT_CLIENT_ADMIN = 'per-tenant-client-admin'; // a user who has client admin rights within a tenant

export function accountHasTenantPermission(perm) {
  if (localAccountInfo.tenantPermissions?.includes(perm)) {
    return true;
  }
  return false;
}
export function accountHasPermissionForGroupAndType(perm, groupId, groupType, groupOwner = null) {
  if (localAccountInfo.id && groupOwner != null && groupOwner.id === localAccountInfo.id) {
    return true;
  }
  if (localAccountInfo.groupAccessPermissions) {
    for (const groupPerm of localAccountInfo.groupAccessPermissions) {
      if (
        groupPerm.rights.includes(perm) &&
        (groupPerm.groupId === GROUP_ALL || groupPerm.groupId === groupId) &&
        groupPerm.groupType === groupType
      ) {
        return true;
      }
    }
  }
  return false;
}

export function accountHasPermissionSetForGroupAndType(
  permSet,
  groupId,
  groupType,
  groupOwner = null
) {
  if (localAccountInfo.id && groupOwner != null && groupOwner.id === localAccountInfo.id) {
    return true;
  }
  if (localAccountInfo.groupAccessPermissions) {
    for (const groupPerm of localAccountInfo.groupAccessPermissions) {
      if (
        groupPerm.groupType === groupType &&
        (groupPerm.groupId === GROUP_ALL || groupPerm.groupId === groupId) &&
        GROUP_PERMISSION_SETS[permSet]?.every((p) => groupPerm.rights.includes(p))
      ) {
        return true;
      }
    }
  }
  return false;
}

export function accountHasPermissionForItemAndType(perm, item, itemType) {
  if (localAccountInfo.id && item.owner != null && item.owner.id === localAccountInfo.id) {
    return true;
  }
  if (localAccountInfo.groupAccessPermissions && item.groups) {
    for (const groupPerm of localAccountInfo.groupAccessPermissions) {
      if (groupPerm.groupId === GROUP_ALL) {
        if (groupPerm.groupType === itemType && groupPerm.rights.includes(perm)) {
          return true;
        }
      } else {
        for (const group of item.groups) {
          if (
            group.id === groupPerm.groupId &&
            groupPerm.groupType === itemType &&
            groupPerm.rights.includes(perm)
          ) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

/**
 * @function accountHasSomeGroupPermissionForTenant
 * @param ipType
 * @param tenantId
 * @returns boolean - true if the logged on user has some permissions for this ipType and tenant
 */
export function accountHasSomeGroupPermissionForTenant(ipType, tenantId) {
  for (const groupAccessPermission of localAccountInfo.groupAccessPermissions) {
    if (groupAccessPermission.groupType === ipType && groupAccessPermission.tenantId === tenantId) {
      return true;
    }
  }
  return false;
}

/**
 * @function accountHasSomeItemPermissionForTenant
 * @param ipType
 * @param tenantId
 * @returns boolean - true if the logged on user has some permissions for this ipType and tenant
 */
export function accountHasSomeItemPermissionForTenant(ipType, tenantId) {
  for (const itemAccessPermission of localAccountInfo.itemAccessPermissions) {
    if (itemAccessPermission.itemType === ipType && itemAccessPermission.tenantId === tenantId) {
      return true;
    }
  }
  return false;
}

export function accountHasPermissionForTenant(perm) {
  if (localAccountInfo.tenantPermissions) {
    return localAccountInfo.tenantPermissions.includes(perm);
  }
  return false;
}
