<script>
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Checkbox,
    Col,
    Dialog,
    Divider,
    ProgressCircular,
    Row,
  } from 'svelte-materialify';
  import { _ } from '../../../services/i18n';
  import { apiFetch } from '../../../services/network';

  const dispatch = createEventDispatcher();
  export let show;
  export let tenantId = -1;
  export let userId;
  export let permissions = [];
  export let selectedEntities = [];

  let userIndex = -1;
  let userList = [];
  const showProgressSpinner = false;
  let selectedUsers = [Number.parseInt(userId)];
  const permissionTypes = ['edit', 'view'];
  const ipTypes = ['patent', 'trademark', 'contract', 'report'];
  $: {
    console.log(`show multiple users dialog ${show}`);
  }

  onMount(async () => {
    userList = await load();
  });

  onDestroy(async () => {});

  async function load() {
    let url = `/api/user`;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const response = await apiFetch(url);
    if (response.ok) {
      const users = await response.json();
      const activeUsers = users.filter((i) => i.suspended === false);
      activeUsers.sort((a, b) => a.firstName.localeCompare(b.firstName));
      userIndex = activeUsers.findIndex((user) => user.id == userId);
      return activeUsers;
    }
    return [];
  }

  function selectAll(event) {
    for (const user of userList) {
      if (!selectedUsers.includes(user.id)) {
        selectedUsers = [...selectedUsers, user.id];
      }
    }
  }

  function unselectAll(event) {
    selectedUsers = [Number.parseInt(userId)];
  }

  function handleSave(event) {
    event.preventDefault();
    event.stopPropagation();
    // call save function in parent dialog
    dispatch('save', { selectedUsers, permissions });
  }

  function getFamilyNames(ipType, permission, entityId) {
    const filteredPermissions = permissions.filter(
      (perm) =>
        perm.ipType === ipType &&
        perm.permissionType.type === permission &&
        perm.entityId == entityId
    );

    const families = [];
    for (const perm of filteredPermissions) families.push(...perm.families);
    const familyNames = [];
    for (const family of families) familyNames.push(family.label);
    return familyNames;
  }

  function capitalizeFirstCharacter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function permissionsBeingSetForType(permission, entityId) {
    return permissions.some(
      (p) => p.entityId == entityId && p.permissionType.type == permission && p.families.length > 0
    );
  }
  function test(event) {
    console.log(userId);
  }
</script>

<Dialog persistent={true} bind:active={show} width={800}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('administration.users.apply-permissions-to-users-dialog.title')}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color mb-4" />

      {#each selectedEntities as entity}
        <div>
          <h7 class="secondary-text">{entity.name}</h7>
          {#each permissionTypes as permissionType}
            {#if permissionsBeingSetForType(permissionType, entity.id)}
              <Row dense style="padding-left:10px;">
                <Col class="col-1">
                  {capitalizeFirstCharacter(permissionType)}
                </Col>
                <Col>
                  {#each ipTypes as ipType}
                    {#if getFamilyNames(ipType, permissionType, entity.id).length > 0}
                      <Row dense>
                        <Col class="col-3">{capitalizeFirstCharacter(ipType)} Families:</Col>
                        <Col>{getFamilyNames(ipType, permissionType, entity.id).join(', ')}</Col>
                      </Row>
                    {/if}
                  {/each}
                </Col>
              </Row>
            {/if}
          {/each}
        </div>
      {/each}
      <Divider class="mt-2" />
      <Button size="small" class="mt-4 ml-2 secondary-color" text on:click={selectAll}>
        {$_('administration.users.group-permissions.add-dialog.selectAll')}
      </Button>
      <Button size="small" class="mt-4 ml-2 secondary-color" on:click={unselectAll} text>
        {$_('administration.users.group-permissions.add-dialog.unselectAll')}
      </Button>
      <table class="mt-3">
        <tbody>
          {#each userList as user, index}
            <tr>
              <td width="450px">
                <Checkbox
                  bind:group={selectedUsers}
                  value={user.id}
                  color={user.id == userId ? '' : 'secondary'}
                  disabled={user.id == userId}
                >
                  {user.firstName}
                  {user.lastName} ({user.email})
                </Checkbox>
              </td>
            </tr>
          {/each}
        </tbody>
      </table>

      <Divider class="mt-4 secondary-color" />

      <Button type="submit" class="mt-4 ml-2 secondary-color" on:click={handleSave} text>
        {#if showProgressSpinner}
          <ProgressCircular indeterminate color={'grey-color'} size={24} class="mr-3" />
        {/if}
        {$_('administration.users.apply-permissions-to-users-dialog.apply')}
      </Button>
      <Button class="mt-4 ml-2" on:click={() => (show = false)} text>
        {$_('administration.users.apply-permissions-to-users-dialog.cancel')}
      </Button>
    </CardText>
  </Card>
</Dialog>

<style>
</style>
