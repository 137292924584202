<script>
  import { getContext, onMount } from 'svelte';
  import {
    Button,
    Col,
    Container,
    ExpansionPanel,
    ExpansionPanels,
    Icon,
    List,
    ListItem,
    Menu,
    Row,
  } from 'svelte-materialify';
  import { mdiTrashCanOutline } from '@mdi/js';
  import { _ } from '../../../services/i18n';
  import FamilyPermissionRow from './FamilyPermissionRow.svelte';
  import { getAdminGroupPermissions } from '../../../services/ip';

  export let entity;
  const tenant = getContext('tenantInfo');
  const entityId = entity.id ?? tenant.id;
  export let showRemoveEntity = false;
  export let selectedEntities = [];
  export let allPermissions = [];
  $: currentEntityPermissions = allPermissions.filter((perm) => perm.entityId == entityId) ?? [];

  onMount(() => {
    if (currentEntityPermissions.length === 0) {
      addEmptyPermission();
    }
  });

  function removeEntity() {
    selectedEntities = selectedEntities.filter((e) => e != entity);
    allPermissions = allPermissions.filter((perm) => perm.entityId != entityId);
  }

  function addEditAllPermissionsForCurrentTenancy() {
    allPermissions = allPermissions.filter((perm) => perm.entityId != entityId);
    const editPermissions = getAdminGroupPermissions(entityId);
    allPermissions.push(...editPermissions);
    addEmptyPermission();
  }

  function clearAllPermissionsForCurrentTenancy() {
    allPermissions = allPermissions.filter((perm) => perm.entityId != entityId);
    addEmptyPermission();
  }

  function addEmptyPermission() {
    allPermissions.push({
      ipType: [],
      families: [],
      permissionType: { value: false, type: 'view' },
      entityId: entity?.id ?? tenant.id,
    });
  }
</script>

<ExpansionPanels>
  <ExpansionPanel class="ma-2">
    <span slot="header" class="secondary-text">{entity.name}</span>
    <Container>
      <Row>
        <Menu closeOnClick>
          <div slot="activator">
            <Button size="x-small" class="secondary-color">
              {$_('administration.users.group-permissions.add-dialog.entity-group.quick-actions')}
            </Button>
          </div>
          <List dense>
            <ListItem on:click={addEditAllPermissionsForCurrentTenancy}>
              {$_('administration.users.group-permissions.add-dialog.entity-group.edit-all')}
            </ListItem>
            <ListItem on:click={clearAllPermissionsForCurrentTenancy}>
              {$_('administration.users.group-permissions.add-dialog.entity-group.clear-all')}
            </ListItem>
          </List>
        </Menu>
      </Row>
      <Row noGutters class="d-flex align-center mt-3 font-weight-bold">
        <Col cols="3" class="d-flex justify-center pl-5 pr-5 mr-5">
          {$_('administration.users.group-permissions.add-dialog.family-permission-row.ip-type')}
        </Col>
        <Col cols="5" class="d-flex justify-center ml-5">
          {$_(
            'administration.users.group-permissions.add-dialog.family-permission-row.family-names'
          )}
        </Col>
        <Col class="d-flex justify-center ml-2" cols="2">
          {$_('administration.users.group-permissions.add-dialog.family-permission-row.permission')}
        </Col>
        <Col class="d-flex" cols="1" />
      </Row>
      {#each currentEntityPermissions as p (p)}
        <FamilyPermissionRow {entityId} bind:permission={p} bind:allPermissions />
      {/each}
      {#if showRemoveEntity}
        <Row class="d-flex justify-end">
          <Button
            fab
            size="small"
            class="secondary-color underline"
            title={$_('administration.users.group-permissions.add-dialog.entity-group.remove')}
            on:click={removeEntity}
          >
            <Icon path={mdiTrashCanOutline} />
          </Button>
        </Row>
      {/if}
    </Container>
  </ExpansionPanel>
</ExpansionPanels>
