<script>
  import { createEventDispatcher, onMount, setContext } from 'svelte';
  import { router } from 'tinro';
  import { Button, Icon, ProgressLinear, Snackbar, TextField } from 'svelte-materialify';
  import { mdiContentCopy, mdiPlus } from '@mdi/js';
  import {
    accountHasTenantPermission,
    PER_TENANT_USER_ADMIN,
    SYSTEM_WIDE_USER_ADMIN,
  } from '../../../services/account';
  import { apiFetch } from '../../../services/network';
  import { _ } from '../../../services/i18n';
  import AddOrUpdateUser from './AddOrUpdateUser.svelte';
  import ChangePassword from './ChangePassword.svelte';
  import SuspendUser from './SuspendUser.svelte';
  import ResetPhoneNumber from './ResetPhoneNumber.svelte';
  import DataTable from '../../DataTable.svelte';

  export let tenantId = -1;
  export let columnDefs = [];
  export let users = [];
  export let showNewUserDialog = false;
  let showEditUserDialog = false;
  let showChangePasswordDialog = false;
  let showSuspendUserDialog = false;
  let showCopyUserDialog = false;
  let showNewPasswordNotice = false;
  let showResetPhoneNumberDialog = false;
  let selectedUser = {};
  let activeUsers = [];
  let newPasswordNoticeText = '';
  let activeUsersTable;
  $: {
    setContext('userId', selectedUser.id);
  }

  export let rowActions = [];

  const linkActionsActive = [
    {
      enabledFunction: isActionEnabled,
      href: `/administration/tenant/${tenantId}/user/`,
      title: $_('administration.clients.open'),
      onClickEvent: 'userPermissionsClicked',
      elementId: `active-users`,
      getNameFunction: getName,
    },
  ];

  function isActionEnabled() {
    return (
      accountHasTenantPermission(SYSTEM_WIDE_USER_ADMIN) ||
      accountHasTenantPermission(PER_TENANT_USER_ADMIN)
    );
  }

  const globalActions = [
    {
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.clients.new'),
      path: mdiPlus,
      onClickEvent: 'newUserClicked',
      elementId: `active-users`,
    },
  ];

  function getName(item) {
    return item.email;
  }

  async function load() {
    activeUsers = users.filter((i) => i.suspended === false);
  }

  function newUserClicked() {
    showNewUserDialog = true;
  }

  function findUserById(id) {
    return users.find((item) => item.id === id);
  }

  function userPermissionsClicked(event) {
    const { id, action } = event.detail;
    let url = `/administration/user/${id}`;
    if (tenantId > -1) {
      url = action.href + id;
    }
    router.goto(url);
  }

  function editUserClicked(event) {
    selectedUser = findUserById(event.detail.id);
    showEditUserDialog = true;
  }

  function changeUserPasswordClicked(event) {
    selectedUser = findUserById(event.detail.id);
    showChangePasswordDialog = true;
  }
  function resetUserPhoneNumberClicked(event) {
    selectedUser = findUserById(event.detail.id);
    showResetPhoneNumberDialog = true;
  }
  function suspendUserClicked(event) {
    selectedUser = findUserById(event.detail.id);
    showSuspendUserDialog = true;
  }

  function copyUserClicked(event) {
    selectedUser = findUserById(event.detail.id);
    showCopyUserDialog = true;
  }

  async function userUpdated(event) {
    const item = event.detail;
    activeUsersTable.updateRow(item.item.id);
  }

  function userPasswordUpdated(event) {
    const { item, password } = event.detail;
    if (password) {
      newPasswordNoticeText = `${$_('administration.users.table.email')}: ${item.email}  ${$_(
        'administration.users.newPassword'
      )}: ${password}`;
      showNewPasswordNotice = true;
    }
  }

  async function userAdded(event) {
    const { item } = event.detail;
    users.push(item);
    if (item.suspended) {
      const eventDetail = { item };
      const customEvent = new CustomEvent('userSuspended', { detail: eventDetail });
      const eventElement = document.querySelector('#suspended-users');
      eventElement.dispatchEvent(customEvent);
    } else {
      activeUsers.push(item);
      activeUsersTable.addNewRows([item]);
    }
  }

  async function userCopied(event) {
    const newUser = event.detail.item;
    users.push(newUser);
    activeUsers = users.filter((i) => i.suspended === false);
    activeUsersTable.addNewRows([newUser]);
    // new user created...now create the permissions based on the previously selectedUser
    let url = `/api/user/${selectedUser.id}/copy-permissions/${newUser.id}`;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    await apiFetch(url, 'PUT');
  }

  function userSuspended(event) {
    const { item } = event.detail;
    for (const user of users) {
      if (user.id == item.id) {
        user.suspended = true;
        break;
      }
    }
    activeUsersTable.deleteRows(item.id);
    for (let j = 0; j < activeUsers.length; j++) {
      if (activeUsers[j].id == item.id) {
        activeUsers.splice(j, 1);
        break;
      }
    }
    // activeUsers = users.filter(i => i.suspended === false);
    const eventDetail = { item };
    const customEvent = new CustomEvent('userSuspended', { detail: eventDetail });
    const eventElement = document.querySelector('#suspended-users');
    eventElement.dispatchEvent(customEvent);
  }

  function userReactivated(event) {
    activeUsers.push(event.detail.item);
    for (const user of users) {
      if (user.id == event.detail.item.id) {
        user.suspended = false;
        activeUsersTable.addNewRows([user]);
        break;
      }
    }
  }

  function copyToClipboardClicked() {
    navigator.clipboard.writeText(newPasswordNoticeText);
  }
</script>

{#await load()}
  <ProgressLinear />
{:then}
  <div
    id="active-users"
    on:newUserClicked={newUserClicked}
    on:userAdded={userAdded}
    on:userSuspended={userSuspended}
    on:userReactivated={userReactivated}
  >
    <DataTable
      bind:rowData={activeUsers}
      bind:this={activeUsersTable}
      {columnDefs}
      {rowActions}
      {globalActions}
      linkActions={linkActionsActive}
      tableName="users"
      gridId="activeusers"
      on:giveAdminPermissionsClicked
      on:sendWelcomeEmail
      on:copyUserClicked={copyUserClicked}
      on:editUserClicked={editUserClicked}
      on:userPermissionsClicked={userPermissionsClicked}
      on:changeUserPasswordClicked={changeUserPasswordClicked}
      on:resetUserPhoneNumberClicked={resetUserPhoneNumberClicked}
      on:suspendUserClicked={suspendUserClicked}
    />
  </div>
{/await}

{#if showNewUserDialog}
  <AddOrUpdateUser bind:show={showNewUserDialog} {tenantId} on:save={userAdded} />
{/if}
{#if showCopyUserDialog}
  <AddOrUpdateUser bind:show={showCopyUserDialog} {tenantId} on:save={userCopied} />
{/if}
{#if showEditUserDialog}
  <AddOrUpdateUser
    bind:show={showEditUserDialog}
    {tenantId}
    user={selectedUser}
    on:save={userUpdated}
  />
{/if}
{#if showChangePasswordDialog}
  <ChangePassword
    bind:show={showChangePasswordDialog}
    {tenantId}
    user={selectedUser}
    on:save={userPasswordUpdated}
  />
{/if}
{#if showSuspendUserDialog}
  <SuspendUser
    bind:show={showSuspendUserDialog}
    {tenantId}
    user={selectedUser}
    on:save={userSuspended}
  />
{/if}
<!-- {#if showDeleteUserDialog}
        <DeleteUser bind:show={showDeleteUserDialog} tenantId={tenantId} user={selectedUser} on:save={userUpdated}/>
    {/if} -->
{#if showResetPhoneNumberDialog}
  <ResetPhoneNumber
    bind:show={showResetPhoneNumberDialog}
    {tenantId}
    user={selectedUser}
    on:save={userUpdated}
  />
{/if}
{#if showNewPasswordNotice}
  <Snackbar class="flex-column" bind:active={showNewPasswordNotice} center>
    <div class="pa-4" style="width: 550px;background-color: white;">
      <div class="mt-4">
        <TextField
          bind:value={newPasswordNoticeText}
          outlined={true}
          dense
          color={'secondary'}
          readonly
        >
          {$_('administration.users.account-info-title')}
          <span slot="append">
            <Button
              icon
              on:click={copyToClipboardClicked}
              title={$_('administration.users.copy-to-clipboard')}
            >
              <Icon path={mdiContentCopy} />
            </Button>
          </span>
        </TextField>
      </div>
      <div class="pt-2 d-flex justify-center">
        <Button
          class="secondary-color"
          title={$_('administration.users.dismiss')}
          on:click={() => {
            showNewPasswordNotice = false;
          }}
          text
        >
          {$_('administration.users.dismiss')}
        </Button>
      </div>
    </div>
  </Snackbar>
{/if}

<style>
  table {
    border: 1px solid white;
    border-collapse: collapse;
  }
  thead > th:first-child {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }
  thead > th:last-child {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  th {
    border: 1px solid white;
    height: 41px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #1a76d2;
    color: white;
  }
  td {
    padding: 4px;
    border-bottom: 1px solid #1a76d2;
  }
</style>
