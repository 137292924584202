<script>
  import { Button, Card, CardText, CardTitle, Dialog, Divider, Icon } from 'svelte-materialify';
  import { mdiDelete } from '@mdi/js';
  import { createEventDispatcher } from 'svelte';
  import { _ } from '../../services/i18n';
  import { apiFetch } from '../../services/network';

  export let user;
  export let dataRoom;

  const dispatch = createEventDispatcher();

  const deleteRecordLevelPermission = () =>
    apiFetch(`/record-level-permission/user/${user.id}/data_room/${dataRoom.id}`, 'PUT', []).then(
      async (response) => {
        if (response.ok) {
          dispatch('success');
        }
      }
    );
</script>

<Dialog persistent={true} active>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{$_('data-rooms.share-data-room.delete-title')}</h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />

      <div class="pt-4">
        <h6>
          {$_('data-rooms.share-data-room.delete-confirmation', {
            values: { email: user.email, dataRoomName: dataRoom.name },
          })}
        </h6>
      </div>

      <Button type="button" class="mt-4 error-color" on:click={deleteRecordLevelPermission} text>
        <Icon path={mdiDelete} />
        {$_('data-rooms.share-data-room.delete')}
      </Button>
      <Button class="mt-4 ml-2" on:click={() => dispatch('cancel')} text>
        {$_('data-rooms.share-data-room.cancel')}
      </Button>
    </CardText>
  </Card>
</Dialog>
