<script>
  import {
    ProgressLinear,
    Icon,
    ExpansionPanels,
    ExpansionPanel,
    Container,
    Row,
    Col,
    Button,
  } from 'svelte-materialify';
  import { mdiPencilOutline, mdiShareVariant, mdiViewListOutline } from '@mdi/js';
  import { createEventDispatcher, onMount } from 'svelte';
  import { Route } from 'tinro';
  import { IP_TYPE_PATENT, IP_SCOPE_GROUP, _ } from '../../services/i18n';
  import { apiFetch, setSelectedClientByTenant } from '../../services/network';
  import {
    GROUP_SHARE,
    accountHasPermissionForGroupAndType,
    GROUP_UPDATE,
  } from '../../services/account';
  import ShareGroup from '../ShareGroup.svelte';
  import AddOrUpdatePatent from './AddOrUpdatePatent.svelte';
  import PatentDetail from './PatentDetail.svelte';
  import AddPatentsToGroup from './AddPatentsToGroup.svelte';
  import AddOrUpdatePatentGroup from './AddOrUpdatePatentGroup.svelte';
  import DeletePatentFromGroup from './DeletePatentFromGroup.svelte';
  import PatentItemsInFamily from './PatentItemsInFamily.svelte';
  import FilesContainer from '../FilesContainer.svelte';
  import Notes from '../Notes.svelte';
  import UrlBreadcrumb from '../UrlBreadcrumb.svelte';
  import MovePatentBetweenGroups from './MovePatentBetweenGroups.svelte';
  import CopyURL from '../CopyURL.svelte';

  export let patentGroupId;
  let group;
  let detailsPanel = [0];
  let activePanels = [0, 1, 2];
  const dispatch = createEventDispatcher();
  let breadCrumbItems = [];
  let showShareGroupDialog = false;
  let showEditGroupMembersDialog = false;
  let showAddNewItemDialog = false;
  let showDeleteItemFromGroupDialog = false;
  let showMoveItemBetweenGroupsDialog = false;
  let showUpdateDialog = false;
  let showEditItemDialog = false;
  let selectedItem = {};
  onMount(() => {});

  async function load() {
    const url = `/patent/group/${patentGroupId}`;
    const response = await apiFetch(url);
    if (response.ok) {
      group = await response.json();
      setSelectedClientByTenant(group.tenant);
      breadCrumbItems = [
        { text: `${$_('patent-group.title')}`, href: '/patent/family' },
        { text: `${$_('patent-group.singular')} - ${group.name}` },
      ];
    }
  }

  function formatUser(userProfile) {
    return `${userProfile.firstName} ${userProfile.lastName} - ${userProfile.tenant.name}`;
  }

  function owner() {
    return formatUser(group.owner);
  }

  function originator() {
    return formatUser(group.originator);
  }

  function editOwner(event) {}

  function findItemById(id) {
    return group.items.find((item) => item.id === id);
  }

  async function newItem(event) {
    const { item } = event.detail;
    const response = await apiFetch(`/api/patent/group/${group.id}/item/${item.id}`, 'POST');
    if (response.ok) {
      group = await response.json();
    }
  }

  function updatedItemInGroup(event) {
    load();
  }

  async function removeItemFromGroup(event) {
    const { id } = event.detail;
    const response = await apiFetch(`/api/patent/group/${group.id}/item/${id}`, 'DELETE');
    if (response.ok) {
      group = await response.json();
    }
  }

  function moveFromGroupClicked(event) {
    const element = event.currentTarget;
    const id = Number.parseInt(element.dataset.id);
    selectedItem = findItemById(id);
    showMoveItemBetweenGroupsDialog = true;
  }

  function patentsAddedToGroup(event) {
    load();
  }

  function shareGroupButtonClicked(event) {
    event.preventDefault();
    event.stopPropagation();
    showShareGroupDialog = true;
  }

  function updateGroupButtonClicked(event) {
    showUpdateDialog = true;
  }

  function updatedItemSaved(event) {
    group = event.detail.item;
  }

  function itemUpdated(event) {
    const { item } = event.detail;
    const foundIndex = group.items.findIndex((listItem) => listItem.id === item.id);
    if (foundIndex >= 0) {
      group.items[foundIndex] = item;
    }
  }
</script>

<h5 class="secondary-text text-center">{$_('patent.singular')}</h5>
{#await load()}
  <ProgressLinear />
{:then}
  <Route path="/" let:meta>
    <Container>
      <Row>
        <Col class="col-12">
          <ExpansionPanels accordion bind:value={detailsPanel}>
            <ExpansionPanel>
              <div class="d-flex justify-space-between" style="width:100%;" slot="header">
                <span>
                  <UrlBreadcrumb name={group.name} />
                </span>
                <span class="pa-3 ma-1">
                  <Button
                    disabled={!accountHasPermissionForGroupAndType(
                      GROUP_SHARE,
                      group.id,
                      IP_TYPE_PATENT,
                      group.owner
                    )}
                    class={accountHasPermissionForGroupAndType(
                      GROUP_SHARE,
                      group.id,
                      IP_TYPE_PATENT,
                      group.owner
                    )
                      ? 'secondary-color'
                      : ''}
                    on:click={shareGroupButtonClicked}
                  >
                    <Icon class="text-white" path={mdiShareVariant} />{$_('patent-group.share')}
                  </Button>
                </span>
              </div>
              <Container>
                <Button
                  icon
                  disabled={!accountHasPermissionForGroupAndType(
                    GROUP_UPDATE,
                    group.id,
                    IP_TYPE_PATENT,
                    group.owner
                  )}
                  class={accountHasPermissionForGroupAndType(
                    GROUP_UPDATE,
                    group.id,
                    IP_TYPE_PATENT,
                    group.owner
                  )
                    ? 'secondary-color'
                    : ''}
                  on:click={updateGroupButtonClicked}
                  title={$_('contract-group.edit')}
                >
                  <Icon class="text-white" path={mdiPencilOutline} />
                </Button>
                <CopyURL />
                <div class="pl-3">
                  <Row>
                    <Col class="col-4">
                      <Row>
                        <span class="font-weight-bold">{$_('patent-group.table.title')}: </span>
                      </Row>
                      <Row>
                        {group.name ?? ''}
                      </Row>
                    </Col>
                    <Col class="col-4">
                      <Row>
                        <span class="font-weight-bold">{$_('patent-group.table.nickName')}: </span>
                      </Row>
                      <Row>
                        {group.nickName ?? ''}
                      </Row>
                    </Col>
                    <Col class="col-4">
                      <Row>
                        <span class="font-weight-bold">{$_('patent-group.table.lawFirmId')}: </span>
                      </Row>
                      <Row>
                        {group.lawFirmIdentifier ?? ''}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col class="col-4">
                      <Row>
                        <span class="font-weight-bold">{$_('patent-group.table.otherId')}: </span>
                      </Row>
                      <Row>
                        {group.otherIdentifier ?? ''}
                      </Row>
                    </Col>
                    <Col class="col-4">
                      <Row>
                        <span class="font-weight-bold">{$_('patent-group.originator')}: </span>
                      </Row>
                      <Row>
                        {originator() ?? ''}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Container>
            </ExpansionPanel>
          </ExpansionPanels>
        </Col>
      </Row>
      <Row>
        <Col class="col-12">
          <ExpansionPanels bind:value={activePanels} multiple>
            <ExpansionPanel>
              <div slot="header" class="secondary-text">
                <span><Icon class="secondary-text" path={mdiViewListOutline} /></span>
                <span>{$_('patent.title')}</span>
              </div>
              <!-- overflow-x: scroll;  -->
              <div style="width: 100%">
                <PatentItemsInFamily {group} />
              </div>
            </ExpansionPanel>
            <FilesContainer
              ipType={IP_TYPE_PATENT}
              ipScope={IP_SCOPE_GROUP}
              scopeId={group.id}
              readonly={!accountHasPermissionForGroupAndType(
                GROUP_UPDATE,
                group.id,
                IP_TYPE_PATENT,
                group.owner
              )}
            />
            <Notes
              ipType={IP_TYPE_PATENT}
              ipScope={IP_SCOPE_GROUP}
              scopeId={group.id}
              readonly={!accountHasPermissionForGroupAndType(
                GROUP_UPDATE,
                group.id,
                IP_TYPE_PATENT,
                group.owner
              )}
            />
          </ExpansionPanels>
        </Col>
      </Row>
    </Container>
  </Route>
  <Route path="/item/:id" let:meta>
    <PatentDetail patentId={meta.params.id} name={group.name} on:itemUpdated={itemUpdated} />
  </Route>
{/await}

{#if showUpdateDialog}
  <AddOrUpdatePatentGroup bind:show={showUpdateDialog} {group} on:save={updatedItemSaved} />
{/if}
{#if showShareGroupDialog}
  <ShareGroup bind:show={showShareGroupDialog} groupType="patent" {group} />
{/if}
{#if showEditGroupMembersDialog}
  <AddPatentsToGroup bind:show={showEditGroupMembersDialog} {group} on:save={patentsAddedToGroup} />
{/if}
{#if showAddNewItemDialog}
  <AddOrUpdatePatent bind:show={showAddNewItemDialog} on:save={newItem} />
{/if}
{#if showEditItemDialog}
  <AddOrUpdatePatent
    bind:show={showEditItemDialog}
    patent={selectedItem}
    on:save={updatedItemInGroup}
  />
{/if}
{#if showDeleteItemFromGroupDialog}
  <DeletePatentFromGroup
    bind:show={showDeleteItemFromGroupDialog}
    groupId={group.id}
    patent={selectedItem}
    on:delete={removeItemFromGroup}
  />
{/if}

{#if showMoveItemBetweenGroupsDialog}
  <MovePatentBetweenGroups
    bind:show={showMoveItemBetweenGroupsDialog}
    groupId={group.id}
    item={selectedItem}
    on:delete={removeItemFromGroup}
  />
{/if}

<style>
</style>
