<script>
  import { onDestroy, onMount } from 'svelte';
  import { Route } from 'tinro';
  import { mdiAccountBoxMultiple, mdiAccountGroup, mdiOfficeBuildingOutline } from '@mdi/js';
  import { Icon, ListItem } from 'svelte-materialify';
  import {
    accountHasTenantPermission,
    accountInfo,
    PER_TENANT_CLIENT_ADMIN,
    PER_TENANT_USER_ADMIN,
    SYSTEM_WIDE_TENANT_ADMIN,
  } from '../../services/account';
  import { _ } from '../../services/i18n';
  import Tenants from './Tenants/Tenants.svelte';

  onMount(() => {});

  onDestroy(() => {});
</script>

<Route path="/">
  <h5 class="secondary-text  text-left">{$_('administration.title')}</h5>
  <ul class="pt-4">
    {#if accountHasTenantPermission(SYSTEM_WIDE_TENANT_ADMIN)}
      <li class="pt-2 pb-2 pr-r text-h6">
        <Icon class="secondary-text" path={mdiOfficeBuildingOutline} />
        <a class="pl-2" href="/administration/tenant">{$_('administration.tenants.title')}</a>
      </li>
    {:else}
      <!--per tenant user admin, per tenant client admin-->
      {#if accountHasTenantPermission(PER_TENANT_USER_ADMIN)}
        <a class="secondary-text" href={`/administration/tenant/${accountInfo.tenant.Id}/user`}>
          <ListItem>
            <span class="secondary-text" slot="prepend">
              <Icon class="secondary-text" path={mdiAccountBoxMultiple} />{$_(
                'administration.users.title'
              )}
            </span>
          </ListItem>
        </a>
      {/if}
      {#if accountHasTenantPermission(PER_TENANT_CLIENT_ADMIN)}
        <a class="secondary-text" href={`/administration/tenant/${accountInfo.tenant.Id}/client`}>
          <ListItem>
            <span class="secondary-text" slot="prepend">
              <Icon class="secondary-text" path={mdiAccountGroup} />{$_(
                'administration.clients.title'
              )}
            </span>
          </ListItem>
        </a>
      {/if}
    {/if}
    <!-- {#if accountHasTenantPermission(SYSTEM_WIDE_CLIENT_ADMIN)}
    <li class="pt-2 pb-2 pr-r text-h6"><Icon class="secondary-text" path={mdiOfficeBuildingOutline} />
        <a class="pl-2" href="/administration/client">{$_('administration.clients.title')}</a>
    </li>
{/if} -->
  </ul>
</Route>

<Route path="/tenant/*">
  <Tenants />
</Route>
<!-- <Route path="/client/*">
    <Clients />
</Route> -->

<!--ul class="pt-4">
    <li class="pt-2 pb-2 pr-r text-h6"><Icon class="secondary-text" path={mdiCog} /><span class="pl-2">{$_('administration.accounts.title')}</span></li>
    <li class="pt-2 pb-2 pr-r text-h6"><Icon class="secondary-text" path={mdiAccountGroup} /><span class="pl-2">{$_('administration.settings.title')}</span></li> 
</ul-->
<style>
  ul {
    list-style-type: none;
  }
</style>
