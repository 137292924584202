<script>
  import { mdiChevronDoubleLeft, mdiChevronDoubleRight, mdiMenu } from '@mdi/js';
  import { onMount, tick } from 'svelte';
  import { _ } from 'svelte-i18n';
  import {
    AppBar,
    Avatar,
    Button,
    Icon,
    ListItem,
    NavigationDrawer,
    Overlay,
    ProgressLinear,
    Tooltip,
  } from 'svelte-materialify';
  import { router } from 'tinro';
  import { accountInfo, authenticated } from '../services/account';
  import { apiFetch, clientContacts } from '../services/network';
  import { isSmAndDown } from '../services/stores/media-queries';
  import { sideBarPinnedOpen } from '../services/stores/sidebar';
  import AboutDialog from './AboutDialog.svelte';
  import NetworkError from './NetworkError.svelte';
  import PrimaryRouter from './PrimaryRouter.svelte';
  import Sidebar from './Sidebar.svelte';
  import SignInWorkflow from './SignInWorkflow.svelte';
  import UploadProgressManager from './UploadProgressManager.svelte';
  import VersionCheck from './VersionCheck.svelte';
  import WebViewer from './WebViewer.svelte';

  let breakpoints = {};
  let mobile = false;
  $: navDrawerActive = false;
  $: navMini = false;
  $: initials = Object.keys($accountInfo).includes('firstName') ?
    $accountInfo.firstName.charAt(0) + $accountInfo.lastName.charAt(0) :
    '';
  let navHoverHandler = () => {};
  const navDrawerWidth = '200px';
  const navMiniWidth = '70px';
  router.subscribe((_) => window.scrollTo(0, 0));

  onMount(async () => {
    import('svelte-materialify/src/utils/breakpoints').then(({ default: data }) => {
      breakpoints = data;
      checkMobile();
    });

    // flipping value so we can use the toggle function
    $sideBarPinnedOpen = !$sideBarPinnedOpen;
    toggleNavPinnedMini();
  });

  function checkMobile() {
    mobile = window.matchMedia(breakpoints['sm-and-down']).matches;
    $isSmAndDown = mobile;
  }

  function toggleNavDrawerActive(e) {
    navDrawerActive = !navDrawerActive;
  }

  function titleClicked() {
    const event = new CustomEvent('show');
    const aboutDialog = document.querySelector('#about-dialog');
    aboutDialog.dispatchEvent(event);
  }

  async function loadClients() {
    const url = `/api/client`;
    const response = await apiFetch(url);
    if (response.ok) {
      const list = await response.json();
      clientContacts.update((l) => list);
    }
  }

  /**
   * Toggles whether the nav is in its full or mini state
   * @param isNavMini true if nav should be put in a mini state
   */
  function toggleNavMini(isNavMini = !navMini) {
    navMini = isNavMini;
  }

  /**
   * Toggle the user-selected state of the nav (full or mini).
   * Needed to return the nav to its desired state after hovering over menu
   * options
   */
  async function toggleNavPinnedMini() {
    navHoverHandler = () => {};
    toggleNavMini($sideBarPinnedOpen);
    $sideBarPinnedOpen = !$sideBarPinnedOpen;
    await tick();
    // set hover handler if nav not pinned open
    if (!$sideBarPinnedOpen) {
      setTimeout(() => {
        navHoverHandler = toggleNavHover;
      }, 300);
    }
  }

  async function toggleNavHover(e) {
    if (!$sideBarPinnedOpen) {
      toggleNavMini();
      await tick();
    }
  }
</script>

<svelte:window on:resize={checkMobile} />

<!--{#if $authenticated === false} -->
<SignInWorkflow />
<!--{:else}-->
{#if $authenticated === true}
  {#await loadClients()}
    <ProgressLinear />
  {:then}
    <div class="wrapper">
      <main class="d-flex">
        <AppBar fixed class="pl-n5 pr-n4 pt-n2" style="width: 100vw">
          <span slot="icon">
            {#if $isSmAndDown}
              <Button fab depressed on:click={toggleNavDrawerActive}>
                <Icon class="secondary-text" path={mdiMenu} />
              </Button>
            {/if}
          </span>
          <div
            id="app-title"
            slot="title"
            class="d-flex align-center"
            title={$_('app.about')}
            on:click={titleClicked}
          >
            <img
              src="/images/logos/Fortress_RGB.png"
              class="logo"
              alt="{$_('app.name')} logo"
              title="{$_('app.name')}"
            />
          </div>
          <div class="flex-grow-1" />

          <div class="d-flex align-right">
            <a href="/profile" class={$isSmAndDown ? '' : 'profile-badge'}>
              <ListItem class="pt-1 pb-1">
                <div slot="prepend">
                  <Avatar class="secondary-color" size="32px">
                    <span class="font-weight-medium">{ initials }</span>
                  </Avatar>
                </div>
                {#if !$isSmAndDown}
                  <div class="secondary-text">
                    {$accountInfo.firstName} {$accountInfo.lastName}
                  </div>
                {/if}
              </ListItem>
            </a>
          </div>
        </AppBar>
        <div
          class="pb-2 mt-15 d-flex flex-row flex-grow-1"
          style="overflow-y: auto"
        >
          <div
            class="d-flex flex-column"
            style="min-width: fit-content"
            on:mouseenter={navHoverHandler}
            on:mouseleave={navHoverHandler}
          >
            <!-- Need to hard-code style width so drawer is the proper width
              when switching between mobile and desktop view
          -->
            <NavigationDrawer
              active={$isSmAndDown ? navDrawerActive : true}
              absolute={$isSmAndDown}
              fixed={!$isSmAndDown}
              class={$isSmAndDown ? '' : 'pt-15'}
              mini={navMini && !$isSmAndDown}
              miniWidth={navMiniWidth}
              width={navDrawerWidth}
              style="width: {navMini && !$isSmAndDown ? navMiniWidth : navDrawerWidth}"
              index={$isSmAndDown ? 10 : 4}
            >
              <div slot="append" class="full-width">
                {#if !$isSmAndDown}
                  <Tooltip top>
                    <span slot="tip">
                      {#if navMini || !$sideBarPinnedOpen}
                        {$_('sidebar.pinOpen')}
                      {:else}
                        {$_('sidebar.collapse')}
                      {/if}
                    </span>
                    <Button text block class="secondary-text" on:click={toggleNavPinnedMini}>
                      {#if navMini || !$sideBarPinnedOpen}
                        {#if !navMini}
                          {$_('sidebar.pinOpen')}
                        {/if}
                        <Icon path={mdiChevronDoubleRight} />
                      {:else}
                        <Icon path={mdiChevronDoubleLeft} />
                        {$_('sidebar.collapse')}
                      {/if}
                    </Button>
                  </Tooltip>
                {/if}
              </div>
              <Sidebar
                on:itemClicked={(e) => {
                  if ($isSmAndDown) {
                    toggleNavDrawerActive(e);
                  }
                }}
              />
            </NavigationDrawer>
          </div>
          <div
            class="d-flex flex-column flex-grow-1 pl-4 pr-4 pt-2 pb-2"
            style="margin-left: {$isSmAndDown ? '' : navMini ? navMiniWidth : navDrawerWidth}"
          >
            <PrimaryRouter />
          </div>
          <Overlay
            absolute
            active={$isSmAndDown ? navDrawerActive : false}
            on:click={toggleNavDrawerActive}
          />
        </div>
      </main>
    </div>

    <UploadProgressManager />
  {/await}
{/if}
<!--if/else authenticated -->

<WebViewer />
<VersionCheck />
<NetworkError />
<AboutDialog />

<style>
  main {
    height: 100%;
  }
  :global(img.logo-shield) {
    width: 48px;
    height: 48px;
  }
  :global(img.logo) {
    height: 48px;
    width: 130px;
  }
  nav {
    width: 345px;
    height: fit-content;
    min-height: 100vh;
    border-style: none solid none none;
    border-color: gray;
    border-width: 1px;
  }
  #app-title {
    cursor: pointer;
  }
  .wrapper {
    height: 100%;
  }
  .profile-badge:hover {
    background-color: #e0e0e0;
  }
  main {
    height: 100%;
    width: 100%;
  }
  :global(.s-app) {
    height: 100%;
    min-width: 360px;
  }
  :global(.full-width .s-tooltip__wrapper) {
    width: 100%;
  }
</style>
