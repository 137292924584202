<script>
  import {
    ProgressLinear,
    Icon,
    ExpansionPanels,
    ExpansionPanel,
    Container,
    Row,
    Col,
    TextField,
    Button,
  } from 'svelte-materialify';
  import { createEventDispatcher, onMount } from 'svelte';
  import { mdiPencilOutline } from '@mdi/js';
  import { _, IP_TYPE_TRADEMARK, IP_SCOPE_ITEM } from '../../services/i18n';
  import { apiFetch, setSelectedClientByTenant } from '../../services/network';
  import { ITEM_UPDATE, accountHasPermissionForItemAndType } from '../../services/account';
  import FilesContainer from '../FilesContainer.svelte';
  import AddOrUpdateTrademark from './AddOrUpdateTrademark.svelte';
  import Notes from '../Notes.svelte';
  import UrlBreadcrumb from '../UrlBreadcrumb.svelte';
  import CopyURL from '../CopyURL.svelte';

  export let trademarkId;
  export let name = '';
  let trademark;
  let detailsPanel = [0];
  let activePanels = [0, 1];
  const dispatch = createEventDispatcher();
  let breadCrumbItems = [];
  const attachments = [];
  let showUpdateDialog = false;
  let countries = [];

  onMount(() => {});

  async function load() {
    const url = `/api/trademark/${trademarkId}`;
    const response = await apiFetch(url);
    if (response.ok) {
      trademark = await response.json();
      setSelectedClientByTenant(trademark.tenant);
      if (name == '') {
        name = trademark.name;
      }
      breadCrumbItems = [
        { text: $_('trademark.title'), href: '/trademark/item' },
        { text: `${$_('trademark.singular')} - ${trademark.name}` },
      ];
    }

    const countriesResponse = await fetch('/countries2.json');
    if (countriesResponse.ok) {
      countries = await countriesResponse.json();
    }
  }

  function editOwner(event) {}

  function groups() {
    return trademark.groups?.map(group => `"${group.name}"`).join(' ') ?? ''
  }

  function updateItemButtonClicked(event) {
    showUpdateDialog = true;
  }

  function itemSaved(event) {
    trademark = event.detail.item;
    dispatch('itemUpdated', { item: trademark });
  }
</script>

{#if name.length === 0}
  <h5 class="secondary-text text-center">{$_('trademark.singular')}</h5>
{/if}
{#await load()}
  <ProgressLinear />
{:then}
  <Container>
    <Row>
      <Col class="col-12">
        <ExpansionPanels accordion bind:value={detailsPanel}>
          <ExpansionPanel>
            <div slot="header">
              <span>
                <UrlBreadcrumb {name} name2={trademark.name} />
              </span>
            </div>
            <Container>
              <Button
                icon
                disabled={!accountHasPermissionForItemAndType(
                  ITEM_UPDATE,
                  trademark,
                  IP_TYPE_TRADEMARK
                )}
                class={accountHasPermissionForItemAndType(ITEM_UPDATE, trademark, IP_TYPE_TRADEMARK)
                  ? 'secondary-color'
                  : ''}
                on:click={updateItemButtonClicked}
                title={$_('trademark.edit')}
              >
                <Icon class="text-white" path={mdiPencilOutline} />
              </Button>
              <CopyURL />
              <div class="pl-3">
                <Row>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('trademark.table.kind')}: </span>
                    </Row>
                    <Row>
                      {$_(`trademark.kinds.${trademark.trademarkKind}`) ?? ''}
                    </Row>
                  </Col>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('trademark.table.status')}: </span>
                    </Row>
                    <Row>
                      {$_(`trademark.states.${trademark.status}`) ?? ''}
                    </Row>
                  </Col>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('trademark.table.title')}: </span>
                    </Row>
                    <Row>
                      {trademark.name ?? ''}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('trademark.table.nickName')}: </span>
                    </Row>
                    <Row>
                      {trademark.nickName ?? ''}
                    </Row>
                  </Col>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('trademark.table.lawFirmId')}: </span>
                    </Row>
                    <Row>
                      {trademark.lawFirmIdentifier ?? ''}
                    </Row>
                  </Col>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('trademark.table.country')}: </span>
                    </Row>
                    <Row>
                      {countries.find(country => country.code === trademark.country)?.name ?? trademark.country ?? ''}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold"
                        >{$_('trademark.table.applicationNumber')}:
                      </span>
                    </Row>
                    <Row>
                      {trademark.applicationNumber ?? ''}
                    </Row>
                  </Col>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold"
                        >{$_('trademark.table.applicationDate')}:
                      </span>
                    </Row>
                    <Row>
                      {trademark.applicationDate ?? ''}
                    </Row>
                  </Col>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold"
                        >{$_('trademark.table.registrationNumber')}:
                      </span>
                    </Row>
                    <Row>
                      {trademark.registrationNumber ?? ''}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold"
                        >{$_('trademark.table.registrationDate')}:
                      </span>
                    </Row>
                    <Row>
                      {trademark.registrationDate ?? ''}
                    </Row>
                  </Col>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('trademark.table.nextAction')}: </span>
                    </Row>
                    <Row>
                      {trademark.nextAction ?? ''}
                    </Row>
                  </Col>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('trademark.table.actionDueDate')}: </span>
                    </Row>
                    <Row>
                      {trademark.actionDueDate ?? ''}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('trademark.table.classes')}: </span>
                    </Row>
                    <Row>
                      {trademark.classes ?? ''}
                    </Row>
                  </Col>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('trademark.table.groups')}: </span>
                    </Row>
                    <Row>
                      {groups() ?? ''}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Container>
          </ExpansionPanel>
        </ExpansionPanels>
      </Col>
    </Row>
    <Row>
      <Col class="col-12">
        <ExpansionPanels bind:value={activePanels} multiple>
          <FilesContainer
            ipType={IP_TYPE_TRADEMARK}
            ipScope={IP_SCOPE_ITEM}
            scopeId={trademark.id}
            readonly={!accountHasPermissionForItemAndType(
              ITEM_UPDATE,
              trademark,
              IP_TYPE_TRADEMARK
            )}
          />
          <Notes
            ipType={IP_TYPE_TRADEMARK}
            ipScope={IP_SCOPE_ITEM}
            scopeId={trademark.id}
            readonly={!accountHasPermissionForItemAndType(
              ITEM_UPDATE,
              trademark,
              IP_TYPE_TRADEMARK
            )}
          />
        </ExpansionPanels>
      </Col>
    </Row>
  </Container>
{/await}

{#if showUpdateDialog}
  <AddOrUpdateTrademark bind:show={showUpdateDialog} {trademark} on:save={itemSaved} />
{/if}
