<script>
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import { Route, router } from 'tinro';
  import { _ } from '../../services/i18n';
  import {
    accountHasPermissionForGroupAndType,
    GROUP_ALL,
    GROUP_CREATE,
    GROUP_PATENT,
  } from '../../services/account';
  import {
    clientContacts,
    noClientContactSelectedId,
    selectedClientContactId,
  } from '../../services/network';
  import PatentItemsAll from './PatentItemsAll.svelte';
  import PatentDetail from './PatentDetail.svelte';
  import PatentGroups from './PatentGroups.svelte';
  import PatentGroupDetail from './PatentGroupDetail.svelte';
  import FamiliesAndItemsContainer from '../FamiliesAndItemsContainer.svelte';
  import NoClientSelected from '../NoClientSelected.svelte';

  const GROUPS_TAB = 0;
  const ITEMS_TAB = 1;

  let itemsTableElement;
  let groupsTableElement;
  let itemsList = [];
  let groupsList = [];
  const gridOptions = {};
  let api;

  onMount(() => {});

  onDestroy(() => {});
  const dispatch = createEventDispatcher();

  function search(tableElement, searchTerm) {
    if (!tableElement) {
      return;
    }
    const filter = searchTerm.toLowerCase();
    const rows = tableElement.querySelectorAll('tr');
    for (const row_ of rows) {
      const cols = row_.querySelectorAll('td');
      let found = false;
      for (const col_ of cols) {
        const txtValue = col_.textContent || col_.innerText;
        if (txtValue.toLowerCase().includes(filter)) {
          found = true;
        }
      }
      row_.style.display = found ? '' : 'none';
    }
  }

  function tabChanged(event) {
    const { tabName, tabIndex } = event.detail;
    const routeNames = ['/patent/family', '/patent/item'];
    router.goto(routeNames[tabIndex]);
  }

  function itemsSearch(event) {
    const { searchTerm } = event.detail;
    search(itemsTableElement, searchTerm);
  }

  function groupsSearch(event) {
    const { searchTerm } = event.detail;
    search(groupsTableElement, searchTerm);
  }
</script>

<Route path="/family">
  {#if $clientContacts.length > 0 && $selectedClientContactId === noClientContactSelectedId}
    <NoClientSelected />
  {:else}
    <FamiliesAndItemsContainer
      readonly={!accountHasPermissionForGroupAndType(GROUP_CREATE, GROUP_ALL, GROUP_PATENT)}
      groupsTitle={`${$_('patent.singular')} ${$_('groups-and-items.groups.plural')}`}
      itemsTitle={$_('patent.title')}
      tabIndex={GROUPS_TAB}
      on:tabChanged={tabChanged}
      on:groupsSearch={groupsSearch}
      on:itemsSearch={itemsSearch}
    >
      <div slot="groups" class="ml-2">
        <PatentGroups bind:list={groupsList} bind:tableElement={groupsTableElement} />
      </div>
      <div slot="items" class="ml-2">
        <PatentItemsAll
          bind:patentItems={itemsList}
          bind:tableElement={itemsTableElement}
          on:itemsSearch={itemsSearch}
        />
      </div>
    </FamiliesAndItemsContainer>
  {/if}
</Route>

<Route path="/family/:id/*" let:meta>
  <PatentGroupDetail patentGroupId={meta.params.id} />
</Route>

<Route path="/item">
  {#if $clientContacts.length > 0 && $selectedClientContactId === noClientContactSelectedId}
    <NoClientSelected />
  {:else}
    <FamiliesAndItemsContainer
      readonly={!accountHasPermissionForGroupAndType(GROUP_CREATE, GROUP_ALL, GROUP_PATENT)}
      groupsTitle={`${$_('patent.singular')} ${$_('groups-and-items.groups.plural')}`}
      itemsTitle={$_('patent.title')}
      tabIndex={ITEMS_TAB}
      on:tabChanged={tabChanged}
      on:groupsSearch={groupsSearch}
      on:itemsSearch={itemsSearch}
    >
      <div slot="groups" class="ml-2">
        <PatentGroups bind:list={groupsList} bind:tableElement={groupsTableElement} />
      </div>
      <div slot="items" class="ml-2">
        <PatentItemsAll bind:patentItems={itemsList} bind:tableElement={itemsTableElement} />
      </div>
    </FamiliesAndItemsContainer>
  {/if}
</Route>

<Route path="/item/:id" let:meta>
  <PatentDetail patentId={meta.params.id} />
</Route>

<style>
</style>
