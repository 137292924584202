<script>
  import { mdiApplicationImport, mdiDownloadOutline } from '@mdi/js';
  import { Button, Container, Icon } from 'svelte-materialify';
  import { _ } from '../../services/i18n';

  function replaceStrings(sourceString, replaceMap) {
    let resultString = sourceString;

    for (const item in replaceMap) {
      resultString = resultString.replaceAll(`{{${item}}}`, `${replaceMap[item]}`);
    }
    return resultString;
  }
</script>

<Container>
  <p>{$_('ip-import.instructions.purpose')}</p>
  <dl>
    <dt>{$_('ip-import.instructions.stages.step-1.title')}</dt>
    <dd>
      {@html replaceStrings($_('ip-import.instructions.stages.step-1.details'), { reg: '&#174;' })}
      <Button text fab disabled size="x-small" class="secondary-color"
        ><Icon path={mdiDownloadOutline} /></Button
      >
    </dd>

    <dt>{$_('ip-import.instructions.stages.step-2.title')}</dt>
    <dd>
      {@html replaceStrings($_('ip-import.instructions.stages.step-2.details'), { reg: '&#174;' })}
    </dd>

    <dt>{$_('ip-import.instructions.stages.step-3.title')}</dt>
    <dd>
      {@html replaceStrings($_('ip-import.instructions.stages.step-3.details'), { reg: '&#174;' })}
      <Button text fab disabled size="x-small" class="secondary-color">
        <Icon path={mdiApplicationImport} />
      </Button>
    </dd>

    <dt>{$_('ip-import.instructions.stages.duplicate-records.title')}</dt>
    <dd>
      {@html $_('ip-import.instructions.stages.duplicate-records.details')}
    </dd>

    <dt>{$_('ip-import.instructions.stages.repeating-imports.title')}</dt>
    <dd>
      {@html $_('ip-import.instructions.stages.repeating-imports.details')}
    </dd>

    <dt>{$_('ip-import.instructions.stages.importing-documents-in-IP.title')}</dt>
    <dd>
      {@html $_('ip-import.instructions.stages.importing-documents-in-IP.details')}
    </dd>

    <dt>{$_('ip-import.instructions.stages.importing-documents-in-data-rooms.title')}</dt>
    <dd>
      {@html replaceStrings(
        $_('ip-import.instructions.stages.importing-documents-in-data-rooms.details'),
        {
          'support-email': '<a href="mailto:support@fortressiam.com">support@fortressiam.com</a>',
        }
      )}
    </dd>
  </dl>
</Container>

<style>
  dt {
    display: inline;
    font-weight: bold;
  }

  dt::after {
    content: ': ';
  }

  dd {
    display: inline;
  }
  dd::after {
    content: '\a\a';
    white-space: pre;
  }
</style>
