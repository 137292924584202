<script>
  import jstz from 'jstz';
  import { afterUpdate, createEventDispatcher, onMount, tick } from 'svelte';
  import { router } from 'tinro';
  import { mdiLockReset } from '@mdi/js';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    Icon,
    TextField,
  } from 'svelte-materialify';
  import { accountInfo, authenticated } from '../services/account';
  import { apiFetch } from '../services/network';
  import { _ } from '../services/i18n';

  const dispatch = createEventDispatcher();

  const timezone = jstz.determine();
  export let email = '';
  export let password = '';
  export let redirect = true;
  export let phoneNumber = '';
  let errorString = '';
  $: buttonsDisabled = !email || !password || !phoneNumber;
  let getPhoneNumberEmailField;
  let getPhoneNumberPasswordField;
  let getPhoneNumberField;
  let isFocused = false;

  onMount(() => {
    errorString = '';
  });

  afterUpdate(() => {
    if (!isFocused) {
      if (!email) {
        getPhoneNumberEmailField.focus();
        isFocused = true;
      }
      if (!password) {
        getPhoneNumberPasswordField.focus();
        isFocused = true;
      }
      if (!phoneNumber) {
        getPhoneNumberField.focus();
        isFocused = true;
      }
    }
  });

  async function getPhoneNumberFormSubmitted(event) {
    const url = `/authentication/signin`;
    const request = {
      email,
      password,
      phoneNumber,
      timezone: timezone.name(),
    };
    const response = await apiFetch(url, 'POST', request);
    const signInResponse = await response.json();
    if (response.ok) {
      dispatch('phoneNumberProvided');
      await tick();
      accountInfo.update((info) => signInResponse);
      authenticated.update((auth) => true);
      dispatch('resetLogin');
      if (redirect) {
        router.goto('/');
      }
    } else {
      if (response.status === 401) {
        errorString = $_('getPhoneNumber.fail.unauthorized');
        authenticated.update((auth) => false);
      } else if (response.status === 403) {
        errorString = $_('getPhoneNumber.fail.suspended');
        authenticated.update((auth) => false);
      } else if (
        response.status === 409 &&
        signInResponse.reason === 'mustProvideOneTimePassword'
      ) {
        dispatch('needOTP', { phoneNumberVerified: signInResponse.phoneNumberVerified });
      } else if (response.status === 409) {
        errorString = $_(`getPhoneNumber.fail.${signInResponse.reason}`);
        authenticated.update((auth) => false);
      } else {
        authenticated.update((auth) => false);
        errorString = $_('getPhoneNumber.fail.other');
      }

      return response;
    }
  }
  function cancel(event) {
    dispatch('phoneNumberChangeCanceled');
  }
</script>

<Dialog persistent={true} active={true}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <div class="d-flex align-center">
        <img
          src="/images/logos/Fortress_RGB.png"
          class="logo"
          alt="{$_('app.name')} logo"
          title="{$_('app.name')}"
        />
      </div>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <h5 class="pt-2 text-center">{$_('getPhoneNumber.banner')}</h5>
      <div>
        <p />
        <form on:submit|preventDefault={getPhoneNumberFormSubmitted}>
          <TextField
            type="email"
            outlined={true}
            color={'secondary'}
            readonly={true}
            placeholder={$_('getPhoneNumber.emailAddress')}
            bind:value={email}
            bind:inputElement={getPhoneNumberEmailField}
          >
            {$_('getPhoneNumber.emailAddress')}
          </TextField>
          <TextField
            type="password"
            outlined={true}
            color={'secondary'}
            readonly={true}
            placeholder={$_('getPhoneNumber.currentPassword')}
            bind:value={password}
            bind:inputElement={getPhoneNumberPasswordField}
          >
            {$_('getPhoneNumber.currentPassword')}
          </TextField>
          <TextField
            type="tel"
            outlined={true}
            clearable={true}
            color={'secondary'}
            placeholder={$_('getPhoneNumber.providedPhoneNumber')}
            bind:value={phoneNumber}
            bind:inputElement={getPhoneNumberField}
          >
            {$_('getPhoneNumber.providedPhoneNumber')}
          </TextField>
          <div class="error-text">
            {errorString}
          </div>
          <br />
          {#if redirect}
            <Button
              block
              class={buttonsDisabled ? 'grey-color' : 'secondary-color'}
              type="submit"
              disabled={buttonsDisabled}
              color="secondary"
            >
              <Icon
                path={mdiLockReset}
                class={buttonsDisabled ? 'grey-color' : 'secondary-color'}
              />{$_('getPhoneNumber.signIn')}
            </Button>
          {:else}
            <Button
              class="mt-4 {buttonsDisabled ? 'mt-4 grey-color' : 'secondary-color'}"
              type="submit"
              disabled={buttonsDisabled}
              color="secondary"
            >
              <Icon
                path={mdiLockReset}
                class="mt-4 {buttonsDisabled ? 'mt-4 grey-color' : 'secondary-color'}"
              />{$_('getPhoneNumber.signIn')}
            </Button>
            <Button class="" type="button" on:click={cancel}>
              {$_('changePhoneNumber.cancel')}
            </Button>
          {/if}
        </form>
      </div>
    </CardText>
  </Card>
</Dialog>
