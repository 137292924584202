import { writable } from "svelte/store";

// Get the store value
const _sideBarPinnedOpen = localStorage.sideBarPinnedOpen ?? true;

// Set the store value
export const sideBarPinnedOpen = writable(JSON.parse(_sideBarPinnedOpen) ?? true);

// Update localstorage when value changes
sideBarPinnedOpen.subscribe((val) => localStorage.sideBarPinnedOpen = val);
