<script>
  import { Col, Container, Row } from 'svelte-materialify';
  import { _ } from '../../services/i18n';

  /**
   * @param errors - array: the list of errors
   */
  export let errors;

  const listHeight = '120px';
</script>

<Container fluid>
  <div class="container pl-4 mb-4">
    <!-- Header -->
    <Row class="font-weight-bold" dense style="scrollbar-gutter: stable; overflow: hidden auto;">
      <Col>{$_('ip-import.errors.sheet')}</Col>
      <Col>{$_('ip-import.errors.row')}</Col>
      <Col>{$_('ip-import.errors.error-code')}</Col>
      <Col>{$_('ip-import.errors.error-msg')}</Col>
    </Row>
    <div
      style="padding-top:10px; max-height:{listHeight}; scrollbar-gutter: stable; overflow: hidden auto;"
    >
      {#each errors as error}
        <Row dense style="margin-top:-11px;">
          <Col>{error.record.ipType}</Col>
          <Col>{error.record.row}</Col>
          <Col>{error.status.code}</Col>
          <Col>{error.status.reason}</Col>
        </Row>
      {/each}
    </div>
  </div>
</Container>
