<script>
  import { MaterialApp, ProgressLinear } from 'svelte-materialify';
  import { setupI18N } from './services/i18n';
  import AppShell from './components/AppShell.svelte';
  import './services/bugsnag';
  import './main.css';
</script>

<MaterialApp>
  {#await setupI18N()}
    <ProgressLinear indeterminate color="secondary" />
  {:then locale}
    <AppShell />
  {:catch}
    <p>Error loading language file</p>
  {/await}
</MaterialApp>
