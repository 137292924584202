<script>
  import Svelecte from 'svelecte';
  import { _ } from '../services/i18n';

  export let value;

  let placeholderText = $_('select-country.placeholder-text');

  function checkIfCountrySelectionCleared(event) {
    if (!event.detail) {
      value = '';
    }
    placeholderText = $_('select-country.placeholder-text');
  }

  function handleInvalidValue(event) {
    placeholderText = event.detail;
  }

</script>

<Svelecte
  class="svelecte-control pb-2 country"
  bind:value
  clearable
  placeholder={placeholderText}
  fetch="/countries2.json"
  valueField="code"
  labelField="name"
  on:change={checkIfCountrySelectionCleared}
  on:invalidValue={handleInvalidValue}
/>

<style>
  :global(.svelecte-control.country .sv-control) {
    border: 1px solid #949494 !important;
    --sv-color: #000;
  }
</style>