<script>
  import { onDestroy, onMount } from 'svelte';
  import { Route, router } from 'tinro';
  import { _ } from '../../services/i18n';
  import {
    accountHasPermissionForGroupAndType,
    GROUP_ALL,
    GROUP_CREATE,
    GROUP_TRADEMARK,
  } from '../../services/account';
  import {
    clientContacts,
    noClientContactSelectedId,
    selectedClientContactId,
  } from '../../services/network';
  import TrademarkItemsAll from './TrademarkItemsAll.svelte';
  import TrademarkDetail from './TrademarkDetail.svelte';
  import TrademarkGroups from './TrademarkGroups.svelte';
  import TrademarkGroupDetail from './TrademarkGroupDetail.svelte';
  import FamiliesAndItemsContainer from '../FamiliesAndItemsContainer.svelte';
  import NoClientSelected from '../NoClientSelected.svelte';

  const GROUPS_TAB = 0;
  const ITEMS_TAB = 1;

  let groupsTableElement;
  let itemsList = [];
  let groupsList = [];

  function tabChanged(event) {
    const { tabIndex } = event.detail;
    const routeNames = ['/trademark/family', '/trademark/item'];
    router.goto(routeNames[tabIndex]);
  }
</script>

<Route path="/family">
  {#if $clientContacts.length > 0 && $selectedClientContactId === noClientContactSelectedId}
    <NoClientSelected />
  {:else}
    <FamiliesAndItemsContainer
      readonly={!accountHasPermissionForGroupAndType(GROUP_CREATE, GROUP_ALL, GROUP_TRADEMARK)}
      groupsTitle={`${$_('trademark.singular')} ${$_('groups-and-items.groups.plural')}`}
      itemsTitle={$_('trademark.title')}
      tabIndex={GROUPS_TAB}
      on:tabChanged={tabChanged}
    >
      <div slot="groups" class="ml-2">
        <TrademarkGroups bind:list={groupsList} bind:tableElement={groupsTableElement} />
      </div>
      <div slot="items" class="ml-2">
        <TrademarkItemsAll bind:trademarkItems={itemsList} />
      </div>
    </FamiliesAndItemsContainer>
  {/if}
</Route>

<Route path="/family/:id/*" let:meta>
  <TrademarkGroupDetail trademarkGroupId={meta.params.id} />
</Route>

<Route path="/item">
  {#if $clientContacts.length > 0 && $selectedClientContactId === noClientContactSelectedId}
    <NoClientSelected />
  {:else}
    <FamiliesAndItemsContainer
      readonly={!accountHasPermissionForGroupAndType(GROUP_CREATE, GROUP_ALL, GROUP_TRADEMARK)}
      groupsTitle={`${$_('trademark.singular')} ${$_('groups-and-items.groups.plural')}`}
      itemsTitle={$_('trademark.title')}
      tabIndex={ITEMS_TAB}
      on:tabChanged={tabChanged}
    >
      <div slot="groups" class="ml-2">
        <TrademarkGroups bind:list={groupsList} bind:tableElement={groupsTableElement} />
      </div>
      <div slot="items" class="ml-2">
        <TrademarkItemsAll bind:trademarkItems={itemsList} />
      </div>
    </FamiliesAndItemsContainer>
  {/if}
</Route>

<Route path="/item/:id" let:meta>
  <TrademarkDetail trademarkId={meta.params.id} />
</Route>

<style>
</style>
