<script>
  import { onDestroy, onMount } from 'svelte';
  import {
    Container,
    ExpansionPanel,
    ExpansionPanels,
    Icon,
    Row,
    TextField,
  } from 'svelte-materialify';
  import { mdiMagnify } from '@mdi/js';
  import { Route } from 'tinro';
  import { apiFetch } from '../services/network';
  import { _ } from '../services/i18n';
  import GuidanceItem from './GuidanceItem.svelte';

  onMount(() => {});

  onDestroy(() => {});

  const searchResultsOpen = true;
  let searchResults = [];
  let searchString = '';
  let debounceSearchTimer;
  let panelStates = [0, 1, 2];

  async function search() {
    clearTimeout(debounceSearchTimer);

    async function innerSearch() {
      const searchTerm = searchString.toLowerCase();
      if (searchTerm.length < 2) {
        return;
      }
      const response = await apiFetch(`/guidance/search/${searchTerm}`, 'POST');
      if (response.ok) {
        searchResults = await response.json();
      }
    }
    debounceSearchTimer = setTimeout(innerSearch, 500);
  }
</script>

<h5 class="secondary-text text-center">{$_('guidance.title')}</h5>

<Route path="/">
  <div class="d-flex align-center pt-4 pb-4">
    <TextField
      bind:value={searchString}
      on:keyup={search}
      style="max-width: 400px;"
      class="pl-4"
      color={'blue'}
      clearable
      outlined
      rounded
      dense
    >
      <div slot="prepend">
        <Icon path={mdiMagnify} />
      </div>
      {$_('guidance.search.title')}
    </TextField>
  </div>
  <ExpansionPanels multiple={true} bind:value={panelStates} accordion={true}>
    {#if searchString.length > 0 && searchResults.length > 0}
      <Container>
        <Row>
          <span class="pl-4 text-h6">
            {$_('guidance.search.results')}
          </span>
        </Row>
        {#each searchResults as searchResult}
          <Row>
            <a class="pl-8 text-h7" href="/guidance/{searchResult.section}/{searchResult.name}">
              {searchResult.title}
            </a>
          </Row>
        {/each}
      </Container>
    {/if}

    <ExpansionPanel>
      <span class="text-h6" slot="header"
        >{$_('guidance.intellectual-property.intellectual-property')}</span
      >
      <Container>
        <Row>
          <a class="pl-4 text-h7" href="https://www.wipo.int/publications/en/details.jsp?id=4545">
            {$_('guidance.intellectual-property.intellectual-property-guide')}
          </a>
        </Row>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/intellectual-property/intellectual-property">
            {$_('guidance.intellectual-property.intellectual-property')}
          </a>
        </Row>
        <Row>
          <a
            class="pl-4 text-h7"
            href="/guidance/intellectual-property/intellectual-property-right"
          >
            {$_('guidance.intellectual-property.intellectual-property-right')}
          </a>
        </Row>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/intellectual-property/confidential-information">
            {$_('guidance.intellectual-property.confidential-information')}
          </a>
        </Row>
      </Container>
    </ExpansionPanel>

    <ExpansionPanel>
      <span class="text-h6" slot="header">{$_('guidance.ip-types.title')}</span>
      <Container>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/ip-types/contracts">
            {$_('guidance.ip-types.contracts')}
          </a>
        </Row>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/ip-types/contract-types">
            {$_('guidance.ip-types.contract-types')}
          </a>
        </Row>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/ip-types/copyright">
            {$_('guidance.ip-types.copyright')}
          </a>
        </Row>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/ip-types/domains">
            {$_('guidance.ip-types.domains')}
          </a>
        </Row>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/ip-types/geo-indications">
            {$_('guidance.ip-types.geo-indications')}
          </a>
        </Row>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/ip-types/industrial-designs">
            {$_('guidance.ip-types.industrial-designs')}
          </a>
        </Row>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/ip-types/ic-topography">
            {$_('guidance.ip-types.ic-topography')}
          </a>
        </Row>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/ip-types/patents">
            {$_('guidance.ip-types.patents')}
          </a>
        </Row>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/ip-types/plant-breeders-rights">
            {$_('guidance.ip-types.plant-breeders-rights')}
          </a>
        </Row>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/ip-types/personality-rights">
            {$_('guidance.ip-types.personality-rights')}
          </a>
        </Row>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/ip-types/trademarks">
            {$_('guidance.ip-types.trademarks')}
          </a>
        </Row>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/ip-types/tradename">
            {$_('guidance.ip-types.tradename')}
          </a>
        </Row>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/ip-types/trade-secrets">
            {$_('guidance.ip-types.trade-secrets')}
          </a>
        </Row>
        <Row>
          <a class="pl-4 text-h7" href="/guidance/ip-types/utility-models">
            {$_('guidance.ip-types.utility-models')}
          </a>
        </Row>
      </Container>
    </ExpansionPanel>

    <!-- <ExpansionPanel >
            <span class="text-h6" slot="header">{$_('guidance.ip-information.title')}</span>
           <Container>
               <Row><div class="pr-2 text-left error-text">Coming Soon</div> -->
    <!-- <a class = "pl-4 text-h7" href="/guidance/ip-types/patent-filing-process">
                        {$_('guidance.ip-information.patent-filing-process')}
                    </a>
                </Row>
                <Row>
                    <a class = "pl-4 text-h7" href="/guidance/ip-types/trademark-filing-process">
                        {$_('guidance.ip-information.trademark-filing-process')}
                    </a>-->
    <!-- </Row>
            </Container>
            
        </ExpansionPanel> -->

    <!-- <ExpansionPanel >
            <span class="text-h6" slot="header">{$_('guidance.budgeting.title')}</span>
            <Container><Row><div class="pr-2 text-left error-text">Coming Soon</div></Row></Container> -->
    <!--Container>
                <Row>
                </Row>
            </Container-->
    <!-- </ExpansionPanel> -->

    <!-- <ExpansionPanel >
            <span class="text-h6" slot="header">{$_('guidance.templates.title')}</span>
            <Container><Row><div class="pr-2 text-left error-text">Coming Soon</div></Row></Container> -->
    <!--Container>
                <Row>
                </Row>
            </Container-->
    <!-- </ExpansionPanel> -->

    <ExpansionPanel>
      <span class="text-h6" slot="header">Patent and Trademark Search</span>
      <Container>
        <Row>
          <Container>
            <Row>
              <h6>Patent Databases</h6>
            </Row>
            <Row>
              <a
                class="pl-4 text-h7"
                target="_blank"
                tinro-ignore
                href="https://www.ic.gc.ca/opic-cipo/cpd/eng/introduction.html"
              >
                Canadian Patent Database
              </a>
            </Row>
            <Row>
              <a class="pl-4 text-h7" target="_blank" tinro-ignore href="http://patft.uspto.gov/">
                U.S. Patent Office
              </a>
            </Row>
            <Row>
              <a
                class="pl-4 text-h7"
                target="_blank"
                tinro-ignore
                href="https://worldwide.espacenet.com/?locale=en_EP"
              >
                European Patent Office
              </a>
            </Row>
            <Row>
              <a
                class="pl-4 text-h7"
                target="_blank"
                tinro-ignore
                href="https://patents.google.com"
              >
                Google Patents
              </a>
            </Row>
          </Container>
          <Container>
            <Row>
              <h6>Trademark Databases</h6>
            </Row>
            <Row>
              <a
                class="pl-4 text-h7"
                target="_blank"
                tinro-ignore
                href="http://www.cipo.ic.gc.ca/app/opic-cipo/trdmrks/srch/home?lang=eng"
              >
                Canadian Trademarks Office Database
              </a>
            </Row>
            <Row>
              <a
                class="pl-4 text-h7"
                target="_blank"
                tinro-ignore
                href="https://www.uspto.gov/trademark"
              >
                USPTO Trade-marks Database
              </a>
            </Row>
            <Row>
              <a
                class="pl-4 text-h7"
                target="_blank"
                tinro-ignore
                href="https://www3.wipo.int/branddb/en/"
              >
                WIPO (Madrid Express Search)
              </a>
            </Row>
          </Container>
        </Row>
      </Container>
    </ExpansionPanel>
  </ExpansionPanels>
</Route>

<Route path="/:section/:item" let:meta>
  <GuidanceItem guidanceSection={meta.params.section} guidanceId={meta.params.item} />
</Route>

<style>
</style>
