<script>
  import {
    ProgressLinear,
    Icon,
    ExpansionPanels,
    ExpansionPanel,
    Container,
    Row,
    Col,
    TextField,
    Button,
  } from 'svelte-materialify';
  import { mdiPencilOutline, mdiShareVariant, mdiViewListOutline } from '@mdi/js';
  import { createEventDispatcher, onMount } from 'svelte';
  import { Route } from 'tinro';
  import { IP_TYPE_TRADEMARK, IP_SCOPE_GROUP, _ } from '../../services/i18n';
  import { apiFetch, setSelectedClientByTenant } from '../../services/network';
  import {
    GROUP_SHARE,
    accountHasPermissionForGroupAndType,
    GROUP_UPDATE,
  } from '../../services/account';
  import ShareGroup from '../ShareGroup.svelte';
  import TrademarkDetail from './TrademarkDetail.svelte';
  import TrademarkItemsInFamily from './TrademarkItemsInFamily.svelte';
  import AddOrUpdateTrademarkGroup from './AddOrUpdateTrademarkGroup.svelte';
  import FilesContainer from '../FilesContainer.svelte';
  import Notes from '../Notes.svelte';
  import UrlBreadcrumb from '../UrlBreadcrumb.svelte';
  import CopyURL from '../CopyURL.svelte';

  export let trademarkGroupId;
  let group;
  let detailsPanel = [0];
  let activePanels = [0, 1, 2];
  const dispatch = createEventDispatcher();
  let breadCrumbItems = [];
  let showShareGroupDialog = false;
  let showUpdateDialog = false;

  onMount(() => {});

  async function load() {
    const url = `/api/trademark/group/${trademarkGroupId}`;
    const response = await apiFetch(url);
    if (response.ok) {
      group = await response.json();
      setSelectedClientByTenant(group.tenant);
      breadCrumbItems = [
        { text: `${$_('trademark-group.title')}`, href: '/trademark/family' },
        { text: `${$_('trademark-group.singular')} - ${group.name}` },
      ];
    }
  }

  function formatUser(userProfile) {
    return `${userProfile.firstName} ${userProfile.lastName} - ${userProfile.tenant.name}`;
  }

  function owner() {
    return formatUser(group.owner);
  }

  function originator() {
    return formatUser(group.originator);
  }

  function editOwner(event) {}

  function shareGroupButtonClicked(event) {
    event.preventDefault();
    event.stopPropagation();
    showShareGroupDialog = true;
  }

  function updateGroupButtonClicked(event) {
    showUpdateDialog = true;
  }

  function updatedItemSaved(event) {
    group = event.detail.item;
  }

  function itemUpdated(event) {
    const { item } = event.detail;
    const foundIndex = group.items.findIndex((listItem) => listItem.id === item.id);
    if (foundIndex >= 0) {
      group.items[foundIndex] = item;
    }
  }
</script>

<h5 class="secondary-text text-center">{$_('trademark.singular')}</h5>
{#await load()}
  <ProgressLinear />
{:then}
  <Route path="/" let:meta>
    <Container>
      <Row>
        <Col class="col-12">
          <ExpansionPanels accordion bind:value={detailsPanel}>
            <ExpansionPanel>
              <div class="d-flex justify-space-between" style="width:100%;" slot="header">
                <span>
                  <UrlBreadcrumb name={group.name} />
                </span>
                <span class="pa-3 ma-1">
                  <Button
                    disabled={!accountHasPermissionForGroupAndType(
                      GROUP_SHARE,
                      group.id,
                      IP_TYPE_TRADEMARK,
                      group.owner
                    )}
                    class={accountHasPermissionForGroupAndType(
                      GROUP_SHARE,
                      group.id,
                      IP_TYPE_TRADEMARK,
                      group.owner
                    )
                      ? 'secondary-color'
                      : ''}
                    on:click={shareGroupButtonClicked}
                  >
                    <Icon class="text-white" path={mdiShareVariant} />{$_('trademark-group.share')}
                  </Button>
                </span>
              </div>
              <Container>
                <Button
                  icon
                  disabled={!accountHasPermissionForGroupAndType(
                    GROUP_UPDATE,
                    group.id,
                    IP_TYPE_TRADEMARK,
                    group.owner
                  )}
                  class={accountHasPermissionForGroupAndType(
                    GROUP_UPDATE,
                    group.id,
                    IP_TYPE_TRADEMARK,
                    group.owner
                  )
                    ? 'secondary-color'
                    : ''}
                  on:click={updateGroupButtonClicked}
                  title={$_('trademark-group.edit')}
                >
                  <Icon class="text-white" path={mdiPencilOutline} />
                </Button>
                <CopyURL />
                <div class="pl-3">
                  <Row>
                    <Col class="col-4">
                      <Row>
                        <span class="font-weight-bold">{$_('trademark-group.table.title')}: </span>
                      </Row>
                      <Row>
                        {group.name ?? ''}
                      </Row>
                    </Col>
                    <Col class="col-4">
                      <Row>
                        <span class="font-weight-bold"
                          >{$_('trademark-group.table.nickName')}:
                        </span>
                      </Row>
                      <Row>
                        {group.nickName ?? ''}
                      </Row>
                    </Col>
                    <Col class="col-4">
                      <Row>
                        <span class="font-weight-bold"
                          >{$_('trademark-group.table.lawFirmId')}:
                        </span>
                      </Row>
                      <Row>
                        {group.lawFirmIdentifier ?? ''}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col class="col-4">
                      <Row>
                        <span class="font-weight-bold"
                          >{$_('trademark-group.table.otherId')}:
                        </span>
                      </Row>
                      <Row>
                        {group.otherIdentifier ?? ''}
                      </Row>
                    </Col>
                    <Col class="col-4">
                      <Row>
                        <span class="font-weight-bold">{$_('trademark-group.originator')}: </span>
                      </Row>
                      <Row>
                        {originator() ?? ''}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Container>
            </ExpansionPanel>
          </ExpansionPanels>
        </Col>
      </Row>
      <Row>
        <Col class="col-12">
          <ExpansionPanels bind:value={activePanels} multiple>
            <ExpansionPanel>
              <div slot="header" class="secondary-text">
                <span><Icon class="secondary-text" path={mdiViewListOutline} /></span>
                <span>{$_('trademark.title')}</span>
              </div>

              <div style="width: 100%">
                <TrademarkItemsInFamily {group} />
              </div>
            </ExpansionPanel>
            <FilesContainer
              ipType={IP_TYPE_TRADEMARK}
              ipScope={IP_SCOPE_GROUP}
              scopeId={group.id}
              readonly={!accountHasPermissionForGroupAndType(
                GROUP_UPDATE,
                group.id,
                IP_TYPE_TRADEMARK,
                group.owner
              )}
            />
            <Notes
              ipType={IP_TYPE_TRADEMARK}
              ipScope={IP_SCOPE_GROUP}
              scopeId={group.id}
              readonly={!accountHasPermissionForGroupAndType(
                GROUP_UPDATE,
                group.id,
                IP_TYPE_TRADEMARK,
                group.owner
              )}
            />
          </ExpansionPanels>
        </Col>
      </Row>
    </Container>
  </Route>
  <Route path="/item/:id" let:meta>
    <TrademarkDetail trademarkId={meta.params.id} name={group.name} on:itemUpdated={itemUpdated} />
  </Route>
{/await}

{#if showUpdateDialog}
  <AddOrUpdateTrademarkGroup bind:show={showUpdateDialog} {group} on:save={updatedItemSaved} />
{/if}
{#if showShareGroupDialog}
  <ShareGroup bind:show={showShareGroupDialog} groupType="trademark" {group} />
{/if}

<style>
</style>
