<script>
  import jstz from 'jstz';
  import { afterUpdate, createEventDispatcher, onMount, tick } from 'svelte';
  import { mdiLockReset } from '@mdi/js';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    Icon,
    TextField,
  } from 'svelte-materialify';
  import { accountInfo } from '../services/account';
  import { apiFetch } from '../services/network';
  import { _ } from '../services/i18n';

  const dispatch = createEventDispatcher();

  const timezone = jstz.determine();
  export let email = '';
  export let password = '';
  let phoneNumber = '';
  let errorString = '';
  let changePhoneNumberEmailField;
  let changePhoneNumberPasswordField;
  let changePhoneNumberField;
  let isFocused = false;

  $: changePhoneNumberButtonDisabled = !email || !password || !phoneNumber;

  onMount(() => {
    errorString = '';
    phoneNumber = '';
  });

  afterUpdate(() => {
    if (!isFocused && !email) {
      changePhoneNumberEmailField.focus();
      isFocused = true;
    }
  });

  async function changePhoneNumberFormSubmitted(event) {
    const url = `/authentication/changephonenumber`;
    const request = {
      email,
      password,
      phoneNumber,
      oneTimePassword: null,
      timezone: timezone.name(),
    };
    const response = await apiFetch(url, 'POST', request);
    if (response.ok) {
      accountInfo.update((info) => ({ ...info, phoneNumber }));
      // dispatch("resetLogin");
    } else {
      const signinReply = await response.json();
      if (response.status === 400 && signinReply.reason === 'invalidPhoneNumber') {
        errorString = $_('changePhoneNumber.fail.invalidPhoneNumber');
      } else if (response.status === 401) {
        errorString = $_('changePhoneNumber.fail.unauthorized');
      } else if (response.status === 403) {
        errorString = $_('changePhoneNumber.fail.suspended');
      } else if (response.status === 409 && signinReply.reason === 'mustProvidePhoneNumber') {
        dispatch('needOTPPhoneNumber');
      } else if (response.status === 409 && signinReply.reason === 'mustProvideOneTimePassword') {
        dispatch('needOTP', {
          phoneNumberVerified: signinReply.phoneNumberVerified,
          tmpPhoneNumber: phoneNumber,
        });
        await tick();
      } else if (response.status === 409) {
        errorString = $_(`changePhoneNumber.fail.${signinReply.reason}`);
      } else {
        errorString = $_('changePhoneNumber.fail.other');
      }
      return response;
    }
  }

  function cancel(event) {
    dispatch('phoneNumberChangeCanceled');
  }
</script>

<div id="changePhoneNumberDialog">
  <Dialog persistent={true} active={true}>
    <Card outlined={true} raised={true}>
      <CardTitle>
        <div class="d-flex align-center">
          <img
            src="/images/logos/Fortress_RGB.png"
            class="logo"
            alt="{$_('app.name')} logo"
            title="{$_('app.name')}"
          />
        </div>
      </CardTitle>
      <CardText>
        <Divider class="secondary-color" />
        <h5 class="pt-2 text-center">{$_('changePhoneNumber.banner')}</h5>
        <div>
          <p />
          <form on:submit|preventDefault={changePhoneNumberFormSubmitted}>
            <TextField
              type="email"
              outlined={true}
              color={'secondary'}
              readonly={true}
              placeholder={$_('changePhoneNumber.emailAddress')}
              bind:value={email}
              bind:inputElement={changePhoneNumberEmailField}
            >
              {$_('changePhoneNumber.emailAddress')}
            </TextField>
            <TextField
              type="password"
              outlined={true}
              color={'secondary'}
              readonly={false}
              placeholder={$_('changePhoneNumber.password')}
              bind:value={password}
              bind:inputElement={changePhoneNumberPasswordField}
            >
              {$_('changePhoneNumber.password')}
            </TextField>
            <TextField
              type="tel"
              outlined={true}
              clearable={true}
              color={'secondary'}
              placeholder={$_('changePhoneNumber.newPhoneNumber')}
              bind:value={phoneNumber}
              bind:inputElement={changePhoneNumberField}
            >
              {$_('changePhoneNumber.newPhoneNumber')}
            </TextField>
            <div class="error-text">
              {errorString}
            </div>
            <br />
            <Button
              class={changePhoneNumberButtonDisabled ? 'grey-color' : 'secondary-color'}
              type="submit"
              disabled={changePhoneNumberButtonDisabled}
              color="secondary"
            >
              <Icon
                path={mdiLockReset}
                class={changePhoneNumberButtonDisabled ? 'grey-color' : 'secondary-color'}
              />{$_('changePhoneNumber.changePhoneNumber')}
            </Button>
            <Button class="" style="float: right;" type="button" on:click={cancel}>
              {$_('changePhoneNumber.cancel')}
            </Button>
          </form>
        </div>
      </CardText>
    </Card>
  </Dialog>
  <!-- <script>changePassword.update(changePassword => false);</script> -->
</div>
