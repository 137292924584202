<script>
  import { createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider } from 'svelte-materialify';
  import { _ } from '../services/i18n';
  import { validateForm } from '../services/validation';

  const dispatch = createEventDispatcher();
  export let show = false;
  export let title = '';
  export let text = '';
  let formElement;

  function cancel(event) {
    show = false;
  }

  async function save(event) {
    event.preventDefault();
    if (!validateForm(formElement)) {
      return;
    }
    dispatch('save');
    show = false;
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{title}</h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <div>{text}</div>
        <Divider class="mt-4 secondary-color" />
        <Button type="submit" class="mt-4 error-color" on:click={save} text>
          {$_('administration.users.give-admin-permissions.save')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('administration.users.give-admin-permissions.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>

<style>
</style>
