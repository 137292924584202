<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Col,
    Dialog,
    Divider,
    Icon,
    ProgressLinear,
    Row,
    Select,
    TextField,
  } from 'svelte-materialify';
  import { mdiDeleteOutline, mdiMagnify, mdiPlus } from '@mdi/js';
  import { _ } from '../services/i18n';
  import { accountInfo, GROUP_ALL } from '../services/account';
  import {
    apiFetch,
    noClientContactSelectedId,
    selectedClientContactId,
  } from '../services/network';

  const dispatch = createEventDispatcher();

  export let show;
  export let groupType;
  export let group;

  let itemsList = [];
  let allGroupsList = [];
  let otherGroupsList = [];
  let searchResults = [];
  let searchString = '';
  let debounceSearchTimer;
  let permissionValues = [];
  let scopeValues = [];
  let searchBar;
  let isFocused = false;

  afterUpdate(() => {
    if (!isFocused && !searchString) {
      searchBar.focus();
      isFocused = true;
    }
  });

  const permissions = [
    { name: $_('share-group.permission.view'), value: 'view' },
    // { name: $_('share-group.permission.notes'), value: "notes"},
    { name: $_('share-group.permission.edit'), value: 'edit' },
  ];

  const scopes = [
    { name: group.name, value: 'this-group' },
    { name: $_('share-group.scope-all-groups'), value: 'all-groups' },
  ];

  const editRights = ['share', 'list', 'read', 'create', 'update', 'delete'];
  const viewRights = ['list', 'read'];

  function setDisplayValue(groupMemberItem) {
    const value = setSelectValue(groupMemberItem);
    for (const permission of permissions) {
      if (permission.value === value) {
        return permission.name;
      }
    }
    return 'unknown';
  }

  function setSelectValue(groupMemberItem) {
    let selectValue = 'view';
    const groupMemberRightsSet = new Set(groupMemberItem.rights);
    const difference = [...new Set(editRights.filter((x) => !groupMemberRightsSet.has(x)))];
    if (difference.length === 0) {
      selectValue = 'edit';
    }
    return selectValue;
  }

  function allowDelete(groupMemberItem) {
    // a proxy account (such as a law-firm accessing another tenant via a client relationship)
    if ($selectedClientContactId === noClientContactSelectedId) {
      // is not a proxy
      if (group.id === GROUP_ALL && setSelectValue(groupMemberItem) === 'edit') {
        // only allow if there is more than one GROUP_ALL that can edit in this tenant
        let count = 0;
        for (const member of itemsList) {
          if (
            member.tenantId === groupMemberItem.tenantId &&
            member.groupId === GROUP_ALL &&
            setSelectValue(member) === 'edit'
          ) {
            count++;
          }
        }
        if (count > 1) {
          return true;
        }
      }
    } else if (groupMemberItem.groupId === group.id && setSelectValue(groupMemberItem) === 'edit') {
      return true;
    }

    // the owner of this group can delete shares for this group, but not for GROUP_ALL
    if ($accountInfo.id === group.owner.id && groupMemberItem.groupId !== GROUP_ALL) {
      return true;
    }

    return false;
  }

  async function search() {
    clearTimeout(debounceSearchTimer);

    async function innerSearch() {
      const searchTerm = searchString.toLowerCase();
      if (searchTerm.length < 2) {
        return;
      }
      const response = await apiFetch(`/api/share/search/${searchTerm}`, 'POST');
      if (response.ok) {
        const results = await response.json();
        // filter out search results that are already in our share item list
        searchResults = results.filter((item) => !alreadyInItemsList(item.id));
        permissionValues = Array.from({ length: searchResults.length }).fill('edit');
        scopeValues =
          group.id === GROUP_ALL
            ? Array.from({ length: searchResults.length }).fill('all-groups')
            : Array.from({ length: searchResults.length }).fill('this-group');
      }
    }
    debounceSearchTimer = setTimeout(innerSearch, 500);
  }

  function alreadyInItemsList(id) {
    const foundItem = itemsList.find((item) => item.userProfile.id === id);
    if (foundItem) {
      return true;
    }
    if (id === group.owner.id) {
      return true;
    }
    return false;
  }

  function filterSharePermissions(sharePermissions) {
    itemsList = sharePermissions;

    allGroupsList = itemsList.filter(
      (item) => item.groupId === GROUP_ALL && item.userProfile.id !== group.owner.id
    );
    otherGroupsList = itemsList.filter(
      (item) => item.groupId !== GROUP_ALL && item.userProfile.id !== group.owner.id
    );
  }

  async function getSharePermissions() {
    const url = `/api/share/${groupType}/group/${group.id}`;
    const response = await apiFetch(url);
    if (response.ok) {
      const sharePermissions = await response.json();
      filterSharePermissions(sharePermissions);
    }
  }

  function done(event) {
    show = false;
  }

  async function addItem(event) {
    const element = event.currentTarget;
    const index = Number.parseInt(element.dataset.index);
    let groupId;
    switch (scopeValues[index]) {
      case 'this-group': {
        groupId = group.id;
        break;
      }
      case 'all-groups': {
        groupId = GROUP_ALL;
        break;
      }
      default: {
        return;
      }
    }
    const url = `/api/share/${groupType}/group/${groupId}/sharewith/${searchResults[index].id}/permission/${permissionValues[index]}`;
    const response = await apiFetch(url, 'POST');
    if (response.ok) {
      const sharePermissions = await response.json();
      filterSharePermissions(sharePermissions);
      searchResults = [];
      searchString = '';
      permissionValues = [];
      scopeValues = [];
    }
  }

  async function updateItem(event) {
    const { currentTarget } = event;
    const id = Number.parseInt(currentTarget.dataset.id);
    const item = itemsList.find((i) => i.id === id);
    const url = `/api/share/group-access-permission/${id}/${currentTarget.value}`;
    const response = await apiFetch(url, 'PUT');
    if (response.ok) {
      const sharePermissions = await response.json();
      filterSharePermissions(sharePermissions);
    }
  }

  async function deleteItem(event) {
    const element = event.currentTarget;
    const id = Number.parseInt(element.dataset.id);
    const item = itemsList.find((i) => id === i.id);
    const url = `/api/share/group-access-permission/${id}`;
    const response = await apiFetch(url, 'DELETE');
    if (response.ok) {
      const sharePermissions = await response.json();
      filterSharePermissions(sharePermissions);
    }
  }
</script>

<Dialog width={900} persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{$_('share-group.title')} - {group.name}</h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <TextField
        name="search"
        autocomplete="off"
        bind:value={searchString}
        bind:inputElement={searchBar}
        on:keyup={search}
        style="max-width: 400px;"
        class="pt-2"
        color={'blue'}
        clearable
        outlined
        rounded
        dense
      >
        <!-- This text field was marked as type="search" but that was removed because it was causing two clear field buttons to show.-->
        <div slot="prepend">
          <Icon path={mdiMagnify} />
        </div>
        {$_('share-group.search')}
      </TextField>
      {#if searchString.length > 0}
        {#if searchResults.length > 0}
          {#each searchResults as searchResult, index}
            <Row class="pt-0">
              <Col cols={4} class="text-subtitle-2">
                {searchResult.firstName}
                {searchResult.lastName} ({searchResult.email})
              </Col>
              <Col cols={2}>
                {searchResult.tenant.name}
              </Col>
              <Col cols={2}>
                <Select
                  outlined
                  dense
                  items={permissions}
                  bind:value={permissionValues[index]}
                  class=""
                  mandatory
                >
                  {$_('share-group.permission.title')}
                </Select>
              </Col>
              <Col cols={3}>
                {#if group.id === GROUP_ALL}
                  <span>{$_('share-group.scope-all-groups')}</span>
                {:else}
                  <Select
                    outlined
                    dense
                    items={scopes}
                    bind:value={scopeValues[index]}
                    class=""
                    mandatory
                  >
                    {$_('share-group.table.share-scope')}
                  </Select>
                {/if}
              </Col>
              <Col cols={1}>
                <Button
                  data-index={index.toString()}
                  class="secondary-color"
                  on:click={addItem}
                  text
                  title={$_('share-group.add')}
                  fab
                  size="small"
                >
                  <Icon path={mdiPlus} />
                </Button>
              </Col>
            </Row>
          {/each}
        {:else if searchResults.length === 0}
          <div class="error-text">{$_('data-rooms.share-data-room.no-search-results')}</div>
        {/if}
      {/if}
      <table classList="pt-4">
        <thead class="text-caption ">
          <th style="width: 50px;" />
          <th style="width: 275px;">{$_('share-group.table.user-account')}</th>
          <th style="width: 275px;">{$_('share-group.table.company')}</th>
          <th style="width: 275px;">{$_('share-group.table.permission')}</th>
          <th style="width: 275px;">{$_('share-group.table.share-scope')}</th>
        </thead>
        <tbody class="text-caption font-weight-thin">
          {#if group.id !== GROUP_ALL}
            <tr>
              <td />
              <td>{group.owner.firstName} {group.owner.lastName} ({group.owner.email})</td>
              <td>{group.owner.tenant.name}</td>
              <td>{$_('share-group.permission.owner')}</td>
              <td>{$_('share-group.scope-this-group')}</td>
            </tr>
          {/if}
          {#await getSharePermissions()}
            <ProgressLinear />
          {:then}
            {#each allGroupsList as row}
              <tr>
                <td>
                  {#if allowDelete(row)}
                    <!---->
                    <Button
                      class="secondary-text"
                      icon
                      data-id={row.id.toString()}
                      on:click={deleteItem}
                      text
                      title={$_('share-group.delete')}
                      fab
                      size="small"
                    >
                      <Icon path={mdiDeleteOutline} />
                    </Button>
                  {:else}
                    <Button disabled icon text title={$_('share-group.delete')} fab size="small">
                      <Icon path={mdiDeleteOutline} />
                    </Button>
                  {/if}
                </td>
                <td
                  >{row.userProfile.firstName}
                  {row.userProfile.lastName} ({row.userProfile.email})</td
                >
                <td>{row.userProfile.tenant.name}</td>
                <td>
                  {#if row.groupId === GROUP_ALL}
                    <span>{setDisplayValue(row)}</span>
                  {:else}
                    <select
                      data-id={row.id.toString()}
                      value={setSelectValue(row)}
                      on:change={updateItem}
                    >
                      {#each permissions as permission}
                        <option value={permission.value}>{permission.name}</option>
                      {/each}
                    </select>
                  {/if}
                </td>
                <td>{$_('share-group.scope-all-groups')}</td>
              </tr>
            {/each}
            {#each otherGroupsList as row}
              <tr>
                <td>
                  {#if allowDelete(row)}
                    <!---->
                    <Button
                      class="secondary-text"
                      icon
                      data-id={row.id.toString()}
                      on:click={deleteItem}
                      text
                      title={$_('share-group.delete')}
                      fab
                      size="small"
                    >
                      <Icon path={mdiDeleteOutline} />
                    </Button>
                  {:else}
                    <Button disabled icon text title={$_('share-group.delete')} fab size="small">
                      <Icon path={mdiDeleteOutline} />
                    </Button>
                  {/if}
                </td>
                <td
                  >{row.userProfile.firstName}
                  {row.userProfile.lastName} ({row.userProfile.email})</td
                >
                <td>{row.userProfile.tenant.name}</td>
                <td>
                  {#if row.groupId === GROUP_ALL}
                    <span>{setDisplayValue(row)}</span>
                  {:else}
                    <select
                      data-id={row.id.toString()}
                      value={setSelectValue(row)}
                      on:change={updateItem}
                    >
                      {#each permissions as permission}
                        <option value={permission.value}>{permission.name}</option>
                      {/each}
                    </select>
                  {/if}
                </td>
                <td>{$_('share-group.scope-this-group')}</td>
              </tr>
            {/each}
          {/await}
        </tbody>
      </table>
      <div class="d-flex justify-center">
        <Button class="secondary-color mt-4" on:click={done} text>
          {$_('share-group.done')}
        </Button>
      </div>
    </CardText>
  </Card>
</Dialog>

<style>
  table {
    border: 1px solid white;
    border-collapse: collapse;
  }
  thead > th:first-child {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }
  thead > th:last-child {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  th {
    border: 1px solid white;
    height: 41px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #1a76d2;
    color: white;
  }
  td {
    padding: 4px;
    border-bottom: 1px solid #1a76d2;
  }
  select {
    color: #1a76d2;
    text-decoration: underline;
  }
</style>
