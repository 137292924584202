<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider, Select } from 'svelte-materialify';
  import {
    _,
    getContractStateSelectOptions,
    getContractTypeSelectOptions,
  } from '../../services/i18n';
  import { validateForm } from '../../services/validation';
  import { apiFetch } from '../../services/network';
  import DateField from '../DateField.svelte';
  import TextField from '../TextField.svelte';

  const dispatch = createEventDispatcher();
  export let show;

  let contractNameField;
  let contractNickNameField;
  let contractDescriptionField;
  let contractLawFirmIdField;
  let isFocused = false;

  const today = new Date().toISOString().split('T')[0];

  export let contract = {
    lawFirmIdentifier: '',
    name: '',
    nickName: '',
    contractType: 'nda',
    description: '',
    contractStatus: 'drafting',
    effectiveDate: '',
    expiryDate: '',
  };

  const newContract = {};
  Object.assign(newContract, contract);

  $: saveDisabled = !newContract.contractType || !newContract.name || !newContract.contractStatus;

  let formElement;

  function cancel(event) {
    // clear unsaved changes
    show = false;
    dispatch('cancel');
  }

  afterUpdate(async () => {
    if (!isFocused && !contract.name) {
      contractNameField.focus();
      isFocused = true;
    }
  });

  async function save(event) {
    event.preventDefault();
    Object.assign(contract, newContract);
    if (!validateForm(formElement)) {
      return;
    }

    let url = `/contract`;
    let method = 'POST';
    if (contract.id) {
      url += `/${contract.id}`;
      method = 'PUT';
    }
    const request = {};
    for (const property in newContract) {
      if (newContract[property] != null && newContract !== 'id') {
        request[property] = newContract[property];
      }
    }
    saveDisabled = true;
    const response = await apiFetch(url, method, request);
    saveDisabled = !newContract.contractType || !newContract.name || !newContract.contractStatus;
    if (response.ok) {
      const item = await response.json();
      dispatch('save', { item });
      show = false;
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      {#if contract.id}
        <h6 class="secondary-text">{$_('contract.edit')} {$_('contract.singular')}</h6>
      {:else}
        <h6 class="secondary-text">{$_('contract.new')} {$_('contract.singular')}</h6>
      {/if}
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <Select
          outlined
          dense
          items={getContractTypeSelectOptions()}
          bind:value={newContract.contractType}
          class="pb-2"
          mandatory
        >
          {$_('contract.table.type')}
        </Select>
        <TextField
          bind:value={newContract.name}
          bind:inputElement={contractNameField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          required
        >
          {$_('contract.table.title')}
        </TextField>
        <TextField
          bind:value={newContract.nickName}
          bind:inputElement={contractNickNameField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('contract.table.nickName')}
        </TextField>
        <TextField
          bind:value={newContract.description}
          bind:inputElement={contractDescriptionField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('contract.table.description')}
        </TextField>
        <TextField
          bind:value={newContract.lawFirmIdentifier}
          bind:inputElement={contractLawFirmIdField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('contract.table.lawFirmId')}
        </TextField>
        <Select
          outlined
          dense
          items={getContractStateSelectOptions()}
          bind:value={newContract.contractStatus}
          class="pb-2"
          mandatory
        >
          {$_('contract.table.status')}
        </Select>
        <DateField
          bind:value={newContract.effectiveDate}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          clearable
        >
          {$_('contract.table.effective-date')}
        </DateField>
        <DateField
          bind:value={newContract.expiryDate}
          bind:min={newContract.effectiveDate}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          clearable
        >
          {$_('contract.table.expiry-date')}
        </DateField>
        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          text
          bind:disabled={saveDisabled}
          on:click={save}
          class="mt-4 ml-2 {saveDisabled ? 'grey-color' : 'secondary-color'}"
        >
          {$_('contract.save')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('contract.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
