<script>
  import { onMount, tick } from 'svelte';
  import { meta } from 'tinro';
  import { accountInfo, authenticated } from '../services/account';
  import analyticsInfo from '../services/stores/analytics';

  const route = meta();
  const environment = import.meta.env.MODE;
  const GTAG_MEASUREMENT_ID = import.meta.env.FORTRESS_ANALYTICS_MEASUREMENT_ID;
  let previous_login_state = false;

  onMount(async () => {
    if (import.meta.env.FORTRESS_ENABLE_ANALYTICS === 'true') {
      if (GTAG_MEASUREMENT_ID) {
        analyticsInfo.set({
          enabled: true,
          GTAG_MEASUREMENT_ID: GTAG_MEASUREMENT_ID,
          environment: environment,
        });

        // Wait for a cycle so analyticsInfo can be set
        await tick();
        if (typeof gtag !== 'undefined') {
          // On initial page load, set the page title and page path to register
          //  and send the page_view event
          gtag('config', GTAG_MEASUREMENT_ID, {
            page_title: document.title,
            page_path: $route.url,
            'user_properties': {
              environment: environment,
            }
          });
        }
      } else {
        console.warn(
          'Metrics gathering has been disabled as "FORTRESS_ENABLE_ANALYTICS" environment variable'
          + ' set to "true", but no Measurement ID provided.  Set the "FORTRESS_ANALYTICS_MEASUREMENT_ID"'
          + ' environment variable to enable analytics.'
        );
      }
    } else {
      console.log(
        'Metrics gathering disabled as "FORTRESS_ENABLE_ANALYTICS" is set to "false" or not provided'
      );
    }
  });

  // Reactive block to alter page view metadata to send to analytics backend
  $: {
    const { url } = $route;

    if ($analyticsInfo.enabled && typeof gtag !== 'undefined') {
      // Configure new page title/path to be sent with 'page_view' event
      gtag('config', GTAG_MEASUREMENT_ID, {
        page_title: document.title,
        page_path: url,
      });
    }
  }

  // fire login event
  $: if ($analyticsInfo.enabled && typeof gtag !== 'undefined') {
    if (!previous_login_state && $authenticated) {
      // Set some user properties to be used for all subsequent events
      gtag('config', $analyticsInfo.GTAG_MEASUREMENT_ID, {
        'user_properties': {
          user_id: $accountInfo.id,
          tenant_id: $accountInfo.tenant.id,
          tenant_name: $accountInfo.tenant.name,
          environment: environment,
        }
      });
      gtag('event', 'login', {
        method: 'Fortress',
      });
    }
    // save authenticated state so we don't send login event every time /isSignedIn
    //  triggers.
    previous_login_state = $authenticated;
  }
</script>

<svelte:head>
  {#if $analyticsInfo.enabled}
    <!-- Google tag (gtag.js) -->
    <script async src="https://www.googletagmanager.com/gtag/js?id={GTAG_MEASUREMENT_ID}"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
    </script>
  {/if}
</svelte:head>
