<script>
  import {
    ProgressLinear,
    Icon,
    ExpansionPanels,
    ExpansionPanel,
    Container,
    Row,
    Col,
    Button,
  } from 'svelte-materialify';
  import Portal from 'svelte-portal';
  import { mdiPencilOutline, mdiShareVariant, mdiViewListOutline } from '@mdi/js';
  import { createEventDispatcher, onMount } from 'svelte';
  import { Route } from 'tinro';
  import { _, IP_TYPE_CONTRACT, IP_SCOPE_GROUP } from '../../services/i18n';
  import { apiFetch, setSelectedClientByTenant } from '../../services/network';
  import {
    GROUP_SHARE,
    accountHasPermissionForGroupAndType,
    GROUP_UPDATE,
  } from '../../services/account';
  import ShareGroup from '../ShareGroup.svelte';
  import AddOrUpdateContract from './AddOrUpdateContract.svelte';
  import ContractDetail from './ContractDetail.svelte';
  import ContractItemsInFamily from './ContractItemsInFamily.svelte';
  import DeleteContractFromGroup from './DeleteContractFromGroup.svelte';
  import AddOrUpdateContractGroup from './AddOrUpdateContractGroup.svelte';
  import AddContractsToGroup from './AddContractsToGroup.svelte';
  import FilesContainer from '../FilesContainer.svelte';
  import CopyURL from '../CopyURL.svelte';
  import Notes from '../Notes.svelte';
  import UrlBreadcrumb from '../UrlBreadcrumb.svelte';
  import MoveContractBetweenGroups from './MoveContractBetweenGroups.svelte';

  export let contractGroupId;
  let group;
  let detailsPanel = [0];
  let activePanels = [0, 1, 2];
  const dispatch = createEventDispatcher();
  let breadCrumbItems = [];
  let showShareGroupDialog = false;
  let showEditGroupMembersDialog = false;
  let currentItem;
  let showUpdateDialog = false;
  let showAddNewItemDialog = false;
  let showDeleteItemFromGroupDialog = false;
  let showMoveItemBetweenGroupsDialog = false;
  let showEditItemDialog = false;
  const selectedItem = {};

  onMount(() => {});

  async function load() {
    const url = `/contract/group/${contractGroupId}`;
    const response = await apiFetch(url);
    if (response.ok) {
      group = await response.json();
      setSelectedClientByTenant(group.tenant);
      breadCrumbItems = [
        { text: `${$_('contract-group.title')}`, href: '/contract/family' },
        { text: `${$_('contract-group.singular')} - ${group.name}` },
      ];
    }
  }

  function formatUser(userProfile) {
    return `${userProfile.firstName} ${userProfile.lastName} - ${userProfile.tenant.name}`;
  }
  function owner() {
    return formatUser(group.owner);
  }

  function originator() {
    return formatUser(group.originator);
  }

  function editOwner(event) {}

  function findItemById(id) {
    return group.items.find((item) => item.id === id);
  }

  async function newItem(event) {
    const { item } = event.detail;
    const response = await apiFetch(`/api/contract/group/${group.id}/item/${item.id}`, 'POST');
    if (response.ok) {
      group = await response.json();
    }
  }

  function updatedItemInGroup(event) {
    load();
  }

  async function removeItemFromGroup(event) {
    const { id } = event.detail;
    const response = await apiFetch(`/api/contract/group/${group.id}/item/${id}`, 'DELETE');
    if (response.ok) {
      group = await response.json();
    }
  }

  function contractsAddedToGroup(event) {
    load();
  }

  function shareGroupButtonClicked(event) {
    event.preventDefault();
    event.stopPropagation();
    showShareGroupDialog = true;
  }
  function updateGroupButtonClicked(event) {
    showUpdateDialog = true;
  }

  function updatedItemSaved(event) {
    group = event.detail.item;
  }

  function itemUpdated(event) {
    const { item } = event.detail;
    const foundIndex = group.items.findIndex((listItem) => listItem.id === item.id);
    if (foundIndex >= 0) {
      group.items[foundIndex] = item;
    }
  }
</script>

<h5 class="secondary-text text-center">{$_('contract.singular')}</h5>
{#await load()}
  <ProgressLinear />
{:then}
  <Route path="/" let:meta>
    <Container>
      <Row>
        <Col class="col-12">
          <ExpansionPanels accordion bind:value={detailsPanel}>
            <ExpansionPanel>
              <div class="d-flex justify-space-between" style="width:100%;" slot="header">
                <span>
                  <UrlBreadcrumb name={group.name} />
                </span>
                <span class="pa-3 ma-1">
                  <Button
                    disabled={!accountHasPermissionForGroupAndType(
                      GROUP_SHARE,
                      group.id,
                      IP_TYPE_CONTRACT,
                      group.owner
                    )}
                    class={accountHasPermissionForGroupAndType(
                      GROUP_SHARE,
                      group.id,
                      IP_TYPE_CONTRACT,
                      group.owner
                    )
                      ? 'secondary-color'
                      : ''}
                    on:click={shareGroupButtonClicked}
                  >
                    <Icon class="text-white" path={mdiShareVariant} />{$_('contract-group.share')}
                  </Button>
                </span>
              </div>
              <Container>
                <Button
                  icon
                  disabled={!accountHasPermissionForGroupAndType(
                    GROUP_UPDATE,
                    group.id,
                    IP_TYPE_CONTRACT,
                    group.owner
                  )}
                  class={accountHasPermissionForGroupAndType(
                    GROUP_UPDATE,
                    group.id,
                    IP_TYPE_CONTRACT,
                    group.owner
                  )
                    ? 'secondary-color'
                    : ''}
                  on:click={updateGroupButtonClicked}
                  title={$_('contract-group.edit')}
                >
                  <Icon class="text-white" path={mdiPencilOutline} />
                </Button>
                <CopyURL />
                <div class="pl-4">
                  <Row>
                    <Col class="col-4">
                      <Row>
                        <span class="font-weight-bold">{$_('contract-group.table.title')}: </span>
                      </Row>
                      <Row>
                        {group.name ?? ''}
                      </Row>
                    </Col>
                    <Col class="col-4">
                      <Row>
                        <span class="font-weight-bold"
                          >{$_('contract-group.table.nickName')}:
                        </span>
                      </Row>
                      <Row>
                        {group.nickName ?? ''}
                      </Row>
                    </Col>
                    <Col class="col-4">
                      <Row>
                        <span class="font-weight-bold"
                          >{$_('contract-group.table.lawFirmId')}:
                        </span>
                      </Row>
                      <Row>
                        {group.lawFirmIdentifier ?? ''}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col class="col-4">
                      <Row>
                        <span class="font-weight-bold">{$_('contract-group.table.otherId')}: </span>
                      </Row>
                      <Row>
                        {group.otherIdentifier ?? ''}
                      </Row>
                    </Col>
                    <Col class="col-4">
                      <Row>
                        <span class="font-weight-bold">{$_('contract-group.originator')}: </span>
                      </Row>
                      <Row>
                        {originator() ?? ''}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Container>
            </ExpansionPanel>
          </ExpansionPanels>
        </Col>
      </Row>
      <Row>
        <Col class="col-12">
          <ExpansionPanels bind:value={activePanels} multiple>
            <ExpansionPanel>
              <div slot="header" class="secondary-text">
                <span><Icon class="secondary-text" path={mdiViewListOutline} /></span>
                <span>{$_('contract.title')}</span>
              </div>
              <div style="width: 100%">
                <ContractItemsInFamily {group} />
              </div>
            </ExpansionPanel>
            <FilesContainer
              ipType={IP_TYPE_CONTRACT}
              ipScope={IP_SCOPE_GROUP}
              scopeId={group.id}
              readonly={!accountHasPermissionForGroupAndType(
                GROUP_UPDATE,
                group.id,
                IP_TYPE_CONTRACT,
                group.owner
              )}
            />
            <Notes
              ipType={IP_TYPE_CONTRACT}
              ipScope={IP_SCOPE_GROUP}
              scopeId={group.id}
              readonly={!accountHasPermissionForGroupAndType(
                GROUP_UPDATE,
                group.id,
                IP_TYPE_CONTRACT,
                group.owner
              )}
            />
          </ExpansionPanels>
        </Col>
      </Row>
    </Container>
  </Route>
  <Route path="/item/:id" let:meta>
    <ContractDetail contractId={meta.params.id} name={group.name} on:itemUpdated={itemUpdated} />
  </Route>
{/await}

{#if showUpdateDialog}
  <AddOrUpdateContractGroup bind:show={showUpdateDialog} {group} on:save={updatedItemSaved} />
{/if}
{#if showShareGroupDialog}
  <ShareGroup bind:show={showShareGroupDialog} groupType={IP_TYPE_CONTRACT} {group} />
{/if}
{#if showEditGroupMembersDialog}
  <AddContractsToGroup
    bind:show={showEditGroupMembersDialog}
    {group}
    on:save={contractsAddedToGroup}
  />
{/if}
{#if showAddNewItemDialog}
  <AddOrUpdateContract bind:show={showAddNewItemDialog} on:save={newItem} />
{/if}
{#if showEditItemDialog}
  <AddOrUpdateContract
    bind:show={showEditItemDialog}
    contract={selectedItem}
    on:save={updatedItemInGroup}
  />
{/if}
{#if showDeleteItemFromGroupDialog}
  <DeleteContractFromGroup
    bind:show={showDeleteItemFromGroupDialog}
    contract={selectedItem}
    on:delete={removeItemFromGroup}
  />
{/if}
{#if showMoveItemBetweenGroupsDialog}
  <MoveContractBetweenGroups
    bind:show={showMoveItemBetweenGroupsDialog}
    groupId={group.id}
    item={selectedItem}
    on:delete={removeItemFromGroup}
  />
{/if}

<style>
</style>
