<script>
  import { Route, router } from 'tinro';
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import { Breadcrumbs, ProgressLinear } from 'svelte-materialify';
  import { mdiFolderOpenOutline, mdiPencilOutline, mdiPlus } from '@mdi/js';
  import { apiFetch } from '../../../services/network';
  import { _ } from '../../../services/i18n';
  import AddOrUpdateTenant from './AddOrUpdateTenant.svelte';
  import TenantDetail from './TenantDetail.svelte';
  import { accountHasTenantPermission, SYSTEM_WIDE_TENANT_ADMIN } from '../../../services/account';
  import DataTable from '../../DataTable.svelte';
  import { cellRendererFactory } from '../../DataTable/CellRendererFactory';
  import DataTableRowActions from '../../DataTable/DataTableRowActions.svelte';

  let breadCrumbItems;
  const tableElement = null;
  const searchTerm = '';
  let list = [];
  let showNewDialog = false;
  let showUpdateDialog = false;
  let showDeleteDialog = false;
  let selectedTenant = {};
  let dataTable;
  breadCrumbItems = accountHasTenantPermission(SYSTEM_WIDE_TENANT_ADMIN)
    ? [
        { text: `${$_('administration.title')}`, href: '/administration' },
        { text: `${$_('administration.tenants.title')}`, href: `/administration/tenant` },
      ]
    : [
        { text: `${$_('administration.title')}`, href: `/administration` },
        {
          text: `${$_('administration.tenants.title')}`,
          href: `/administration/tenant/${tenantId}/client`,
        },
      ];

  const rowActions = [
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: `/administration/tenant/`,
      title: $_('administration.tenants.open'),
      path: mdiFolderOpenOutline,
      onClickEvent: 'openClicked',
      elementId: 'tenants',
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.tenants.edit'),
      path: mdiPencilOutline,
      onClickEvent: 'editClicked',
      elementId: 'tenants',
      value: '',
    },
    { type: 'copyLinkButton' },
    // {type: "button", enabledFunction: isActionEnabled, href: '', title: $_('administration.tenants.delete'), path: mdiDeleteOutline, onClickEvent: "deleteClicked", elementId: "tenants", value:''}
  ];

  const columnDefs = [
    {
      field: 'buttons',
      headerName: '',
      pinned: 'left',
      resizable: false,
      sortable: false,
      maxWidth: 40,
      editable: false,
      cellClass: 'table-actions pa-0 text-center',
      cellStyle: { cursor: 'pointer' },
      cellRenderer: cellRendererFactory((cell) => {
        new DataTableRowActions({
          target: cell.eGui,
        });
      }),
      suppressColumnsToolPanel: true,
    },
    {
      field: 'name',
      headerName: $_('administration.tenants.table.name'),
      cellRenderer: 'linkRenderer',
      sortable: true,
      filter: true,
      resizable: true,

      sort: 'asc',
    },
    {
      field: 'id',
      headerName: $_('administration.tenants.table.id'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'type',
      headerName: $_('administration.tenants.table.type'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'status',
      headerName: $_('administration.tenants.table.status'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'createdDate',
      headerName: $_('administration.tenants.table.created'),
      resizable: true,
      suppressColumnsToolPanel: true,
    },
    {
      field: 'updatedDate',
      headerName: $_('administration.clients.table.updated'),
      resizable: true,
      suppressColumnsToolPanel: true,
    },
  ];

  const globalActions = [
    {
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.tenants.new'),
      path: mdiPlus,
      onClickEvent: 'newClicked',
      elementId: 'tenants',
    },
  ];

  const linkActions = [
    {
      enabledFunction: isActionEnabled,
      href: '/administration/tenant/',
      title: $_('administration.tenants.open'),
      onClickEvent: 'openClicked',
      elementId: 'tenants',
    },
  ];

  $: {
    if (searchTerm === '') {
      search();
    }
  }

  const dispatch = createEventDispatcher();

  onMount(() => {});

  onDestroy(() => {});

  function isActionEnabled(item) {
    return accountHasTenantPermission(SYSTEM_WIDE_TENANT_ADMIN);
  }

  async function load() {
    const url = `/api/tenant`;
    const response = await apiFetch(url);
    if (response.ok) {
      list = await response.json();
    }
  }

  function newClicked(event) {
    showNewDialog = true;
  }

  function findTenantById(id) {
    return list.find((t) => {
      if (t.id === id) {
        return t;
      }
    });
  }

  function openClicked(event) {
    const { href } = event.detail.action;
    router.goto(href + event.detail.id);
  }

  function editClicked(event) {
    selectedTenant = findTenantById(event.detail.id);
    showUpdateDialog = true;
  }

  function deleteClicked(event) {
    const element = event.detail.button;
    const id = Number.parseInt(element.dataset.id);
    selectedTenant = findTenantById(id);
    showDeleteDialog = true;
  }

  function search() {
    if (tableElement === null) {
      return;
    }
    const filter = searchTerm.toLowerCase();
    const rows = tableElement.querySelectorAll('tr');
    for (const row_ of rows) {
      const cols = row_.querySelectorAll('td');
      let found = false;
      for (const col_ of cols) {
        const txtValue = col_.textContent || col_.innerText;
        if (txtValue.toLowerCase().includes(filter)) {
          found = true;
        }
      }
      row_.style.display = found ? '' : 'none';
    }
  }

  function updatedItemSaved(event) {
    const { item } = event.detail;
    const { id } = item;
    selectedTenant = event.detail.item;
    dataTable.updateRow(id);
  }

  function addNewTenant(event) {
    const newItem = event.detail.item;
    list.push(newItem);
    selectedTenant = event.detail.item;
    dataTable.addNewRows([newItem]);
  }
</script>

<Route path="/">
  <Breadcrumbs items={breadCrumbItems} />
  <!-- <div class="d-flex align-center pt-4 pb-4">
        <Button class="secondary-color" on:click={newClicked} text title={$_('administration.tenants.new')} fab size="small"><Icon path={mdiPlus} /></Button>
        <TextField bind:value={searchTerm} on:keyup={search} style="max-width: 400px;" class="pl-4" color={"blue"} clearable outlined rounded dense>
            <div slot="prepend">
                <Icon path={mdiMagnify} />
            </div>
            {$_('administration.tenants.search')}
        </TextField>
    </div> -->

  {#await load()}
    <ProgressLinear indeterminate color="secondary" />
  {:then}
    <div id="tenants" on:deleteClicked={deleteClicked} on:newClicked={newClicked}>
      <DataTable
        bind:rowData={list}
        bind:this={dataTable}
        {columnDefs}
        {rowActions}
        {globalActions}
        {linkActions}
        tableName="tenants"
        on:openClicked={openClicked}
        on:editClicked={editClicked}
      />
    </div>
  {/await}

  <!-- <table classList="pt-4" bind:this={tableElement}>
        <thead class="text-caption ">
            <th style="width: 130px;min-width: 124px;"></th>
            <th style="">{$_('administration.tenants.table.id')}</th>
            <th style="width: 300px;">{$_('administration.tenants.table.name')}</th>
            <th style="">{$_('administration.tenants.table.type')}</th>
            <th style="">{$_('administration.tenants.table.status')}</th>
            <th style="">{$_('administration.tenants.table.created')}</th>
            <th style="">{$_('administration.tenants.table.updated')}</th>
        </thead>
        <tbody class="text-caption font-weight-thin">
            {#await load()}
            <ProgressLinear />
            {:then}
            {#each list as row}
                <tr>
                    <td>
                        <Button icon title={$_('administration.tenants.open')}
                            class="secondary-text" 
                            data-id="{row.id.toString()}" 
                            on:click={openClicked}  >
                            <Icon path={mdiFolderOpenOutline} class="secondary-text" />
                        </Button>
                        <Button icon title={$_('administration.tenants.edit')}
                            class="secondary-text" 
                            data-id="{row.id.toString()}" 
                            on:click={editClicked}  >
                            <Icon path={mdiPencilOutline} class="secondary-text" />
                        </Button>
                        Button icon title={$_('administration.tenants.delete')}
                            class="secondary-text" 
                            data-id="{row.id.toString()}" 
                            on:click={deleteClicked}  >
                            <Icon path={mdiDeleteOutline} class="secondary-text" />
                        </Button
                    </td>
                    <td>{row.id}</td>
                    <td>{row.name}</td>
                    <td>{row.type}</td>
                    <td>{row.status}</td>
                    <td>{row.createdDate}</td>
                    <td>{row.updatedDate}</td>
                </tr>
            {/each}
            {/await}
        </tbody>
    </table> -->

  {#if showNewDialog}
    <AddOrUpdateTenant bind:show={showNewDialog} on:save={addNewTenant} />
  {/if}
  {#if showUpdateDialog}
    <AddOrUpdateTenant
      bind:show={showUpdateDialog}
      tenant={selectedTenant}
      on:save={updatedItemSaved}
    />
  {/if}
  {#if showDeleteDialog}
    <!--DeleteClient bind:show={showDeleteDialog} on:delete={itemDeleted} patent={currentItem}/-->
  {/if}
</Route>

<Route path="/:id/*" let:meta>
  <TenantDetail tenantId={meta.params.id} initialBreadCrumbItems={breadCrumbItems} />
</Route>

<style>
  table {
    border: 1px solid white;
    border-collapse: collapse;
  }
  thead > th:first-child {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }
  thead > th:last-child {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  th {
    border: 1px solid white;
    height: 41px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #1a76d2;
    color: white;
  }
  td {
    padding: 4px;
    border-bottom: 1px solid #1a76d2;
  }
</style>
