<script>
  import {
    mdiApplicationImport,
    mdiDeleteOutline,
    mdiFolderOpen,
    mdiFolderOpenOutline,
    mdiPencilOutline,
    mdiPlus,
  } from '@mdi/js';
  import { router } from 'tinro';
  import { _ } from '../../services/i18n';
  import {
    accountHasPermissionForItemAndType,
    ITEM_CONTRACT,
    ITEM_CREATE,
    ITEM_DELETE,
    ITEM_READ,
    ITEM_UPDATE,
  } from '../../services/account';
  import AddOrUpdateContract from './AddOrUpdateContract.svelte';
  import DeleteContract from './DeleteContract.svelte';
  import ContractItems from './ContractItems.svelte';
  import { isImportActionEnabledForItems } from '../Import/Import.svelte';

  export let readonly = true;
  let currentItem;
  let showUpdateDialog = false;
  let showDeleteDialog = false;
  let showAddItemDialog = false;
  export let contractItems;

  function isViewActionEnabled(item) {
    return accountHasPermissionForItemAndType(ITEM_READ, item, ITEM_CONTRACT);
  }

  function isUpdateActionEnabled(item) {
    return accountHasPermissionForItemAndType(ITEM_UPDATE, item, ITEM_CONTRACT);
  }

  function isDeleteActionEnabled(item) {
    return accountHasPermissionForItemAndType(ITEM_DELETE, item, ITEM_CONTRACT);
  }

  const rowActions = [
    {
      type: 'button',
      enabledFunction: isViewActionEnabled,
      href: '/contract/item/',
      title: $_('contract.open'),
      path: mdiFolderOpen,
      onClickEvent: 'viewButtonClicked',
      elementId: 'contract-items-all',
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isUpdateActionEnabled,
      href: '',
      title: $_('contract.edit'),
      path: mdiPencilOutline,
      onClickEvent: 'editButtonClicked',
      elementId: 'contract-items-all',
      value: '',
    },
    {
      type: 'copyLinkButton',
    },
    {
      type: 'button',
      enabledFunction: isDeleteActionEnabled,
      href: '',
      title: $_('contract.delete'),
      path: mdiDeleteOutline,
      onClickEvent: 'deleteButtonClicked',
      elementId: 'contract-items-all',
      cssClasses: ['red-text'],
      value: '',
    },
  ];

  // same as rowActions but buttons go above the datatable
  const globalActions = [
    {
      enabledFunction: readonly,
      href: '',
      title: $_('groups-and-items.new'),
      path: mdiPlus,
      onClickEvent: 'newItemClicked',
      elementId: 'contract-items-all',
    },
    {
      enabledFunction: isImportActionEnabledForItems(ITEM_CREATE),
      href: '/import',
      title: $_('groups-and-items.import-records'),
      path: mdiApplicationImport,
      onClickEvent: 'importButtonClicked',
      elementId: 'contract-items-all',
    },
  ];

  const linkActions = [
    {
      enabledFunction: isViewActionEnabled,
      href: '/contract/item/',
      title: $_('contract.open'),
      onClickEvent: 'viewButtonClicked',
      elementId: 'contract-items-all',
    },
  ];

  async function updatedItemSaved(event) {
    const { item } = event.detail;
    const { id } = item;
    contractItems.updateRow(id);
  }

  function itemDeleted(event) {
    const { id } = event.detail;
    contractItems.deleteRows(id);
  }

  async function newItem(event) {
    const newItem = event.detail.item;
    router.goto(`/contract/item/${newItem.id}`);
    contractItems.addNewRows([newItem], true);
  }

  function viewButtonClicked(event) {
    const { href } = event.detail.action;
    router.goto(href + event.detail.id);
  }

  function editButtonClicked(event) {
    currentItem = event.detail.data;
    showUpdateDialog = true;
  }

  function deleteButtonClicked(event) {
    currentItem = event.detail.data;
    showDeleteDialog = true;
  }

  function newItemClicked() {
    showAddItemDialog = true;
  }

  function importButtonClicked(event) {
    router.goto(event.detail.href);
  }
</script>

<div
  id="contract-items-all"
  on:itemDeleted={itemDeleted}
  on:newItem={newItem}
  on:updatedItemSaved={updatedItemSaved}
  on:newItemClicked={newItemClicked}
  on:importButtonClicked={importButtonClicked}
>
  <ContractItems
    bind:this={contractItems}
    list={contractItems}
    tableName="contract-all"
    {rowActions}
    {globalActions}
    {linkActions}
    on:viewButtonClicked={viewButtonClicked}
    on:editButtonClicked={editButtonClicked}
    on:deleteButtonClicked={deleteButtonClicked}
  />
</div>

{#if showUpdateDialog}
  <AddOrUpdateContract
    bind:show={showUpdateDialog}
    contract={currentItem}
    on:save={updatedItemSaved}
  />
{/if}
{#if showDeleteDialog}
  <DeleteContract bind:show={showDeleteDialog} on:delete={itemDeleted} contract={currentItem} />
{/if}
{#if showAddItemDialog}
  <AddOrUpdateContract bind:show={showAddItemDialog} on:save={newItem} />
{/if}
