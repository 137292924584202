<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Col,
    Container,
    Dialog,
    Divider,
    Row,
    TextField,
  } from 'svelte-materialify';
  import { _ } from '../../services/i18n';
  import { validateForm } from '../../services/validation';
  import { apiFetch } from '../../services/network';

  const dispatch = createEventDispatcher();

  export let fileSystemNode;
  let duplicateFolderName = false;

  let formElement;
  let fileNameField;
  let isFocused = false;
  const fileNamePieces = fileSystemNode.name.split('.');

  let name = fileNamePieces.slice(0, -1).join('.');
  const extension = fileNamePieces[fileNamePieces.length - 1];

  afterUpdate(async () => {
    if (!isFocused && !name) {
      fileNameField.focus();
      isFocused = true;
    }
  });

  async function save(event) {
    event.preventDefault();
    if (!validateForm(formElement)) {
      return;
    }

    const url = `/api/file-system/file/${fileSystemNode.id}/rename`;
    const response = await apiFetch(url, 'PUT', { name: `${name}.${extension} ` });
    if (response.ok) {
      const fileSystemNode = await response.json();
      dispatch('success', { fileSystemNode });
    } else if (response.status === 409) {
      duplicateFolderName = true;
    }
  }
</script>

<Dialog persistent={true} active>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{$_('files.rename-file-dialog.title')}</h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <Container>
          <Row>
            <Col cols={10}>
              <TextField
                bind:value={name}
                bind:inputElement={fileNameField}
                outlined={true}
                dense
                color={'secondary'}
                required
              >
                {$_('files.rename-file-dialog.file-name')}
              </TextField>
            </Col>
            <Col class="pt-5 pl-0" cols={2}>
              <span class="font-weight-regular text-body-1">.{extension}</span>
            </Col>
          </Row>
          {#if duplicateFolderName}
            <Row>
              <span class="error-text">{$_('files.rename-file-dialog.duplicate-folder')}</span>
            </Row>
          {/if}
        </Container>
        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          class="mt-4 {name ? 'secondary-color' : 'grey-color'}"
          on:click={save}
          text
          disabled={!name}
        >
          {$_('files.rename-file-dialog.rename')}
        </Button>
        <Button class="mt-4 ml-2" on:click={() => dispatch('cancel')} text>
          {$_('files.rename-file-dialog.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
