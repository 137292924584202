<script>
  import {
    mdiDeleteOutline,
    mdiDownloadOutline,
    mdiFileMoveOutline,
    mdiFolderMultipleOutline,
    mdiFolderOpenOutline,
    mdiFolderOutline,
    mdiFolderPlusOutline,
    mdiMenu,
    mdiPencilOutline,
    mdiShareVariantOutline,
    mdiUploadOutline,
  } from '@mdi/js';
  import { createEventDispatcher, onDestroy, onMount, tick } from 'svelte';
  import {
    Badge,
    Button,
    Container,
    Divider,
    ExpansionPanel,
    ExpansionPanels,
    Icon,
    List,
    ListItem,
    Menu,
    Overlay,
    Row,
  } from 'svelte-materialify';
  import { sortBy, sumBy } from 'lodash-es';
  import { _ } from '../../services/i18n';
  import { apiFetch, apiFormatUrl, selectedClientContact } from '../../services/network';

  import CreateFolder from './CreateFolder.svelte';
  import DeleteFolder from './DeleteFolder.svelte';
  import FileSystemFile from './FileSystemFile.svelte';
  import MoveToFolder from './MoveToFolder.svelte';
  import RenameFolder from './RenameFolder.svelte';
  import ShareDataRoom from './ShareDataRoom.svelte';
  import UploadFiles from './UploadFiles.svelte';

  import StarburstWithTextIcon from '../StarburstWithTextIcon.svelte';
  import { hasPermissions } from '../../services/helpers/permissions';
  import { accountInfo } from '../../services/account';

  export let fileSystemGroupName;
  export let rootFileSystemNode;
  export let fileSystemNode;
  export let folderIndex;
  export let outerPanelsActive;
  export let showDownloadDataRoomSnackBar;

  let panelsActive = [];
  let listing = [];
  let dropAreaElement;
  let fileInputElement;
  let showDropZone = false;
  let showCreateFolderDialog = false;
  let showRenameFolderDialog = false;
  let showDeleteFolderDialog = false;
  let showMoveDialog = false;
  let showShareDataRoomDialog = false;
  let filesToUpload = [];
  $: uploadUrl = `/api/file-system/folder/${fileSystemNode.id}/file`;
  $: folderUrl = `/api/file-system/folder/${fileSystemNode.id}/export`;
  let showUploadFilesDialog = false;

  const dispatch = createEventDispatcher();

  $: sortedListing = listing
    ? sortBy(
        listing.map((item) => ({ ...item, name: resolveFileSystemNodeName(item) })),
        'name'
      )
    : [];
  $: numFilesInFolder = sortedListing.filter((item) => item.mimeType !== 'inode/directory').length;

  const hasPermissionForDataRoom = (action) =>
    hasPermissions(
      $selectedClientContact?.userProfile?.tenant?.id || $accountInfo.tenant.id,
      action,
      'data_room',
      fileSystemNode.rootNodeId
    );

  onMount(async () => {
    if (hasPermissionForDataRoom('update')) {
      // only register drag'n'drop handlers if the user has write permission which is required for upload
      dropAreaElement.addEventListener('dragenter', preventDefaults, false);
      dropAreaElement.addEventListener('dragover', preventDefaults, false);
      dropAreaElement.addEventListener('dragleave', preventDefaults, false);
      dropAreaElement.addEventListener('drop', preventDefaults, false);

      dropAreaElement.addEventListener('dragenter', highlight, false);
      dropAreaElement.addEventListener('dragover', highlight, false);

      dropAreaElement.addEventListener('dragleave', unhighlight, false);
      dropAreaElement.addEventListener('drop', unhighlight, false);

      dropAreaElement.addEventListener('drop', handleDrop, false);
    }
    await load();
  });

  onDestroy(() => {
    if (hasPermissionForDataRoom('update')) {
      // only deregister drag'n'drop handlers if the user has write permission which is required for upload
      dropAreaElement.removeEventListener('dragenter', preventDefaults, false);
      dropAreaElement.removeEventListener('dragover', preventDefaults, false);
      dropAreaElement.removeEventListener('dragleave', preventDefaults, false);
      dropAreaElement.removeEventListener('drop', preventDefaults, false);

      dropAreaElement.removeEventListener('dragenter', highlight, false);
      dropAreaElement.removeEventListener('dragover', highlight, false);

      dropAreaElement.removeEventListener('dragleave', unhighlight, false);
      dropAreaElement.removeEventListener('drop', unhighlight, false);

      dropAreaElement.removeEventListener('drop', handleDrop, false);
    }
  });

  function preventDefaults(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  function highlight(event) {
    showDropZone = true;
  }

  function unhighlight(event) {
    showDropZone = false;
  }

  function handleDrop(event) {
    const dt = event.dataTransfer;
    filesToUpload = [...dt.files]; // convert FileList to javascript Array
    showUploadFilesDialog = true;
  }

  // need to keep track of folders when we iterate over the sortedListing
  let folderIndexCount = -1;
  function getFolderIndex() {
    folderIndexCount += 1;
    return folderIndexCount;
  }

  async function load() {
    const url = `/api/file-system/folder/${fileSystemNode.id}`;
    const response = await apiFetch(url);
    if (response.ok) {
      listing = await response.json();
      // notify container data room contents retrieved.  Use timeout to ensure listing
      // is completely retrieved and updated in the UI
      await tick();
      setTimeout(() => {
        dispatch('listingRetrieved');
      }, 500);
    }
  }

  function resolveFileSystemNodeName(fileSystemNode) {
    if (fileSystemNode.name && fileSystemNode.name.length > 0) {
      return fileSystemNode.name;
    }
    return $_(`file-system.${fileSystemNode.symbolName}.title`);
  }

  function generateId() {
    return `file-system-folder-${fileSystemNode.id}`;
  }

  function fileUploaded(event) {
    const { responsePayload } = event.detail;
    listing.push(responsePayload);
    listing = listing;
  }

  function nodeMovedHere(event) {
    const { fileSystemNode } = event.detail;
    listing.push(fileSystemNode);
    listing = listing;
  }

  async function nodeMovedFromHere(event) {
    const { fileSystemNode, copyAndMove } = event.detail;
    if (copyAndMove) {
      // reload current directory list
      await load();
    } else {
      const itemIndex = listing.findIndex((item) => item.id === fileSystemNode.id);
      listing.splice(itemIndex, 1);
      listing = listing;
    }
  }

  function folderCreated(event) {
    const { fileSystemNode } = event.detail;
    listing.push(fileSystemNode);
    listing = listing;
  }

  function fileDeleted(event) {
    const { fileSystemNode } = event.detail;
    const itemIndex = listing.findIndex((item) => item.id === fileSystemNode.id);
    listing.splice(itemIndex, 1);
    listing = listing;
  }

  function fileRenamed(event) {
    const { fileSystemNode } = event.detail;
    const itemIndex = listing.findIndex((item) => item.id === fileSystemNode.id);
    listing.splice(itemIndex, 1);
    listing.push(fileSystemNode);
    listing = listing;
  }

  async function fileCopied(event) {
    const { fileSystemNode } = event.detail;

    // reload directory listing
    await load();
  }

  async function folderRenamed(event) {
    fileSystemNode = event.detail.fileSystemNode;
    showRenameFolderDialog = false;
    dispatch('rename', { fileSystemNode });
  }

  async function subFolderRenamed(event) {
    const renamedNode = event.detail.fileSystemNode;
    const itemIndex = listing.findIndex((item) => item.id === renamedNode.id);
    listing.splice(itemIndex, 1);
    listing.push(renamedNode);
    listing = listing;
  }

  function fileSelectionChanged(event) {
    filesToUpload = [...fileInputElement.files]; // convert FileList to javascript Array
    showUploadFilesDialog = true;
  }

  async function folderDeleted(event) {
    const { fileSystemNode } = event.detail;
    const itemIndex = listing.findIndex((item) => item.id === fileSystemNode.id);
    // if the deleted folder is found in my listing, then process it here, otherwise forward it up to the enclosing folder
    if (itemIndex > -1) {
      listing.splice(itemIndex, 1);
      listing = listing;
    } else {
      dispatch('delete', { fileSystemNode });
    }
  }

  async function downloadFolderButtonClicked(event) {
    showDownloadDataRoomSnackBar = true;
    const a = document.createElement('a');
    a.href = apiFormatUrl(folderUrl);
    a.setAttribute('download', '');
    a.setAttribute('tinro-ignore', '');
    a.click();
  }
</script>

<ExpansionPanel>
  <div
    id={generateId()}
    class="pl-2"
    bind:this={dropAreaElement}
    slot="header"
    on:file-uploaded={fileUploaded}
    on:folder-created={folderCreated}
    on:node-moved-here={nodeMovedHere}
    on:node-moved-from-here={nodeMovedFromHere}
  >
    <input
      bind:this={fileInputElement}
      on:change={fileSelectionChanged}
      on:click|stopPropagation
      type="file"
      name="file"
      multiple={true}
    />
    <!-- Need span to prevent menu clicks from reaching expansion panel -->
    <span on:click|stopPropagation>
      <Menu offsetX={true} style="width: 200px; word-wrap: break-word;flex: inherit">
        <div slot="activator" on:click|stopPropagation>
          <Button icon title={$_('files.file-actions')}>
            <Icon class="secondary-text" path={mdiMenu} />
          </Button>
        </div>
        <List dense>
          <ListItem disabled style="white-space: pre-line;">
            <span slot="prepend">
              <Icon path={mdiFolderMultipleOutline} />
            </span>
            <span style="white-space: pre-line;">{resolveFileSystemNodeName(fileSystemNode)}</span>
          </ListItem>
          <Divider />
          <ListItem
            class={hasPermissionForDataRoom('update') ? 'secondary-text' : 'grey-text'}
            on:click={() => fileInputElement.click()}
            disabled={!hasPermissionForDataRoom('update')}
          >
            <span slot="prepend">
              <Icon
                class={hasPermissionForDataRoom('update') ? 'secondary-text' : 'grey-text'}
                path={mdiUploadOutline}
              />
            </span>
            {$_('files.upload')}
          </ListItem>
          <ListItem
            class={hasPermissionForDataRoom('update') ? 'secondary-text' : 'grey-text'}
            on:click={() => (showCreateFolderDialog = true)}
            disabled={!hasPermissionForDataRoom('update')}
          >
            <span slot="prepend">
              <Icon
                class={hasPermissionForDataRoom('update') ? 'secondary-text' : 'grey-text'}
                path={mdiFolderPlusOutline}
              />
            </span>
            {$_('files.new-folder')}
          </ListItem>
          <ListItem
            class={hasPermissionForDataRoom('update') ? 'secondary-text' : 'grey-text'}
            on:click={() => (showRenameFolderDialog = true)}
            disabled={!hasPermissionForDataRoom('update')}
          >
            <span slot="prepend"
              ><Icon
                class={hasPermissionForDataRoom('update') ? 'secondary-text' : 'grey-text'}
                path={mdiPencilOutline}
              /></span
            >
            {$_('files.rename')}
          </ListItem>
          {#if fileSystemNode !== rootFileSystemNode}
            <ListItem
              class={hasPermissionForDataRoom('delete') ? 'secondary-text' : 'grey-text'}
              on:click={() => (showMoveDialog = true)}
              disabled={!hasPermissionForDataRoom('delete')}
            >
              <span slot="prepend">
                <Icon
                  class={hasPermissionForDataRoom('delete') ? 'secondary-text' : 'grey-text'}
                  path={mdiFileMoveOutline}
                />
              </span>
              {$_('files.move')}
            </ListItem>
          {/if}
          <ListItem
            class={hasPermissionForDataRoom('delete') ? 'secondary-text' : 'grey-text'}
            on:click={() => (showDeleteFolderDialog = true)}
            disabled={!hasPermissionForDataRoom('delete')}
          >
            <span slot="prepend">
              <Icon
                class={hasPermissionForDataRoom('delete') ? 'secondary-text' : 'grey-text'}
                path={mdiDeleteOutline}
              />
            </span>
            {$_('files.delete')}
          </ListItem>
          <ListItem
            class={hasPermissionForDataRoom('download') ? 'secondary-text' : 'grey-text'}
            on:click={downloadFolderButtonClicked}
            disabled={!hasPermissionForDataRoom('download')}
          >
            <span slot="prepend"
              ><Icon
                class={hasPermissionForDataRoom('download') ? 'secondary-text' : 'grey-text'}
                path={mdiDownloadOutline}
              /></span
            >
            {$_('files.download')}
            <StarburstWithTextIcon
              text={$_('new-feature.beta')}
              altText={$_('new-feature.beta-feature')}
              slot="append"
            />
          </ListItem>
          {#if fileSystemNode.id === rootFileSystemNode.id && hasPermissionForDataRoom('share')}
            <ListItem class="secondary-text" on:click={(_) => (showShareDataRoomDialog = true)}>
              <span slot="prepend">
                <Icon
                  class={hasPermissionForDataRoom('share') ? 'secondary-text' : 'grey-text'}
                  path={mdiShareVariantOutline}
                />
              </span>
              {$_('files.share')}
            </ListItem>
          {/if}
        </List>
      </Menu>
    </span>
    <Badge
      class="secondary-color"
      bordered
      active={numFilesInFolder > 0}
      bind:value={numFilesInFolder}
      offsetX={8}
      offsetY={16}
    >
      {#if fileSystemNode === rootFileSystemNode}
        <span><Icon class="pl-3" path={mdiFolderMultipleOutline} /></span>
      {:else if outerPanelsActive.includes(folderIndex)}
        <span><Icon class="pl-3" path={mdiFolderOpenOutline} /></span>
      {:else}
        <span><Icon class="pl-3" path={mdiFolderOutline} /></span>
      {/if}
    </Badge>
    <span class="pl-6">{resolveFileSystemNodeName(fileSystemNode)}</span>
    {#if showDropZone}
      <Overlay color="secondary" bind:active={showDropZone} absolute={true}>
        <Icon size="70px" class="secondary-text" path={mdiUploadOutline} />
      </Overlay>
    {/if}
  </div>
  <ExpansionPanels multiple bind:value={panelsActive}>
    {#each sortedListing as listItem, index (listItem.id)}
      {#if listItem.mimeType === 'inode/directory'}
        <svelte:self
          {fileSystemGroupName}
          {rootFileSystemNode}
          fileSystemNode={listItem}
          bind:outerPanelsActive={panelsActive}
          on:delete={folderDeleted}
          on:rename={subFolderRenamed}
          folderIndex={getFolderIndex()}
        />
      {:else}
        <Container class="ml-6">
          <Row>
            <table>
              <tbody>
                <FileSystemFile
                  {rootFileSystemNode}
                  fileSystemNode={listItem}
                  {fileSystemGroupName}
                  on:delete={fileDeleted}
                  on:rename={fileRenamed}
                  on:copy={fileCopied}
                />
              </tbody>
            </table>
          </Row>
        </Container>
      {/if}
    {/each}
  </ExpansionPanels>
</ExpansionPanel>

{#if showCreateFolderDialog}
  <CreateFolder
    bind:show={showCreateFolderDialog}
    parentFileSystemNode={fileSystemNode}
    on:create={folderCreated}
  />
{/if}

{#if showDeleteFolderDialog}
  <DeleteFolder
    {fileSystemNode}
    on:delete={folderDeleted}
    on:cancel={() => {
      showDeleteFolderDialog = false;
    }}
  />
{/if}

{#if showRenameFolderDialog}
  <RenameFolder
    {fileSystemNode}
    on:success={folderRenamed}
    on:cancel={() => {
      showRenameFolderDialog = false;
    }}
  />
{/if}

{#if showUploadFilesDialog}
  <UploadFiles
    bind:show={showUploadFilesDialog}
    {filesToUpload}
    listing={sortedListing}
    {uploadUrl}
    senderId={generateId()}
    folderName={resolveFileSystemNodeName(fileSystemNode)}
  />
{/if}

{#if showMoveDialog}
  <MoveToFolder
    {fileSystemGroupName}
    fileSystemNodeToMove={fileSystemNode}
    on:cancel={() => {
      showMoveDialog = false;
    }}
  />
{/if}

{#if showShareDataRoomDialog}
  <ShareDataRoom {fileSystemNode} on:cancel={() => (showShareDataRoomDialog = false)} />
{/if}

<style>
  input {
    display: none;
  }
</style>
