<script context="module">
  /**
   * If you want to copy a specific path different than the root path (default),
   * specify this property, it will be appended to the base URL defined
   * in the environment (ie. "http://localhost:8000/#").
   *
   * @param customPath specifying path = "/my/path" would result in (using the localhost
   *  example above) the path "http://localhost:8000/#/my/path" being copied to the
   *  clipboard.
   */
  export async function CopyUrlToClipboard(path = '/') {
    const urlToCopy = `${import.meta.env.FORTRESS_BASE_URL}/#${path}`;
    await navigator.clipboard.writeText(urlToCopy);
  }
</script>

<script>
  import { _ } from 'svelte-i18n';
  import { Button, Snackbar, Icon } from 'svelte-materialify';
  import { mdiLinkVariant } from '@mdi/js';
  import { meta } from 'tinro';

  const route = meta();
  let showSnackBar = false;

  async function copyURL() {
    // Call module function
    await CopyUrlToClipboard(route.url);
    showSnackBar = true;
  }
</script>

<Button icon class="secondary-color" on:click={copyURL} title={$_('ip.copy-link')}>
  <Icon class="text-white" path={mdiLinkVariant} />
</Button>

<Snackbar class="flex-column" bind:active={showSnackBar} timeout={2000} top right>
  {$_('ip.link-copied')}
</Snackbar>
