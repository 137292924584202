<script>
  import { afterUpdate, createEventDispatcher, onMount, tick } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider, Select } from 'svelte-materialify';
  import Svelecte from 'svelecte';
  import {
    _,
    getPatentOwnershipStatusOptions,
    getPatentStateSelectOptions,
    getPatentTypeSelectOptions,
  } from '../../services/i18n';
  import { validateForm } from '../../services/validation';
  import { apiFetch } from '../../services/network';
  import DateField from '../DateField.svelte';
  import TextField from '../TextField.svelte';
  import SelectCountryV2 from '../SelectCountryV2.svelte';

  const dispatch = createEventDispatcher();
  export let show;

  const today = new Date().toISOString().split('T')[0];

  export let patent = {
    name: '',
    nickName: '',
    lawFirmIdentifier: '',
    otherIdentifier: '',
    patentType: 'provisional',
    country: '',
    status: 'not-yet-filed',
    filingDate: '',
    applicationNumber: '',
    grantDate: '',
    grantNumber: '',
    publicationDate: '',
    publicationNumber: '',
    expiryDate: '',
    nextAction: '',
    actionDueDate: '',
    ownershipStatus: [],
    licensees: '',
    licensors: '',
    licenseDocumentLocation: '',
    patentOwners: '',
    productId: '',
    inventors_text: '',
  };

  const newPatent = {};
  patent.ownershipStatus ??= [];
  Object.assign(newPatent, patent);
  onMount(async () => {
    ownershipOptions.forEach((ownershipStatus) => (ownershipStatus.disabled = false));
    await tick();
    if (newPatent.ownershipStatus.length > 0) {
      enableOrDisableOwnershipOptions(
        newPatent.ownershipStatus.map((status) => ({ value: status }))
      );
    }
  });

  $: saveDisabled = !newPatent.patentType || !newPatent.status || !newPatent.name;

  let formElement;
  let patentNameField;
  let patentNickNameField;
  let lawFirmIdField;
  let otherIdField;
  let patentApplicationNumberField;
  let patentGrantNumberField;
  let patentNextActionField;
  let isFocused = false;
  let ownershipOptions = getPatentOwnershipStatusOptions();

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  afterUpdate(async () => {
    if (!isFocused && !patent.name) {
      patentNameField.focus();
      isFocused = true;
    }
  });

  async function save(event) {
    event.preventDefault();
    Object.assign(patent, newPatent);
    if (!validateForm(formElement)) {
      return;
    }

    let url = `/patent`;
    let method = 'POST';
    if (patent.id) {
      url += `/${patent.id}`;
      method = 'PUT';
    }
    const request = {};
    for (const property in newPatent) {
      if (newPatent[property] != null && property !== 'id') {
        request[property] = newPatent[property];
      }
    }
    saveDisabled = true;
    const response = await apiFetch(url, method, request);
    saveDisabled = !newPatent.patentType || !newPatent.status || !newPatent.name;
    if (response.ok) {
      const item = await response.json();
      dispatch('save', { item });
      show = false;
    }
  }

  /**
   * Disable or enable ownership status options based on selected values
   * @param event
   */
  function onChangeOwnershipStatus(event) {
    event.preventDefault();

    enableOrDisableOwnershipOptions(event.detail);
  }

  function enableOrDisableOwnershipOptions(selectedOptions) {
    ownershipOptions.find((option) => option.value === 'owned').disabled = selectedOptions.some(
      (option) => option.value.startsWith('licensed-in')
    );

    ownershipOptions.find((option) => option.value === 'licensed-out-exclusive').disabled =
      selectedOptions.some((option) => option.value === 'licensed-out-non-exclusive');

    ownershipOptions.find((option) => option.value === 'licensed-out-non-exclusive').disabled =
      selectedOptions.some((option) => option.value === 'licensed-out-exclusive');

    ownershipOptions.find((option) => option.value === 'licensed-in-exclusive').disabled =
      selectedOptions.some((option) => option.value === 'owned') ||
      selectedOptions.some((option) => option.value === 'licensed-in-non-exclusive');

    ownershipOptions.find((option) => option.value === 'licensed-in-non-exclusive').disabled =
      selectedOptions.some((option) => option.value === 'owned') ||
      selectedOptions.some((option) => option.value === 'licensed-in-exclusive');

    if (!selectedOptions.some((option) => option.value.startsWith('licensed-out'))) {
      newPatent.licensees = '';
    }
    if (!selectedOptions.some((option) => option.value.startsWith('licensed-in'))) {
      newPatent.licensors = '';
    }

    // Clear licenseDocumentLocation if no 'licensed' option selected
    if (!selectedOptions.some((status) => status.value.startsWith('licensed'))) {
      newPatent.licenseDocumentLocation = '';
    }

    ownershipOptions = ownershipOptions;
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      {#if patent.id}
        <h6 class="secondary-text">{$_('patent.edit')} {$_('patent.singular')}</h6>
      {:else}
        <h6 class="secondary-text">{$_('patent.new')} {$_('patent.singular')}</h6>
      {/if}
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <Select
          outlined
          dense
          items={getPatentTypeSelectOptions()}
          bind:value={newPatent.patentType}
          class="pb-2"
          mandatory
        >
          {$_('patent.table.type')}
        </Select>
        <Select
          outlined
          dense
          items={getPatentStateSelectOptions()}
          bind:value={newPatent.status}
          class="pb-2 secondary-text"
          color="secondary"
          mandatory
        >
          {$_('patent.table.status')}
        </Select>
        <TextField
          bind:value={newPatent.name}
          bind:inputElement={patentNameField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          required
        >
          {$_('patent.table.title')}
        </TextField>
        <TextField
          bind:value={newPatent.nickName}
          bind:inputElement={patentNickNameField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('patent.table.nickName')}
        </TextField>
        <TextField
          bind:value={newPatent.lawFirmIdentifier}
          bind:inputElement={lawFirmIdField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('patent.table.lawFirmId')}
        </TextField>
        <TextField
          bind:value={newPatent.otherIdentifier}
          bind:inputElement={otherIdField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('patent.table.otherId')}
        </TextField>
        <SelectCountryV2 bind:value={newPatent.country} class="pb-2" />
        <DateField
          bind:value={newPatent.filingDate}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          clearable
        >
          {$_('patent.table.filingDate')}
        </DateField>
        <TextField
          bind:value={newPatent.applicationNumber}
          bind:inputElement={patentApplicationNumberField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('patent.table.applicationNumber')}
        </TextField>
        <DateField
          bind:value={newPatent.earliestPriorityDate}
          class="pb-2"
          outlined={true}
          dense
          color="secondary"
        >
          {$_('patent.table.earliestPriorityDate')}
        </DateField>
        <TextField
          bind:value={newPatent.priorityApplicationNumber}
          class="pb-2"
          outlined={true}
          dense
          color="secondary"
        >
          {$_('patent.table.priorityApplicationNumber')}
        </TextField>
        <DateField
          bind:value={newPatent.grantDate}
          bind:min={newPatent.filingDate}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          clearable
          errorMessages={{
            dateBefore: $_('patent.validation.dateBeforeFilingDate'),
          }}
        >
          {$_('patent.table.grantDate')}
        </DateField>
        <TextField
          bind:value={newPatent.grantNumber}
          bind:inputElement={patentGrantNumberField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('patent.table.grantNumber')}
        </TextField>
        <DateField
          bind:value={newPatent.publicationDate}
          bind:min={newPatent.filingDate}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          clearable
          errorMessages={{
            dateBefore: $_('patent.validation.dateBeforeFilingDate'),
          }}
        >
          {$_('patent.table.publicationDate')}
        </DateField>
        <TextField
          bind:value={newPatent.publicationNumber}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('patent.table.publicationNumber')}
        </TextField>
        <TextField
          bind:value={newPatent.inventors_text}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('patent.inventors')}
        </TextField>
        <DateField
          bind:value={newPatent.expiryDate}
          min={today}
          class="pb-2"
          outlined={true}
          dense
          color="secondary"
          clearable
        >
          {$_('patent.table.expiryDate')}
        </DateField>

        <TextField
          bind:value={newPatent.nextAction}
          bind:inputElement={patentNextActionField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('patent.table.nextAction')}
        </TextField>
        <DateField
          bind:value={newPatent.actionDueDate}
          min={today}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          clearable
        >
          {$_('patent.table.actionDueDate')}
        </DateField>
        <TextField bind:value={newPatent.productId} class="pb-2" outlined dense color="secondary">
          {$_('patent.table.productId')}
        </TextField>
        <TextField
          bind:value={newPatent.patentOwners}
          class="pb-2"
          outlined
          dense
          color="secondary"
        >
          {$_('patent.table.patentOwners')}
        </TextField>
        <Svelecte
          bind:value={newPatent.ownershipStatus}
          class="pb-2 svelecte-control ownership-status"
          clearable
          multiple
          options={ownershipOptions}
          placeholder="Ownership Status"
          disabledField="disabled"
          lazyDropdown={true}
          on:change={onChangeOwnershipStatus}
        />
        {#if newPatent.ownershipStatus.some((status) => status.startsWith('licensed-out'))}
          <TextField
            bind:value={newPatent.licensees}
            class="pb-2"
            outlined
            dense
            color="secondary"
            validateOnBlur
            rules={[
              (value) => {
                if (
                  newPatent.ownershipStatus.some((status) => status.startsWith('licensed-out')) &&
                  value.length > 0
                ) {
                  return true;
                }
                return $_('patent.validation.licenseesRequired');
              },
            ]}
          >
            {$_('patent.table.licensees')}
          </TextField>
        {/if}
        {#if newPatent.ownershipStatus.some((status) => status.startsWith('licensed-in'))}
          <TextField
            bind:value={newPatent.licensors}
            class="pb-2"
            outlined
            dense
            color="secondary"
            validateOnBlur
            rules={[
              (value) => {
                if (
                  newPatent.ownershipStatus.some((status) => status.startsWith('licensed-in')) &&
                  value.length > 0
                ) {
                  return true;
                }
                return $_('patent.validation.licensorsRequired');
              },
            ]}
          >
            {$_('patent.table.licensors')}
          </TextField>
        {/if}
        {#if newPatent.ownershipStatus.some((status) => status.startsWith('licensed'))}
          <TextField
            bind:value={newPatent.licenseDocumentLocation}
            class="pb-2"
            outlined
            dense
            color="secondary"
            validateOnBlur
            rules={[
              (value) => {
                if (
                  newPatent.ownershipStatus.some((status) => status.startsWith('licensed')) &&
                  value.length > 0
                ) {
                  return true;
                }
                return $_('patent.validation.licenseDocumentLocationRequired');
              },
            ]}
          >
            {$_('patent.table.licenseDocumentLocation')}
          </TextField>
        {/if}
        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          text
          disabled={saveDisabled}
          class="mt-4 {saveDisabled ? 'grey-color' : 'secondary-color'}"
          on:click={save}
        >
          {$_('patent.save')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('patent.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>

<style>
  :global(.svelecte-control.ownership-status .sv-control) {
    border: 1px solid #949494 !important;
    --sv-color: #000;
  }
</style>
