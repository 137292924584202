<script>
  import { Button, Col, Icon, Row, Select as MaterialifySelect, Switch } from 'svelte-materialify';
  import { mdiTrashCanOutline } from '@mdi/js';
  import Select from 'svelte-select';
  import { onMount } from 'svelte';
  import { apiFetch } from '../../../services/network';
  import { supportedIPTypes } from '../../../services/ip';
  import { _ } from '../../../services/i18n';

  export let entityId;
  export let allPermissions = [];
  export let permission;
  let checked = permission.permissionType.value ?? false;
  let families = [];
  const permissionTypes = [
    {
      value: false,
      type: 'view',
    },
    {
      value: true,
      type: 'edit',
    },
  ];
  const emptyPermission = {
    ipType: [],
    families: [],
    permissionType: { value: false, type: 'view' },
    entityId,
  };
  $: selectionMade = permission.ipType.length > 0;
  $: {
    permission.permissionType = checked ? permissionTypes[1] : permissionTypes[0];
  }

  onMount(() => {
    getFamilies();
  });

  async function getFamilies() {
    if (permission.ipType?.length > 0) {
      let url = `/api/user/${permission.ipType}/group`;
      families = [{ label: 'all', value: 0 }];
      url += `?tenantId=${entityId}`;
      const response = await apiFetch(url);
      if (response.ok) {
        const groups = await response.json();
        for (const group of groups) {
          families.push({ label: group.name, value: group.id });
        }
      }
      families = families;
      addEmptyRow();
    }
  }

  function addEmptyRow() {
    if (!allPermissions.some((p) => JSON.stringify(emptyPermission) == JSON.stringify(p))) {
      allPermissions.push({
        ipType: [],
        families: [],
        permissionType: { value: false, type: 'view' },
        entityId,
      });
      allPermissions = allPermissions;
    }
  }

  function removeRow() {
    allPermissions = allPermissions.filter((p) => p != permission);
  }
</script>

<Row>
  <Col>
    <div style="width: 215px;">
      <MaterialifySelect
        items={supportedIPTypes}
        bind:value={permission.ipType}
        on:change={getFamilies}
        outlined
        dense
        mandatory
        class="pl-5 pr-5 secondary-text"
      />
    </div>
  </Col>
  <Col cols="5">
    <Select
      items={families}
      bind:value={permission.families}
      isMulti={true}
      containerClasses="secondary-text"
    />
  </Col>
  <Col>
    <Switch disabled={!selectionMade} bind:checked class="pt-2 pl-5 pr-5" color="secondary">
      {permission.permissionType.type}
    </Switch>
  </Col>
  {#if selectionMade}
    <Col>
      <Button
        fab
        size="x-small"
        class="mr-4 float-right secondary-color"
        on:click={removeRow}
        title={$_('administration.users.group-permissions.add-dialog.entity-group.remove')}
      >
        <Icon class="secondary-color" path={mdiTrashCanOutline} />
      </Button>
    </Col>
  {/if}
</Row>
