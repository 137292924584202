<script>
  import {
    mdiApplicationImport,
    mdiDeleteOutline,
    mdiFolderOpenOutline,
    mdiPencilOutline,
    mdiPlus,
  } from '@mdi/js';
  import { createEventDispatcher } from 'svelte';
  import { router } from 'tinro';
  import { _ } from '../../services/i18n';
  import {
    accountHasPermissionForItemAndType,
    ITEM_CREATE,
    ITEM_DELETE,
    ITEM_PATENT,
    ITEM_READ,
    ITEM_UPDATE,
  } from '../../services/account';
  import AddOrUpdatePatent from './AddOrUpdatePatent.svelte';
  import DeletePatent from './DeletePatent.svelte';
  import PatentItems from './PatentItems.svelte';
  import { isImportActionEnabledForItems } from '../Import/Import.svelte';

  export let readonly = true;
  let currentItem;
  let showUpdateDialog = false;
  let showDeleteDialog = false;
  let showAddItemDialog = false;
  export let patentItems;

  const dispatch = createEventDispatcher();

  const rowActions = [
    {
      type: 'button',
      enabledFunction: isViewActionEnabled,
      href: '/patent/item/',
      title: $_('patent.open'),
      path: mdiFolderOpenOutline,
      onClickEvent: 'viewButtonClicked',
      elementId: 'patent-items-all',
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isUpdateActionEnabled,
      href: '',
      title: $_('patent.edit'),
      path: mdiPencilOutline,
      onClickEvent: 'editButtonClicked',
      elementId: 'patent-items-all',
      value: '',
    },
    {
      type: 'copyLinkButton',
    },
    {
      type: 'button',
      enabledFunction: isDeleteActionEnabled,
      href: '',
      title: $_('patent.delete'),
      path: mdiDeleteOutline,
      onClickEvent: 'deleteButtonClicked',
      cssClasses: ['red-text'],
      elementId: 'patent-items-all',
      value: '',
    },
  ];

  // same as rowActions but buttons go above the datatable
  const globalActions = [
    {
      enabledFunction: readonly,
      href: '',
      title: $_('groups-and-items.new'),
      path: mdiPlus,
      onClickEvent: 'newItemClicked',
      elementId: 'patent-items-all',
    },
    {
      enabledFunction: isImportActionEnabledForItems(ITEM_CREATE),
      href: '/import',
      title: $_('groups-and-items.import-records'),
      path: mdiApplicationImport,
      onClickEvent: 'importButtonClicked',
      elementId: 'patent-items-all',
    },
  ];

  const linkActions = [
    {
      enabledFunction: isViewActionEnabled,
      href: '/patent/item/',
      title: $_('patent.open'),
      onClickEvent: 'viewButtonClicked',
      elementId: 'patent-items-all',
    },
  ];

  function isViewActionEnabled(item) {
    return accountHasPermissionForItemAndType(ITEM_READ, item, ITEM_PATENT);
  }

  function isUpdateActionEnabled(item) {
    return accountHasPermissionForItemAndType(ITEM_UPDATE, item, ITEM_PATENT);
  }

  function isDeleteActionEnabled(item) {
    return accountHasPermissionForItemAndType(ITEM_DELETE, item, ITEM_PATENT);
  }

  async function updatedItemSaved(event) {
    const { item } = event.detail;
    const { id } = item;
    patentItems.updateRow(id);
  }

  function itemDeleted(event) {
    const { id } = event.detail;
    patentItems.deleteRows(id);
  }

  async function newItem(event) {
    const newItem = event.detail.item;
    router.goto(`/patent/item/${newItem.id}`);
    patentItems.addNewRows([newItem], true);
  }

  function viewButtonClicked(event) {
    const { href } = event.detail.action;
    router.goto(href + event.detail.id);
  }

  function editButtonClicked(event) {
    currentItem = event.detail.data;
    showUpdateDialog = true;
  }

  function deleteButtonClicked(event) {
    currentItem = event.detail.data;
    showDeleteDialog = true;
  }

  function newItemClicked() {
    showAddItemDialog = true;
  }

  function importButtonClicked(event) {
    router.goto(event.detail.href);
  }
</script>

<div
  id="patent-items-all"
  on:updatedItemSaved={updatedItemSaved}
  on:newItemClicked={newItemClicked}
  on:importButtonClicked={importButtonClicked}
>
  <PatentItems
    bind:this={patentItems}
    tableName="patent-all"
    {rowActions}
    {globalActions}
    {linkActions}
    on:itemDeleted={itemDeleted}
    on:newItem={newItem}
    on:updatedItemSaved={updatedItemSaved}
    on:viewButtonClicked={viewButtonClicked}
    on:editButtonClicked={editButtonClicked}
    on:deleteButtonClicked={deleteButtonClicked}
  />
</div>

{#if showUpdateDialog}
  <AddOrUpdatePatent bind:show={showUpdateDialog} patent={currentItem} on:save={updatedItemSaved} />
{/if}
{#if showDeleteDialog}
  <DeletePatent bind:show={showDeleteDialog} on:delete={itemDeleted} patent={currentItem} />
{/if}
{#if showAddItemDialog}
  <AddOrUpdatePatent bind:show={showAddItemDialog} on:save={newItem} />
{/if}
