<script>
  import { createEventDispatcher } from 'svelte';
  import uid from 'svelte-materialify/src/internal/uid';
  import { Button, Card, CardText, CardTitle, Dialog, Divider, Switch } from 'svelte-materialify';
  import { _ } from '../../../services/i18n';
  import { validateForm } from '../../../services/validation';
  import { apiFetch } from '../../../services/network';
  import TextField from '../../TextField.svelte';

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const dispatch = createEventDispatcher();
  const fortress = 'FORTRESS';
  export let show;
  export let tenantId = -1;
  export let user;

  let forcePasswordChange = true;
  let password = `${fortress[getRandomInt(0, 7)]}${uid(3)}-${uid(5)}`;
  let errorString = '';
  let formElement;

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function save(event) {
    event.preventDefault();
    if (!validateForm(formElement)) {
      return;
    }

    let url = `/api/user/${user.id}/change-password`;
    const method = 'POST';
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const request = {
      changePasswordAtNextSignin: forcePasswordChange,
      password,
    };

    const response = await apiFetch(url, method, request);
    if (response.ok) {
      show = false;
      const item = await response.json();
      const msg = { item, password };
      dispatch('save', msg);
    } else if (response.status === 409) {
      const err = await response.json();
      errorString = $_(`changePassword.fail.${err.reason}`);
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{$_('administration.users.change-password')} - {user.email}</h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <Switch bind:checked={forcePasswordChange} color="secondary">
          {$_('administration.users.force-password-change')}
        </Switch>
        <TextField
          bind:value={password}
          class="pb-2"
          outlined={true}
          dense
          clearable
          color={'secondary'}
          required
        >
          {$_('administration.users.newPassword')}
        </TextField>
        {#if errorString.length > 0}
          <span class="error-text">{errorString}</span>
        {/if}
        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          on:click={save}
          text
          disabled={!password}
          class={password ? 'secondary-color' : 'grey-color'}
        >
          {$_('administration.users.save')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('administration.users.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
