<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider, Select } from 'svelte-materialify';
  import {
    _,
    getTrademarkKindSelectOptions,
    getTrademarkStateSelectOptions,
  } from '../../services/i18n';
  import { validateForm } from '../../services/validation';
  import { apiFetch } from '../../services/network';
  import DateField from '../DateField.svelte';
  import TextField from '../TextField.svelte';
  import SelectClasses from './SelectClasses.svelte';
  import SelectCountryV2 from '../SelectCountryV2.svelte';

  const dispatch = createEventDispatcher();
  export let show;

  const today = new Date().toISOString().split('T')[0];

  export let trademark = {
    name: '',
    nickName: '',
    lawFirmIdentifier: '',
    trademarkKind: 'word',
    classes: '',
    country: '',
    status: 'not-yet-filed',
    applicationDate: '',
    applicationNumber: '',
    registrationDate: '',
    registrationNumber: '',
    nextAction: '',
    actionDueDate: '',
  };

  const newTrademark = {};
  Object.assign(newTrademark, trademark);

  $: saveDisabled = !newTrademark.trademarkKind || !newTrademark.status || !newTrademark.name;

  let formElement;
  let classesElement;
  let trademarkNameField;
  let trademarkNickNameField;
  let trademarkLawFirmIdField;
  let trademarkApplicationNumberField;
  let trademarkRegistrationNumberField;
  let trademarkNextActionField;
  let isFocused = false;

  afterUpdate(async () => {
    if (!isFocused && !trademark.name) {
      trademarkNameField.focus();
      isFocused = true;
    }
  });

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function save(event) {
    event.preventDefault();
    Object.assign(trademark, newTrademark);
    if (!validateForm(formElement)) {
      return;
    }

    let url = `/api/trademark`;
    let method = 'POST';
    if (trademark.id) {
      url += `/${trademark.id}`;
      method = 'PUT';
    }
    const request = {};
    for (const property in newTrademark) {
      if (newTrademark[property] != null && property !== 'id') {
        request[property] = newTrademark[property];
      }
    }
    saveDisabled = true;
    const response = await apiFetch(url, method, request);
    saveDisabled = !newTrademark.trademarkKind || !newTrademark.status || !newTrademark.name;
    if (response.ok) {
      const item = await response.json();
      dispatch('save', { item });
      show = false;
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      {#if trademark.id}
        <h6 class="secondary-text">{$_('trademark.edit')} {$_('trademark.singular')}</h6>
      {:else}
        <h6 class="secondary-text">{$_('trademark.new')} {$_('trademark.singular')}</h6>
      {/if}
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <Select
          outlined
          dense
          items={getTrademarkKindSelectOptions()}
          bind:value={newTrademark.trademarkKind}
          class="pb-2"
          mandatory
        >
          {$_('trademark.table.kind')}
        </Select>
        <Select
          outlined
          dense
          items={getTrademarkStateSelectOptions()}
          bind:value={newTrademark.status}
          class="pb-2"
          mandatory
        >
          {$_('trademark.table.status')}
        </Select>
        <TextField
          bind:value={newTrademark.name}
          bind:inputElement={trademarkNameField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          required
        >
          {$_('trademark.table.title')}
        </TextField>
        <TextField
          bind:value={newTrademark.nickName}
          bind:inputElement={trademarkNickNameField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('trademark.table.nickName')}
        </TextField>
        <TextField
          bind:value={newTrademark.lawFirmIdentifier}
          bind:inputElement={trademarkLawFirmIdField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('trademark.table.lawFirmId')}
        </TextField>
        <SelectCountryV2
          bind:value={newTrademark.country}
          class="pb-2"
        />
        <SelectClasses bind:classes={newTrademark.classes} />
        <DateField
          bind:value={newTrademark.applicationDate}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          clearable
        >
          {$_('trademark.table.applicationDate')}
        </DateField>
        <TextField
          bind:value={newTrademark.applicationNumber}
          bind:inputElement={trademarkApplicationNumberField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('trademark.table.applicationNumber')}
        </TextField>
        <DateField
          bind:value={newTrademark.registrationDate}
          bind:min={newTrademark.filingDate}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          clearable
        >
          {$_('trademark.table.registrationDate')}
        </DateField>
        <TextField
          bind:value={newTrademark.registrationNumber}
          bind:inputElement={trademarkRegistrationNumberField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('trademark.table.registrationNumber')}
        </TextField>
        <TextField
          bind:value={newTrademark.nextAction}
          bind:inputElement={trademarkNextActionField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
        >
          {$_('trademark.table.nextAction')}
        </TextField>
        <DateField
          bind:value={newTrademark.actionDueDate}
          min={today}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          clearable
        >
          {$_('trademark.table.actionDueDate')}
        </DateField>

        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          on:click={save}
          text
          bind:disabled={saveDisabled}
          class="mt-4 {saveDisabled ? 'grey-color' : 'secondary-color'}"
        >
          {$_('trademark.save')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('trademark.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
