<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider, Select } from 'svelte-materialify';
  import { _ } from 'svelte-i18n';
  import { validateForm } from '../../services/validation';
  import { apiFetch } from '../../services/network';
  import DateField from '../DateField.svelte';
  import TextField from '../TextField.svelte';

  const dispatch = createEventDispatcher();
  export let show;
  export let contractId;
  export let contractParty = {
    partyType: 'individual',
    partyName: '',
    signatoryName: '',
    signedDate: '',
  };

  const newContractParty = {};
  Object.assign(newContractParty, contractParty);
  let formElement;
  let contractPartyNameField;
  let contractPartySignatoryNameField;
  let isFocused = false;

  afterUpdate(async () => {
    if (!isFocused && !contractParty.signatoryName) {
      contractPartySignatoryNameField.focus();
      isFocused = true;
    }
  });

  const partyTypeItems = [
    { name: $_('contract.parties.individual'), value: 'individual' },
    { name: $_('contract.parties.company'), value: 'company' },
  ];

  const today = new Date().toISOString().split('T')[0];

  $: filledPartyName =
    newContractParty.partyType === 'individual' ? true : newContractParty.partyName != '';
  $: saveDisabled = !newContractParty.partyType || !filledPartyName;

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function save(event) {
    event.preventDefault();
    Object.assign(contractParty, newContractParty);
    if (contractParty.partyType === 'individual') {
      contractParty.partyName = contractParty.signatoryName;
      newContractParty.partyName = newContractParty.signatoryName;
    }
    if (!validateForm(formElement)) {
      return;
    }

    let url = `/contract/${contractId}/party`;
    let method = 'POST';
    if (contractParty.id) {
      url += `/${contractParty.id}`;
      method = 'PUT';
    }
    const request = {};
    for (const property in newContractParty) {
      if (
        newContractParty[property] != null &&
        newContractParty[property].length > 0 &&
        newContractParty !== 'id'
      ) {
        request[property] = newContractParty[property];
      }
    }
    saveDisabled = true;
    const response = await apiFetch(url, method, request);
    saveDisabled = !newContractParty.partyType || !filledPartyName;
    if (response.ok) {
      show = false;
      const partyList = await response.json();
      dispatch('save', { partyList });
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      {#if contractParty.id}
        <h6 class="secondary-text">{$_('contract.edit')} {$_('contract.parties.singular')}</h6>
      {:else}
        <h6 class="secondary-text">{$_('contract.new')} {$_('contract.parties.singular')}</h6>
      {/if}
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <Select
          bind:value={newContractParty.partyType}
          class="pb-2"
          outlined={true}
          dense
          items={partyTypeItems}
          mandatory
        >
          {$_('contract.parties.party-type')}
        </Select>
        {#if contractParty.partyType === 'company'}
          <TextField
            bind:value={newContractParty.partyName}
            bind:inputElement={contractPartyNameField}
            class="pb-2"
            outlined={true}
            dense
            color={'secondary'}
            required
          >
            {$_('contract.parties.party-name')}
          </TextField>
        {/if}
        <TextField
          bind:value={newContractParty.signatoryName}
          bind:inputElement={contractPartySignatoryNameField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          required
        >
          {$_('contract.parties.signatory-name')}
        </TextField>
        <DateField
          bind:value={newContractParty.signedDate}
          class="pb-2"
          outlined={true}
          dense
          clearable
          color={'secondary'}
        >
          {$_('contract.parties.signed-date')}
        </DateField>

        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          on:click={save}
          text
          disabled={saveDisabled}
          class="mt-4 {saveDisabled ? 'grey-color' : 'secondary-color'}"
        >
          {$_('contract.save')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('contract.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
