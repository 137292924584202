let countries;

export async function initializeCountries() {
  if (!countries) {
    const countriesResponse = await fetch('/countries2.json');
    if (countriesResponse.ok) {
      countries = await countriesResponse.json();
    }
  }
}

export async function lookupCountry(countryCode) {
  // In case the countries haven't been initialized yet
  await initializeCountries();
  return countries.find(country => country.code === countryCode)?.name ?? countryCode ?? '';
}