<script>
  import { mdiAccountBoxMultiple, mdiAccountGroup, mdiCogOutline } from '@mdi/js';
  import { createEventDispatcher, onMount } from 'svelte';
  import {
    Breadcrumbs,
    Container,
    ExpansionPanel,
    ExpansionPanels,
    Icon,
    ListItem,
    ProgressLinear,
    Radio,
    Row,
    Slider,
    Snackbar,
  } from 'svelte-materialify';
  import { Route } from 'tinro';
  import {
    accountHasTenantPermission,
    SYSTEM_WIDE_CLIENT_ADMIN,
    SYSTEM_WIDE_TENANT_ADMIN,
    SYSTEM_WIDE_USER_ADMIN,
  } from '../../../services/account';
  import { _ } from '../../../services/i18n';
  import { apiFetch } from '../../../services/network';
  import Clients from '../Clients/Clients.svelte';
  import Users from '../Users/Users.svelte';

  export let tenantId;
  export let initialBreadCrumbItems = [];
  let breadCrumbItems = [];
  let tenant;
  const dispatch = createEventDispatcher();
  const selectedItem = {};
  const users = [];
  let activePanels = [0];
  let group = 1;
  let timeout = getTwofaTimeout();
  let showSnackBar = false;
  const snackBarText = '';

  onMount(() => {});

  async function load() {
    const url = `/api/tenant/${tenantId}`;
    const response = await apiFetch(url);
    if (response.ok) {
      tenant = await response.json();
      breadCrumbItems = initialBreadCrumbItems.concat([
        {
          text: `${$_('administration.tenants.singular')} - ${tenant.name}`,
          href: `/administration/tenant/${tenantId}`,
        },
      ]);
    }
  }

  async function turnOff2fa(event) {
    const url = `/tenant-settings/2fa/off/${tenantId}`;
    const method = 'PUT';
    await apiFetch(url, method);
  }

  async function turnOnOTP(event) {
    const url = `/tenant-settings/2fa/otp/${tenantId}`;
    const method = 'PUT';
    await apiFetch(url, method);
  }

  async function getTwofaTimeout() {
    const url = `/tenant-settings/timeout/${tenantId}`;
    const method = 'GET';
    const response = await apiFetch(url, method);
    if (response.ok) {
      const tmp = await response.json();
      timeout = tmp.timeout;
    } else {
      timeout = 7;
    }
  }
  async function setTwofaTimeout(event) {
    const url = `/tenant-settings/timeout/${timeout}/${tenantId}`;
    const method = 'PUT';
    const response = await apiFetch(url, method);
    getTwofaTimeout();
  }
  async function setTwofaRadioButton() {
    const url = `/tenant-settings/2fa/${tenantId}`;
    const method = 'GET';
    let settings = 'off';
    const response = await apiFetch(url, method);
    if (response.ok) {
      settings = await response.json();
    }
    if (settings.twofa == 'otp') {
      group = 2;
    }
  }
</script>

{#await load()}
  <ProgressLinear />
{:then}
  <Route path="/">
    <Breadcrumbs items={breadCrumbItems} />
    {#if accountHasTenantPermission(SYSTEM_WIDE_USER_ADMIN)}
      <a class="secondary-text" href={`/administration/tenant/${tenantId}/user`}>
        <ListItem>
          <span class="secondary-text" slot="prepend">
            <Icon class="secondary-text" path={mdiAccountBoxMultiple} />{$_(
              'administration.users.title'
            )}
          </span>
        </ListItem>
      </a>
    {/if}
    {#if accountHasTenantPermission(SYSTEM_WIDE_CLIENT_ADMIN)}
      <a class="secondary-text" href={`/administration/tenant/${tenantId}/client`}>
        <ListItem>
          <span class="secondary-text" slot="prepend">
            <Icon class="secondary-text" path={mdiAccountGroup} />{$_(
              'administration.clients.title'
            )}
          </span>
        </ListItem>
      </a>
    {/if}
    {#if accountHasTenantPermission(SYSTEM_WIDE_TENANT_ADMIN)}
      {#await setTwofaRadioButton()}
        <ProgressLinear />
      {:then}
        <ExpansionPanels class="pt-2" multiple bind:value={activePanels}>
          <ExpansionPanel>
            <span slot="header" class="secondary-text"
              ><!---->
              <Icon class="secondary-text" path={mdiCogOutline} />
              {$_('administration.settings.title')}
            </span>
            <Container>
              <span class="secondary-text" style="text-align: right;">
                {$_('administration.settings.twofa')}
              </span>
              <Row>
                <div class="d-flex justify-space-around pl-3 pt-3" on:click={turnOff2fa}>
                  <Radio color="secondary" bind:group value={1}
                    >{$_('administration.settings.off')}</Radio
                  >
                </div>
              </Row>
              <Row>
                <div class="d-flex justify-space-around pl-3 pt-3" on:click={turnOnOTP}>
                  <Radio color="secondary" bind:group value={2}
                    >{$_('administration.settings.otp')}</Radio
                  >
                </div>
              </Row>
            </Container>
            {#if group != 1}
              <Container>
                <span class="secondary-text">
                  {$_('administration.settings.timeout')}
                </span>
                <Row>
                  <p class="d-flex justify-space-around pl-4 pb-4">
                    {$_('administration.settings.about')}
                  </p>
                </Row>
                <Row>
                  <p class="pl-3 pt-3">0</p>
                  <Slider
                    thumb
                    persistentThumb
                    thumbClass="secondary-color"
                    class="secondary-color pt-6"
                    color="secondary"
                    min={0}
                    max={90}
                    bind:value={timeout}
                    on:change={setTwofaTimeout}
                  />
                  <p class="pl-3 pt-3">90</p>
                </Row>
              </Container>
            {/if}
          </ExpansionPanel>
        </ExpansionPanels>
      {/await}
    {/if}
  </Route>

  <Route path="/user/*">
    <Users {tenant} tenantId={tenant.id} initialBreadCrumbItems={breadCrumbItems} />
  </Route>

  <Route path="/client/*">
    <Clients tenantId={tenant.id} initialBreadCrumbItems={breadCrumbItems} />
  </Route>
{/await}
<Snackbar class="flex-column" bind:active={showSnackBar} timeout={2000} top right>
  <div>{snackBarText}</div>
</Snackbar>

<style>
</style>
