<script>
  import { createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider, Icon } from 'svelte-materialify';
  import { mdiDelete } from '@mdi/js';
  import { _ } from '../../services/i18n';
  import { apiFetch } from '../../services/network';

  const dispatch = createEventDispatcher();
  export let show;
  export let contract;
  export let contractDate;

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function deleteDate(event) {
    event.preventDefault();

    const url = `/contract/${contract.id}/date/${contractDate.id}`;
    const options = {
      method: 'DELETE',
    };
    const response = await apiFetch(url, 'DELETE');
    if (response.ok) {
      show = false;
      const dateList = await response.json();
      dispatch('delete', { dateList });
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('contract.delete')}
        {$_('contract.dates.singular')} - {contractDate.description}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />

      <div class="pt-4">
        <h6>
          {$_('contract.dates.delete-confirmation')}
        </h6>
      </div>

      <form>
        <Button type="submit" class="mt-4 error-color" on:click={deleteDate} text>
          <Icon path={mdiDelete} />
          {$_('contract.delete')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('contract.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
