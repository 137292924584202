<script>
  import { mdiCheckOutline, mdiExclamationThick } from '@mdi/js';
  import { ExpansionPanel, ExpansionPanels, Icon } from 'svelte-materialify';
  import { _ } from '../../services/i18n';
  import ImportErrorList from './ImportErrorList.svelte';
  import ImportSuccessList from './ImportSuccessList.svelte';

  /**
   * @param errors - array: the list of records that could not be created
   */
  export let errors;

  /**
   * @param successfulPatents - array: the list of successfully created Patents
   */
  export let successfulPatents;

  /**
   * @param successfulTrademarks - array: the list of successfully created Trademarks
   */
  export let successfulTrademarks;

  /**
   * @param successfulContracts - array: the list of successfully created Contracts
   */
  export let successfulContracts;

  /**
   * @param timestamp - Date: the time the import ran
   */
  export let timestamp;

  let value = [0];

  $: showErrorPanel = errors.length > 0;
  $: showPatentSuccessPanel = successfulPatents.length > 0;
  $: showTrademarkSuccessPanel = successfulTrademarks.length > 0;
  $: showContractSuccessPanel = successfulContracts.length > 0;
</script>

<ExpansionPanels multiple accordion bind:value>
  <ExpansionPanel>
    <div slot="header" class="secondary-text">
      <Icon class="success-text" path={mdiCheckOutline} />
      <span> {$_('ip-import.results-title')} {timestamp} </span>
    </div>
    <ExpansionPanels multiple accordion>
      {#if showErrorPanel}
        <ExpansionPanel>
          <div slot="header" class="secondary-text">
            <Icon class="error-text" path={mdiExclamationThick} />
            <span>
              {errors.length}
              {$_('ip-import.errors.header')}
            </span>
          </div>
          <ImportErrorList {errors} />
        </ExpansionPanel>
      {/if}
      {#if showPatentSuccessPanel}
        <ExpansionPanel>
          <div slot="header" class="secondary-text">
            <Icon class="success-text" path={mdiCheckOutline} />
            <span>
              {successfulPatents.length}
              {$_('ip-import.successes.patents-header')}
            </span>
          </div>
          <ImportSuccessList successes={successfulPatents} />
        </ExpansionPanel>
      {/if}
      {#if showTrademarkSuccessPanel}
        <ExpansionPanel>
          <div slot="header" class="secondary-text">
            <Icon class="success-text" path={mdiCheckOutline} />
            <span>
              {successfulTrademarks.length}
              {$_('ip-import.successes.trademarks-header')}
            </span>
          </div>
          <ImportSuccessList successes={successfulTrademarks} />
        </ExpansionPanel>
      {/if}
      {#if showContractSuccessPanel}
        <ExpansionPanel>
          <div slot="header" class="secondary-text">
            <Icon class="success-text" path={mdiCheckOutline} />
            <span>
              {successfulContracts.length}
              {$_('ip-import.successes.contracts-header')}
            </span>
          </div>
          <ImportSuccessList successes={successfulContracts} />
        </ExpansionPanel>
      {/if}
    </ExpansionPanels>
  </ExpansionPanel>
</ExpansionPanels>
