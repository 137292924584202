<script>
  import { router } from 'tinro';
  import {
    mdiDeleteSweepOutline,
    mdiFileMoveOutline,
    mdiFolderOpenOutline,
    mdiPencilOutline,
    mdiPlus,
  } from '@mdi/js';
  import Portal from 'svelte-portal';
  import { _, IP_TYPE_CONTRACT } from '../../services/i18n';
  import { accountHasPermissionForGroupAndType, GROUP_UPDATE } from '../../services/account';
  import ContractItems from './ContractItems.svelte';
  import { apiFetch } from '../../services/network';
  import AddOrUpdateContract from './AddOrUpdateContract.svelte';
  import AddContractsToGroup from './AddContractsToGroup.svelte';
  import DeleteContractFromGroup from './DeleteContractFromGroup.svelte';
  import MoveContractBetweenGroups from './MoveContractBetweenGroups.svelte';

  let currentItem;
  export let group;
  let showAddNewItemDialog = false;
  let showEditItemDialog = false;
  let showDeleteItemFromGroupDialog = false;
  let showEditGroupMembersDialog = false;
  let showMoveItemFromGroupDialog = false;
  let checkList = {};
  let contractItems;

  const rowActions = [
    {
      type: 'button',
      enabledFunction: () => true,
      href: `/contract/family/${group.id}/item/`,
      title: $_('contract.open'),
      path: mdiFolderOpenOutline,
      onClickEvent: 'viewButtonClicked',
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isUpdateGroupActionEnabled,
      href: '',
      title: $_('contract.edit'),
      path: mdiPencilOutline,
      onClickEvent: 'editButtonClicked',
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isUpdateGroupActionEnabled,
      href: '',
      title: $_('contract-group.move-from-group'),
      path: mdiFileMoveOutline,
      onClickEvent: 'moveFromGroupClicked',
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isUpdateGroupActionEnabled,
      href: '',
      title: $_('contract-group.remove-from-group'),
      path: mdiDeleteSweepOutline,
      onClickEvent: 'removeFromGroupClicked',
      value: '',
    },
  ];

  const dropDownActions = [
    {
      enabledFunction: isUpdateGroupActionEnabled,
      href: '',
      title: $_('contract-group.add-new'),
      onClickEvent: 'addNewClicked',
      text: $_('contract-group.add-new'),
      dropdown: false,
    },
    {
      enabledFunction: isUpdateGroupActionEnabled,
      href: '',
      title: $_('contract-group.add-existing'),
      onClickEvent: 'addItemToGroupClicked',
      text: $_('contract-group.add-existing'),
      dropdown: false,
    },
  ];
  // same as rowActions but buttons go above the datatable
  const globalActions = [
    {
      enabledFunction: isUpdateGroupActionEnabled,
      href: '',
      title: $_('contract-group.add'),
      path: mdiPlus,
      onClickEvent: '',
      elementId: 'contract-items-in-family',
      text: $_('contract-group.add'),
      dropdown: true,
      dropdownActions: dropDownActions,
    },
  ];

  const linkActions = [
    {
      enabledFunction: () => true,
      href: `/contract/family/${group.id}/item/`,
      title: $_('contract.open'),
      onClickEvent: 'viewButtonClicked',
      elementId: 'contract-items-in-family',
    },
  ];

  function isUpdateGroupActionEnabled(group) {
    return accountHasPermissionForGroupAndType(GROUP_UPDATE, group, IP_TYPE_CONTRACT, group.owner);
  }

  // creted by Quinn to make the button I added make a post request.
  async function report() {
    const url = `/api/report/contract`;
    const element = document.createElement('a');
    element.href = url;
    element.setAttribute('download', '');
    element.setAttribute('tinro-ignore', '');
    element.click();
  }

  async function updatedItemSaved(event) {
    const { item } = event.detail;
    contractItems.updateRow(item);
  }

  function updateCurrentItem(event) {
    currentItem = event.detail.item;
    const { id } = currentItem;
    contractItems.updateRow(id);
  }

  function newItemClicked(event) {
    currentItem = event.detail.item;
    showAddNewItemDialog = true;
  }

  async function newItem(event) {
    const { item } = event.detail;
    group.items.push(item);
    const response = await apiFetch(`/api/contract/group/${group.id}/item/${item.id}`, 'POST');
    if (response.ok) {
      router.goto(`/contract/family/${group.id}/item/${item.id}/`);
      contractItems.addNewRows([item]);
    }
  }

  function newItemsAdded(event) {
    const { item } = event.detail;
    const add = [];
    const ids = [];
    for (let i = 0; i < group.items.length; i++) {
      ids.push(group.items[i].id);
    }
    for (let j = 0; j < item.items.length; j++) {
      if (!ids.includes(item.items[j].id)) {
        add.push(item.items[j]);
        group.items.push(item.items[j]);
      }
    }
    contractItems.addNewRows(add);
  }

  async function removeItemFromGroup(event) {
    const { id } = event.detail;
    contractItems.deleteRows(id);
    for (let i = 0; i < group.items.length; i++) {
      if (id === group.items[i].id) {
        group.items.splice(i, 1);
      }
    }
  }

  function addItemToGroupClicked() {
    showEditGroupMembersDialog = true;
  }

  function viewButtonClicked(event) {
    const { href } = event.detail.action;
    router.goto(href + event.detail.id);
  }

  function editButtonClicked(event) {
    currentItem = event.detail.data;
    showEditItemDialog = true;
  }

  function removeFromGroupClicked(event) {
    currentItem = event.detail.data;
    showDeleteItemFromGroupDialog = true;
  }
  function moveFromGroupClicked(event) {
    currentItem = event.detail.data;
    showMoveItemFromGroupDialog = true;
  }

  function checked(event) {
    let eventElement;
    const eventDetail = { item: event.detail.item };
    const newEvent = new CustomEvent('checked', { detail: eventDetail });
    if (!eventElement) {
      eventElement = document.querySelector('#add-contract-to-group');
    }
    eventElement.dispatchEvent(newEvent);
  }
</script>

<div
  id="contract-items-in-family"
  on:updateCurrentItem={updateCurrentItem}
  on:updatedItemSaved={updatedItemSaved}
  on:addNewClicked={newItemClicked}
  on:addItemToGroupClicked={addItemToGroupClicked}
>
  <ContractItems
    bind:this={contractItems}
    tableName="contract-infamily"
    {rowActions}
    {globalActions}
    {linkActions}
    bind:group
    bind:currentItem
    on:viewButtonClicked={viewButtonClicked}
    on:editButtonClicked={editButtonClicked}
    on:removeFromGroupClicked={removeFromGroupClicked}
    on:moveFromGroupClicked={moveFromGroupClicked}
    on:checked={checked}
  />
</div>

<Portal target=".s-app">
  {#if showAddNewItemDialog}
    <AddOrUpdateContract bind:show={showAddNewItemDialog} on:save={newItem} />
  {/if}
  {#if showEditItemDialog}
    <AddOrUpdateContract
      bind:show={showEditItemDialog}
      contract={currentItem}
      on:save={updateCurrentItem}
    />
  {/if}
  {#if showDeleteItemFromGroupDialog}
    <DeleteContractFromGroup
      bind:show={showDeleteItemFromGroupDialog}
      groupId={group.id}
      contract={currentItem}
      on:delete={removeItemFromGroup}
    />
  {/if}
  {#if showEditGroupMembersDialog}
    <AddContractsToGroup
      bind:show={showEditGroupMembersDialog}
      bind:checkList
      bind:group
      on:save={newItemsAdded}
    />
  {/if}
  {#if showMoveItemFromGroupDialog}
    <MoveContractBetweenGroups
      bind:show={showMoveItemFromGroupDialog}
      bind:groupId={group.id}
      item={currentItem}
      on:delete={removeItemFromGroup}
    />
  {/if}
</Portal>
