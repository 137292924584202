<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import {
    Button,
    Col,
    Container,
    ExpansionPanel,
    ExpansionPanels,
    Icon,
    ProgressLinear,
    Row,
  } from 'svelte-materialify';
  import {
    mdiAlertOctagonOutline,
    mdiCalendarOutline,
    mdiCheck,
    mdiDeleteOutline,
    mdiPencilOutline,
    mdiPlus,
    mdiSignature,
  } from '@mdi/js';
  import { _, IP_SCOPE_ITEM, IP_TYPE_CONTRACT } from '../../services/i18n';
  import { apiFetch, setSelectedClientByTenant } from '../../services/network';
  import { accountHasPermissionForItemAndType, ITEM_UPDATE } from '../../services/account';
  import AddOrUpdateContract from './AddOrUpdateContract.svelte';
  import FilesContainer from '../FilesContainer.svelte';
  import AddOrUpdateContractDate from './AddOrUpdateContractDate.svelte';
  import AddOrUpdateParty from './AddOrUpdateParty.svelte';
  import DeleteContractDate from './DeleteContractDate.svelte';
  import DeleteParty from './DeleteParty.svelte';
  import Notes from '../Notes.svelte';
  import UrlBreadcrumb from '../UrlBreadcrumb.svelte';
  import CopyUrl from '../CopyURL.svelte';

  export let contractId;
  export let name = '';

  let contract;
  let detailsPanel = [0];
  let activePanels = [0, 1, 2, 3];
  const dispatch = createEventDispatcher();
  let breadCrumbItems = [];
  let attachments = [];
  let showUpdateDialog = false;
  let showNewDateDialog = false;
  let showUpdateDateDialog = false;
  let showDeleteDateDialog = false;
  let currentDateItem = null;
  let showNewPartyDialog = false;
  let showUpdatePartyDialog = false;
  let showDeletePartyDialog = false;
  let currentPartyItem = null;
  let signedCount = 0;
  let executedCount = 0;

  $: {
    if (attachments.length >= 0) {
      executedCount = countExecuted();
    }
  }

  const today = new Date().toISOString().split('T')[0];
  onMount(() => {});

  async function load() {
    const url = `/contract/${contractId}`;
    const response = await apiFetch(url);
    if (response.ok) {
      contract = await response.json();
      setSelectedClientByTenant(contract.tenant);
      if (name.length === 0) {
        name = contract.name;
      }
      breadCrumbItems = [
        { text: $_('contract.title'), href: '/contract/item' },
        { text: `${$_('contract.singular')} - ${contract.name}` },
      ];
      signedCount = countSigned();
    }
  }

  async function loadAttachments() {
    const url = `/contract/${contractId}/attachment`;
    const response = await apiFetch(url);
    if (response.ok) {
      attachments = await response.json();
      executedCount = countExecuted();
    }
  }

  function groups() {
    let str = '';
    for (const group of contract.groups) {
      str += `"${group.name}" `;
    }
    return str;
  }

  function formatUser(userProfile) {
    return `${userProfile.firstName} ${userProfile.lastName} - ${userProfile.tenant.name}`;
  }

  function owner() {
    return formatUser(contract.owner);
  }

  function originator() {
    return formatUser(contract.originator);
  }

  function editOwner(event) {}

  function updateItemButtonClicked(event) {
    showUpdateDialog = true;
  }

  function itemSaved(event) {
    contract = event.detail.item;
    dispatch('itemUpdated', { item: contract });
  }

  function newMilestoneDateButtonClicked(event) {
    showNewDateDialog = true;
  }

  function newPartyButtonClicked(event) {
    showNewPartyDialog = true;
  }

  function editDateButtonClicked(event) {
    const element = event.currentTarget;
    const id = Number.parseInt(element.dataset.id);
    currentDateItem = contract.milestoneDates.find((d) => d.id === id);
    showUpdateDateDialog = true;
  }

  function editPartyButtonClicked(event) {
    const element = event.currentTarget;
    const id = Number.parseInt(element.dataset.id);
    currentPartyItem = contract.parties.find((p) => p.id === id);
    showUpdatePartyDialog = true;
  }

  function deleteDateButtonClicked(event) {
    const element = event.currentTarget;
    const id = Number.parseInt(element.dataset.id);
    currentDateItem = contract.milestoneDates.find((d) => d.id === id);
    showDeleteDateDialog = true;
  }

  function deletePartyButtonClicked(event) {
    const element = event.currentTarget;
    const id = Number.parseInt(element.dataset.id);
    currentPartyItem = contract.parties.find((p) => p.id === id);
    showDeletePartyDialog = true;
  }

  function dateDeleted(event) {
    contract.milestoneDates = event.detail.dateList;
  }

  function updatedDateSaved(event) {
    contract.milestoneDates = event.detail.dateList;
  }

  function partyDeleted(event) {
    contract.parties = event.detail.partyList;
    signedCount = countSigned();
  }

  function updatedPartySaved(event) {
    contract.parties = event.detail.partyList;
    signedCount = countSigned();
  }

  function countSigned() {
    if (!contract.parties) {
      return 0;
    }
    const signed = contract.parties.filter((p) => p.signedDate && p.signedDate.length > 0);
    return signed.length;
  }

  function countExecuted() {
    const executed = attachments.filter((a) => a.folderSymbol === 'executed-version');
    return executed.length;
  }

  async function changeStatusToExecuted(event) {
    contract.contractStatus = 'executed';
    const url = `/contract/${contractId}`;
    const request = {
      name: contract.name,
      contractType: contract.contractType,
      contractStatus: 'executed',
    };
    const response = await apiFetch(url, 'PUT', request);
    if (response.ok) {
      contract.contractStatus = 'executed';
    }
  }
</script>

{#if name.length === 0}
  <h5 class="secondary-text text-center">{$_('contract.singular')}</h5>
{/if}
{#await load()}
  <ProgressLinear />
{:then}
  <Container>
    <Row>
      <Col class="col-12">
        <ExpansionPanels accordion bind:value={detailsPanel}>
          <ExpansionPanel>
            <div slot="header">
              <span>
                <UrlBreadcrumb {name} name2={contract.name} />
              </span>
            </div>
            <Container>
              <Button
                icon
                disabled={!accountHasPermissionForItemAndType(
                  ITEM_UPDATE,
                  contract,
                  IP_TYPE_CONTRACT
                )}
                class={accountHasPermissionForItemAndType(ITEM_UPDATE, contract, IP_TYPE_CONTRACT)
                  ? 'secondary-color'
                  : ''}
                on:click={updateItemButtonClicked}
                title={$_('contract.edit')}
              >
                <Icon class="text-white" path={mdiPencilOutline} />
              </Button>
              <CopyUrl />
              <div class="pl-3">
                <Row>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('contract.table.title')}: </span>
                    </Row>
                    <Row>
                      {contract.name ?? ''}
                    </Row>
                  </Col>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('contract.table.description')}: </span>
                    </Row>
                    <Row>
                      {contract.description ?? ''}
                    </Row>
                  </Col>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('contract.table.type')}: </span>
                    </Row>
                    <Row>
                      {$_(`contract.types.${contract.contractType}`) ?? ''}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('contract.table.status')}: </span>
                    </Row>
                    <Row>
                      {$_(`contract.states.${contract.contractStatus}`) ?? ''}
                    </Row>
                  </Col>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('patent.table.lawFirmId')}: </span>
                    </Row>
                    <Row>
                      {contract.lawFirmIdentifier ?? ''}
                    </Row>
                  </Col>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('patent.table.nickName')}: </span>
                    </Row>
                    <Row>
                      {contract.nickName ?? ''}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('contract.table.effective-date')}: </span>
                    </Row>
                    <Row>
                      {contract.effectiveDate ?? ''}
                    </Row>
                  </Col>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('contract.table.expiry-date')}: </span>
                    </Row>
                    <Row>
                      {contract.expiryDate ?? ''}
                      <span slot="append-outer">
                        {#if !contract.expiryDate || contract.expiryDate.length === 0 || contract.expiryDate.localeCompare(today) === -1}
                          <Icon class="error-text" path={mdiAlertOctagonOutline} />
                        {:else}
                          <Icon class="success-text" path={mdiCheck} />
                        {/if}
                      </span>
                    </Row>
                  </Col>
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('contract.originator')}: </span>
                    </Row>
                    <Row>
                      {originator() ?? ''}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <!-- </Row> -->
                  <!-- <Row> -->
                  <Col class="col-4">
                    <Row>
                      <span class="font-weight-bold">{$_('patent.table.groups')}: </span>
                    </Row>
                    <Row>
                      {groups() ?? ''}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Container>
          </ExpansionPanel>
        </ExpansionPanels>
      </Col>
    </Row>
    <Row>
      <Col class="col-12">
        <ExpansionPanels bind:value={activePanels} multiple>
          <ExpansionPanel>
            <div slot="header" class="secondary-text">
              <span><Icon class="secondary-text" path={mdiCalendarOutline} /></span>
              <span>{$_('contract.dates.plural')}</span>
            </div>
            <div>
              <Button
                icon
                disabled={!accountHasPermissionForItemAndType(
                  ITEM_UPDATE,
                  contract,
                  IP_TYPE_CONTRACT
                )}
                class={accountHasPermissionForItemAndType(ITEM_UPDATE, contract, IP_TYPE_CONTRACT)
                  ? 'secondary-color mt-2 mb-2'
                  : 'secondary-color grey-text mt-2 mb-2'}
                on:click={newMilestoneDateButtonClicked}
                text
                title={$_('contract.new')}
                fab
                size="small"
              >
                <Icon path={mdiPlus} />
              </Button>
              <table classList="pt-4">
                <thead class="text-caption ">
                  <th style="width: 90px;" />
                  <th style="width: 300px;">{$_('contract.dates.description')}</th>
                  <th>{$_('contract.dates.date')}</th>
                  <th>{$_('contract.dates.reminder-date')}</th>
                </thead>
                <tbody class="text-caption font-weight-thin">
                  {#each contract.milestoneDates as contractDate}
                    <tr>
                      <td>
                        <Button
                          icon
                          title={$_('contract.edit')}
                          disabled={!accountHasPermissionForItemAndType(
                            ITEM_UPDATE,
                            contract,
                            IP_TYPE_CONTRACT
                          )}
                          class={accountHasPermissionForItemAndType(
                            ITEM_UPDATE,
                            contract,
                            IP_TYPE_CONTRACT
                          )
                            ? 'secondary-text'
                            : ''}
                          data-id={contractDate.id.toString()}
                          on:click={editDateButtonClicked}
                        >
                          <Icon path={mdiPencilOutline} />
                        </Button>
                        <Button
                          icon
                          title={$_('contract.delete')}
                          disabled={!accountHasPermissionForItemAndType(
                            ITEM_UPDATE,
                            contract,
                            IP_TYPE_CONTRACT
                          )}
                          class={accountHasPermissionForItemAndType(
                            ITEM_UPDATE,
                            contract,
                            IP_TYPE_CONTRACT
                          )
                            ? 'secondary-text'
                            : ''}
                          data-id={contractDate.id.toString()}
                          on:click={deleteDateButtonClicked}
                        >
                          <Icon path={mdiDeleteOutline} />
                        </Button>
                      </td>
                      <td>{contractDate.description ? contractDate.description : ''}</td>
                      <td>
                        {#if contractDate.date.length === 0 || contractDate.date.localeCompare(today) === -1}
                          <span class="error-text"
                            >{contractDate.date ? contractDate.date : ''}</span
                          >
                        {:else}
                          <span class="">{contractDate.date ? contractDate.date : ''}</span>
                        {/if}
                      </td>
                      <td>
                        {#if contractDate.reminderDate && (contractDate.reminderDate.length === 0 || contractDate.reminderDate.localeCompare(today) === -1)}
                          <span class="error-text"
                            >{contractDate.reminderDate ? contractDate.reminderDate : ''}</span
                          >
                        {:else}
                          <span class=""
                            >{contractDate.reminderDate ? contractDate.reminderDate : ''}</span
                          >
                        {/if}
                      </td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            </div>
          </ExpansionPanel>
          <ExpansionPanel>
            <div slot="header" class="secondary-text">
              <span><Icon class="secondary-text" path={mdiSignature} /></span>
              <span
                >{$_('contract.parties.plural')} - {signedCount}/{contract.parties.length}
                {$_('contract.parties.signed')}</span
              >
              {#if contract.parties.length > 1}
                {#if signedCount === contract.parties.length}
                  <Icon class="success-text" path={mdiCheck} />
                  {#if contract.contractStatus !== 'executed' && accountHasPermissionForItemAndType(ITEM_UPDATE, contract, IP_TYPE_CONTRACT)}
                    <Button class="success-color" size="small" on:click={changeStatusToExecuted}>
                      {$_('contract.parties.change-contract-status-to-executed')}
                    </Button>
                  {:else if executedCount < 1}
                    <span class="error-text">{$_('contract.parties.no-executed-files')}</span>
                  {/if}
                {:else}
                  <Icon class="error-text" path={mdiAlertOctagonOutline} />
                {/if}
              {/if}
            </div>
            <div>
              <Button
                icon
                disabled={!accountHasPermissionForItemAndType(
                  ITEM_UPDATE,
                  contract,
                  IP_TYPE_CONTRACT
                )}
                class={accountHasPermissionForItemAndType(ITEM_UPDATE, contract, IP_TYPE_CONTRACT)
                  ? 'secondary-color mt-2 mb-2'
                  : 'secondary-color grey-text mt-2 mb-2'}
                on:click={newPartyButtonClicked}
                text
                title={$_('contract.new')}
                fab
                size="small"
              >
                <Icon path={mdiPlus} />
              </Button>
              <table classList="pt-4">
                <thead class="text-caption ">
                  <th style="width: 90px;" />
                  <th style="width: 300px;">{$_('contract.parties.party-name')}</th>
                  <th>{$_('contract.parties.signatory-name')}</th>
                  <th>{$_('contract.parties.signed-date')}</th>
                </thead>
                <tbody class="text-caption font-weight-thin">
                  {#each contract.parties as party}
                    <tr>
                      <td>
                        <Button
                          icon
                          title={$_('contract.edit')}
                          disabled={!accountHasPermissionForItemAndType(
                            ITEM_UPDATE,
                            contract,
                            IP_TYPE_CONTRACT
                          )}
                          class={accountHasPermissionForItemAndType(
                            ITEM_UPDATE,
                            contract,
                            IP_TYPE_CONTRACT
                          )
                            ? 'secondary-text'
                            : ''}
                          data-id={party.id.toString()}
                          on:click={editPartyButtonClicked}
                        >
                          <Icon path={mdiPencilOutline} />
                        </Button>
                        <Button
                          icon
                          title={$_('contract.delete')}
                          disabled={!accountHasPermissionForItemAndType(
                            ITEM_UPDATE,
                            contract,
                            IP_TYPE_CONTRACT
                          )}
                          class={accountHasPermissionForItemAndType(
                            ITEM_UPDATE,
                            contract,
                            IP_TYPE_CONTRACT
                          )
                            ? 'secondary-text'
                            : ''}
                          data-id={party.id.toString()}
                          on:click={deletePartyButtonClicked}
                        >
                          <Icon path={mdiDeleteOutline} />
                        </Button>
                      </td>
                      <td>{party.partyName ? party.partyName : ''}</td>
                      <td>{party.signatoryName ? party.signatoryName : ''}</td>
                      <td>{party.signedDate ? party.signedDate : ''}</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            </div>
          </ExpansionPanel>
          {#await loadAttachments()}
            <ProgressLinear />
          {:then}
            <FilesContainer
              ipType={IP_TYPE_CONTRACT}
              ipScope={IP_SCOPE_ITEM}
              scopeId={contract.id}
              readonly={!accountHasPermissionForItemAndType(
                ITEM_UPDATE,
                contract,
                IP_TYPE_CONTRACT
              )}
              bind:attachments
            />
          {/await}
          <Notes
            ipType={IP_TYPE_CONTRACT}
            ipScope={IP_SCOPE_ITEM}
            scopeId={contract.id}
            readonly={!accountHasPermissionForItemAndType(ITEM_UPDATE, contract, IP_TYPE_CONTRACT)}
          />
        </ExpansionPanels>
      </Col>
    </Row>
  </Container>
{/await}

{#if showUpdateDialog}
  <AddOrUpdateContract bind:show={showUpdateDialog} {contract} on:save={itemSaved} />
{/if}

{#if showNewDateDialog}
  <AddOrUpdateContractDate
    bind:show={showNewDateDialog}
    contractId={contract.id}
    on:save={updatedDateSaved}
  />
{/if}
{#if showUpdateDateDialog}
  <AddOrUpdateContractDate
    bind:show={showUpdateDateDialog}
    contractId={contract.id}
    contractDate={currentDateItem}
    on:save={updatedDateSaved}
  />
{/if}
{#if showDeleteDateDialog}
  <DeleteContractDate
    bind:show={showDeleteDateDialog}
    {contract}
    contractDate={currentDateItem}
    on:delete={dateDeleted}
  />
{/if}

{#if showNewPartyDialog}
  <AddOrUpdateParty
    bind:show={showNewPartyDialog}
    contractId={contract.id}
    on:save={updatedPartySaved}
  />
{/if}
{#if showUpdatePartyDialog}
  <AddOrUpdateParty
    bind:show={showUpdatePartyDialog}
    contractId={contract.id}
    contractParty={currentPartyItem}
    on:save={updatedPartySaved}
  />
{/if}
{#if showDeletePartyDialog}
  <DeleteParty
    bind:show={showDeletePartyDialog}
    {contract}
    contractParty={currentPartyItem}
    on:delete={partyDeleted}
  />
{/if}

<style>
  table {
    border: 1px solid white;
    border-collapse: collapse;
  }
  thead > th:first-child {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }
  thead > th:last-child {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  th {
    border: 1px solid white;
    height: 41px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #1a76d2;
    color: white;
  }
  td {
    padding: 4px;
    border-bottom: 1px solid #1a76d2;
  }
</style>
