<script>
  import { onMount } from 'svelte';
  import WebViewer from '@pdftron/webviewer';
  import classnames from 'classnames';
  import { docViewerInstance, openedFile } from '../services/stores/doc-viewer';
  import { isSmAndDown } from '../services/stores/media-queries';

  const getLogo = (version) => {
    if (!['light', 'dark'].includes(version)) {
      throw new Error('Invalid logo theme version');
    }
    const logo = document.createElement('img');
    logo.src = `/images/logos/Fortress_RGB${version === 'dark' ? '_Reverse' : ''}.png`;
    logo.style.width = '80px';
    logo.style.marginLeft = '20px';
    logo.style.cursor = 'pointer';
    logo.style.verticalAlign = 'middle';
    return logo;
  };

  $: if ($openedFile) {
    $docViewerInstance.UI.loadDocument($openedFile.url, {
      withCredentials: true,
      extension: $openedFile.extension,
    });
    if (!$openedFile.canDownload) {
      $docViewerInstance.UI.disableElements(['downloadButton']);
      $docViewerInstance.UI.disableElements(['saveAsButton']);
      $docViewerInstance.UI.disableElements(['printButton']);
    }
    // When opening up a document, we have to remove the scrollbar from the
    // Fortress App, otherwise we'll have 2.
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }

  let isFullscreen = false;

  $: if ($openedFile) {
    // Before doing anything here, let's delete the fullscreen button to simplify the following logic
    $docViewerInstance.UI.setHeaderItems((header) => {
      header.getHeader('default').delete('fullScreenButton');
    });
    if (!$isSmAndDown) {
      // If we're not on mobile, we want to add an "expand" button to the header
      $docViewerInstance.UI.setHeaderItems((header) => {
        header
          .getHeader('default')
          .get('closeButton')
          .insertBefore({
            type: 'actionButton',
            img: isFullscreen ? 'icon-header-full-screen-exit' : 'icon-header-full-screen',
            dataElement: 'fullScreenButton',
            onClick: () => {
              isFullscreen = !isFullscreen;
            },
          });
      });
    }
  }

  onMount(async () => {
    WebViewer(
      {
        path: '/webviewer',
        disabledElements: ['ribbons', 'toggleNotesButton', 'toolsHeader'],
        licenseKey: import.meta.env.FORTRESS_PDFTRON_API_KEY,
      },
      document.querySelector('#viewer')
    ).then((_instance) => {
      _instance.UI.addEventListener(_instance.UI.Events.THEME_CHANGED, ({ detail: theme }) => {
        _instance.UI.setHeaderItems((header) => {
          header.shift();
          header.unshift({
            type: 'customElement',
            render: () => getLogo(theme),
          });
        });
      });
      _instance.UI.setHeaderItems((header) => {
        header.push({
          type: 'actionButton',
          img: 'icon-close',
          dataElement: 'closeButton',
          onClick: () => {
            $openedFile = undefined;
            isFullscreen = false;
          },
        });
        header.unshift(
          {
            type: 'customElement',
            render: () => getLogo('light'),
          },
          {
            type: 'spacer',
          }
        );
        return header;
      });
      docViewerInstance.set(_instance);
    });
  });
</script>

<div
  id="viewer"
  class={classnames(
    $openedFile ? 'd-block' : 'd-none',
    isFullscreen || $isSmAndDown ? 'full-screen-viewer' : 'viewer rounded elevation-1'
  )}
/>
<div
  id="overlay"
  class={$openedFile && !isFullscreen ? 'd-block' : 'd-none'}
  on:click={() => ($openedFile = undefined)}
/>

<style>
  #overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(17, 20, 24, 0.7);
    z-index: 90;
  }

  :global(.no-scroll) {
    overflow: hidden;
  }

  .viewer {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    height: 90vh;
    width: 90vw;
    z-index: 100;
  }

  .full-screen-viewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
</style>
