<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Col,
    Container,
    Dialog,
    Divider,
    Row,
    Select,
    TextField,
  } from 'svelte-materialify';
  import { _, getFolderSelectOptions } from '../services/i18n';
  import { validateForm } from '../services/validation';
  import { apiFetch } from '../services/network';
  // import TextField from '../../TextField.svelte';

  const dispatch = createEventDispatcher();

  export let show;
  export let url;
  export let name;
  export let ipType;
  export let ipScope;
  export let folderSymbol;

  let formElement;
  const fileNamePieces = name.split('.');
  let fileNameField;
  let isFocused = false;

  let baseName = fileNamePieces.slice(0, -1).join('.');
  const extension = fileNamePieces[fileNamePieces.length - 1];

  $: saveButtonDisabled = !baseName;
  function cancel(event) {
    show = false;
    dispatch('cancel');
  }
  afterUpdate(() => {
    if (!isFocused && !baseName) {
      fileNameField.focus();
      isFocused = true;
    }
  });

  async function save(event) {
    event.preventDefault();
    if (!validateForm(formElement)) {
      return;
    }

    const response = await apiFetch(url, 'PUT', {
      fileName: `${baseName}.${extension}`,
      folderSymbol,
    });
    if (response.ok) {
      show = false;
      const attachments = await response.json();
      dispatch('moved', { responsePayload: attachments });
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{$_('files.move-rename-file-dialog.title')}</h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <Container>
          <Row>
            <Col cols={10}>
              <TextField
                bind:value={baseName}
                bind:inputElement={fileNameField}
                outlined={true}
                dense
                clearable
                color={'secondary'}
                required
              >
                {$_('files.move-rename-file-dialog.file-name')}
              </TextField>
            </Col>
            <Col class="pt-5 pl-0" cols={2}>
              <span class="font-weight-regular text-body-1">.{extension}</span>
            </Col>
          </Row>
          <Row>
            <Col cols={10}>
              <Select
                outlined
                dense
                class="mt-2 mb-2"
                items={getFolderSelectOptions(ipType, ipScope)}
                bind:value={folderSymbol}
              >
                {$_('upload.dialog.folder')}
              </Select>
            </Col>
          </Row>
        </Container>
        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          class={saveButtonDisabled ? 'grey-color' : 'secondary-color'}
          disabled={saveButtonDisabled}
          on:click={save}
          text
        >
          {$_('files.move-rename-file-dialog.rename')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('files.move-rename-file-dialog.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
