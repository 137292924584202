<script>
  import { Button, Divider, Icon, List, ListItem, Menu, Tooltip } from 'svelte-materialify';
  import {
    mdiContentCopy,
    mdiDeleteOutline,
    mdiDownloadOutline,
    mdiEyeOutline,
    mdiFileMoveOutline,
    mdiMenu,
    mdiPencilOutline,
  } from '@mdi/js';
  import { createEventDispatcher } from 'svelte';
  import { _ } from '../../services/i18n';
  import { apiFetch, apiFormatUrl, selectedClientContact } from '../../services/network';
  import FileIcon from '../FileIcon.svelte';
  import DeleteFile from './DeleteFile.svelte';
  import RenameFile from './RenameFile.svelte';
  import MoveToFolder from './MoveToFolder.svelte';
  import StarburstWithTextIcon from '../StarburstWithTextIcon.svelte';
  import { openedFile, supportedFileTypes } from '../../services/stores/doc-viewer';
  import { hasPermissions } from '../../services/helpers/permissions';
  import { accountInfo } from '../../services/account';

  export let rootFileSystemNode;
  export let fileSystemNode;
  export let fileSystemGroupName;

  const fileUrl = `/api/file-system/file/${fileSystemNode.id}`;
  let showDeleteDialog = false;
  let showRenameDialog = false;
  let showMoveDialog = false;

  const dispatch = createEventDispatcher();

  async function downloadButtonClicked(event) {
    const a = document.createElement('a');
    a.href = apiFormatUrl(fileUrl);
    a.setAttribute('download', '');
    a.setAttribute('tinro-ignore', '');
    a.click();
  }

  async function fileRenamed(event) {
    const { fileSystemNode } = event.detail;
    showRenameDialog = false;
    dispatch('rename', { fileSystemNode });
  }

  async function deleteButtonClicked(event) {
    showDeleteDialog = true;
  }

  async function fileDeleted(event) {
    const { fileSystemNode } = event.detail;
    dispatch('delete', { fileSystemNode });
  }

  async function moveButtonClicked(event) {
    showMoveDialog = true;
  }

  async function copyButtonClicked(event) {
    const response = await apiFetch(`/api/file-system/file/${fileSystemNode.id}/copy`, 'POST');
    if (response.ok) {
      dispatch('copy', { fileSystemNode: await response.json() });
    }
  }

  async function fileMoved(event) {
    const { fileSystemNode } = event.detail;
    dispatch('delete', { fileSystemNode });
  }

  function formatBytes(bytes, decimals = 1) {
    if (bytes == 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${Number.parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }

  function formatDate(isoStr) {
    if (isoStr === '') {
      return '';
    }
    const d = Date.parse(isoStr);
    return new Intl.DateTimeFormat('fr-ca').format(d);
  }

  function formatTime(isoStr) {
    if (isoStr === '') {
      return '';
    }
    const d = Date.parse(isoStr);
    return new Intl.DateTimeFormat('fr-ca').format(d);
  }

  const hasPermissionForDataRoom = (action) =>
    hasPermissions(
      $selectedClientContact?.userProfile?.tenant?.id || $accountInfo.tenant.id,
      action,
      'data_room',
      fileSystemNode.rootNodeId
    );

  $: canViewFilePreview =
    hasPermissionForDataRoom('read') && supportedFileTypes.includes(fileSystemNode.mimeType);
</script>

<tr>
  <td style="width: 52px;min-width: 52px;">
    <Menu offsetX={true} style="width: 200px; word-wrap: break-word;flex: inherit">
      <div slot="activator">
        <Button icon title={$_('files.file-actions')}
          ><Icon class="secondary-text" path={mdiMenu} /></Button
        >
      </div>
      <List dense>
        <ListItem disabled style="white-space: pre-line;">
          <span slot="prepend"><FileIcon filename={fileSystemNode.name} /></span>
          <span style="white-space: pre-line;">{fileSystemNode.name}</span>
        </ListItem>
        <Divider />
        <ListItem
          class={canViewFilePreview ? 'secondary-text' : 'grey-text'}
          on:click={() =>
            openedFile.set({
              url: apiFormatUrl(fileUrl),
              extension: fileSystemNode.storagePath.split('.').pop(),
              canDownload: hasPermissionForDataRoom('download'),
            })}
          disabled={!canViewFilePreview}
        >
          <span slot="prepend"
            ><Icon
              class={canViewFilePreview ? 'secondary-text' : 'grey-text'}
              path={mdiEyeOutline}
            /></span
          >
          {$_('files.view')}
        </ListItem>
        <ListItem
          class={hasPermissionForDataRoom('download') ? 'secondary-text' : 'grey-text'}
          on:click={downloadButtonClicked}
          disabled={!hasPermissionForDataRoom('download')}
        >
          <span slot="prepend"
            ><Icon
              class={hasPermissionForDataRoom('download') ? 'secondary-text' : 'grey-text'}
              path={mdiDownloadOutline}
            /></span
          >
          {$_('files.download')}
        </ListItem>
        <ListItem
          class={hasPermissionForDataRoom('update') ? 'secondary-text' : 'grey-text'}
          on:click={() => {
            showRenameDialog = true;
          }}
          disabled={!hasPermissionForDataRoom('update')}
        >
          <span slot="prepend"
            ><Icon
              class={hasPermissionForDataRoom('update') ? 'secondary-text' : 'grey-text'}
              path={mdiPencilOutline}
            /></span
          >
          {$_('files.rename')}
        </ListItem>
        <ListItem
          class={hasPermissionForDataRoom('delete') ? 'secondary-text' : 'grey-text'}
          on:click={() => {
            showDeleteDialog = true;
          }}
          disabled={!hasPermissionForDataRoom('delete')}
        >
          <span slot="prepend"
            ><Icon
              class={hasPermissionForDataRoom('delete') ? 'secondary-text' : 'grey-text'}
              path={mdiDeleteOutline}
            /></span
          >
          {$_('files.delete')}
        </ListItem>
        <ListItem
          class={hasPermissionForDataRoom('delete') ? 'secondary-text' : 'grey-text'}
          on:click={() => {
            showMoveDialog = true;
          }}
          disabled={!hasPermissionForDataRoom('delete')}
        >
          <span slot="prepend"
            ><Icon
              class={hasPermissionForDataRoom('delete') ? 'secondary-text' : 'grey-text'}
              path={mdiFileMoveOutline}
            /></span
          >
          {$_('files.move')}
        </ListItem>
        <ListItem
          class={hasPermissionForDataRoom('update') ? 'secondary-text' : 'grey-text'}
          on:click={copyButtonClicked}
          disabled={!hasPermissionForDataRoom('update')}
        >
          <span slot="prepend"
            ><Icon
              class={hasPermissionForDataRoom('update') ? 'secondary-text' : 'grey-text'}
              path={mdiContentCopy}
            /></span
          >
          {$_('files.copy')}
          <StarburstWithTextIcon
            text={$_('new-feature.new')}
            altText={$_('new-feature.new')}
            slot="append"
          />
        </ListItem>
      </List>
    </Menu>
  </td>
  <td style="rwidth: 700px;">
    <Tooltip bottom>
      <FileIcon filename={fileSystemNode.name} />
      <span class="pl-6">{fileSystemNode.name}</span>
      <div slot="tip">
        <table>
          <thead>
            <th>Size</th>
            <th>Updated</th>
            <th>Owner</th>
          </thead>
          <tbody>
            <tr>
              <td>{formatBytes(fileSystemNode.sizeInBytes)}</td>
              <td
                >{formatDate(fileSystemNode.updated)}
                {formatTime(fileSystemNode.updated)}</td
              >
              <td
                >{fileSystemNode.owner.firstName}
                {fileSystemNode.owner.lastName}</td
              >
            </tr>
          </tbody>
        </table>
      </div>
    </Tooltip>
  </td>
</tr>

{#if showDeleteDialog}
  <DeleteFile bind:show={showDeleteDialog} {fileSystemNode} on:delete={fileDeleted} />
{/if}

{#if showRenameDialog}
  <RenameFile
    {fileSystemNode}
    on:success={fileRenamed}
    on:cancel={() => {
      showRenameDialog = false;
    }}
  />
{/if}

{#if showMoveDialog}
  <MoveToFolder
    {fileSystemGroupName}
    fileSystemNodeToMove={fileSystemNode}
    on:cancel={() => {
      showMoveDialog = false;
    }}
  />
{/if}

<style>
  td {
    padding-left: 6px;
    padding-right: 0px;
    /*border-bottom: 1px solid #1a76d2;*/
  }
  /*tr > td:not(:first-of-type) {
        border-left: 1px solid gray;
     }*/
</style>
