<script>
  import { setContext } from 'svelte';
  import { meta, Route } from 'tinro';
  import { Breadcrumbs, ProgressLinear, Snackbar, Tab, Tabs } from 'svelte-materialify';
  import {
    mdiAccountCancelOutline,
    mdiAccountCheckOutline,
    mdiAccountCogOutline,
    mdiCellphoneCog,
    mdiContentCopy,
    mdiEmailOutline,
    mdiFolderOpenOutline,
    mdiLockReset,
    mdiPencilOutline,
  } from '@mdi/js';
  import {
    accountHasTenantPermission,
    PER_TENANT_CONTENT_ADMIN,
    PER_TENANT_USER_ADMIN,
    SYSTEM_WIDE_USER_ADMIN,
  } from '../../../services/account';
  import { apiFetch } from '../../../services/network';
  import { _ } from '../../../services/i18n';
  import UserPermissions from './UserPermissions.svelte';
  import ActiveUsers from './ActiveUsers.svelte';
  import SuspendedeUsers from './SuspendedUsers.svelte';
  import ConfirmationDialog from '../../ConfirmationDialog.svelte';
  import { cellRendererFactory } from '../../DataTable/CellRendererFactory';
  import DataTableRowActions from '../../DataTable/DataTableRowActions.svelte';

  const routeInfo = meta();

  export let tenantId = -1;
  export let initialBreadCrumbItems = [];
  export let breadCrumbItems = [];
  let showNewUserDialog = false;
  let showGiveAdminPermissionsDialog = false;
  let selectedUser = {};
  let users = [];
  let filteredActiveUsers = [];
  let filteredSuspendedUsers = [];
  const activeUsersSearchTerm = '';
  const suspendedUsersSearchTerm = '';
  let tab = 0;
  let twofaEnabled = false;
  let showSnackBar = false;
  let snackBarText = '';

  export let tenant = {};
  setContext('tenantInfo', tenant);

  $: {
    if (activeUsersSearchTerm.length === 0) {
      activeUsersSearch(null);
    }
  }
  $: {
    if (suspendedUsersSearchTerm.length === 0) {
      suspendedUsersSearch(null);
    }
  }

  const rowActionsActive = [
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: `/administration/tenant/${tenantId}/user/`,
      title: $_('administration.users.permissions'),
      path: mdiFolderOpenOutline,
      onClickEvent: 'userPermissionsClicked',
      elementId: `active-users`,
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.edit'),
      path: mdiPencilOutline,
      onClickEvent: 'editUserClicked',
      elementId: `active-users`,
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.change-password'),
      path: mdiLockReset,
      onClickEvent: 'changeUserPasswordClicked',
      elementId: `active-users`,
      value: '',
    },
    {
      type: 'button',
      enabledFunction: () => twofaEnabled,
      href: '',
      title: $_('administration.users.resetPhoneNumber'),
      path: mdiCellphoneCog,
      onClickEvent: 'resetUserPhoneNumberClicked',
      elementId: `active-users`,
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.suspend'),
      path: mdiAccountCancelOutline,
      onClickEvent: 'suspendUserClicked',
      elementId: `active-users`,
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.copy'),
      path: mdiContentCopy,
      onClickEvent: 'copyUserClicked',
      elementId: `active-users`,
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.makeAdmin', { values: { tenantName: tenant.name } }),
      path: mdiAccountCogOutline,
      onClickEvent: 'giveAdminPermissionsClicked',
      elementId: `active-users`,
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.send-welcome-email'),
      path: mdiEmailOutline,
      onClickEvent: 'sendWelcomeEmail',
      elementId: `active-users`,
      value: '',
    },
  ];
  const rowActionsSuspended = [
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: `/administration/tenant/${tenantId}/user/`,
      title: $_('administration.users.permissions'),
      path: mdiFolderOpenOutline,
      onClickEvent: 'userPermissionsClicked',
      elementId: `suspended-users`,
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.edit'),
      path: mdiPencilOutline,
      onClickEvent: 'editUserClicked',
      elementId: `suspended-users`,
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.change-password'),
      path: mdiLockReset,
      onClickEvent: 'changeUserPasswordClicked',
      elementId: `suspended-users`,
      value: '',
    },
    {
      type: 'button',
      enabledFunction: () => twofaEnabled,
      href: '',
      title: $_('administration.users.resetPhoneNumber'),
      path: mdiCellphoneCog,
      onClickEvent: 'resetUserPhoneNumberClicked',
      elementId: `suspended-users`,
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.reactivate'),
      path: mdiAccountCheckOutline,
      onClickEvent: 'reactivateUserClicked',
      elementId: `suspended-users`,
      value: '',
    },
  ];

  function isActionEnabled() {
    return (
      accountHasTenantPermission(SYSTEM_WIDE_USER_ADMIN) ||
      accountHasTenantPermission(PER_TENANT_USER_ADMIN)
    );
  }

  const columnDefs = [
    { field: 'id', headerName: '', hide: true, suppressColumnsToolPanel: true },
    {
      field: 'buttons',
      headerName: '',
      pinned: 'left',
      resizable: false,
      sortable: false,
      maxWidth: 40,
      editable: false,
      cellClass: 'table-actions pa-0 text-center',
      cellStyle: { cursor: 'pointer' },
      cellRenderer: cellRendererFactory((cell) => {
        new DataTableRowActions({
          target: cell.eGui,
        });
      }),
      suppressColumnsToolPanel: true,
    },
    {
      field: 'email',
      headerName: $_('administration.users.table.email'),
      cellRenderer: 'linkRenderer',
      suppressColumnsToolPanel: true,
    },
    {
      field: 'firstName',
      headerName: $_('administration.users.table.firstName'),
      sortable: true,
      filter: true,
      resizable: true,

      sort: 'asc',
    },
    {
      field: 'lastName',
      headerName: $_('administration.users.table.lastName'),
      sortable: true,
      filter: true,
      resizable: true,

      sort: 'asc',
    },
    {
      field: 'suspended',
      headerName: $_('administration.users.table.suspended'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'changePasswordAtNextSignin',
      headerName: $_('administration.users.table.changePasswordAtNextSignin'),
      suppressColumnsToolPanel: true,
    },
    {
      field: 'formattedLastSigninDate',
      headerName: $_('administration.users.table.lastSigninDate'),
      resizable: true,
      suppressColumnsToolPanel: true,
    },
  ];

  async function load() {
    breadCrumbItems =
      tenantId > -1
        ? initialBreadCrumbItems.concat([
            {
              text: `${$_('administration.users.title')}`,
              href: `/administration/tenant/${tenantId}/user`,
            },
          ])
        : breadCrumbItems.concat([
            { text: `${$_('administration.users.title')}`, href: `/administration/user` },
          ]);
    await is2faOn();
    let url = `/api/user`;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const response = await apiFetch(url);
    if (response.ok) {
      users = await response.json();
      formatLastSignInDate();
    }

    if (routeInfo.from == '/administration/tenant') {
      showNewUserDialog = true;
    }
  }

  async function is2faOn(item) {
    const url = `/api/tenant-settings/2fa/${tenantId}`;
    const method = 'GET';
    const response = await apiFetch(url, method);
    if (response.ok) {
      const twofaSettings = await response.json();
      if (twofaSettings.twofa == 'otp') {
        twofaEnabled = true;
      }
    }
    return twofaEnabled;
  }

  function formatLastSignInDate() {
    for (const user of users) {
      if (user.lastSigninDate) {
        user.formattedLastSigninDate = user.lastSigninDate.toString().slice(0, 10);
      }
    }
  }

  function findUserById(id) {
    return users.find((item) => item.id === Number(id));
  }

  function activeUsersSearch() {
    const term = activeUsersSearchTerm.toLocaleLowerCase();
    filteredActiveUsers = users.filter((user) => {
      if (
        !user.suspended &&
        (user.email.toLowerCase().includes(term) ||
          user.firstName.toLocaleLowerCase().includes(term) ||
          user.lastName.toLocaleLowerCase().includes(term))
      ) {
        return true;
      }
      return false;
    });
  }

  function suspendedUsersSearch() {
    const term = suspendedUsersSearchTerm.toLocaleLowerCase();
    filteredSuspendedUsers = users.filter((user) => {
      if (
        user.suspended &&
        (user.email.toLowerCase().includes(term) ||
          user.firstName.toLocaleLowerCase().includes(term) ||
          user.lastName.toLocaleLowerCase().includes(term))
      ) {
        return true;
      }
      return false;
    });
  }

  async function grantPermission(url) {
    let success = false;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const response = await apiFetch(url, 'POST');
    if (response.ok) {
      success = true;
    }
    return success;
  }

  async function sendWelcomeEmail(event) {
    const { id } = event.detail;
    snackBarText = $_('administration.users.sending');
    showSnackBar = true;
    const url = `/api/user/${id}/send-welcome-email`;
    const sendEmailResponse = await apiFetch(url, 'POST');
    showSnackBar = false;
    snackBarText = sendEmailResponse.ok
      ? $_(`administration.users.email-sent`)
      : $_(`administration.users.email-error`);
    showSnackBar = true;
  }

  function giveAdminPermissionsClicked(event) {
    selectedUser = findUserById(event.detail.id);
    showGiveAdminPermissionsDialog = true;
  }

  async function makeAdmin() {
    const urls = [
      `/api/user/${selectedUser.id}/${PER_TENANT_CONTENT_ADMIN}`,
      `/api/user/${selectedUser.id}/group-access-permission/contract/0/edit`,
      `/api/user/${selectedUser.id}/group-access-permission/patent/0/edit`,
      `/api/user/${selectedUser.id}/group-access-permission/trademark/0/edit`,
      `/api/user/${selectedUser.id}/group-access-permission/report/0/edit`,
      `/api/user/${selectedUser.id}/item-access-permission/contract/0/create`,
      `/api/user/${selectedUser.id}/item-access-permission/patent/0/create`,
      `/api/user/${selectedUser.id}/item-access-permission/trademark/0/create`,
    ];
    for (const url of urls) {
      const success = await grantPermission(url);
      snackBarText = success
        ? $_('administration.users.give-admin-permissions.success', {
            values: { tenantName: tenant.name },
          })
        : $_('administration.users.give-admin-permissions.error');
    }
    showSnackBar = true;
  }
</script>

{#await load()}
  <ProgressLinear />
{:then}
  <Route path="/">
    <Breadcrumbs bind:items={breadCrumbItems} />
    <Tabs class="secondary-text" bind:value={tab}>
      <div slot="tabs">
        <Tab>{$_('administration.users.active-tab')}</Tab>
        <Tab>{$_('administration.users.suspended-tab')}</Tab>
      </div>
    </Tabs>
    <div id="users-0" class={tab === 0 ? 'h-full flex-grow-1' : 'd-none'}>
      <ActiveUsers
        bind:users
        bind:showNewUserDialog
        {tenantId}
        {columnDefs}
        rowActions={rowActionsActive}
        on:giveAdminPermissionsClicked={giveAdminPermissionsClicked}
        bind:showSnackBar
        bind:snackBarText
        on:sendWelcomeEmail={sendWelcomeEmail}
      />
    </div>
    <div id="users-1" class={tab === 1 ? 'h-full flex-grow-1' : 'd-none'}>
      <SuspendedeUsers
        bind:users
        {tenantId}
        {columnDefs}
        rowActions={rowActionsSuspended}
        {initialBreadCrumbItems}
      />
    </div>
  </Route>
{/await}
<Snackbar class="flex-column" bind:active={showSnackBar} timeout={2000} top right>
  {snackBarText}
</Snackbar>
{#if showGiveAdminPermissionsDialog}
  <ConfirmationDialog
    bind:show={showGiveAdminPermissionsDialog}
    title={$_('administration.users.give-admin-permissions.title')}
    text={$_('administration.users.give-admin-permissions.give-permissions', {
      values: { email: selectedUser.email, tenantName: tenant.name },
    })}
    on:save={makeAdmin}
  />
{/if}
<Route path="/:id" let:meta>
  <UserPermissions {tenantId} userId={meta.params.id} {initialBreadCrumbItems} />
</Route>

<Route fallback />
<Snackbar class="flex-column" bind:active={showSnackBar} timeout={4000} top right>
  <div>{snackBarText}</div>
</Snackbar>
