<script>
  import { onMount } from 'svelte';
  import { Breadcrumbs } from 'svelte-materialify';
  import { _, IP_SCOPE_ITEM, ipTypes } from '../services/i18n';

  export let name = '';
  export let name2 = '';

  let breadCrumbItems = [{ text: $_('patent.title'), href: '/patent/item' }];

  const IP_SCOPE_FAMILY = 'family';
  const ipScopes = new Set([IP_SCOPE_ITEM, IP_SCOPE_FAMILY]);

  onMount(() => {
    if (window.location.hash) {
      const { hash } = window.location;
      const parts = hash.split('/');
      if (parts.length >= 4) {
        const ipType = parts[1];
        const ipScope = parts[2];
        const scopeId = parts[3];
        if (ipTypes.includes(ipType) && ipScopes.has(ipScope)) {
          if (parts.length > 4) {
            if (ipScope === IP_SCOPE_FAMILY && parts[4] === IP_SCOPE_ITEM) {
              const itemId = parts[5];
              breadCrumbItems = [
                { text: $_(`${ipType}-group.title`), href: `/${ipType}/${ipScope}` },
                { text: name, href: `/${ipType}/${ipScope}/${scopeId}` },
                { text: $_(`${ipType}.title`), href: `/${ipType}/${IP_SCOPE_ITEM}` },
                { text: name2, href: `/${ipType}/${ipScope}/${scopeId}/item/${itemId}` },
              ];
              return;
            }
          } else {
            let title = '';
            title = ipScope === IP_SCOPE_ITEM ? $_(`${ipType}.title`) : $_(`${ipType}-group.title`);
            breadCrumbItems = [
              { text: title, href: `/${ipType}/${ipScope}` },
              { text: name, href: `/${ipType}/${ipScope}/${scopeId}` },
            ];
            return;
          }
        }
      }
      breadCrumbItems.push({ text: `UrlBreadcrumb - ERROR - misunderstood URL ${hash}` });
    }
  });
</script>

<Breadcrumbs large bind:items={breadCrumbItems} />
