<script>
  import { createEventDispatcher, tick } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    Icon,
    TextField,
  } from 'svelte-materialify';
  import { _ } from 'svelte-i18n';
  // import TextField from './TextField.svelte';
  import { onDestroy } from 'svelte';
  import { apiFetch, API_URL, defaultHeaders } from '../services/network';

  const dispatch = createEventDispatcher();

  export let show = false;

  let email = '';
  let inProgress = false;
  let responseError = '';
  let emailInputField;

  $: disableInput = inProgress || email.length < 3;

  const emailRules = [
    (v) => !!v || 'Required',
    (v) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(v) || 'Invalid e-mail.';
    },
    (v) => responseError.length === 0 || responseError,
  ];

  onDestroy(() => {
    email = '';
  });

  async function addAdditionalEmail() {
    const url = `${API_URL}/user/verified-emails`;
    const method = 'POST';
    const response = await apiFetch(url, method, { email }, defaultHeaders, true);
    if (response.ok) {
      dispatch('success');
      show = false;
    } else {
      const respBody = await response.json();
      // responseError = respBody.reason;
      dispatch('conflict', respBody);
      show = false;
      // emailInputField.blur();
      // await tick();
    }
  }
</script>

<div id="getAdditionalEmail">
  <Dialog persistent={true} active={true}>
    <Card outlined={true} raised={true}>
      <CardTitle>
        <div class="d-flex align-center">
          <img
            src="/images/logos/Fortress_RGB.png"
            class="logo"
            alt="{$_('app.name')} logo"
            title={$_('app.name')}
          />
        </div>
      </CardTitle>
      <CardText>
        <Divider class="secondary-color" />
        <h5 class="pt-2 text-center">
          {$_('profile.additionalEmails.addAdditionalEmails').slice(0, -1)}
        </h5>
        <p class="mt-4 mb-4">
          {$_('profile.additionalEmails.description')}
        </p>

        <TextField
          bind:value={email}
          rules={emailRules}
          bind:inputElement={emailInputField}
          on:keyup={() => (responseError = '')}
        >
          {$_('profile.additionalEmails.email')}
        </TextField>

        <Button
          class="mt-4 ml-2 {disableInput ? 'grey-text' : 'secondary-text'}"
          text
          disabled={inProgress || email.length < 3}
          on:click={addAdditionalEmail}
        >
          {$_('profile.additionalEmails.actions.create')}
        </Button>

        <Button
          class="mt-4 ml-2"
          text
          disabled={inProgress}
          on:click={() => {
            show = false;
          }}
        >
          {$_('profile.additionalEmails.actions.cancel')}
        </Button>
      </CardText>
    </Card>
  </Dialog>
</div>
