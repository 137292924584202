import { dictionary, locale } from 'svelte-i18n';

const MESSAGE_FILE_URL_TEMPLATE = '/lang/{locale}.json';

async function delay() {
  return new Promise((resolve, reject) => {
    window.setTimeout(() => {
      resolve();
    }, 2500);
  });
}

let langFile = {};

async function setupI18N({ withLocale: _locale } = { withLocale: 'en' }) {
  const messageFileUrl = MESSAGE_FILE_URL_TEMPLATE.replace('{locale}', _locale);

  // await delay();
  const response = await fetch(messageFileUrl);
  if (response.ok) {
    langFile = await response.json();
    dictionary.set({ [_locale]: langFile });
    locale.set(_locale);
    return _locale;
  }
}

// const FOLDER_CLASS_ITEM = 'item';
// const FOLDER_CLASS_GROUP = 'group';

// const folderClasses = [ FOLDER_CLASS_ITEM, FOLDER_CLASS_GROUP ];
const IP_SCOPE_ITEM = 'item';
const IP_SCOPE_GROUP = 'group';
const IP_SCOPE_FAMILY = 'family';

const ipScopes = [IP_SCOPE_ITEM, IP_SCOPE_GROUP, IP_SCOPE_FAMILY];

const IP_TYPE_PATENT = 'patent';
const IP_TYPE_TRADEMARK = 'trademark';
const IP_TYPE_COPYRIGHT = 'copyright';
const IP_TYPE_DESIGN = 'design';
const IP_TYPE_PLANTBREEDERSRIGHT = 'plant-breeders-right';
const IP_TYPE_CONTRACT = 'contract';
const IP_TYPE_IDEA = 'idea';
const IP_TYPE_TRADESECRET = 'trade-secret';
const IP_TYPE_OPENSOURCE = 'open-source';
const IP_TYPE_COMPANY = 'company';
const IP_TYPE_REPORT = 'report';

const ipTypes = [
  IP_TYPE_PATENT,
  IP_TYPE_TRADEMARK,
  IP_TYPE_COPYRIGHT,
  IP_TYPE_DESIGN,
  IP_TYPE_PLANTBREEDERSRIGHT,
  IP_TYPE_CONTRACT,
  IP_TYPE_IDEA,
  IP_TYPE_TRADESECRET,
  IP_TYPE_OPENSOURCE,
  IP_TYPE_COMPANY,
  IP_TYPE_REPORT,
];

/**
 * @function getFolderSymbols
 * @param {string} ipType - one of the following strings: 'patent', 'contract'
 * @param {string} ipScope one of 'item' or 'group'
 * @returns {string[]}
 */
function getFolderSymbols(ipType, ipScope) {
  let folderSymbols = [];
  if (!ipTypes.includes(ipType)) {
    console.error(`i18n.getFolderSymbols - invalid ipType=${ipType}`);
    return folderSymbols;
  }
  if (!ipScopes.includes(ipScope)) {
    console.error(`i18n.getFolderSymbols - invalid ipScope=${ipScope}`);
    return folderSymbols;
  }
  folderSymbols = Object.keys(langFile.folders[ipType][ipScope]);
  return folderSymbols;
}

/**
 * @function getFolderSelectOptions
 * @param {string} ipType - one of the following strings: 'patent', 'contract'
 * @param {string} ipScope one of 'item' or 'group'
 * @returns array of {name:'', value:''} objects to be used in constructing Materialify Select options
 */
function getFolderSelectOptions(ipType, ipScope) {
  const selectOptions = [];
  if (!ipTypes.includes(ipType)) {
    console.error(`i18n.getFolderSelectOptions - invalid ipType=${ipType}`);
    return selectOptions;
  }
  if (!ipScopes.includes(ipScope)) {
    console.error(`i18n.getFolderSelectOptions - invalid ipScope=${ipScope}`);
    return selectOptions;
  }
  for (const key of Object.keys(langFile.folders[ipType][ipScope])) {
    selectOptions.push({ name: langFile.folders[ipType][ipScope][key].name, value: key });
  }
  return selectOptions;
}

/**
 * @function getFolderNameForSymbol
 * @param {string} ipType - one of the following strings: 'patent', 'contract'
 * @param {string} ipScope one of 'item' or 'group'
 * @param {string} folderSymbol - one of the symbols available within that ipType/ipScope combo (see folderDescriptions above)
 * @returns a string which is the localized folderName
 */
function getFolderNameForSymbol(ipType, ipScope, folderSymbol) {
  if (!ipTypes.includes(ipType)) {
    console.error(`i18n.getFolderNameForSymbol - invalid ipType=${ipType}`);
    return `${ipType}.${ipScope}.${folderSymbol}`;
  }
  if (!ipScopes.includes(ipScope)) {
    console.error(`i18n.getFolderNameForSymbol - invalid ipScope=${ipScope}`);
    return `${ipType}.${ipScope}.${folderSymbol}`;
  }
  const keys = Object.keys(langFile.folders[ipType][ipScope]);
  if (!keys.includes(folderSymbol)) {
    console.error(
      `i18n.getFolderNameForSymbol - invalid folderSymbol=${folderSymbol} for ipType=${ipType} & ipScope=${ipScope}`
    );
    return `${ipType}.${ipScope}.${folderSymbol}`;
  }
  return langFile.folders[ipType][ipScope][folderSymbol].name;
}
/**
 * @function getTooltipForSymbol
 * @param {string} ipType - one of the following strings: 'patent', 'contract'
 * @param {string} ipScope one of 'item' or 'group'
 * @param {string} folderSymbol - one of the symbols available within that ipType/ipScope combo (see folderDescriptions above)
 * @returns a string which is the localized folderName
 */
function getTooltipForSymbol(ipType, ipScope, folderSymbol) {
  if (!ipTypes.includes(ipType)) {
    console.error(`i18n.getTooltipForSymbol - invalid ipType=${ipType}`);
    return `${ipType}.${ipScope}.${folderSymbol}`;
  }
  if (!ipScopes.includes(ipScope)) {
    console.error(`i18n.getTooltipForSymbol - invalid ipScope=${ipScope}`);
    return `${ipType}.${ipScope}.${folderSymbol}`;
  }
  const keys = Object.keys(langFile.folders[ipType][ipScope]);
  if (!keys.includes(folderSymbol)) {
    console.error(
      `i18n.getTooltipForSymbol - invalid folderSymbol=${folderSymbol} for ipType=${ipType} & ipScope=${ipScope}`
    );
    return `${ipType}.${ipScope}.${folderSymbol}`;
  }
  return langFile.folders[ipType][ipScope][folderSymbol].tooltip;
}

/**
 * @function getContractTypeSelectOptions
 * @returns array of {name:'', value:''} objects to be used in constructing Materialify Select options
 */
function getContractTypeSelectOptions() {
  const selectOptions = [];
  for (const key of Object.keys(langFile.contract.types)) {
    selectOptions.push({ name: langFile.contract.types[key], value: key });
  }
  return selectOptions;
}

/**
 * @function getContractStateSelectOptions
 * @returns array of {name:'', value:''} objects to be used in constructing Materialify Select options
 */
function getContractStateSelectOptions() {
  const selectOptions = [];
  for (const key of Object.keys(langFile.contract.states)) {
    selectOptions.push({ name: langFile.contract.states[key], value: key });
  }
  return selectOptions;
}

/**
 * @function getPatentTypeSelectOptions
 * @returns array of {name:'', value:''} objects to be used in constructing Materialify Select options
 */
function getPatentTypeSelectOptions() {
  const selectOptions = [];
  for (const key of Object.keys(langFile.patent.types)) {
    selectOptions.push({ name: langFile.patent.types[key], value: key });
  }
  return selectOptions;
}

/**
 * @function getPatentStateSelectOptions
 * @returns array of {name:'', value:''} objects to be used in constructing Materialify Select options
 */
function getPatentStateSelectOptions() {
  const selectOptions = [];
  for (const key of Object.keys(langFile.patent.states)) {
    selectOptions.push({ name: langFile.patent.states[key], value: key });
  }
  return selectOptions;
}

function getPatentOwnershipStatusOptions() {
  return Object.entries(langFile.patent.ownershipStatus).map(([k, v]) => ({ name: v, value: k}));
}

/**
 * @function getTrademarkKindSelectOptions
 * @returns array of {name:'', value:''} objects to be used in constructing Materialify Select options
 */
function getTrademarkKindSelectOptions() {
  const selectOptions = [];
  for (const key of Object.keys(langFile.trademark.kinds)) {
    selectOptions.push({ name: langFile.trademark.kinds[key], value: key });
  }
  return selectOptions;
}

/**
 * @function getTrademarkStateSelectOptions
 * @returns array of {name:'', value:''} objects to be used in constructing Materialify Select options
 */
function getTrademarkStateSelectOptions() {
  const selectOptions = [];
  for (const key of Object.keys(langFile.trademark.states)) {
    selectOptions.push({ name: langFile.trademark.states[key], value: key });
  }
  return selectOptions;
}

export {
  setupI18N,
  ipTypes,
  ipScopes,
  IP_SCOPE_ITEM,
  IP_SCOPE_GROUP,
  IP_SCOPE_FAMILY,
  IP_TYPE_PATENT,
  IP_TYPE_TRADEMARK,
  IP_TYPE_COPYRIGHT,
  IP_TYPE_DESIGN,
  IP_TYPE_PLANTBREEDERSRIGHT,
  IP_TYPE_CONTRACT,
  IP_TYPE_IDEA,
  IP_TYPE_TRADESECRET,
  IP_TYPE_OPENSOURCE,
  IP_TYPE_COMPANY,
  IP_TYPE_REPORT,
  getFolderSymbols,
  getFolderSelectOptions,
  getFolderNameForSymbol,
  getTooltipForSymbol,
  getContractTypeSelectOptions,
  getContractStateSelectOptions,
  getPatentTypeSelectOptions,
  getPatentStateSelectOptions,
  getPatentOwnershipStatusOptions,
  getTrademarkKindSelectOptions,
  getTrademarkStateSelectOptions,
};
export { _ } from 'svelte-i18n';
