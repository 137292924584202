<script>
  import { afterUpdate, createEventDispatcher, onMount } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    Icon,
    Radio,
    TextField,
  } from 'svelte-materialify';
  import { mdiMagnify } from '@mdi/js';
  import { _ } from '../../services/i18n';
  import { apiFetch } from '../../services/network';

  const dispatch = createEventDispatcher();
  export let show;
  export let groupId;
  export let item;

  let groupsList = [];
  let filteredGroupsList = [];
  let destinationGroupId = 0;

  let searchString = '';
  let searchField;
  let isFocused = false;

  $: {
    if (searchString) {
      filteredGroupsList = groupsList;
    }
  }

  onMount(async () => {
    await load();
  });

  afterUpdate(async () => {
    if (!isFocused && !searchString) {
      searchField.focus();
      isFocused = true;
    }
  });

  async function load() {
    const url = `/api/contract/group`;
    const response = await apiFetch(url);
    if (response.ok) {
      groupsList = await response.json();
      filteredGroupsList = groupsList;
    }
  }

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function moveItem(event) {
    event.preventDefault();
    // add item to destination group
    const addResponse = await apiFetch(
      `/api/contract/group/${destinationGroupId}/item/${item.id}`,
      'POST'
    );
    if (addResponse.ok) {
      // remove item from existing group
      const deleteResponse = await apiFetch(
        `/api/contract/group/${groupId}/item/${item.id}`,
        'DELETE'
      );
      show = false;
      dispatch('delete', { id: item.id });
    }
  }

  function search(event) {
    const searchTerm = searchString.toLocaleLowerCase();
    filteredGroupsList = groupsList.filter((group) =>
      group.name.toLocaleLowerCase().includes(searchTerm)
    );
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{$_('contract-group.move-contract.title')}</h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <h6 class="pt-2">Choose Family</h6>
      <TextField
        bind:value={searchString}
        bind:inputElement={searchField}
        on:keyup={search}
        style="max-width: 400px;"
        color={'blue'}
        clearable
        outlined
        rounded
        dense
      >
        <div slot="prepend">
          <Icon path={mdiMagnify} />
        </div>
        {$_('guidance.search.title')}
      </TextField>
      <div class="pt-2">
        {#each filteredGroupsList as groupItem}
          {#if groupItem.id !== groupId}
            <Radio color={'secondary-color'} bind:group={destinationGroupId} value={groupItem.id}
              >{groupItem.name}</Radio
            >
          {/if}
        {/each}
      </div>

      <form>
        <Button
          type="submit"
          on:click={moveItem}
          text
          disabled={destinationGroupId === 0}
          class="mt-4 {destinationGroupId == 0 ? 'grey-color' : 'secondary-color'}"
        >
          {$_('contract-group.move-contract.move')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('contract-group.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
