<script>
  import { mdiImport } from '@mdi/js';
  import { createEventDispatcher } from 'svelte';
  import { Button, Icon } from 'svelte-materialify';
  import { _ } from '../../services/i18n';
  import { apiFetch } from '../../services/network';
  import { uploadFiles } from '../UploadProgressManager.svelte';

  const dispatch = createEventDispatcher();

  let fileInputElement;
  const fileSystemGroupName = 'corporate';

  async function importDataRoomsButtonClicked(event) {
    fileInputElement.click();
  }

  async function fileSelectionChanged(event) {
    let senderId;
    const existingPaths = [];
    for (const file of fileInputElement.files) {
      const folders = file.webkitRelativePath.split('/');
      // Current implementation assumes Data Rooms are directly in the
      // directory chosen for upload. If folders only has size 2 it
      // implies user tried to upload a file at the Data Room level.
      if (folders.length > 2) {
        const folderPath = file.webkitRelativePath.replace(`/${file.name}`, '');
        // Check if the current file is being added to an already
        // existing folder
        let nodeId = existingPaths.find((existingPath) => existingPath.path == folderPath)?.node;
        if (!nodeId) {
          let parentFileSystemNode;
          let folderRelativePath = folders[1];
          for (let i = 1; i < folders.length - 1; i++) {
            if (i > 1) {
              folderRelativePath = folderRelativePath.concat('/', folders[i]);
            }
            // Check if we've already created this folder and
            // know its nodeId
            nodeId = existingPaths.find(
              (existingPath) => existingPath.path == folderRelativePath
            )?.node;
            // If we know the foler's nodeId, set parent since we may
            // need it for a child, otherwise try to create it now
            if (nodeId) {
              parentFileSystemNode = { id: nodeId };
            } else {
              parentFileSystemNode = await createFolder(folders[i], parentFileSystemNode);
              nodeId = parentFileSystemNode.id;
              existingPaths.push({
                path: folderRelativePath,
                node: nodeId,
              });
            }
          }
        }
        const uploadUrl = `/api/file-system/folder/${nodeId}/file`;
        senderId = `file-system-folder-${nodeId}`;
        const fileNames = [];
        const filesToUpload = [];
        let fileExists;
        fileExists = await isDuplicate(nodeId, file.name);
        if (fileExists) {
          console.error(`${file.name}: ${$_('files.upload-file-dialog.duplicate-folder')}`);
        } else {
          filesToUpload.push(file);
          fileNames.push(file.name);
          uploadFiles(
            filesToUpload,
            fileNames,
            'file-system',
            'user-generated',
            uploadUrl,
            senderId
          );
        }
      }
    }
    fileInputElement.value = '';
  }

  async function isDuplicate(nodeId, filename) {
    let listing;
    const url = `/api/file-system/folder/${nodeId}`;
    const response = await apiFetch(url, 'GET');
    listing = await response.json();
    for (const fileSystemNode of listing) {
      if (
        fileSystemNode.name.localeCompare(filename, undefined, {
          sensitivity: 'accent',
        }) === 0
      ) {
        return true;
      }
    }
    return false;
  }

  async function createFolder(name, parentFileSystemNode) {
    let url;
    let request;
    let fileSystemNode;
    if (parentFileSystemNode) {
      url = `/api/file-system/folder/${parentFileSystemNode.id}/create`;
      request = { name };
    } else {
      url = `/api/file-system`;
      request = { name, fileSystemGroupName };
    }
    const response = await apiFetch(url, 'POST', request);
    if (response.ok) {
      fileSystemNode = await response.json();
      if (parentFileSystemNode) {
        // for folders notify FileSystemFolder folderCreated
        const element = document.getElementById(`file-system-folder-${parentFileSystemNode.id}`);
        if (element) {
          const event = new CustomEvent('folder-created', {
            detail: { fileSystemNode },
          });
          element.dispatchEvent(event);
        }
      } else {
        // for Data Rooms notify DataRooms folderCreated
        dispatch('folder-created', { fileSystemNode });
      }
      return fileSystemNode;
    }
    if (response.status === 409) {
      const conflict = await response.json();
      fileSystemNode = conflict.existingObject;
      return fileSystemNode;
    }
  }
</script>

<Button
  text
  fab
  size="small"
  class="secondary-color"
  title={$_('data-rooms.import-data-rooms')}
  on:click={importDataRoomsButtonClicked}
>
  <Icon path={mdiImport} />
</Button>
<input
  bind:this={fileInputElement}
  on:change={fileSelectionChanged}
  type="file"
  webkitdirectory
  hidden
/>
