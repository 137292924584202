import jstz from 'jstz';
import { writable } from 'svelte/store';
import { router } from 'tinro';
import { accountInfo, authenticated } from './account';

const timezone = jstz.determine();
export const API_URL = import.meta.env.FORTRESS_API_URL;

/**
 * Create a store that holds a clientId.
 * When a user is logged on whose tenant.type !== "company" then a clientId >= 0 will be set
 */
export const noClientContactSelectedId = -1;

export const selectedClientContactId = writable(noClientContactSelectedId);

export const selectedClientContact = writable({});

export const clientContacts = writable([]);

export const selectableClientContacts = writable([]);

let localClientContacts;
clientContacts.subscribe((value) => {
  localClientContacts = value;
});

let localClientContactId;
selectedClientContactId.subscribe((value) => {
  localClientContactId = value;
});

let localAccountInfo;
accountInfo.subscribe((value) => {
  localAccountInfo = value;
});

// When logged out, clear all client information
authenticated.subscribe((value) => {
  if (!value) {
    clientContacts.set([]);
    selectableClientContacts.set([]);
    selectedClientContact.set({});
    selectedClientContactId.set(noClientContactSelectedId);
  }
});

export function setSelectedClientByTenant(tenant) {
  const client = localClientContacts.find((c) => c.userProfile.tenant.id === tenant.id);
  if (client) {
    selectedClientContact.update((c) => client);
    selectedClientContactId.update((identifier) => client.id);
  }
}

async function reportJavascriptError(msg, url, line, col, error) {
  const ignoreList = [
    "Uncaught TypeError: Cannot read property 'style' of null", // this occurs somewhere in svelte-materialify
    "TypeError: null is not an object (evaluating 'container.style')", // this occurs somewhere in svelte-materialify
  ];

  if (ignoreList.includes(msg)) {
    return true; // suppress error alert by ancient version of IE which we do not support...
  }
  let uiVersion = 'unknown';
  if (typeof webuiVersion !== 'undefined') {
    uiVersion = webuiVersion;
  }
  const jsError = {
    errorType: 'client-js-error',
    webuiVersion: uiVersion,
    timezone: timezone.name(),
    userProfile: localAccountInfo,
    clientUrl: window.location.href,
    msg,
    url,
    line,
    col,
    error,
  };
  const errorOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(jsError),
  };

  await fetch('/api/report/client-js-error', errorOptions);
  return true;
}

window.onerror = reportJavascriptError;

/**
 * @function apiFormatUrl
 * @param {string} url - src url to format
 * @returns {string} formatted url - may have '/api' prepended, may have '?clientId=n' appended
 */
export function apiFormatUrl(url) {
  if (url.startsWith('http')) {
    return url;
  }
  let formatted = `${API_URL}${url}`;

  // Remove duplicate "/api"
  if (url.startsWith('/api')) {
    formatted = `${API_URL}${url.replace('/api', '')}`;
  }
  if (localClientContactId > 0 && !formatted.includes('clientContactId')) {
    let prefix = '?';
    if (formatted.includes('?')) {
      prefix = '&';
    }
    formatted += `${prefix}clientContactId=${localClientContactId}`;
  }
  return formatted;
}

export const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

/**
 * @function apiFetch
 * @description - wrapper around browser fetch that does additional url formatting and error reporting
 * @param {string} url - src url to fetch
 * @param {string} method - optional HTTP method, defaults to 'GET'
 * @param {object} body - optional object that will be converted to JSON
 * @param {object} httpHeaders - optional object representing httpHeaders to use for fetch, defaults to defaultHeaders
 * @param {boolean} suppressNetworkError - optional check to suppress network error events, defaults to false
 * @returns {Promise} Promise that resolves to the response object normally returned by browser fetch
 */
export async function apiFetch(
  url,
  method = 'GET',
  body = null,
  httpHeaders = defaultHeaders,
  suppressNetworkError = false
) {
  let errorResponseContent;
  const formattedUrl = apiFormatUrl(url);
  const reportErrors = !formattedUrl.includes('/authentication/') && !suppressNetworkError;
  const options = {
    method,
    credentials: 'include',
    headers: httpHeaders,
  };
  if (body !== null) {
    options.body = JSON.stringify(body) === '{}' ? body : JSON.stringify(body);
  }
  let response;
  try {
    response = await fetch(formattedUrl, options);
  } catch (error) {
    console.log(`error ${error}`);
    // In the event of an authentication error, change the state of the UI to NOT authenticated.
    // This will cause the Signin page to appear.
    authenticated.update((auth) => false);
    router.goto('/');
    return { ok: false, status: 401 };
  }
  if (reportErrors) {
    switch (response.status) {
      case 401: {
        // In the event of a 401 unauthorized, change the state of the UI to NOT authenticated.
        // This will cause the Signin page to appear.
        authenticated.update((auth) => false);
        selectedClientContact.update((c) => {});
        selectedClientContactId.update((identifier) => -1);

        break;
      }
      case 403: {
        const event = new CustomEvent('network-error', {
          detail: {
            errorMessage: '403 Forbidden',
            errorURL: `${method} ${formattedUrl}`,
          },
        });
        const networkErrorManagerElement = document.querySelector('#network-error-manager');
        networkErrorManagerElement.dispatchEvent(event);

        break;
      }
      case 400: {
        const eventDetail = {
          detail: {
            errorMessage: '400 Bad Request',
            errorURL: `${method} ${formattedUrl}`,
          },
        };
        const contentType = response.headers.get('content-type');
        if (contentType) {
        }
        if (contentType.includes('text/html')) {
          errorResponseContent = await response.text();
        } else if (contentType.includes('application/json')) {
          errorResponseContent = await response.json();
        }
        const event = new CustomEvent('network-error', eventDetail);
        const networkErrorManagerElement = document.querySelector('#network-error-manager');
        networkErrorManagerElement.dispatchEvent(event);

        break;
      }
      case 413: {
        const event = new CustomEvent('network-error', {
          detail: {
            errorMessage: '413 Payload Too Large',
            errorURL: `${method} ${formattedUrl}`,
          },
        });
        const networkErrorManagerElement = document.querySelector('#network-error-manager');
        networkErrorManagerElement.dispatchEvent(event);

        break;
      }
      case 500: {
        const event = new CustomEvent('network-error', {
          detail: {
            errorMessage: '500 Server Request',
            errorURL: `${method} ${formattedUrl}`,
          },
        });
        const networkErrorManagerElement = document.querySelector('#network-error-manager');
        networkErrorManagerElement.dispatchEvent(event);

        break;
      }
      // No default
    }
    // POST information about the error to the server so that it can be analyzed
    if (!response.ok && response.status !== 401) {
      let uiVersion = 'unknown';
      if (typeof webuiVersion !== 'undefined') {
        uiVersion = webuiVersion;
      }
      const clientError = {
        errorType: 'client-http-error',
        webuiVersion: uiVersion,
        timezone: timezone.name(),
        userProfile: localAccountInfo,
        method,
        serverUrl: formattedUrl,
        clientUrl: window.location.href,
        status: response.status,
      };
      if (body !== null) {
        clientError.requestBody = body;
      }
      if (errorResponseContent) {
        clientError.responseBody = errorResponseContent;
      }
      const errorOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(clientError),
      };

      await fetch('/api/report/client-http-error', errorOptions);
    }
  }
  return response;
}
