<script>
  import { onDestroy, onMount } from 'svelte';
  import { Button, Icon } from 'svelte-materialify';
  import { mdiCommentQuoteOutline, mdiLifebuoy } from '@mdi/js';
  import { _ } from '../services/i18n';
  import { accountInfo } from '../services/account';
  import Reminders from './ImportantDates/Reminders.svelte';

  onMount(() => {});

  onDestroy(() => {});

  function feedbackClicked(event) {
    const now = new Date();
    let version = 'dev';
    if (typeof webuiVersion !== 'undefined') {
      version = webuiVersion;
    }
    const url = `mailto:feedback@fortressiam.com?subject=Fortress IAM Feedback [${now.toDateString()}][${version}]&cc=${
      $accountInfo.email
    }"`;
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('tinro-ignore', '');
    a.click();
  }

  function supportClicked(event) {
    const now = new Date();
    let version = 'dev';
    if (typeof webuiVersion !== 'undefined') {
      version = webuiVersion;
    }
    const url = `mailto:support@fortressiam.com?subject=Fortress IAM Support Request [${now.toDateString()}][${version}]&cc=${
      $accountInfo.email
    }"`;
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('tinro-ignore', '');
    a.click();
  }
</script>

<h5 class="secondary-text text-left">{$_('dashboard.title')}</h5>

<div class="pt-4 pl-6 d-flex flex-column">
  <Reminders />
</div>

<style>
  ul {
    list-style-type: none;
  }
  td {
    padding: 0.5rem;
  }
  .link {
    cursor: pointer;
    text-decoration: underline;
  }
</style>
