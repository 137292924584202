import { IP_TYPE_CONTRACT, IP_TYPE_PATENT, IP_TYPE_REPORT, IP_TYPE_TRADEMARK } from './i18n';

export const supportedIPTypes = [
  IP_TYPE_PATENT,
  IP_TYPE_TRADEMARK,
  IP_TYPE_CONTRACT,
  IP_TYPE_REPORT,
];

/**
 * @function getSupportedIPTypes
 * @returns array of IP type objects to be used in constructing permissions. Objects are strings.
 */
function getSupportedIPTypes() {
  return supportedIPTypes;
}

/**
 * @function getAdminGroupPermissions
 * @param {number} entityId - the id of entity the permissions are for.
 * @returns array of {id: #, userId: #, ipType: '', families: [], permission: '', entityId: #} objects to be used in constructing group permissions.
 */
function getAdminGroupPermissions(entityId) {
  return supportedIPTypes.map((type) => ({
    ipType: type,
    families: [{ label: 'all', value: 0 }],
    permissionType: { value: true, type: 'edit' },
    entityId,
  }));
}

export { getSupportedIPTypes, getAdminGroupPermissions };
