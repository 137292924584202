<script>
  import jstz from 'jstz';
  import { afterUpdate, createEventDispatcher, onMount, tick } from 'svelte';
  import { router } from 'tinro';
  import { mdiCheck, mdiLockReset } from '@mdi/js';

  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    Icon,
    TextField,
  } from 'svelte-materialify';
  import { accountInfo, authenticated } from '../services/account';
  import { apiFetch } from '../services/network';
  import { _ } from '../services/i18n';

  const dispatch = createEventDispatcher();

  const timezone = jstz.determine();
  export let email = '';
  export let password = '';
  export let phoneNumber = '';
  export let redirect = true;
  export let showChangePhoneNumberButton = false;
  export let onProfilePage = false;
  export let url = `/authentication/signin`;
  let canceled = false;
  let providedOneTimePassword = '';
  const resetOTP = false;
  let errorString = '';
  let readonly = true;
  let newCodeSent = false;
  let OTPEmailField;
  let OTPCodeField;
  let isFocused = false;

  $: buttonsDisabled = !email || !password || !providedOneTimePassword;

  onMount(() => {
    errorString = '';
  });

  afterUpdate(() => {
    if (!isFocused) {
      if (!email) {
        OTPEmailField.focus();
        isFocused = true;
      } else if (providedOneTimePassword === '') {
        OTPCodeField.focus();
        isFocused = true;
      }
    }
  });

  function changePhoneNumber(event) {
    dispatch('needOTPPhoneNumber');
  }

  async function signInOTPFormSubmitted(event) {
    const request = {
      email,
      password,
      phoneNumber,
      oneTimePassword: providedOneTimePassword,
      timezone: timezone.name(),
      canceled,
    };
    if (password === '') {
      readonly = false;
    }
    const response = await apiFetch(url, 'POST', request);
    if (response.ok) {
      if (!canceled) {
        dispatch('oneTimePasswordProvided');
      }
      await tick();
      if (response.status != 204) {
        const signinReply = await response.json();
        accountInfo.update((info) => signinReply);
      }
      authenticated.update((auth) => true);
      dispatch('resetLogin');
      if (onProfilePage) {
      } else if (redirect) {
        router.goto('/');
      }
    } else {
      const signinReply = await response.json();
      if (response.status === 400 && signinReply.reason === 'oneTimePasswordIncorrect') {
        errorString = $_('signInOTP.fail.incorrectPassword');
      } else if (response.status === 401 && signinReply.reason === 'oneTimePasswordExpired') {
        errorString = $_('signInOTP.fail.oneTimePasswordExpired');
      } else if (response.status === 401 && signinReply.reason === 'tooManyFailedLoginAttempts') {
        errorString = $_('signInOTP.fail.tooManyFailedLoginAttempts');
      } else if (response.status === 401) {
        errorString = $_('signInOTP.fail.unauthorized');
      } else if (response.status === 403) {
        errorString = $_('signInOTP.fail.suspended');
      } else if (
        response.status === 409 &&
        (signinReply.reason === 'mustProvidePhoneNumber' ||
          signinReply.reason === 'mustProvideOneTimePassword')
      ) {
      } else if (response.status === 409) {
        errorString = $_(`signInOTP.fail.${signinReply.reason}`);
      } else {
        errorString = $_('signInOTP.fail.other');
      }

      return response;
    }
  }

  function getNewOTP(event) {
    providedOneTimePassword = null;
    newCodeSent = true;
    signInOTPFormSubmitted();
  }

  function cancel(event) {
    canceled = true;
    signInOTPFormSubmitted();
    dispatch('phoneNumberChangeCanceled');
  }
</script>

<Dialog persistent={true} active={true}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <div class="d-flex align-center">
        <img
          src="/images/logos/Fortress_RGB.png"
          class="logo"
          alt="{$_('app.name')} logo"
          title="{$_('app.name')}"
        />
      </div>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <h5 class="pt-2 text-center">{$_('signInOTP.banner')}</h5>
      <div>
        <p />
        <form on:submit|preventDefault={signInOTPFormSubmitted}>
          <TextField
            type="email"
            outlined={true}
            color={'secondary'}
            readonly={true}
            placeholder={$_('signInOTP.emailAddress')}
            bind:value={email}
            bind:inputElement={OTPEmailField}
          >
            {$_('signInOTP.emailAddress')}
          </TextField>
          <TextField
            type="text"
            autocomplete="one-time-code"
            name="token"
            outlined={true}
            clearable={true}
            color={'secondary'}
            placeholder={$_('signInOTP.providedOneTimePassword')}
            bind:value={providedOneTimePassword}
            bind:inputElement={OTPCodeField}
          >
            {$_('signInOTP.providedOneTimePassword')}
          </TextField>
          <div class="error-text">
            {errorString}
          </div>
          <div class="p2-4 pb-4">
            <Button class="secondary-color" size="x-small" outlined on:click={getNewOTP}>
              {$_('signInOTP.resetCode')}
              {#if newCodeSent}
                <Icon path={mdiCheck} class="mr-3" />
              {/if}
            </Button>
            {#if showChangePhoneNumberButton}
              <Button
                class="secondary-color"
                style="float: right;"
                size="x-small"
                outlined
                on:click={changePhoneNumber}
              >
                {$_('signInOTP.changePhoneNumber')}
              </Button>
            {/if}
          </div>
          <Divider class="secondary-color" />
          <div class="pt-4">
            {#if onProfilePage}
              <Button
                class={buttonsDisabled ? 'grey-color' : 'secondary-color'}
                type="submit"
                disabled={buttonsDisabled}
                color="secondary"
              >
                <Icon
                  path={mdiLockReset}
                  class={buttonsDisabled ? 'grey-color' : 'secondary-color'}
                />{$_('signInOTP.submit')}
              </Button>
              <Button class="" style="float: right;" type="button" on:click={cancel}>
                {$_('changePhoneNumber.cancel')}
              </Button>
            {:else if redirect}
              <Button
                block
                class={buttonsDisabled ? 'grey-color' : 'secondary-color'}
                type="submit"
                disabled={buttonsDisabled}
                color="secondary"
              >
                <Icon
                  path={mdiLockReset}
                  class={buttonsDisabled ? 'grey-color' : 'secondary-color'}
                />{$_('signInOTP.signIn')}
              </Button>
            {:else}
              <Button
                class={buttonsDisabled ? 'grey-color' : 'secondary-color'}
                type="submit"
                disabled={buttonsDisabled}
                color="secondary"
              >
                <Icon
                  path={mdiLockReset}
                  class={buttonsDisabled ? 'grey-color' : 'secondary-color'}
                />{$_('signInOTP.signIn')}
              </Button>
            {/if}
          </div>
        </form>
      </div>
    </CardText>
  </Card>
</Dialog>
