<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider } from 'svelte-materialify';
  import { _ } from '../../services/i18n';
  import { validateForm } from '../../services/validation';
  import { apiFetch } from '../../services/network';
  import DateField from '../DateField.svelte';
  import TextField from '../TextField.svelte';

  const dispatch = createEventDispatcher();
  export let show;
  export let contractId;
  export let contractDate = {
    description: '',
    date: '',
    reminderDate: '',
    reminderTarget: 'owner',
  };
  let contractDateDescriptionField;
  let isFocused = false;

  const newContractDate = {};
  Object.assign(newContractDate, contractDate);
  afterUpdate(async () => {
    if (!isFocused && !contractDate.description) {
      contractDateDescriptionField.focus();
      isFocused = true;
    }
  });

  const today = new Date().toISOString().split('T')[0];

  $: saveDisabled = !newContractDate.description || !newContractDate.date;

  let formElement;
  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function save(event) {
    event.preventDefault();
    Object.assign(contractDate, newContractDate);
    if (!validateForm(formElement)) {
      return;
    }

    let url = `/contract/${contractId}/date`;
    let method = 'POST';
    if (contractDate.id) {
      url += `/${contractDate.id}`;
      method = 'PUT';
    }
    const request = {};
    for (const property in newContractDate) {
      if (
        newContractDate[property] != null &&
        newContractDate[property].length > 0 &&
        newContractDate !== 'id'
      ) {
        request[property] = newContractDate[property];
      }
    }
    saveDisabled = true;
    const response = await apiFetch(url, method, request);
    saveDisabled = !newContractDate.description || !newContractDate.date;
    if (response.ok) {
      show = false;
      const dateList = await response.json();
      dispatch('save', { dateList });
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      {#if contractDate.id}
        <h6 class="secondary-text">{$_('contract.edit')} {$_('contract.dates.singular')}</h6>
      {:else}
        <h6 class="secondary-text">{$_('contract.new')} {$_('contract.dates.singular')}</h6>
      {/if}
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <TextField
          bind:value={newContractDate.description}
          bind:inputElement={contractDateDescriptionField}
          class="pb-2"
          outlined={true}
          dense
          color={'secondary'}
          required
        >
          {$_('contract.dates.description')}
        </TextField>
        <DateField
          bind:value={newContractDate.date}
          class="pb-2"
          outlined={true}
          dense
          clearable
          color={'secondary'}
          required
        >
          {$_('contract.dates.date')}
        </DateField>
        <DateField
          bind:value={newContractDate.reminderDate}
          bind:max={newContractDate.date}
          class="pb-2"
          outlined={true}
          dense
          clearable
          color={'secondary'}
        >
          {$_('contract.dates.reminder-date')}
        </DateField>

        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          on:click={save}
          text
          bind:disabled={saveDisabled}
          class="mt-4 {saveDisabled ? 'grey-color' : 'secondary-color'}"
        >
          {$_('contract.save')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('contract.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
