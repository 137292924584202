<script>
  import { createEventDispatcher } from 'svelte';
  import { Button, Card, CardText, CardTitle, Dialog, Divider, Select } from 'svelte-materialify';
  import { _ } from '../../../services/i18n';
  import { validateForm } from '../../../services/validation';
  import { apiFetch } from '../../../services/network';

  const dispatch = createEventDispatcher();
  export let show;
  export let tenantId = -1;
  export let userId;
  export let itemPermission;
  export let permissionSet;

  const permissionSetItems = [
    { name: $_('administration.users.item-permissions.permission-set.create'), value: 'create' },
    { name: $_('administration.users.item-permissions.permission-set.edit'), value: 'edit' },
    { name: $_('administration.users.item-permissions.permission-set.view'), value: 'view' },
  ];

  let formElement;

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function save(event) {
    event.preventDefault();
    if (!validateForm(formElement)) {
      return;
    }

    let url = `/api/user/${userId}/item-access-permission/${itemPermission.id}/${permissionSet}`;
    const method = 'PUT';
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const response = await apiFetch(url, method);
    if (response.ok) {
      show = false;
      const item = await response.json();
      dispatch('save', item);
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('administration.users.edit')}
        {$_('administration.users.item-permissions.singular')}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <Select
          outlined
          dense
          items={permissionSetItems}
          bind:value={permissionSet}
          class="pb-2"
          mandatory
        >
          {$_('administration.users.item-permissions.table.permissions')}
        </Select>

        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          class="mt-4 {permissionSet ? 'secondary-color' : 'grey-color'}"
          on:click={save}
          text
          disabled={!permissionSet}
        >
          {$_('administration.users.save')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('administration.users.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
