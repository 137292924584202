<script>
  import { Icon } from 'svelte-materialify';
  import {
    mdiCalendarOutline,
    mdiEmailOutline,
    mdiFileExcelOutline,
    mdiFileImageOutline,
    mdiFileMusicOutline,
    mdiFileOutline,
    mdiFilePdfBox,
    mdiFilePowerpointOutline,
    mdiFileVideoOutline,
    mdiFileWordOutline,
    mdiFolderZipOutline,
  } from '@mdi/js';

  export let filename;
  export let classes = '';

  const extensionToIcon = {
    doc: mdiFileWordOutline,
    docx: mdiFileWordOutline,
    xls: mdiFileExcelOutline,
    xlsx: mdiFileExcelOutline,
    ppt: mdiFilePowerpointOutline,
    pptx: mdiFilePowerpointOutline,
    jpg: mdiFileImageOutline,
    jpeg: mdiFileImageOutline,
    png: mdiFileImageOutline,
    tiff: mdiFileImageOutline,
    svg: mdiFileImageOutline,
    psd: mdiFileImageOutline,
    ai: mdiFileImageOutline,
    mp4: mdiFileVideoOutline,
    wmv: mdiFileVideoOutline,
    mov: mdiFileVideoOutline,
    avi: mdiFileVideoOutline,
    webm: mdiFileVideoOutline,
    mkv: mdiFileVideoOutline,
    mp3: mdiFileVideoOutline,
    aiff: mdiFileMusicOutline,
    aac: mdiFileMusicOutline,
    wav: mdiFileMusicOutline,
    wma: mdiFileMusicOutline,
    pdf: mdiFilePdfBox,
    zip: mdiFolderZipOutline,
    tar: mdiFolderZipOutline,
    gz: mdiFolderZipOutline,
    tgz: mdiFolderZipOutline,
    bzip: mdiFolderZipOutline,
    eml: mdiEmailOutline,
    ics: mdiCalendarOutline,
  };

  function getIconPathForFileName() {
    const extension = filename.split('.').pop().toLowerCase();
    let icon = mdiFileOutline;

    if (extensionToIcon.hasOwnProperty(extension)) {
      icon = extensionToIcon[extension];
    }
    return icon;
  }
</script>

<Icon class={classes} path={getIconPathForFileName()} />
