<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Dialog,
    Divider,
    TextField,
  } from 'svelte-materialify';
  import { _ } from '../../services/i18n';
  import { validateForm } from '../../services/validation';
  import { apiFetch } from '../../services/network';
  // import TextField from '../../TextField.svelte';

  const dispatch = createEventDispatcher();

  export let show;
  export let parentFileSystemNode = null;
  export let fileSystemGroupName = null;

  let formElement;
  let name = '';
  let folderNameField;
  let duplicateFolderName = false;
  let isFocused = false;

  afterUpdate(() => {
    if (!isFocused && !name) {
      folderNameField.focus();
      isFocused = true;
    }
  });

  function cancel(event) {
    show = false;
    dispatch('cancel');
  }

  async function save(event) {
    event.preventDefault();
    if (!validateForm(formElement)) {
      return;
    }
    let url;
    let request;
    if (parentFileSystemNode) {
      url = `/api/file-system/folder/${parentFileSystemNode.id}/create`;
      request = { name };
    } else {
      url = `/api/file-system`;
      request = { name, fileSystemGroupName };
    }
    const response = await apiFetch(url, 'POST', request);
    if (response.ok) {
      show = false;
      const fileSystemNode = await response.json();
      dispatch('create', { fileSystemNode });
    } else if (response.status === 409) {
      duplicateFolderName = true;
    }
  }
</script>

<Dialog persistent={true} bind:active={show}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">{$_('files.create-folder-dialog.title')}</h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <TextField
          bind:value={name}
          bind:inputElement={folderNameField}
          class="pb-2"
          outlined={true}
          dense
          clearable
          color={'secondary'}
          required
        >
          {$_('files.create-folder-dialog.folder-name')}
        </TextField>
        {#if duplicateFolderName}
          <span class="error-text">{$_('files.create-folder-dialog.duplicate-folder')}</span>
        {/if}
        <Divider class="mt-4 secondary-color" />
        <Button
          type="submit"
          class="mt-4 {name ? 'secondary-color' : 'grey-color'}"
          on:click={save}
          text
          disabled={!name}
        >
          {$_('files.create-folder-dialog.create')}
        </Button>
        <Button class="mt-4 ml-2" on:click={cancel} text>
          {$_('files.create-folder-dialog.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
</Dialog>
