<script>
  import { onDestroy, onMount } from 'svelte';
  import { _ } from '../services/i18n';
  import DataRooms from './FileSystem/DataRooms.svelte';

  onMount(() => {});

  onDestroy(() => {});
</script>

<h5 class="secondary-text text-left">{$_('company.title')}</h5>
<br />
<DataRooms fileSystemGroupName={'corporate'} />

<style>
  ul {
    list-style-type: none;
  }
</style>
