<script context="module">
  const createRights = ['create'];
  const editRights = ['share', 'list', 'read', 'create', 'update', 'delete'];
  const viewRights = ['list', 'read'];

  let groupAccessPermissions = [];

  function mapRights(rightsList) {
    const rightsSet = new Set(rightsList);
    let difference = [...new Set(editRights.filter((x) => !rightsSet.has(x)))];
    if (difference.length === 0) {
      return 'edit';
    }
    difference = [...new Set(createRights.filter((x) => !rightsSet.has(x)))];
    if (difference.length === 0) {
      return 'create';
    }
    difference = [...new Set(viewRights.filter((x) => !rightsSet.has(x)))];
    if (difference.length === 0) {
      return 'view';
    }
    throw new Error(`UserPermissions.mapRights() failed to map ${JSON.stringify(rightsList)}`);
  }

  /**
   *
   * @param uid The userId to retrieve permissions for
   * @param tid The tenantId the user has group access permissions for
   */
  export async function loadUserGroupAccessPermissions(uid, tid) {
    const url = `/api/user/${uid}/group-access-permission?tenantId=${tid}`;
    const response = await apiFetch(url);
    if (response.ok) {
      const tempGroupAccessPermissions = await response.json();
      for (const tgap of tempGroupAccessPermissions) {
        tgap.groupType = tgap.group.groupType;
        tgap.groupName = tgap.group.name;
        tgap.tenantType = tgap.tenant.type;
        tgap.tenantName = tgap.tenant.name;
        tgap.mappedRights = mapRights(tgap.rights);
      }
      return tempGroupAccessPermissions;
    }
    return [];
  }
</script>

<script>
  import { createEventDispatcher, onMount, onDestroy } from 'svelte';
  import {
    Breadcrumbs,
    Checkbox,
    ProgressLinear,
    ExpansionPanels,
    ExpansionPanel,
    Container,
    Row,
    Snackbar,
  } from 'svelte-materialify';
  import { mdiPencilOutline, mdiDeleteOutline, mdiPlus, mdiPlusThick } from '@mdi/js';
  import { _ } from '../../../services/i18n';
  import { apiFetch } from '../../../services/network';
  import {
    accountHasTenantPermission,
    SYSTEM_WIDE_TENANT_ADMIN,
    SYSTEM_WIDE_CLIENT_ADMIN,
    SYSTEM_WIDE_USER_ADMIN,
    PER_TENANT_USER_ADMIN,
    PER_TENANT_CLIENT_ADMIN,
    PER_TENANT_CONTENT_ADMIN,
  } from '../../../services/account';
  import DeleteGroupPermission from './DeleteGroupPermission.svelte';
  import EditGroupPermission from './EditGroupPermission.svelte';
  import AddItemPermission from './AddItemPermission.svelte';
  import EditItemPermission from './EditItemPermission.svelte';
  import DeleteItemPermission from './DeleteItemPermission.svelte';
  import DataTable from '../../DataTable.svelte';
  import AddGroupPermission from './AddGroupPermission.svelte';
  import AddGroupPermissionV2 from '../Permissions/AddGroupPermissionV2.svelte';
  import { cellRendererFactory } from '../../DataTable/CellRendererFactory';
  import DataTableRowActions from '../../DataTable/DataTableRowActions.svelte';

  export let tenantId;
  export let userId;
  export let initialBreadCrumbItems = [];
  let breadCrumbItems = [];
  let user;
  let selectedEntities = [];
  let itemAccessPermissions = [];
  const dispatch = createEventDispatcher();
  let panelStates = [0, 1, 2, 3];

  let selectedGroupPermission = {};
  let selectedItemPermission = {};
  let selectedPermissionSet = '';
  let showDeleteGroupPermissionDialog = false;
  let showEditGroupPermissionDialog = false;
  let showAddGroupPermissionDialog = false;
  let showAddGroupPermissionDialogV2 = false;
  let showDeleteItemPermissionDialog = false;
  let showEditItemPermissionDialog = false;
  let showAddItemPermissionDialog = false;
  let dataTableGroup;
  let dataTableItem;
  const col = 0;
  let showSnackBar = false;
  let errorString = '';

  onDestroy(() => {
    groupAccessPermissions = [];
  });

  const rowActionsGroup = [
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.group-permissions.edit'),
      path: mdiPencilOutline,
      onClickEvent: 'editGroupPermissionClicked',
      elementId: `group-access-permissions`,
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.group-permissions.delete'),
      path: mdiDeleteOutline,
      onClickEvent: 'deleteGroupPermissionClicked',
      elementId: `group-access-permissions`,
      value: '',
      cssClasses: ['red-text'],
    },
  ];
  const columnDefsGroup = [
    { field: 'id', headerName: '', hide: true, suppressColumnsToolPanel: true },
    {
      field: 'buttons',
      headerName: '',
      pinned: 'left',
      resizable: false,
      sortable: false,
      maxWidth: 40,
      editable: false,
      cellClass: 'table-actions pa-0 text-center',
      cellStyle: { cursor: 'pointer' },
      cellRenderer: cellRendererFactory((cell) => {
        new DataTableRowActions({
          target: cell.eGui,
        });
      }),
      suppressColumnsToolPanel: true,
    },
    {
      field: 'groupType',
      headerName: $_('administration.users.group-permissions.table.groupType'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'groupName',
      headerName: $_('administration.users.group-permissions.table.groupName'),
      sortable: true,
      filter: true,
      resizable: true,

      sort: 'asc',
    },
    {
      field: 'tenantType',
      headerName: $_('administration.users.group-permissions.table.tenantType'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'tenantName',
      headerName: $_('administration.users.group-permissions.table.tenantName'),
      sortable: true,
      filter: true,
      resizable: true,

      sort: 'asc',
    },
    {
      field: 'mappedRights',
      headerName: $_('administration.users.group-permissions.table.permissions'),
      sortable: true,
      filter: true,
      resizable: true,
    },
  ];

  const globalActionsGroup = [
    {
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.new'),
      path: mdiPlus,
      onClickEvent: 'newGroupPermissionClicked',
      elementId: `group-access-permissions`,
    },
    {
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.new-v2'),
      path: mdiPlusThick,
      onClickEvent: 'newGroupPermissionClickedV2',
      elementId: `group-access-permissions`,
    },
  ];

  const rowActionsItem = [
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.item-permissions.edit'),
      path: mdiPencilOutline,
      onClickEvent: 'editItemPermissionClicked',
      elementId: `item-access-permissions`,
      value: '',
    },
    {
      type: 'button',
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.item-permissions.delete'),
      path: mdiDeleteOutline,
      onClickEvent: 'deleteItemPermissionClicked',
      elementId: `item-access-permissions`,
      value: '',
      cssClasses: ['red-text'],
    },
  ];

  const columnDefsItem = [
    { field: 'id', headerName: '', hide: true, suppressColumnsToolPanel: true },
    {
      field: 'buttons',
      headerName: '',
      pinned: 'left',
      resizable: false,
      sortable: false,
      maxWidth: 40,
      editable: false,
      cellClass: 'table-actions pa-0 text-center',
      cellStyle: { cursor: 'pointer' },
      cellRenderer: cellRendererFactory((cell) => {
        new DataTableRowActions({
          target: cell.eGui,
        });
      }),
      suppressColumnsToolPanel: true,
    },
    {
      field: 'itemType',
      headerName: $_('administration.users.item-permissions.table.itemType'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'itemName',
      headerName: $_('administration.users.item-permissions.table.itemName'),
      sortable: true,
      filter: true,
      resizable: true,

      sort: 'asc',
    },
    {
      field: 'tenantType',
      headerName: $_('administration.users.item-permissions.table.tenantType'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'tenantName',
      headerName: $_('administration.users.item-permissions.table.tenantName'),
      sortable: true,
      filter: true,
      resizable: true,

      sort: 'asc',
    },
    {
      field: 'mappedRights',
      headerName: $_('administration.users.item-permissions.table.permissions'),
      sortable: true,
      filter: true,
      resizable: true,
    },
  ];

  const globalActionsItem = [
    {
      enabledFunction: isActionEnabled,
      href: '',
      title: $_('administration.users.new'),
      path: mdiPlus,
      onClickEvent: 'newItemPermissionClicked',
      elementId: `item-access-permissions`,
    },
  ];

  function isActionEnabled(item) {
    return (
      accountHasTenantPermission(SYSTEM_WIDE_USER_ADMIN) ||
      accountHasTenantPermission(PER_TENANT_USER_ADMIN)
    );
  }

  async function load() {
    const url = `/api/user/${userId}?tenantId=${tenantId}`;
    const response = await apiFetch(url);
    if (response.ok) {
      user = await response.json();
      breadCrumbItems = initialBreadCrumbItems.concat([
        {
          text: `${$_('administration.users.title')}`,
          href: `/administration/tenant/${tenantId}/user`,
        },
        { text: `${user.email} - ${$_('administration.users.permissions')}` },
      ]);
    }
  }

  async function loadGroupAccessPermissions() {
    groupAccessPermissions = await loadUserGroupAccessPermissions(userId, tenantId);
  }

  async function loadItemAccessPermissions() {
    const url = `/api/user/${userId}/item-access-permission?tenantId=${tenantId}`;
    const response = await apiFetch(url);
    if (response.ok) {
      itemAccessPermissions = await response.json();
    }
    for (const itemAccessPermission of itemAccessPermissions) {
      itemAccessPermission.itemType = itemAccessPermission.item.itemType;
      itemAccessPermission.itemName = itemAccessPermission.item.name;
      itemAccessPermission.tenantType = itemAccessPermission.tenant.type;
      itemAccessPermission.tenantName = itemAccessPermission.tenant.name;
      itemAccessPermission.mappedRights = mapRights(itemAccessPermission.rights);
    }
  }

  async function grantPermission(permission) {
    let url = `/api/user/${userId}/${permission}`;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const response = await apiFetch(url, 'POST');
    if (response.ok) {
      user = await response.json();
    }
  }

  async function revokePermission(permission) {
    let url = `/api/user/${userId}/${permission}`;
    if (tenantId > -1) {
      url += `?tenantId=${tenantId}`;
    }
    const response = await apiFetch(url, 'DELETE');
    if (response.ok) {
      user = await response.json();
    }
  }

  function togglePermission(permission) {
    if (user.tenantPermissions.includes(permission)) {
      revokePermission(permission);
    } else {
      grantPermission(permission);
    }
  }

  function toggleSystemWideTenantAdmin(event) {
    togglePermission(SYSTEM_WIDE_TENANT_ADMIN);
  }

  function toggleSystemWideUserAdmin(event) {
    togglePermission(SYSTEM_WIDE_USER_ADMIN);
  }

  function toggleSystemWideClientAdmin(event) {
    togglePermission(SYSTEM_WIDE_CLIENT_ADMIN);
  }

  function togglePerTenantUserAdmin(event) {
    togglePermission(PER_TENANT_USER_ADMIN);
  }

  function togglePerTenantClientAdmin(event) {
    togglePermission(PER_TENANT_CLIENT_ADMIN);
  }

  function togglePerTenantContentAdmin(event) {
    togglePermission(PER_TENANT_CONTENT_ADMIN);
  }

  function findGroupPermissionById(id) {
    return groupAccessPermissions.find((p) => p.id === id);
  }

  function findItemPermissionById(id) {
    return itemAccessPermissions.find((p) => p.id === id);
  }

  function editGroupPermissionClicked(event) {
    selectedGroupPermission = findGroupPermissionById(event.detail.id);
    selectedPermissionSet = mapRights(selectedGroupPermission.rights);
    showEditGroupPermissionDialog = true;
  }

  function groupPermissionUpdated(event) {
    for (const groupAccessPermission of groupAccessPermissions) {
      if (groupAccessPermission.id == event.detail.id) {
        groupAccessPermission.rights = event.detail.rights;
        groupAccessPermission.mappedRights = mapRights(groupAccessPermission.rights);
        break;
      }
    }
    dataTableGroup.updateRow(event.detail.id);
  }

  function groupPermissionAdded(event) {
    console.log('In groupPermissionAdded');
    console.log(`event ${JSON.stringify(event.detail, null, 2)}`);
    const { item } = event.detail;
    if (!item.userId || item.userId == userId) {
      if (item.groupId == 0) {
        item.groupName = 'all';
      }
      item.group = { id: item.groupId, groupType: item.groupType, name: item.groupName };
      item.tenantType = item.userProfile.tenant.type;
      item.tenantName = item.userProfile.tenant.name;
      item.mappedRights = mapRights(item.rights);
      groupAccessPermissions.push(item);
      groupAccessPermissions = groupAccessPermissions;
      dataTableGroup.addNewRows([item]);
    }
  }

  function itemPermissionAdded(event) {
    const items = event.detail.permissions;
    const add = [];
    for (const item of items) {
      item.itemType = item.itemType;
      if (item.itemId == 0) {
        item.itemName = 'all';
      }
      item.item = { id: item.itemId, itemType: item.itemType, name: item.itemName };
      item.tenantType = item.userProfile.tenant.type;
      item.tenantName = item.userProfile.tenant.name;
      item.mappedRights = mapRights(item.rights);
      itemAccessPermissions.push(item);
      add.push(item);
    }
    dataTableItem.addNewRows(add);
  }

  function deleteGroupPermissionClicked(event) {
    selectedGroupPermission = findGroupPermissionById(event.detail.id);
    showDeleteGroupPermissionDialog = true;
  }

  function groupPermissionDeleted(event) {
    for (let i = 0; i < groupAccessPermissions.length; i++) {
      if (groupAccessPermissions[i].id == event.detail.id) {
        dataTableGroup.deleteRows(event.detail.id);
        groupAccessPermissions.splice(i, 1);
        break;
      }
    }
  }

  function editItemPermissionClicked(event) {
    selectedItemPermission = findItemPermissionById(event.detail.id);
    selectedPermissionSet = mapRights(selectedItemPermission.rights);
    showEditItemPermissionDialog = true;
  }

  function itemPermissionUpdated(event) {
    for (const itemAccessPermission of itemAccessPermissions) {
      if (itemAccessPermission.id == event.detail.id) {
        itemAccessPermission.rights = event.detail.rights;
        itemAccessPermission.mappedRights = mapRights(itemAccessPermission.rights);
        break;
      }
    }
    dataTableItem.updateRow(event.detail.id);
  }

  function deleteItemPermissionClicked(event) {
    selectedItemPermission = findItemPermissionById(event.detail.id);
    showDeleteItemPermissionDialog = true;
  }

  function itemPermissionDeleted(event) {
    for (let i = 0; i < itemAccessPermissions.length; i++) {
      if (itemAccessPermissions[i].id == event.detail.id) {
        dataTableItem.deleteRows(event.detail.id);
        itemAccessPermissions.splice(i, 1);
        break;
      }
    }
  }

  function newGroupPermissionClickedV2(event) {
    showAddGroupPermissionDialogV2 = true;
  }

  function newGroupPermissionClicked(event) {
    showAddGroupPermissionDialog = true;
  }

  function newItemPermissionClicked(event) {
    showAddItemPermissionDialog = true;
  }
</script>

{#await load()}
  <ProgressLinear />
{:then}
  <Breadcrumbs bind:items={breadCrumbItems} />
  <ExpansionPanels multiple bind:value={panelStates} accordion>
    <ExpansionPanel>
      <span slot="header" class="text-h6 secondary-text">
        {$_('administration.users.system-permissions')}
      </span>
      <Container>
        {#if accountHasTenantPermission(SYSTEM_WIDE_TENANT_ADMIN)}
          <Row>
            <Checkbox
              on:change={toggleSystemWideTenantAdmin}
              checked={user.tenantPermissions.includes(SYSTEM_WIDE_TENANT_ADMIN)}
              color="secondary"
            >
              {$_(`administration.users.${SYSTEM_WIDE_TENANT_ADMIN}`)}
            </Checkbox>
          </Row>
        {/if}
        {#if accountHasTenantPermission(SYSTEM_WIDE_USER_ADMIN)}
          <Row>
            <Checkbox
              on:change={toggleSystemWideUserAdmin}
              checked={user.tenantPermissions.includes(SYSTEM_WIDE_USER_ADMIN)}
              color="secondary"
            >
              {$_(`administration.users.${SYSTEM_WIDE_USER_ADMIN}`)}
            </Checkbox>
          </Row>
        {/if}
        {#if accountHasTenantPermission(SYSTEM_WIDE_CLIENT_ADMIN)}
          <Row>
            <Checkbox
              on:change={toggleSystemWideClientAdmin}
              checked={user.tenantPermissions.includes(SYSTEM_WIDE_CLIENT_ADMIN)}
              color="secondary"
            >
              {$_(`administration.users.${SYSTEM_WIDE_CLIENT_ADMIN}`)}
            </Checkbox>
          </Row>
        {/if}
      </Container>
    </ExpansionPanel>
    <ExpansionPanel>
      <span slot="header" class="text-h6 secondary-text">
        {$_('administration.users.tenant-permissions', {
          values: { tenantName: user.tenant.name },
        })}
      </span>
      <Container>
        {#if accountHasTenantPermission(SYSTEM_WIDE_USER_ADMIN) || accountHasTenantPermission(PER_TENANT_USER_ADMIN)}
          <Row>
            <Checkbox
              on:change={togglePerTenantUserAdmin}
              checked={user.tenantPermissions.includes(PER_TENANT_USER_ADMIN)}
              color="secondary"
            >
              {$_(`administration.users.${PER_TENANT_USER_ADMIN}`)}
            </Checkbox>
          </Row>
        {/if}
        {#if accountHasTenantPermission(SYSTEM_WIDE_USER_ADMIN) || accountHasTenantPermission(PER_TENANT_CLIENT_ADMIN)}
          <Row>
            <Checkbox
              on:change={togglePerTenantClientAdmin}
              checked={user.tenantPermissions.includes(PER_TENANT_CLIENT_ADMIN)}
              color="secondary"
            >
              {$_(`administration.users.${PER_TENANT_CLIENT_ADMIN}`)}
            </Checkbox>
          </Row>
        {/if}
        {#if accountHasTenantPermission(SYSTEM_WIDE_USER_ADMIN) || accountHasTenantPermission(PER_TENANT_CONTENT_ADMIN)}
          <Row>
            <Checkbox
              on:change={togglePerTenantContentAdmin}
              checked={user.tenantPermissions.includes(PER_TENANT_CONTENT_ADMIN)}
              color="secondary"
            >
              {$_(`administration.users.${PER_TENANT_CONTENT_ADMIN}`)}
            </Checkbox>
          </Row>
        {/if}
      </Container>
    </ExpansionPanel>

    <ExpansionPanel>
      <span class="text-h6 secondary-text" slot="header"
        >{$_('administration.users.group-permissions.title')}</span
      >
      {#await loadGroupAccessPermissions()}
        <ProgressLinear />
      {:then}
        <div
          id="group-access-permissions"
          style="width: 100%"
          on:newGroupPermissionClicked={newGroupPermissionClicked}
          on:newGroupPermissionClickedV2={newGroupPermissionClickedV2}
        >
          <DataTable
            bind:rowData={groupAccessPermissions}
            bind:this={dataTableGroup}
            columnDefs={columnDefsGroup}
            rowActions={rowActionsGroup}
            globalActions={globalActionsGroup}
            tableName="group-permissions"
            on:editGroupPermissionClicked={editGroupPermissionClicked}
            on:deleteGroupPermissionClicked={deleteGroupPermissionClicked}
          />
        </div>
      {/await}
    </ExpansionPanel>
    <ExpansionPanel>
      <span class="text-h6 secondary-text" slot="header"
        >{$_('administration.users.item-permissions.title')}</span
      >
      {#await loadItemAccessPermissions()}
        <ProgressLinear />
      {:then}
        <div
          id="item-access-permissions"
          style="width: 100%"
          on:newItemPermissionClicked={newItemPermissionClicked}
        >
          <DataTable
            bind:rowData={itemAccessPermissions}
            bind:this={dataTableItem}
            columnDefs={columnDefsItem}
            rowActions={rowActionsItem}
            globalActions={globalActionsItem}
            tableName="item-permissions"
            on:editItemPermissionClicked={editItemPermissionClicked}
            on:deleteItemPermissionClicked={deleteItemPermissionClicked}
          />
        </div>
      {/await}
    </ExpansionPanel>
  </ExpansionPanels>
{/await}

{#if showDeleteGroupPermissionDialog}
  <DeleteGroupPermission
    bind:show={showDeleteGroupPermissionDialog}
    {tenantId}
    {userId}
    groupPermission={selectedGroupPermission}
    on:delete={groupPermissionDeleted}
  />
{/if}

{#if showEditGroupPermissionDialog}
  <EditGroupPermission
    bind:show={showEditGroupPermissionDialog}
    {tenantId}
    {userId}
    groupPermission={selectedGroupPermission}
    permissionSet={mapRights(selectedGroupPermission.rights)}
    on:save={groupPermissionUpdated}
  />
{/if}

{#if showAddGroupPermissionDialog}
  <AddGroupPermission
    bind:show={showAddGroupPermissionDialog}
    {tenantId}
    {userId}
    on:save={groupPermissionAdded}
  />
{/if}
{#if showAddGroupPermissionDialogV2}
  <AddGroupPermissionV2
    bind:show={showAddGroupPermissionDialogV2}
    {userId}
    bind:showSnackBar
    bind:errorString
    bind:selectedEntities
    on:save={groupPermissionAdded}
    on:updated={groupPermissionUpdated}
  />
{/if}

{#if showDeleteItemPermissionDialog}
  <DeleteItemPermission
    bind:show={showDeleteItemPermissionDialog}
    {tenantId}
    {userId}
    itemPermission={selectedItemPermission}
    on:delete={itemPermissionDeleted}
  />
{/if}

{#if showEditItemPermissionDialog}
  <EditItemPermission
    bind:show={showEditItemPermissionDialog}
    {tenantId}
    {userId}
    itemPermission={selectedItemPermission}
    permissionSet={mapRights(selectedItemPermission.rights)}
    on:save={itemPermissionUpdated}
  />
{/if}

{#if showAddItemPermissionDialog}
  <AddItemPermission
    bind:show={showAddItemPermissionDialog}
    {tenantId}
    {userId}
    on:save={itemPermissionAdded}
  />
{/if}

<Snackbar
  class="flex-column"
  bind:active={showSnackBar}
  timeout={4000}
  top
  right
  on:outroend={() => (errorString = '')}
>
  {errorString}
</Snackbar>

<style>
  table {
    border: 1px solid white;
    border-collapse: collapse;
  }
  thead > th:first-child {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }
  thead > th:last-child {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  th {
    border: 1px solid white;
    height: 41px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #1a76d2;
    color: white;
  }
  td {
    padding: 4px;
    border-bottom: 1px solid #1a76d2;
  }
</style>
