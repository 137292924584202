<script>
  import { ProgressLinear } from 'svelte-materialify';
  import { _ } from '../../services/i18n';
  import { apiFetch } from '../../services/network';
  import DataTable from '../DataTable.svelte';
  import DataTableRowActions from '../DataTable/DataTableRowActions.svelte';
  import { cellRendererFactory } from '../DataTable/CellRendererFactory';

  export let rowSelectable = false;
  export let list;
  let currentItem;
  export let group;
  // Array of objects that the button renderer uses to create the buttons for each table.
  export let rowActions = [];
  // same as rowActions but buttons go above the datatable
  export let globalActions = [];
  export let linkActions;
  export let tableName;
  let dataTable;

  function extractValues(mappings) {
    return Object.keys(mappings);
  }

  // let localizedList = list;
  const contractTypeMappings = {
    nda: $_('contract.types.nda'),
    'confidentiality-agreement': $_('contract.types.confidentiality-agreement'),
    'employment-agreement': $_('contract.types.employment-agreement'),
    'contractor-consultant-agreement': $_('contract.types.contractor-consultant-agreement'),
    'consulting-agreement': $_('contract.types.consulting-agreement'),
    'customer-agreement': $_('contract.types.customer-agreement'),
    'joint-venture-agreement': $_('contract.types.joint-venture-agreement'),
    'master-software-agreement': $_('contract.types.master-software-agreement'),
    'master-software-service-agreement': $_('contract.types.master-software-service-agreement'),
    'licensing-agreement': $_('contract.types.licensing-agreement'),
    'vendor-agreement': $_('contract.types.vendor-agreement'),
    'ip-agreement': $_('contract.types.ip-agreement'),
    'material-transfer-agreement': $_('contract.types.material-transfer-agreement'),
    'end-user-licensing-agreement': $_('contract.types.end-user-licensing-agreement'),
    'research-agreement': $_('contract.types.research-agreement'),
    'research-and-development-agreement': $_('contract.types.research-and-development-agreement'),
    'patent-assignment': $_('contract.types.patent-assignment'),
    'industrial-design-assignment': $_('contract.types.industrial-design-assignment'),
    'trademark-assignment': $_('contract.types.trademark-assignment'),
    'copyright-assignment': $_('contract.types.copyright-assignment'),
    'shareholder-agreement': $_('contract.types.shareholder-agreement'),
    'stock-distribution-agreement': $_('contract.types.stock-distribution-agreement'),
    'investment-agreement': $_('contract.types.investment-agreement'),
    'other-agreement': $_('contract.types.other-agreement'),
  };

  const contractTypes = extractValues(contractTypeMappings);
  const contractStatusMappings = {
    drafting: $_('contract.states.drafting'),
    'awaiting-review': $_('contract.states.awaiting-review'),
    'awaiting-signature': $_('contract.states.awaiting-signature'),
    executed: $_('contract.states.executed'),
    inactive: $_('contract.states.inactive'),
    renewed: $_('contract.states.renewed'),
  };

  const contractStatuses = extractValues(contractStatusMappings);
  function lookupValue(mappings, key) {
    return mappings[key];
  }

  // coloumn headers for the grid. See DataTable for more information.
  const columnDefs = [
    { field: 'id', headerName: '', hide: true, suppressColumnsToolPanel: true },
    ...(rowActions.some((rowAction) => rowAction.type === 'button')
      ? [
          {
            field: 'buttons',
            headerName: '',
            pinned: 'left',
            resizable: false,
            sortable: false,
            maxWidth: 40,
            editable: false,
            cellClass: 'table-actions pa-0 text-center',
            cellStyle: { cursor: 'pointer' },
            cellRenderer: cellRendererFactory((cell) => {
              new DataTableRowActions({
                target: cell.eGui,
              });
            }),
            suppressColumnsToolPanel: true,
          },
        ]
      : []),
    ...(rowSelectable
      ? [
          {
            checkboxSelection: true,
            field: 'checkbox',
            pinned: 'left',
            resizable: false,
            sortable: false,
            maxWidth: 40,
            editable: false,
            cellStyle: { paddingLeft: '10px' },
            suppressColumnsToolPanel: true,
          },
        ]
      : []),
    {
      field: 'name',
      headerName: $_('contract.table.title'),
      cellRenderer: 'linkRenderer',
      sortable: true,
      filter: true,
      resizable: true,
      sort: 'asc',
    },
    {
      field: 'contractType',
      headerName: $_('contract.table.type'),
      sortable: true,
      filter: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: contractTypes },
      filterParams: {
        valueFormatter(params) {
          return lookupValue(contractTypeMappings, params.value);
        },
      },
      valueFormatter(params) {
        return lookupValue(contractTypeMappings, params.value);
      },
      resizable: true,
    },
    {
      field: 'contractStatus',
      headerName: $_('contract.table.status'),
      sortable: true,
      filter: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: contractStatuses },
      filterParams: {
        valueFormatter(params) {
          return lookupValue(contractStatusMappings, params.value);
        },
      },
      valueFormatter(params) {
        return lookupValue(contractStatusMappings, params.value);
      },
      resizable: true,
    },
    {
      field: 'nickName',
      headerName: $_('contract.table.nickName'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'lawFirmIdentifier',
      headerName: $_('contract.table.lawFirmId'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'effectiveDate',
      headerName: $_('contract.table.effective-date'),
      sortable: true,
      filter: true,
      resizable: true,
    },
    {
      field: 'expiryDate',
      headerName: $_('contract.table.expiry-date'),
      sortable: true,
      filter: true,
      resizable: true,
    },
  ];

  async function getItemsList(event) {
    if (tableName == 'contract-infamily') {
      list = group.items;
    } else if (tableName == 'contract-addtogroup') {
      list = [];
      const url = `/contract`;
      const response = await apiFetch(url);
      let res;
      if (response.ok) {
        res = await response.json();
        const ids = [];
        for (let i = 0; i < group.items.length; i++) {
          ids.push(group.items[i].id);
        }
        for (const re of res) {
          if (!ids.includes(re.id)) {
            list.push(re);
          }
        }
      }
    } else {
      const url = `/contract`;
      const response = await apiFetch(url);
      if (response.ok) {
        list = await response.json();
      }
    }
  }

  export function addNewRows(newItems, add = false) {
    if (add) {
      for (const newItem of newItems) {
        list.push(newItem);
      }
    }
    return dataTable.addNewRows(newItems);
  }

  export function updateRow(itemId) {
    return dataTable.updateRow(itemId);
  }

  export function deleteRows(itemId) {
    const data = dataTable.deleteRows(itemId);
    for (let i = 0; i < list.length; i++) {
      if (list[i].id == itemId) {
        list.splice(i, 1);
      }
    }
    return data;
  }
</script>

{#await getItemsList()}
  <ProgressLinear indeterminate color="secondary" />
{:then}
  <DataTable
    bind:this={dataTable}
    bind:rowData={list}
    {columnDefs}
    {rowActions}
    {globalActions}
    {linkActions}
    {tableName}
    {rowSelectable}
    bind:currentItem
    on:viewButtonClicked
    on:editButtonClicked
    on:deleteButtonClicked
    on:removeFromGroupClicked
    on:moveFromGroupClicked
    on:rowSelected
  />
{/await}

<style>
</style>
