<script>
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import { Breadcrumbs } from 'svelte-materialify';
  import { apiFetch } from '../services/network';
  import { _ } from '../services/i18n';

  export let guidanceSection;
  export let guidanceId = '';

  const dispatch = createEventDispatcher();
  let guidance;
  let markup = '';
  let breadCrumbItems = [];

  $: if (guidanceId.length > 0) {
    load();
  }

  onMount(() => {});

  onDestroy(() => {});

  async function load() {
    const url = `/guidance/${guidanceSection}/${guidanceId}`;
    const response = await apiFetch(url);
    if (response.ok) {
      guidance = await response.json();
      markup = guidance.markup;
      const localizedSection = $_(`guidance.${guidance.section}.title`);
      breadCrumbItems = [
        { text: $_('guidance.title'), href: '/guidance' },
        { text: `${localizedSection} - ${guidance.title}` },
      ];
    } else {
      markup = `<div>NOT FOUND - ${url}</div>`;
    }
  }
</script>

<Breadcrumbs large bind:items={breadCrumbItems} />

<div class="guidance-item">
  {@html markup}
  <!-- <div>
        <span class="pl-2 d-block secondary-color white-text">
            {$_(`guidance.guidance-item-page.articles`)}
        </span>
    </div>
    <div class="pl-2">
        Put article links here...
    </div>

    <div class="pt-2">
        <span class="pl-2 d-block secondary-color white-text">
            {$_(`guidance.guidance-item-page.videos`)}
        </span>
    </div>

    <div class="pl-2">
        Put video links here...
    </div> -->
</div>

<style>
</style>
