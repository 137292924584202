<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { Checkbox, Icon, Row } from 'svelte-materialify';
  import { mdiFolderMultipleOutline, mdiFolderOpenOutline, mdiFolderOutline } from '@mdi/js';
  import { apiFetch, selectedClientContact } from '../../services/network';
  import { _ } from '../../services/i18n';
  import { hasPermissions } from '../../services/helpers/permissions';
  import { accountInfo } from '../../services/account';

  export let level;
  export let fileSystemNodeToMove;
  export let rootNode;
  export let folderNode;
  let mySelectionChangedElement;
  let isOpen = false;
  let sortedListing = [];
  const horizontalIndent = 12 * level;
  let currentFolder = false;
  let checked = false;

  const dispatch = createEventDispatcher();

  onMount(async () => {
    // horizontalIndent = 12 * level;
    await load();
    if (fileSystemNodeToMove.parentNodeId === folderNode.id) {
      currentFolder = true;
    }
  });

  function resolveFileSystemNodeName(fileSystemNode) {
    if (fileSystemNode.name && fileSystemNode.name.length > 0) {
      return fileSystemNode.name;
    }
    return $_(`file-system.${fileSystemNode.symbolName}.title`);
  }

  async function load() {
    const url = `/api/file-system/folder/${folderNode.id}`;
    const response = await apiFetch(url);
    if (response.ok) {
      const listing = await response.json();
      sortedListing = listing.filter((node) => node.mimeType === 'inode/directory');
      sortedListing = sortedListing.map((node) => {
        node.name = resolveFileSystemNodeName(node);
        return node;
      });
      sortedListing.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
    }
  }

  function checkboxChanged(event) {
    let eventName;
    eventName = checked ? 'folderSelected' : 'folderDeselected';
    const elements = document.querySelectorAll('.folder-selection-changed');
    const customEvent = new CustomEvent(eventName, { detail: { node: folderNode } });
    for (const element of elements) {
      element.dispatchEvent(customEvent);
    }
  }

  function folderSelected(event) {
    const { node, parentNode } = event.detail;
    if (node !== folderNode && checked) {
      checked = false;
    }
    if (folderNode === rootNode) {
      dispatch('folderSelected', { node, parentNode });
    }
  }

  function folderDeselected(event) {
    const { node } = event.detail;
    if (node === folderNode && checked) {
      checked = false;
    }
    if (folderNode === rootNode) {
      dispatch('folderDeselected', { node });
    }
  }

  function folderClicked() {
    isOpen = !isOpen;
  }
</script>

<Row>
  <Checkbox
    bind:checked
    disabled={currentFolder ||
      !hasPermissions(
        $selectedClientContact?.userProfile?.tenant?.id || $accountInfo.tenant.id,
        'update',
        'data_room',
        rootNode.id
      )}
    color="secondary"
    on:change={checkboxChanged}
  />
  <span
    bind:this={mySelectionChangedElement}
    class="folder-selection-changed"
    on:folderSelected={folderSelected}
    on:folderDeselected={folderDeselected}
  />
  <span style={`padding-left: ${horizontalIndent}px;`} on:click={folderClicked}>
    {#if level === 0}
      <Icon path={mdiFolderMultipleOutline} />
    {:else if isOpen}
      <Icon path={mdiFolderOpenOutline} />
    {:else}
      <Icon path={mdiFolderOutline} />
    {/if}
    {resolveFileSystemNodeName(folderNode)}
  </span>
</Row>

{#if isOpen}
  {#each sortedListing as folder}
    <svelte:self level={level + 1} {fileSystemNodeToMove} {rootNode} folderNode={folder} />
  {/each}
{/if}

<style>
</style>
