import { ipScopes, ipTypes } from './i18n';

let splitPath;

export function getIPTypeFromPath(path) {
  splitPath = path.split('/');
  if (splitPath.length > 1 && ipTypes.includes(splitPath[1])) {
    return splitPath[1];
  }
  return null;
}

export function getIPScopeFromPath(path) {
  splitPath = path.split('/');
  if (splitPath.length > 2 && ipScopes.includes(splitPath[2])) {
    return splitPath[2];
  }
  return null;
}

export function getIPScopeIdFromPath(path) {
  splitPath = path.split('/');
  if (splitPath.length > 3 && getIPTypeFromPath(path) && getIPScopeFromPath(path)) {
    return splitPath[3];
  }
  return null;
}
