<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import {
    Button,
    Card,
    CardText,
    CardTitle,
    Checkbox,
    Container,
    Dialog,
    Divider,
    Overlay,
    ProgressCircular,
  } from 'svelte-materialify';
  import { apiFetch } from '../../services/network';
  import { _ } from '../../services/i18n';

  import SelectFolder from './SelectFolder.svelte';

  export let fileSystemGroupName;
  export let fileSystemNodeToMove;
  let listing = [];
  let nodeType;
  let nodeName = '';
  let formElement;
  let destinationFolder = null;
  let errorString = null;
  let copyAndMove = false;
  let disableMoveButton = true;
  let moveInProgress = false;

  const dispatch = createEventDispatcher();

  onMount(async () => {
    await load();
    if (fileSystemNodeToMove.name && fileSystemNodeToMove.name.length > 0) {
      nodeName = fileSystemNodeToMove.name;
    }
    if (fileSystemNodeToMove.mimeType === 'inode/directory') {
      nodeType = $_('files.move-to-folder-dialog.folder');
      if (nodeName.length === 0) {
        nodeName = $_(`file-system.${fileSystemNodeToMove.symbolName}.title`);
      }
    } else {
      nodeType = $_('files.move-to-folder-dialog.file');
    }
  });

  async function load() {
    const url = `/api/file-system?fileSystemGroupName=${fileSystemGroupName}&exclude=write`;
    const response = await apiFetch(url);
    if (response.ok) {
      listing = await response.json();
    }
  }

  async function move(event) {
    let url;
    let moveFolder;
    if (fileSystemNodeToMove.mimeType === 'inode/directory') {
      url = `/api/file-system/folder/${fileSystemNodeToMove.id}/move`;
      moveFolder = true;
    } else {
      url = `/api/file-system/file/${fileSystemNodeToMove.id}/move`;
      moveFolder = false;
    }
    moveInProgress = true;
    const response = await apiFetch(url, 'PUT', {
      parentNodeId: destinationFolder.id,
      copyAndMove,
    });
    if (response.ok) {
      const updatedNode = await response.json();
      const targetFolderId = `#file-system-folder-${destinationFolder.id}`;
      const srcFolderId = `#file-system-folder-${fileSystemNodeToMove.parentNodeId}`;

      const srcElement = document.querySelector(srcFolderId);
      if (srcElement) {
        srcElement.dispatchEvent(
          new CustomEvent('node-moved-from-here', {
            detail: { fileSystemNode: updatedNode, copyAndMove },
          })
        );
      }
      const targetElement = document.querySelector(targetFolderId);
      if (targetElement) {
        targetElement.dispatchEvent(
          new CustomEvent('node-moved-here', {
            detail: { fileSystemNode: updatedNode, copyAndMove },
          })
        );
      }
      dispatch('success');
    } else if (response.status === 409) {
      errorString = moveFolder
        ? $_('files.move-to-folder-dialog.duplicate-folder')
        : $_('files.move-to-folder-dialog.duplicate-file');
    }
    moveInProgress = false;
  }

  function folderSelected(event) {
    const { node } = event.detail;
    destinationFolder = node;
    disableMoveButton = false;
  }

  function folderDeselected(event) {
    const { node } = event.detail;
    if (node === destinationFolder) {
      destinationFolder = null;
      disableMoveButton = true;
    }
  }
</script>

<Dialog persistent={true} active width={800}>
  <Card outlined={true} raised={true}>
    <CardTitle>
      <h6 class="secondary-text">
        {$_('files.move-to-folder-dialog.title', { values: { nodeType, nodeName } })}
      </h6>
    </CardTitle>
    <CardText>
      <Divider class="secondary-color" />
      <form bind:this={formElement} class="pt-4">
        <div class="text-subtitle-1">
          {$_('files.move-to-folder-dialog.select-destination-folder')}
        </div>
        <Container>
          {#each listing as fileSystem}
            <SelectFolder
              level={0}
              {fileSystemNodeToMove}
              rootNode={fileSystem}
              folderNode={fileSystem}
              on:folderSelected={folderSelected}
              on:folderDeselected={folderDeselected}
            />
          {/each}
          {#if errorString !== null}
            <div class="error-text">{errorString}</div>
          {/if}
        </Container>
        {#if fileSystemNodeToMove.mimeType !== 'inode/directory'}
          <Divider class="mt-2 mb-2 secondary-color" />
          <div class="text-subtitle-1">
            {$_('files.move-to-folder-dialog.options')}
          </div>
          <Checkbox bind:checked={copyAndMove} color="secondary"
            >{$_('files.move-to-folder-dialog.keep-copy')}</Checkbox
          >
        {/if}
        <Divider class="mt-4 secondary-color" />
        <Button
          type="button"
          class="mt-4 {disableMoveButton ? '' : 'secondary-color'}"
          text
          outlined={disableMoveButton}
          disabled={disableMoveButton}
          on:click={move}
        >
          {$_('files.move-to-folder-dialog.move')}
        </Button>
        <Button class="mt-4 ml-2" on:click={() => dispatch('cancel')} text>
          {$_('files.move-to-folder-dialog.cancel')}
        </Button>
      </form>
    </CardText>
  </Card>
  <Overlay absolute active={moveInProgress}>
    <div class="d-flex justify-center">
      <div>
        <ProgressCircular color="white" indeterminate size={128} />
      </div>
    </div>
    <div class="d-flex justify-center" style="max-width: 80vw">
      <div class="white-text font-weight-bold text-center">
        {$_('files.move-to-folder-dialog.moving-in-progress')}
      </div>
    </div>
  </Overlay>
</Dialog>
